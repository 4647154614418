<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div
      class="selected"
      :class="{ open: open, disabled: disabled }"
      @click="openSelect"
      :style="[background_, height_, underline_]"
    >
      {{ selectedLabel }}
      <i class="mdi mdi-chevron-down arrow" :class="{ up: open }"></i>
    </div>
    <div
      class="items"
      :class="{ selectHide: !open }"
      :style="[background_, maxHeight_]"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        :class="option.value == selectedValue ? 'currently-selected' : ''"
        @click="select(option)"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectComponent",
  props: {
    options: {
      type: Array,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "rgba(29, 29, 29, 1)"
    },
    height: {
      type: String,
      required: false,
      default: "36px"
    },
    maxHeight: {
      type: String,
      required: false,
      default: "198px"
    }
  },
  methods: {
    select: function(option) {
      if (this.open == true) {
        this.selectedValue = option.value;
        this.selectedLabel = option.label;
        this.open = false;
        this.$emit("input", option.value);
      }
    },
    openSelect: function() {
      if (this.disabled == false) {
        this.open = !this.open;
      }
    }
  },
  computed: {
    background_() {
      return {
        "--background-color": this.backgroundColor
      };
    },
    height_() {
      return {
        height: this.height
      };
    },
    maxHeight_() {
      return {
        "--max-height": this.maxHeight
      };
    },
    underline_() {
      return {
        "--background-size": this.open == true ? "95% 1px" : "0% 1px"
      };
    }
  },
  data() {
    return {
      selectedValue: this.default
        ? this.default.value
        : this.options.length > 0
        ? this.options[0].value
        : null,
      selectedLabel: this.default
        ? this.default.label
        : this.options.length > 0
        ? this.options[0].label
        : null,
      open: false
    };
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 36px;
  line-height: 36px;
  transition: all 500ms;
  font-size: 13.3px;
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

.custom-select .selected {
  background-color: var(--background-color);
  border-radius: 0px;
  border: none;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  z-index: 1;
  position: relative;
  background-image: linear-gradient(
    90deg,
    rgb(177, 115, 247),
    rgb(210, 115, 247)
  );
  background-size: var(--background-size);
  background-repeat: no-repeat;
  transition: background-size 0.3s;
  background-position: 50% calc(100%);
}

.custom-select .selected:hover {
  background-size: 95% 1px;
  transition: background-size 0.3s;
}

.custom-select .selected.open {
  border-radius: 6px 6px 0px 0px;
}

.custom-select .disabled {
  color: rgb(180, 180, 180);
}

.arrow {
  font-size: 18px;
  transition: all 0.3s ease;
  &.up {
    transform: rotate(-180deg);
    transition: all 0.3s ease;
  }
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 0px 0px;
  overflow: hidden;
  border: none;
  position: absolute;
  background-color: var(--background-color);
  left: 0;
  right: 0;
  z-index: 3;
  visibility: visible;
  transition: all 0.3s ease;
  max-height: var(--max-height);
  overflow-y: scroll;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.custom-select .items div {
  color: #fff;
  padding-left: 10px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  margin: 3px 5px;
  transition: padding 300ms ease, color 300ms ease;
}

.custom-select .items div:hover:not(.currently-selected) {
  background-color: rgba(177, 115, 247, 0.05);
  color: rgb(177, 115, 247);
  padding-left: 13px;
  transition: padding 300ms ease, color 300ms ease;
}

.currently-selected {
  background-color: rgba(177, 115, 247, 0.05);
  color: rgb(177, 115, 247) !important;
  z-index: 0;
}

.selectHide {
  visibility: hidden !important;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 0 !important;
  transform: translate(0, -5%);
}

::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: var(--app-components-background-color);
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  background: #313131;
}
</style>
