<template>
  <div class="terms">
    <h1><big>Terms of Use</big></h1>
    <p><br /></p>
    <h1>Terms and Conditions</h1>
    <p>
      Stockgrid.io and its members, officers, directors, owners, employees,
      agents, representatives, suppliers and service providers (collectively
      Stockgrid.io) provides this website (the “Site”) for informational
      purposes only. Use of and access to the Site and the information,
      materials, services, and other content available on or through the Site
      (“Content”) are subject to these terms of use and all applicable laws.
    </p>
    <br /><br />
    <h1>Refund Policy</h1>
    <p>
      Since services are non­-tangible, irrevocable, digital goods; we do not
      issue refunds, which you are solely responsible for understanding. During
      checkout, you will be redirected to Stripe to complete the payment. All
      sales are final. You cannot cancel a purchase or receive a refund for a
      purchase. You can cancel future recurring subscription payments at anytime
      by clicking <i>Manage</i>
      in your account profile page.
    </p>
    <br /><br />
    <h1>No Investment Advice</h1>
    <p>
      The Content is for informational purposes only, you should not construe
      any such information or other material as legal, tax, investment,
      financial, or other advice. Nothing contained on our Site constitutes a
      solicitation, recommendation, endorsement, or offer by Stockgrid.io or any
      third party service provider to buy or sell any securities or other
      financial instruments in this or in in any other jurisdiction in which
      such solicitation or offer would be unlawful under the securities laws of
      such jurisdiction. <br />All Content on this site is information of a
      general nature and does not address the circumstances of any particular
      individual or entity. Nothing in the Site constitutes professional and/or
      financial advice, nor does any information on the Site constitute a
      comprehensive or complete statement of the matters discussed or the law
      relating thereto. Stockgrid.io is not a fiduciary by virtue of any
      person’s use of or access to the Site or Content. You alone assume the
      sole responsibility of evaluating the merits and risks associated with the
      use of any information or other Content on the Site before making any
      decisions based on such information or other Content. In exchange for
      using the Site, you agree not to hold Stockgrid.io, its affiliates or any
      third party service provider liable for any possible claim for damages
      arising from any decision you make based on information or other Content
      made available to you through the Site.
    </p>
    <br /><br />
    <h1>Investment Risks</h1>
    <p>
      There are risks associated with investing in securities. Investing in
      stocks, bonds, exchange traded funds, mutual funds, and money market funds
      involve risk of loss. Loss of principal is possible. Some high risk
      investments may use leverage, which will accentuate gains & losses.
      Foreign investing involves special risks, including a greater volatility
      and political, economic and currency risks and differences in accounting
      methods. A security’s or a firm’s past investment performance is not a
      guarantee or predictor of future investment performance.
    </p>
    <br /><br />
    <h1>Site and content not warranted</h1>
    <p>
      THE SITE AND CONTENT, ARE PROVIDED “AS IS” AND WITHOUT WARRANTIES OF ANY
      KIND. YOU BEAR ALL RISKS ASSOCIATED WITH THE USE OF THE SITE AND CONTENT,
      INCLUDING WITHOUT LIMITATION, ANY RELIANCE ON THE ACCURACY, COMPLETENESS
      OR USEFULNESS OF ANY CONTENT AVAILABLE ON THE SITE. Stockgrid.io AND ITS
      EMPLOYEES, OFFICERS, DIRECTORS, , PARTNERS, AGENTS, REPRESENTATIVES,
      SUPPLIERS AND SERVICE PROVIDERS, DISCLAIM ALL WARRANTIES, EXPRESS OR
      IMPLIED, INCLUDING, WITHOUT LIMITATION, ALL WARRANTIES OF TITLE,
      NON-INFRINGEMENT, ACCURACY, COMPLETENESS, USEFULNESS, MERCHANTABILITY, AND
      FITNESS FOR A PARTICULAR USE, AND WARRANTIES THAT MAY ARISE FROM COURSE OF
      DEALING/PERFORMANCE OR USAGE OF TRADE.
    </p>
    <br /><br />
    <h1>
      Limitation of Liability
    </h1>
    <p>
      YOUR EXCLUSIVE REMEDY FOR DISSATISFACTION WITH THE SITE AND CONTENT IS TO
      STOP USING THE SITE AND CONTENT. Stockgrid.io IS NOT LIABLE FOR ANY
      DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES,
      UNDER ANY THEORY OF LIABILITY, INCLUDING WITHOUT LIMITATION, DAMAGES FOR
      LOSS OF PROFITS, USE, DATA, OR LOSS OF OTHER INTANGIBLES. IN PARTICULAR,
      AND WITHOUT LIMITATION, Stockgrid.io WILL NOT BE LIABLE FOR DAMAGES OF ANY
      KIND RESULTING FROM YOUR USE OF OR INABILITY TO USE THE SITE OR CONTENT.
      While we try to maintain the integrity and security of the Site and the
      servers from which the Site is operated, we do not guarantee that the Site
      or Content is or remains secure, complete or correct, or that access to
      the Site or Content will be uninterrupted or error free. The Site and
      Content may include inaccuracies, errors and materials that violate or
      conflict with these Terms. Additionally, third parties may make
      unauthorized alterations to the Site or Content. If you become aware of
      any unauthorized third party alteration to the Site or Content, contact us
      at info@stockgrid.io with a description of the material(s) at issue and
      the URL.
    </p>
    <br /><br />
    <h1>Disclaimer</h1>
    <p>
      The data, graphics, images and information available on this website are
      for informational purposes only and not for the purposes of providing
      professional investing or trading advice. None of the content on the site
      is to be taken as a recommendation to enter in any transaction. You should
      use the information available for your own analysis or contact an
      investment or trading professional.
    </p>
  </div>
</template>

<script>
export default {
  name: "Terms",
  data() {
    return {};
  },
  head: {
    title: {
      inner: "Terms And Conditions"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content: "Terms and conditions for Stockgrid.io."
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
.terms {
  width: 70%;
  background-color: var(--app-components-background-color);
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
  color: #e7e7e7;
  box-sizing: border-box;
  padding: 50px;
  text-align: left;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Nunito";
  text-align: justify;
  overflow-wrap: anywhere;
}

.terms h1 {
  font-size: 25px;
}

@media only screen and (max-width: 1250px) {
  .terms {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .terms {
    width: 95%;
  }
}
</style>
