var numeral = require("numeral");

export default {
  methods: {
    decimal_0: function(value) {
      return numeral(value).format("0.");
    },
    decimal_1: function(value) {
      return numeral(value).format("0.0");
    },
    decimal_2: function(value) {
      return numeral(value).format("0.00");
    },
    thousands: value => numeral(value).format("0,0"),
    decimal0: value => numeral(value).format("0."),
    decimal1: value => numeral(value).format("0.0"),
    decimal2: value => numeral(value).format("0.00"),
    decimal4: value => numeral(value).format("0.0000")
  }
};
