<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { eventBus } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "ClustersDailyChart",
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "35",
          right: "140",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1]
            },
            // magicType: {
            //   type: ["line"]
            // },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        title: {
          text: "Clusters Daily Returns",
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          formatter: function(params) {
            //console.log(params[0]);
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            return (
              params[0].data[0] +
              "<br/>" +
              colorSpan(params[0].color) +
              "Return:&nbsp;" +
              params[0].data[1] +
              "<br/>" +
              colorSpan(params[0].color) +
              "Cluster:&nbsp;" +
              params[0].data[2]
            );
          },
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: {
          type: "value",
          name: "Returns",
          show: true,
          nameTextStyle: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        series: [
          {
            data: this.clustersDailyData,
            name: "Daily Return",
            type: "scatter",
            itemStyle: {
              color: "rgba(103, 34, 148, 1)",
              borderColor: "rgba(0, 0, 0, 0.7)"
            },
            dimensions: ["Dates", "Return", "Cluster"],
            encode: {
              tooltip: ["Return", "Cluster"],
              seriesName: [1, 2]
            }
          }
        ]
      };
      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "20",
          right: "80",
          containLabel: true
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }
    },
    setOptions: function() {
      var pieces = [];
      this.clustersDailyColors.forEach(function(element) {
        if (window.innerWidth > 850) {
          pieces.push({
            value: element[0],
            label: "Cluster " + String(element[0]),
            color: element[1]
          });
        } else {
          pieces.push({
            value: element[0],
            label: String(element[0]),
            color: element[1]
          });
        }
      });

      this.options["visualMap"] = {
        type: "piecewise",
        dimension: 2,
        top: 100,
        right: 10,
        show: true,
        textStyle: {
          color: "rgba(255, 255, 255, 1)"
        },
        //orient: "horizontal",
        pieces: []
      };

      this.options["visualMap"]["pieces"] = pieces;
      this.$refs.priceChart.setOption(this.options);
    }
  },
  computed: {
    ...mapGetters({
      clustersDailyData: "getClustersDailyData",
      clustersDailyColors: "getClustersDailyColors"
    })
  },
  watch: {
    clustersDailyData: function() {
      this.setChart();
    },
    clustersDailyColors: function() {
      this.setOptions();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
      this.setOptions();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
