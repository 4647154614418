<template>
  <div
    class="navigation-container"
    v-on:keyup.esc="toggleForms(false, false)"
    :tabindex="0"
  >
    <div id="regular-nav" class="nav-container">
      <img src="../assets/logo/logo_white2.svg" alt="" class="logo-img" />
      <div class="nav-controls-container">
        <div class="registration-group">
          <router-link
            to="/subscriptioncards"
            v-if="
              isAuthenticated &&
                (user.subscription == 'Free' || user.subscription == 'free')
            "
            :key="user"
            class="registration-button"
            tag="button"
            >Subscribe</router-link
          >
          <router-link
            to="/subscriptioncards"
            v-if="!isAuthenticated"
            class="registration-button"
            tag="button"
            >Sign Up</router-link
          >
          <button
            v-if="!isAuthenticated"
            class="registration-button"
            @click="toggleForms(false, true)"
          >
            Login
          </button>
        </div>
        <div class="account-group" v-if="isAuthenticated">
          <div class="account-group-header">
            <i class="mdi mdi-account large"></i>
          </div>
          <div class="account-group-items">
            <router-link tag="div" class="account-group-item" to="/profile">
              <span class="nav-icon">
                <i class="mdi mdi-account"></i>
              </span>
              <span class="nav-text">
                Profile
              </span>
            </router-link>
            <div class="account-group-item" @click="submitLogout()">
              <span class="nav-icon">
                <i class="mdi mdi-logout-variant"></i>
              </span>
              <span class="nav-text">
                Logout
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-navbar id="mobile-nav">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="../assets/logo/logo_white2.svg" alt="" class="logo-img" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-dropdown
          label="Options Flow"
          v-if="
            isAuthenticated &&
              (user.subscription == 'basic' || user.subscription == 'Basic')
          "
        >
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/optionsflow-dashboard' }"
          >
            Options Dashboard
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/optionsflow-ticker-overview' }"
          >
            Ticker Overview
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/optionsflow' }">
            Live / Historical
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/optionsflowcumulativestats' }"
          >
            Cumulative Stats
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/optionsflowtoppremiums' }"
          >
            Top Premiums
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown label="Clusters">
          <b-navbar-item tag="router-link" :to="{ path: '/clusters' }">
            Single Stock
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/clusterstable' }">
            All
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" :to="{ path: '/spytovix' }">
          SPY To VIX
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/darkpools' }">
          Dark Pools
        </b-navbar-item>

        <b-navbar-item tag="router-link" :to="{ path: '/optionwalls' }">
          Option Walls
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/SectorRotation' }">
          Sector Rotation
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/SectorPerformance' }">
          Sector Performance
        </b-navbar-item>
        <!-- <b-navbar-item tag="router-link" :to="{ path: '/shortinterest' }">
          Short Data
        </b-navbar-item> -->
        <b-navbar-item tag="router-link" :to="{ path: '/arima' }">
          ARIMA
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/sp500stats' }">
          S&P 500 Statistics
        </b-navbar-item>
        <b-navbar-dropdown label="Factor Analysis">
          <b-navbar-item tag="router-link" :to="{ path: '/factor-analysis' }">
            Single Stock
          </b-navbar-item>
          <b-navbar-item
            tag="router-link"
            :to="{ path: '/factor-analysis-table' }"
          >
            All
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <router-link
              to="/subscriptioncards"
              v-if="
                isAuthenticated &&
                  (user.subscription == 'Free' || user.subscription == 'free')
              "
              :key="user"
              class="registration-button"
              tag="button"
              >Subscribe</router-link
            >
            <router-link
              to="/subscriptioncards"
              v-if="!isAuthenticated"
              class="registration-button"
              tag="button"
              >Sign Up</router-link
            >
            <button
              v-if="!isAuthenticated"
              class="registration-button"
              @click="toggleForms(false, true)"
            >
              Log in
            </button>
            <button
              v-if="isAuthenticated"
              class="registration-button"
              @click="submitLogout"
            >
              Logout
            </button>
            <router-link
              to="/profile"
              v-if="isAuthenticated"
              class="registration-button"
              tag="button"
              >Profile</router-link
            >
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
    <SignUp></SignUp>
    <Login></Login>
    <ResendConfirmation></ResendConfirmation>
    <ResetPassword></ResetPassword>
    <UpdatePassword></UpdatePassword>
  </div>
</template>

<script>
import SignUp from "@/components/SignUp.vue";
import Login from "@/components/Login.vue";
import ResendConfirmation from "@/components/ResendConfirmation.vue";
import ResetPassword from "@/components/ResetPassword.vue";
import UpdatePassword from "@/components/UpdatePassword.vue";
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  data: () => ({
    active: "clusters"
  }),
  components: {
    SignUp,
    Login,
    ResendConfirmation,
    ResetPassword,
    UpdatePassword
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "getIsAuthenticated",
      user: "getUserInfo"
    })
  },
  methods: {
    toggleForms: function(f1, f2) {
      this.$store.commit("setShowSignup", f1);
      this.$store.commit("setShowLogin", f2);
    },
    submitLogout: function() {
      this.$store.dispatch({
        type: "submitLogout",
        username: this.username
      });
    },
    redirectToProfile: function() {
      this.$router.push({
        path: "/profile"
      });
    }
  },
  created() {
    this.active = this.$router.history.current.name;
  },
  updated() {
    this.active = this.$router.history.current.name;
  },
  mounted() {}
};
</script>

<style lang="scss">
.registration-button {
  display: inline-block;
  box-sizing: border-box;
  margin: 5px 8px;
  background-color: var(--darker-nav-color);
  padding: 8px 18px;
  font-family: var(--main-font);
  color: #fff !important;
  font-size: 15px;
  border: solid 0px;
  border-radius: 3px;
  width: 100px;
  height: 38px;
  &:hover {
    background-color: #bb153f;
    transition: all 0.1s ease, color 0s !important;
  }
}

.nav-container {
  background: linear-gradient(
    100deg,
    rgba(225, 36, 100, 1) 0%,
    rgba(233, 34, 84, 1) 25%,
    rgba(233, 34, 84, 1) 100%
  );
  height: 55px;
  &:focus {
    text-decoration: none !important;
    outline: none;
  }
  z-index: 999;
  min-height: 55px;
  display: flex;
  justify-content: space-between;
  min-width: 320px;
  .logo-img {
    margin-left: 7px;
    padding-bottom: 4px;
  }
}

.nav-controls-container {
  display: flex;
  flex-wrap: nowrap;
}

.nav-icon {
  background-color: rgb(37, 36, 36);
  padding: 0 4px;
  border-radius: 2px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  border: solid 0.5px rgb(26, 25, 25);
}

.nav-text {
  white-space: nowrap;
}

.logo-img {
  height: 55px;
  padding: 5px 0px 3px 5px;
}

/////////////////////////////////////////////////////////////////
//MOBILE
/////////////////////////////////////////////////////////////////

#mobile-nav {
  height: 55px;
  max-height: 55px;
  background-color: var(--amaranth) !important;
}

.navbar-brand {
  height: 55px;
  max-height: 55px;
}

.navbar-item.has-dropdown:hover {
  background-color: #cf1a48 !important;
}

.navbar-link {
  color: #ffffff;
  padding-left: 42px;
  &:focus {
    background-color: #cf1a48 !important;
    color: #ffffff;
  }
}
.navbar-link:hover {
  color: #ffffff !important;
  background-color: #cf1a48;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: #ffffff !important;
}

.navbar-end .navbar-item:hover {
  background-color: var(--amaranth) !important;
}

.navbar-dropdown {
  padding: 0;
}

.navbar-menu,
.navbar-brand,
.navbar-start {
  background-color: var(--amaranth);
}

.navbar-item:hover {
  background-color: var(--darker-nav-color) !important;
  color: #ffffff !important;
}

.navbar-item,
.navbar-burger {
  color: #ffffff;
}

.navbar-burger:hover {
  color: #ffffff !important;
}

.navbar-item img {
  max-height: 2.92rem;
  padding: 0;
}

/////////////////////////////////////////////////////////////////
//END MOBILE
/////////////////////////////////////////////////////////////////

.nav-container .mdi:before {
  font-size: 23px;
}

.nav-container i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.nav-container .large {
  height: 55px;
}

.navigation-container:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.registration-group {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  margin-right: 15px;
}

.account-group {
  height: 55px;
  width: 80px;
  margin-right: 25px;
  transition: all 0.25s ease;
  & .account-group-items {
    visibility: hidden;
    opacity: 0;
    transform: translate(0, -5%);
    transition: all 0.25s ease;
  }
  &:hover .account-group-items {
    visibility: visible;
    opacity: 1;
    transform: translate(0, 5%);
    transition: all 0.25s ease;
  }
}

.account-group-header {
  cursor: pointer;
  min-height: 55px;
  transition: all 0.25s ease;
  &:hover {
    background-color: var(--darker-nav-color);
  }
}

.account-group-items {
  position: absolute;
  margin-top: 5px;
  margin-left: -30px;
  border-bottom: solid 2px var(--amaranth);
  border-radius: 0 0 4px 4px;
  transition: all 0.25s ease;
  height: 112px;
  &::after {
    content: "";
    left: 0px;
    top: -5px;
    background: transparent;
    width: 100%;
    height: 5px;
    position: absolute;
    z-index: -1;
  }
}

.account-group-item {
  cursor: pointer;
  transition: all 0.25s ease;
  background-color: var(--app-components-background-color);
  border: none;
  width: 125px;
  height: 55px;
  color: #ffffff;
  padding: 0 15px;
  font-weight: 200;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: var(--app-components-background-color-lighter);
  }
}

@media only screen and (max-width: 1200px) {
  .vs-navbar__item,
  .vs-navbar__group,
  .vs-navbar__group__item {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 1000px) {
  #regular-nav {
    display: none;
  }
}

@media only screen and (min-width: 1001px) {
  #mobile-nav {
    display: none;
  }
}
</style>
