<template>
  <div class="sector-performance">
    <h1 class="page-title">Sector Performance</h1>
    <div class="price-chart-container">
      <SectorPerformanceLineChart
        :data="sectorPerformanceData['line_data']"
      ></SectorPerformanceLineChart>
    </div>
    <div class="price-chart-container">
      <SectorPerformanceHeatmapChart
        :data="sectorPerformanceData['heatmap_data']"
        :title="'Performance Heatmap'"
        :max="10"
        :min="-10"
      ></SectorPerformanceHeatmapChart>
    </div>
    <div class="price-chart-container">
      <SectorPerformanceHeatmapChart
        :data="sectorPerformanceData['seasonality_data']"
        :title="'Seasonality Heatmap'"
        :max="2"
        :min="-2"
      ></SectorPerformanceHeatmapChart>
    </div>

    <div class="price-chart-container">
      <SectorPerformanceBarChart
        :data="sectorPerformanceData['bar_data']['chg_1w']"
        :title="'1 Week Performance'"
      ></SectorPerformanceBarChart>
    </div>
    <div class="price-chart-container">
      <SectorPerformanceBarChart
        :data="sectorPerformanceData['bar_data']['chg_1m']"
        :title="'1 Month Performance'"
      ></SectorPerformanceBarChart>
    </div>
    <div class="price-chart-container">
      <SectorPerformanceBarChart
        :data="sectorPerformanceData['bar_data']['chg_ytd']"
        :title="'Year-to-date Performance'"
      ></SectorPerformanceBarChart>
    </div>
    <div class="price-chart-container">
      <SectorPerformanceBarChart
        :data="sectorPerformanceData['bar_data']['chg_1y']"
        :title="'1 Year Performance'"
      ></SectorPerformanceBarChart>
    </div>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      Sector performances are those of the 11 SPDR Sector ETFs. Seasonality is
      based on data going back to 2004 except for Real Estate and Communication
      Services which only go back to 2015 and 2018 respectively.
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import SectorPerformanceHeatmapChart from "@/components/SectorPerformanceHeatmapChart.vue";
import SectorPerformanceBarChart from "@/components/SectorPerformanceBarChart.vue";
import SectorPerformanceLineChart from "@/components/SectorPerformanceLineChart.vue";
import { mapGetters } from "vuex";
export default {
  name: "SectorPerformance",
  data() {
    return {
      sectorPerformanceData: { heatmap_data: {}, bar_data: {}, line_data: {} }
    };
  },
  head: {
    title: {
      inner: "Sector Performance"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Visualize sector returns, performance and seasonality for the 11 main S&P sectors."
      }
    ]
  },
  methods: {
    getSectorPerformanceData: function() {}
  },
  components: {
    SectorPerformanceHeatmapChart,
    SectorPerformanceBarChart,
    SectorPerformanceLineChart
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      rawSectorPerformanceData: "getSectorPerformanceData"
    })
  },
  watch: {
    rawSectorPerformanceData: function() {
      this.sectorPerformanceData = this.rawSectorPerformanceData;
      console.log(this.sectorPerformanceData);
      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.$store.dispatch({
      type: "getSectorPerformanceData"
    });
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.sector-performance {
  margin-bottom: 70px;
}

.text-content,
.price-chart-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.page-title {
  margin-top: 60px;
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .price-chart-container {
    width: 80%;
    flex-wrap: wrap;
  }
  .price-chart-container {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .price-chart-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
}
</style>
