<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "SectorPerformanceBarChart",
  props: {
    data: Object,
    title: String
    // feature: String,
    // period: String,
    // minmax: Array
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "13%",
          bottom: "7%",
          left: "2%",
          right: "4%",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
            type: "shadow",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          },
          formatter: params => {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';

            var currentData = params.filter(element => element.data != "-")[0];

            return (
              currentData.name +
              "<br/>" +
              colorSpan(currentData.color.colorStops[0].color) +
              currentData.data +
              "&nbsp;" +
              "%"
            );
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "#2effc4",
              borderColor: "#2effc4"
            }
          }
        },
        xAxis: {
          type: "value",
          name: "Performance %",
          nameLocation: "middle",
          nameGap: 20,
          nameTextStyle: {
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "category",
            name: "",
            data: this.data.index,
            show: true,
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              fontSize: 16
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.data["positive"],
            type: "bar",
            barWidth: 25,
            barGap: "-100%",
            name: "",
            label: {
              verticalAlign: "middle",
              show: true,
              position: "outside",
              distance: 10,
              fontSize: 11,
              color: "rgba(255, 251, 251, 1)"
            },
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(110, 252, 183, 1)"
            },
            {
              offset: 0,
              color: "rgb(69, 247, 194)"


            }
          ]),
          // borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
          //   {
          //     offset: 1,
          //     color: "rgba(5, 218, 255, 1)"
          //   },
          //   {
          //     offset: 0,
          //     color: "rgba(10, 255, 223, 1)"
          //   }
          // ])

            }
          },
          {
            data: this.data["negative"],
            type: "bar",
            barWidth: 25,
            barGap: "-100%",
            name: "",
            label: {
              verticalAlign: "middle",
              show: true,
              position: "outside",
              distance: 10,
              fontSize: 11,
              color: "rgba(255, 251, 251, 1)"
            },
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgb(94, 159, 235)"
            },
            {
              offset: 0.999,
              color: "rgb(94, 159, 235)"


            }
          ]),
          // borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
          //   {
          //     offset: 1,
          //     color: "rgba(136, 77, 245, 1)"
          //   },
          //   {
          //     offset: 0,
          //     color: "rgba(217, 79, 255, 1)"
          //   }
          // ])
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "13%",
          bottom: "7%",
          left: "2%",
          right: "8%",
          containLabel: true
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["title"]["text"] = "Sector Performance";
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
