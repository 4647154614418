<template>
  <div class="ticker-info">
    <b-autocomplete
      class="ticker-info-select"
      :data="tickers"
      v-model="name"
      v-on:focus="clearName"
      v-on:input="searchTickers"
      v-on:blur="populateName"
      v-on:select="tickerSelected"
      placeholder="Enter name or ticker"
      spellcheck="false"
    >
    </b-autocomplete>
    <div class="grid-container">
      <div class="item-a">
        <div class="grid-item">
          <div class="price">
            <div>{{ tickerQuote.c }}</div>
            <div class="neutral" v-bind:class="checkReturns">
              {{ tickerQuote.todaysChange }}
            </div>
            <div v-bind:class="checkReturns">
              {{ tickerQuote.todaysChangePerc | formatReturns }}%
            </div>
          </div>
        </div>
        <!-- <div class="grid-item ellipsis">
          {{ tickerQuote.country }}
        </div> -->
        <div class="grid-item ellipsis">
          {{ sector }}
        </div>
        <div class="grid-item ellipsis">
          {{ industry }}
        </div>
      </div>
      <div class="item-b">
        <div class="grid-item">Volume</div>
        <div class="grid-item">Average Volume</div>
        <div class="grid-item">Day's Range</div>
        <div class="grid-item">52 Week's Range</div>
      </div>
      <div class="item-c">
        <div class="grid-item">
          {{ tickerQuote.v | formatNumber }}
        </div>
        <div class="grid-item">
          {{ tickerQuote.avg_volume | formatNumber }}
        </div>
        <div class="grid-item">{{ tickerQuote.l }} - {{ tickerQuote.h }}</div>
        <div class="grid-item">
          {{ tickerQuote["52_week_low"] }} -
          {{ tickerQuote["52_week_high"] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TickerInfo",
  data() {
    return {
      tickers: [],
      name: "",
      ticker: "",
      selected: "",
      tickerQuote: {},
      tickerQuote: {},
      sector: "",
      industry: ""
    };
  },
  props: {
    tickerOrigin: String
  },
  methods: {
    getTickerInfo(ticker) {
      axios
        .get("/get_ticker_info", {
          params: {
            ticker: ticker
          }
        })
        .then(res => {
          this.tickerQuote = res.data.quote;
          console.log(this.tickerQuote);
          if (this.tickerQuote.industry) {
            this.industry = this.tickerQuote.industry;
          } else {
            this.industry = "";
          }
          if (this.tickerQuote.sector) {
            this.sector = this.tickerQuote.sector;
          } else {
            this.sector = "";
          }
          if (this.tickerQuote.company) {
            this.name =
              String(this.ticker) + " - " + String(this.tickerQuote.company);

          }else {
            this.name = String(this.ticker);
          }
          this.selected = this.name;
        })
        .catch(err => {
          console.log(err);
        });
    },
    searchTickers: async function(value) {
      if (value != "" && value != this.selected) {
        await axios
          .get("/search_tickers", {
            params: {
              fragment: this.name
            }
          })
          .then(res => {
            this.tickers = [];
            if (res.data.tickers.length != 0) {
              res.data.tickers.forEach(value => {
                this.tickers.push(value[0] + " - " + value[1]);
              });
            } else {
              this.tickers.push("No result found");
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    clearName: function() {
      this.name = "";
    },
    populateName: function() {
      if (this.name == "") {
        this.name = this.selected;
      }
    },
    tickerSelected: function(value) {
      this.ticker = value.split(" ")[0];
      this.$emit("updateTickerData", this.ticker);
      this.$store.commit("setTicker", this.ticker);
      this.selected = value;
      this.tickers = [];
    }
  },
  computed: {
    checkReturns: function() {
      if (this.tickerQuote.todaysChange > 0) return "positive";
      else if (this.tickerQuote.todaysChange < 0) return "negative";
      else return "neutral";
    }
  },
  watch: {
    tickerOrigin: function() {
      this.ticker = this.tickerOrigin;
      this.$store.commit("setTicker", this.ticker);
    },
    ticker: function() {
      this.getTickerInfo(this.ticker);
    },
    tickerQuote: function() {}
  },
  mounted() {
    this.ticker = this.tickerOrigin;
    this.$store.commit("setTicker", this.ticker);
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Mono&display=swap");

.ticker-info {
  background-color: var(--app-components-background-color);
  color: #ffffff;
  min-width: 70%;
  width: max-content;
  max-width: 100%;
  font-family: var(--main-font);
}

.name {
  font-size: 23px;
  padding: 10px 10px 10px 10px;
  width: 100%;
  text-align: left;
}

.price {
  display: flex;
  flex-direction: row;
}

.price div {
  padding-right: 5px;
}

.neutral {
  color: var(--neutral-color);
}

.positive {
  color: var(--positive-color);
}

.negative {
  color: var(--negative-color);
}

.grid-container {
  padding: 0 15px 0 30px;
  display: grid;
  grid-template-columns: minmax(40%, 52%);
  grid-template-rows: 25% 25% 25% 25%;
  grid-auto-flow: column;
  grid-gap: 0 8px;
}

.grid-item {
  width: 100%;
  text-align: left;
  font-family: "DM Mono", monospace !important;
  white-space: nowrap;
}

.item-a {
  grid-column: 1 / span 1;
  grid-row: 1 / span 4;
}

.item-b {
  grid-column: 2 / span 1;
  grid-row: 1 / span 4;
}

.item-c {
  grid-column: 3 / span 1;
  grid-row: 1 / span 4;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 850px) {
  .ticker-info {
    width: 100%;
    padding-bottom: 15px;
  }
  .grid-container {
    grid-template-columns: 50% 50%;
    grid-template-rows: repeat(8, 1fr);
  }
  .item-a {
    grid-column: 1 / span 2;
    grid-row: 1 / span 4;
  }

  .item-b {
    grid-column: 1 / span 1;
    grid-row: 5 / span 4;
  }

  .item-c {
    grid-column: 2 / span 1;
    grid-row: 5 / span 4;
  }
}
</style>
