<template>
  <div class="options-flow-top-premiums">
    <h1 class="page-title">Options flow / Top Premiums</h1>
    <div class="table-controls-container">
      <div class="tag-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag"
          >Days:
        </b-tag>
        <SelectComponent
          v-model="lookback"
          class="select-component"
          :default="lookbacks[3]"
          :options="lookbacks"
        >
        </SelectComponent>
      </div>
    </div>
    <div class="charts-container">
      <OptionsFlowTopPremiumsChart></OptionsFlowTopPremiumsChart>
    </div>
    <div class="table-container">
      <b-table
        class="datatable top-premiums"
        :data="this.optionsFlowTopPremiumsData['by_ticker']"
        :striped="true"
        :hoverable="true"
        :mobile-cards="false"
        :per-page="20"
        :paginated="true"
        :default-sort="['net', 'desc']"
        sortable
        ref="table"
      >
        <b-table-column field="ticker" label="Ticker" sortable searchable>
          <template v-slot="props">
            {{ props.row.ticker }}
          </template>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
              ref="searchInput"
            />
          </template>
        </b-table-column>
        <b-table-column field="calls" label="Calls" sortable searchable>
          <template v-slot="props">
            {{ props.row.calls | formatThousands }}
          </template>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
              ref="searchInput"
            />
          </template>
        </b-table-column>
        <b-table-column field="puts" label="Puts" sortable searchable>
          <template v-slot="props">
            {{ props.row.puts | formatThousands }}
          </template>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
              ref="searchInput"
            />
          </template>
        </b-table-column>
        <b-table-column field="net" label="Net" sortable searchable>
          <template v-slot="props">
            {{ props.row.net | formatThousands }}
          </template>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
              ref="searchInput"
            />
          </template>
        </b-table-column>
        <b-table-column field="sector" label="Sector" sortable searchable>
          <template v-slot="props">
            {{ props.row.sector }}
          </template>
          <template #searchable="props">
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
              ref="searchInput"
            />
          </template>
        </b-table-column>
      </b-table>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      This page allows you to see the top tickers by total premiums inflow over
      different lookback periods. ETFs are classified as financials but are not
      part of the sector statistics, except for the 11 SPDR sector ETFs which
      follow the SPDR classification.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import OptionsFlowTopPremiumsChart from "@/components/OptionsFlowTopPremiumsChart.vue";
const SelectComponent = () => import("@/components/SelectComponent.vue");
export default {
  name: "OptionsFlowTopPremiums",
  data() {
    return {
      lookback: 20,
      lookbacks: [
        { value: "1", label: "1" },
        { value: "5", label: "5" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "40", label: "40" },
        { value: "60", label: "60" },
        { value: "100", label: "100" }
      ]
    };
  },
  components: {
    OptionsFlowTopPremiumsChart,
    SelectComponent
  },
  head: {
    title: {
      inner: "Top Premiums"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Get a list of the top stocks by large option trades total premiums."
      }
    ]
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      loginEvent: "getLoginEvent",
      alertMessage: "getAlertMessage",
      optionsFlowTopPremiumsData: "getOptionsFlowTopPremiumsData",
      user: "getUserInfo"
    })
  },
  methods: {
    init: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getOptionsFlowTopPremiumsData",
        lookback: this.lookback
      });
    }
  },
  watch: {
    optionsFlowTopPremiumsData: function() {
      this.$store.commit("setLoading", false);
    },
    lookback: function() {
      this.init();
    },
    user: function() {
      console.log("loginEvent", this.loginEvent, this.user);
      if (this.loginEvent == true) {
        if (this.user.subscription == "basic") {
          this.init();
        }
      }
    }
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "More info") {
        setTimeout(() => {
          this.$router.push({
            path: "/subscriptioncards"
          });
        }, 30);
      }
    });
    eventBus.$on("closingAlert", msg => {
      if (msg == "You need to subscribe to access this page.") {
        this.$store.commit("setLoading", false);
      }
    });
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.options-flow-top-premiums {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.page-title,
.charts-container,
.text-content,
.table-controls-container,
.table-container {
  width: 80%;
  margin: 0 auto;
}

.table-controls-container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.charts-container {
  margin-top: 5px;
}

.table-container {
  margin-top: 5px;
}

.tag-container {
  display: flex;
  flex-wrap: nowrap;
}

.select-component {
  width: 200px !important;
}

@media only screen and (max-width: 1250px) {
  .page-title,
  .charts-container,
  .text-content,
  .table-controls-container,
  .table-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .charts-container,
  .text-content,
  .table-controls-container,
  .table-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
}
</style>
