<template>
  <div id="app">
    <!-- @click="click($event)" -->
    <Sidebar></Sidebar>
    <navbar></navbar>
    <router-view class="router-view" />
    <Footer class="footer"></Footer>
    <Alert></Alert>
    <PopUp></PopUp>
  </div>
</template>

<script>
import { eventBus } from "./main";
import Footer from "./components/Footer.vue";
import Alert from "./components/Alert.vue";
import PopUp from "./components/PopUp.vue";
import Sidebar from "./components/SidebarCollapse.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      large_screen: true,
      trading_view: "trading_view.svg",
      trading_view_lighter: "trading_view.svg",
      trading_view_darker: "trading_view_darker.svg",
      windowWidth: window.innerWidth
    };
  },
  components: {
    Footer,
    Alert,
    Sidebar,
    PopUp
  },
  methods: {
    show_popup: function() {
      if (
        (this.user.subscription == "" ||
          this.user.subscription == "free" ||
          this.user.subscription == "Free") &&
        this.showLogin == false &&
        this.showSignup == false
      ) {
        this.$store.commit("setPopupShow", true);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      showSignup: "getShowSignup",
      showLogin: "getShowLogin"
    })
  },
  updated() {
    var isAuthenticated = this.$store.getters.getIsAuthenticated;
    if (isAuthenticated == false) {
      this.$store.commit("deleteJwt");
    }
  },
  watch: {
    large_screen: function() {
      eventBus.$emit("breakpoint");
    },
    windowWidth: function() {
      eventBus.$emit("resize", this.windowWidth);
    }
  },
  created() {
    this.$store.dispatch({
      type: "getUserInfo"
    });
  },
  mounted() {
    // document.dispatchEvent(new Event("render-event"));
    // setTimeout(() => {
    //   this.show_popup();
    // }, 8000);
    if ("token" in this.$router.history.current.params) {
      this.$store.dispatch({
        type: "getTokenConfirmation",
        token: this.$router.history.current.params["token"]
      });
    }
    if ("password_token" in this.$router.history.current.params) {
      this.$store.dispatch({
        type: "getPasswordTokenConfirmation",
        token: this.$router.history.current.params["password_token"]
      });
    }
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 850) {
        this.large_screen = true;
      } else {
        this.large_screen = false;
      }
    });
    const jwt = localStorage.getItem("stockgrid_jwt");
    if (jwt != null) {
      this.$store.commit("setJwt", jwt);
    }
  }
};
</script>

<style lang="scss" scoped>
#app {
  font-family: var(--main-font);
  text-align: center;
  color: #2c3e50;
  min-height: max-content;
  background-color: var(--app-background-color);
  min-height: 100%;
  height: max-content;
  min-width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.logo-container {
  display: block;
  height: 150px;
}

.trading-view {
  width: 70%;
  margin: 50px auto 0 auto;
  color: #494949;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & a {
    color: var(--amaranth);
  }
  & a:hover {
    color: #db2351;
  }
}

.footer {
  height: 190px;
}

.router-view {
  min-height: calc(100vh - 245px);
}

.trading-view-img {
  height: 40px;
  margin: 5px 5px;
}
</style>
