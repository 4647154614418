<template>
  <div class="page-not-found">
    Page Not Found
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
  data() {
    return {};
  },
  computed: {},
  methods: {},

  watch: {},
  mounted() {},
  created() {}
};
</script>

<style lang="scss" scoped>
.page-not-found {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  top: 150px;
  font-size: 40px;
  color: white;
}
</style>
