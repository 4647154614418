<template>
  <div>
    <RegistrationTemplate class="registration" :class="{ visible: showLogin }">
      <template v-slot:function>
        <div class="title">Login</div>
      </template>
      <template v-slot:user>
        <b-field>
          <b-input
            placeholder="Enter Email or Username"
            minlength="5"
            size="is-small"
            icon="account"
            class="form-input"
            v-model="usernameOrEmail"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            placeholder="Enter Password"
            type="password"
            minlength="8"
            maxlength="20"
            size="is-small"
            icon="lock"
            class="form-input"
            v-model="password"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-checkbox v-model="remember">
            Remember me
          </b-checkbox>
        </b-field>
        <button class="registration-button" @click="submitLogin">Login</button>
      </template>
    </RegistrationTemplate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import RegistrationTemplate from "@/components/RegistrationTemplate.vue";
export default {
  name: "Login",
  data: () => ({
    usernameOrEmail: "",
    password: "",
    remember: false
  }),
  components: {
    RegistrationTemplate
  },
  computed: {
    ...mapGetters({
      showLogin: "getShowLogin"
    })
  },
  methods: {
    submitLogin: function() {
      if (this.usernameOrEmail.length < 5) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit(
          "setAlertMessage",
          "Please enter an email or a username"
        );
      } else if (this.password.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter your password");
      } else {
        this.$store.dispatch({
          type: "submitLogin",
          usernameOrEmail: this.usernameOrEmail,
          password: this.password,
          remember: this.remember
        });
      }
    }
  },
  mounted() {
    eventBus.$on("closingAlert", msg => {
      if (msg == "You have successfully logged in.") {
        this.$store.commit("setShowLogin", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "Resend confirmation") {
        this.$store.commit("setShowResend", true);
        this.$store.commit("setShowLogin", false);
      } else if (msg == "Forgot password?") {
        this.$store.commit("setShowResetPassword", true);
        this.$store.commit("setShowLogin", false);
      } else if (msg == "Resend link") {
        this.$store.commit("setShowResetPassword", true);
        this.$store.commit("setShowLogin", false);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.registration {
  visibility: hidden;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0ms, visibility 250ms;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 10000000;
}

.visible {
  visibility: visible;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0ms, visibility 250ms;
  transform: translateY(0px);
  opacity: 1;
  overflow-y: scroll !important;
}

.visible::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.visible {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
