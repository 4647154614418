<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "ShortVolumePercentageChart",
  props: {
    darkPoolIndividualData: Object
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "25%",
          bottom: "8%",
          left: "45",
          right: "40",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            magicType: {
              type: ["line", "bar"]
            },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Price vs Short Volume %",
            left: "30",
            top: "5%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.darkPoolIndividualData["prices"]["dates"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "Price",
            show: true,
            position: "right",
            min: function(value) {
              return value.min;
            },
            max: function(value) {
              return value.max;
            },
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Short Volume %",
            show: true,
            position: "left",
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.darkPoolIndividualData["prices"]["prices"],
            name: "Price",
            type: "line",
            showAllSymbol: false,
            yAxisIndex: 0,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.darkPoolIndividualData["individual_short_volume"][
              "short_volume%"
            ],
            name: "Short Volume %",
            yAxisIndex: 1,
            type: "line",
            showAllSymbol: false,
            lineStyle: { color: "rgba(227, 57, 132, 1)" },
            itemStyle: { color: "rgba(227, 57, 132, 1)" }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "10",
          right: "10",
          containLabel: true
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][1]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    darkPoolIndividualData: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
