<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
//import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "SectorPerformanceLineChart",
  props: {
    data: Object
    // feature: String,
    // period: String,
    // minmax: Array
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "13%",
          bottom: 60,
          left: "5%",
          right: "4%",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "1 Year Returns %",
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            shadowStyle: {
              color: "rgba(162, 160, 160, 1)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
          //   formatter: params => {
          //     var colorSpan = color =>
          //       '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
          //       color +
          //       '"></span>';
          //     return (
          //       this.data.y[params.value[1]] +
          //       "<br/>" +
          //       colorSpan(params.color) +
          //       params.name +
          //       ":" +
          //       "&nbsp;" +
          //       "&nbsp;" +
          //       params.data[2] +
          //       "%"
          //     );
          //   }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "#2effc4",
              borderColor: "#2effc4"
            }
          }
        },
        legend: {
          show: true,
          icon: "roundRect",
          bottom: 10,
          left: 30,
          right: 30,
          align: "auto",
          textStyle: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 12
          }
        },
        xAxis: {
          type: "category",
          name: "",
          data: this.data.index,
          nameLocation: "middle",
          nameGap: 20,
          nameTextStyle: {
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "%",
            show: true,
            nameLocation: "middle",
            nameGap: 30,
            nameTextStyle: {
              fontSize: 16
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["toolbox"]["show"] = false;
        this.options["grid"]["bottom"] = 120;
        this.options["grid"]["right"] = 40;
        this.options["xAxis"]["name"] = "";
        this.options["tooltip"]["confine"] = true;
        this.options["title"]["text"] = "Sector Performance";
      }
      if (window.innerWidth < 850) {
        this.options["toolbox"]["show"] = false;
        this.options["grid"]["bottom"] = 140;
        this.options["grid"]["right"] = 40;
        this.options["xAxis"]["name"] = "";
        this.options["tooltip"]["confine"] = true;
        this.options["title"]["text"] = "Sector Performance";
      }
    },
    setSeries: function() {
    //   var colors = [
    //     "#7400b8ff",
    //     "#6930c3ff",
    //     "#5e60ceff",
    //     "#5390d9ff",
    //     "#4ea8deff",
    //     "#48bfe3ff",
    //     "#56cfe1ff",
    //     "#64dfdfff",
    //     "#72efddff",
    //     "#80ffdbff",
    //     "#80ffb1"
    //   ];

        var colors = [
          "#7f80db",
        "#5d91ed",
        "#17a2fa",
        "#00b1ff",
        "#00c0ff",
        "#00cdfc",
        "#00d9f2",
        "#00e4e4",
        "#00efd4",
        "#45f7c2",
        "#80ffb1"

        ];

      var series = [];
      this.data.series.forEach((element, index) => {
        series.push({
          name: String(element.name),
          type: "line",
          data: element.returns,
          itemStyle: {
            color: colors[index]
          },
          lineStyle: {
            color: colors[index]
          },
          endLabel: {
            show: true,
            color: "rgba(0, 0, 0, 1)",
            borderColor: colors[index],
            backgroundColor: colors[index],
            padding: [9, 5, 5, 5],
            borderRadius: [7, 7, 7, 7],
            verticalAlign: "middle",
            align: "center",
            fontSize: 11
          }
        });
      });
      this.options["series"] = series;

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      console.log(this.data);
      this.setChart();
      this.setSeries();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
      this.setSeries();
    });
    window.addEventListener("resize", () => {
      if (window.innerWidth < 600) {
        this.setChart();
      }
    });
  }
};
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
