<template>
  <div class="options-flow-table-container">
    <!-- <button @click="append">Append</button> -->
    <table class="options-flow-table" ref="table">
      <thead class="headers">
        <td
          v-for="(item, i) in columns"
          :key="i"
          @click="sort($event, item.field)"
          v-show="visibleColumns.includes(item.field)"
        >
          <div class="header-container">
            {{ item.label }}
            <i class="mdi mdi-swap-vertical header-icon"></i>
          </div>
        </td>
      </thead>
      <!-- <thead>
        <td v-for="(item, i) in columns" :key="i">
          <div class="filter-buttons-container">
            <button class="filter-button">&#62;</button>
            <button class="filter-button">&#62;=</button>
            <button class="filter-button">&#60;</button>
            <button class="filter-button">&#60;=</button>
            <button class="filter-button">=</button>
          </div>
          <input type="text" />
        </td>
      </thead> -->
      <tbody name="options-body" is="transition-group" :css="false">
        <!-- <transition name="fade" :key="String(Math.random())"> -->
        <tr
          v-for="item in this.pagination.currentPage"
          :key="item.key"
          class="options-row"
          :class="item.class"
        >
          <td v-show="visibleColumns.includes('date')">{{ item.date }}</td>
          <td>{{ item.time }}</td>
          <td>
            <!-- v-bind:class="tickerClass(item.put_call)" -->
            <div class="ticker">
              {{ item.ticker }}
            </div>
          </td>
          <td>
            <i v-bind:class="pcClass(item.put_call)"></i>{{ item.put_call }}
          </td>
          <td>${{ item.strike_price }}</td>
          <td v-show="visibleColumns.includes('DTE')">{{ item.DTE }}</td>
          <td v-show="visibleColumns.includes('fill')">{{ item.fill }}</td>
          <td v-show="visibleColumns.includes('sentiment')">{{ item.sentiment }}</td>
          <td v-show="visibleColumns.includes('price')">${{ item.price }}</td>
          <td v-show="visibleColumns.includes('size')">{{ item.size }}</td>
          <td
            v-show="visibleColumns.includes('option_activity_type')"
            class="left"
          >
            <div class="trade-type">
              <i :class="iconClass(item.option_activity_type)"></i
              >{{ item.option_activity_type }}
            </div>
          </td>
          <td v-show="visibleColumns.includes('open_interest')">
            {{ item.open_interest }}
          </td>
          <td v-show="visibleColumns.includes('volume')">{{ item.volume }}</td>
          <td v-show="visibleColumns.includes('score')" class="strength-cell">
          <!-- <td v-show="visibleColumns.includes('aggressor_ind')" class="strength-cell"> -->
            <div class="strength-container">
              <div
                class="strength"
                v-bind:style="
                  item.score != undefined
                    ? {
                        width: String(Math.abs(item.score * 100)) + '%'
                      }
                    : { width: '0%' }
                "
                v-bind:class="strengthClass(item.put_call)"
              ></div>
            </div>
          </td>
          <td>${{ item.cost_basis | formatThousands }}K</td>
        </tr>
        <!-- </transition> -->
      </tbody>
    </table>
    <div class="pagination-container">
      <b-pagination
        @change="selectPageIndex(pagination['current'])"
        :total="pagination['total']"
        v-model="pagination['current']"
        :per-page="pagination['perPage']"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OptionsFlowTable",
  data() {
    return {
      scrollX_: 0,
      scrollY_: 0,
      optionsData: [],
      sortOrder: "desc",
      sortedColumn: "time",
      sortedType: "string",
      fadeIn: false,
      columns: [
        { label: "Date", field: "date", breakpoint: true },
        { label: "Time", field: "time", breakpoint: true },
        { label: "Ticker", field: "ticker", breakpoint: true },
        { label: "C/P", field: "put_call", breakpoint: true },
        { label: "Strike", field: "strike_price", breakpoint: true },
        { label: "DTE", field: "DTE", breakpoint: true },
        { label: "Fill", field: "fill", breakpoint: true },
        { label: "Sentiment", field: "sentiment", breakpoint: true },
        // { label: "Price", field: "price", breakpoint: true },
        // { label: "Size", field: "size", breakpoint: true },
        { label: "Type", field: "option_activity_type", breakpoint: true },
        { label: "OI", field: "open_interest", breakpoint: true },
        { label: "Volume", field: "volume", breakpoint: true },
        { label: "Score", field: "score", breakpoint: true },
        //{ label: "Score", field: "aggressor_ind", breakpoint: true },
        { label: "Premium", field: "cost_basis", breakpoint: true }
      ],
      visibleColumns: [],
      pagination: {
        total: 0,
        current: 1,
        perPage: 12,
        currentIndex: [0, 12],
        currentPage: []
      }
    };
  },
  computed: {
    ...mapGetters({
      filteredOptionsFlow: "getFilteredOptionsFlow",
      newFlow: "getNewFlow",
      mode: "getOptionsFlowMode"
    })
  },
  methods: {
    selectPageIndex(selected) {
      this.removeFade();
      this.pagination.currentIndex[0] =
        (selected - 1) * this.pagination.perPage;
      this.pagination.currentIndex[1] = selected * this.pagination.perPage;
      this.pagination.currentPage = this.optionsData.slice(
        this.pagination.currentIndex[0],
        this.pagination.currentIndex[1]
      );
      this.pagination["total"] = this.optionsData.length;
    },
    sort(event, column) {
      this.removeFade();
      const headers = document.getElementsByClassName("header-icon");
      headers.forEach(element => {
        element.classList.remove("purple");
      });
      try {
        event.target.children[0].children[0].classList.add("purple");
      } catch {
        try {
          event.target.children[0].classList.add("purple");
        } catch {
          try {
            event.target.classList.add("purple");
          } catch {
            return;
          }
        }
      }
      if (typeof this.optionsData[0][column] == "number") {
        if (this.sortedColumn != column) {
          this.sortString(this.optionsData, "time", "desc");
          this.sortedColumn = column;
          this.sortOrder = "asc";
          return this.sortDigit(this.optionsData, column, "asc");
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortDigit(this.optionsData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortDigit(this.optionsData, column, "asc");
          }
        }
      } else if (typeof this.optionsData[0][column] == "string") {
        if (this.sortedColumn != column) {
          this.sortString(this.optionsData, "time", "desc");
          if (column != "time") {
            this.sortedColumn = column;
            this.sortOrder = "asc";
            return this.sortString(this.optionsData, column, "asc");
          } else {
            this.sortedColumn = column;
            this.sortOrder = "desc";
          }
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortString(this.optionsData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortString(this.optionsData, column, "asc");
          }
        }
      }
    },
    sortDigit: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "number";
        if (sort == "asc") {
          return [a][0][column] - [b][0][column];
        } else {
          return [b][0][column] - [a][0][column];
        }
      });
    },
    sortString: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "string";
        if (sort == "asc") {
          return [a][0][column].localeCompare([b][0][column]);
        } else {
          return [b][0][column].localeCompare([a][0][column]);
        }
      });
    },
    iconClass: function(item) {
      if (item == "Block") {
        return "mdi mdi-square block";
      } else if (item == "Sweep") {
        return "mdi mdi-layers-triple sweep";
      } else {
        return "mdi mdi-layers-triple hidden";
      }
    },
    pcClass: function(item) {
      if (item == "Call") {
        return "mdi mdi-menu-up call";
      } else if (item == "Put") {
        return "mdi mdi-menu-down put";
      } else {
        return null;
      }
    },
    strengthClass: function(item) {
      if (item == "Call") {
        return "strength-call";
      } else {
        return "strength-put";
      }
    },
    setOptionsData: function() {
      this.optionsData = [...this.filteredOptionsFlow];
      if (this.optionsData.length < this.pagination.perPage) {
        this.optionsData = this.optionsData.concat(
          Array(this.pagination.perPage - this.optionsData.length).fill({
            ticker: "",
            aggressor_ind: 0,
            ask: 0,
            bid: 0,
            cost_basis: 0,
            date: "",
            date_expiration: "",
            exchange: "",
            midpoint: 0,
            open_interest: 0,
            option_activity_type: "",
            price: 0,
            put_call: "",
            sentiment: "",
            size: 0,
            strike_price: 0,
            time: "",
            trade_count: 0,
            underlying_price: 0,
            volume: 0,
            fill: "",
            DTE: 0,
            score: 0,
            key: Math.random(),
            class: "invisible"
          })
        );
        //console.log(this.optionsData);
      }
      if (this.sortedType == "string") {
        this.sortString(this.optionsData, this.sortedColumn, this.sortOrder);
      } else {
        this.sortDigit(this.optionsData, this.sortedColumn, this.sortOrder);
      }
    },
    removeFade: function() {
      this.optionsData.forEach(element => {
        if (element.class == "fade-in") {
          element.class = "";
        }
      });
    },
    beforeEnter: function() {
      //console.log("before");
      const rows = document.getElementsByClassName("options-row");
      rows.forEach(element => {
        element.classList.add("options-body-move");
      });
    },
    hideColumns: function(hidden) {
      hidden.forEach(element => {
        const index = this.visibleColumns.indexOf(element);
        if (index > -1) {
          this.visibleColumns.splice(index, 1);
        }
      });
    },
    resetBreakpoints: function() {
      this.visibleColumns = [];
      this.columns.forEach(element => {
        element["breakpoint"] = true;
        this.visibleColumns.push(element.field);
      });
    },
    setBreakpoints: function(size) {
      this.resetBreakpoints();
      if (size < 1350 && size > 1250) {
        const hidden = ["fill"];
        this.hideColumns(hidden);
      } else if (size < 1250 && size > 850) {
        const hidden = ["fill", "DTE", "open_interest", "volume"];
        this.hideColumns(hidden);
      } else if (size < 850) {
        this.columns.forEach(element => {
          element["breakpoint"] = true;
        });
        const hidden = [
          "fill",
          "DTE",
          "open_interest",
          "volume",
          "price",
          "size",
          "option_activity_type",
          "score"
        ];
        this.hideColumns(hidden);
      }
      if (this.mode == "live") {
        this.hideColumns(["date"]);
      }
    }
  },
  watch: {
    filteredOptionsFlow: function() {
      this.setOptionsData();
      this.removeFade();
      if (
        this.optionsData[0]["ticker"] == this.newFlow["ticker"] &&
        this.optionsData[0]["time"] == this.newFlow["time"]
      ) {
        if (this.optionsData[0].class != "invisible") {
          this.optionsData[0]["class"] = "fade-in";
        }
      }
    },
    optionsData: function() {
      this.scrollY_ = window.pageYOffset;
      this.scrollX_ = window.pageXOffset;
      this.pagination.currentPage = this.optionsData.slice(
        this.pagination.currentIndex[0],
        this.pagination.currentIndex[1]
      );
      this.pagination["total"] = this.optionsData.length;
      //window.scrollTo(this.scrollX_, this.scrollY_);
    },
    mode: function() {
      this.setBreakpoints(window.innerWidth);
    }
  },
  updated() {
    this.$nextTick(() => {
      if (Object.keys(this.newFlow).length != 0) {
        window.scrollTo(this.scrollX_, this.scrollY_);
        this.$store.commit("setNewFlow", {});
      }
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.setBreakpoints(window.innerWidth);
    });
    this.setBreakpoints(window.innerWidth);
    this.hideColumns(["date"]);
  }
};
</script>

<style lang="scss" scoped>
.options-flow-table-container {
  font-family: Nunito;
}

.options-flow-table {
  margin-top: 20px;
  font-family: Nunito;
  background-color: rgba(29, 29, 29, 1);
  color: #fff;
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  zoom: 90%;
  overflow-x: scroll !important;
}

tr {
  border: solid 0.5px rgba(39, 39, 39, 0.4);
}

td {
  font-family: Nunito;
  font-size: 17px;
  white-space: nowrap;
  padding: 10px 15px;
}

.header-container {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: max-content min-content;
  align-items: center;
  justify-items: center;
  margin-left: 15px;
}

thead {
  //border: solid 1px rgba(22, 22, 22, 0.2);
  > td {
    font-size: 20px;
    padding: 30px 0px;
    background-color: rgb(21, 21, 21);
    cursor: pointer;
  }
}

.headers td:hover {
  background-color: rgb(25, 25, 25);
}

tbody {
  cursor: default;
}

tr:hover {
  background-color: rgba(41, 41, 43, 0.3);
}

.options-table {
  overflow: visible;
}

.opttions-body {
  overflow: visible;
}

.options-row {
  transition: transform 1s;
}
.options-row > * {
  transition: transform 1s;
  overflow: visible;
  //animation: fadeIn 2s;
}

.options-body-enter-active,
.options-body-leave-active {
  transition: all 1s;
  overflow: hidden;
}
.options-body-enter,
.options-body-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  overflow: hidden;
}
.options-body-move {
  transition: transform 1s;
}

.fade-in {
  animation: fadeIn 5s;
}

.ticker {
  border-radius: 3px;
  font-family: Nunito;
  font-size: 18px;
}

.header-icon {
  font-size: 20px;
  color: rgb(78, 78, 78);
  //margin-right: -15px;
  &.purple {
    color: rgb(136, 77, 245);
  }
  margin-left: 5px;
}

.block {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(130deg, rgb(136, 77, 245) 40%, #d639eb 70%);
  margin-right: 5px;
  margin-left: 0%;
  font-size: 20px;
}

.sweep {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(130deg, #ff9e30 20%, #ff2bed 70%);
  margin-right: 5px;
  margin-left: 0%;
  font-size: 20px;
}

.call {
  font-size: 40px;
  line-height: 0px;
  position: relative;
  top: 8px;
  margin-right: 5px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #00cfb4 50%, #0bb8fd 90%);
}

.put {
  font-size: 40px;
  line-height: 0px;
  position: relative;
  top: 8px;
  margin-right: 5px;

  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #ff0062 50%, #ff2bdc 90%);
}

.left {
  text-align: left;
}

.trade-type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.strength {
  height: 10px;
  min-width: 2%;
  border-radius: 3px;
  border: 2px 0 2px 0 solid black;
}

.strength-call {
  background: linear-gradient(160deg, #752bff 50%, #0bc4fd 100%);
  background: linear-gradient(160deg, rgb(136, 77, 245) 50%, #b80bfd 100%);
}

.strength-put {
  background: linear-gradient(160deg, #fd0464 50%, #ff2bf4 100%);
  background: linear-gradient(160deg, rgb(136, 77, 245) 50%, #b80bfd 100%);
}

.strength-cell {
  vertical-align: middle;
}

.strength-container {
  background-color: rgb(26, 26, 26);
  border-radius: 3px;
  border: 1px solid rgba(26, 26, 26, 0.3);
}

.pagination-container {
  margin-top: 15px;
  padding: 10px;
  background-color: rgb(29, 29, 29);
}

.hidden {
  color: transparent;
  font-size: 20px;
}
.invisible {
  color: rgba(0, 0, 0, 0);
  & .strength-call {
    background: linear-gradient(
      160deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.invisible .strength-call,
.invisible .strength-put,
.invisible .strength-container {
  background: linear-gradient(
    160deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1350px) {
  td {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 1250px) {
  td {
    padding: 10px 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .price-chart-container,
  .ticker-info-container {
    width: 95%;
  }
  .call {
    color: #00cfb4;
    background-image: none;
  }

  .put {
    color: #ff0062;
    background-image: none;
  }
  .header-icon {
    display: none;
  }
  .header-container {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 850px) {

  .options-flow-table{
    zoom: 0.7;
  }
  td {
  font-size: 16px;
}

.ticker {
  font-size: 16px;
}

thead {
  //border: solid 1px rgba(22, 22, 22, 0.2);
  > td {
    font-size: 17px;
  }
}

}
</style>
