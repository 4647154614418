<template>
  <div class="sector-rotation">
    <h1 class="page-title">Sector Rotation</h1>
    <div class="table-control-container">
      <div class="individual-control-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Index
        </b-tag>
        <SelectComponent
          v-model="index"
          v-on:input="getSectorRotationData()"
          class="select-component"
          :default="{ value: 'sp500, S&P500', label: 'S&P500' }"
          :options="[
            { value: 'sp500, S&P500', label: 'S&P500' },
            { value: 'nasdaq100, NASDAQ 100', label: 'NASDAQ 100' }
          ]"
        >
        </SelectComponent>
      </div>
      <div class="individual-control-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Factor
        </b-tag>
        <SelectComponent
          v-model="factor"
          v-on:input="getSectorRotationData()"
          class="select-component"
          :default="{
            value: 'rate_of_change, Relative Momentum',
            label: 'Relative Momentum'
          }"
          :options="[
            {
              value: 'rate_of_change, Relative Momentum',
              label: 'Relative Momentum'
            },
            { value: 'momentum, Price Momentum', label: 'Price Momentum' },
            {
              value: 'absolute_momentum, Absolute Momentum',
              label: 'Absolute Price Momentum'
            }
          ]"
        >
        </SelectComponent>
      </div>
      <div class="individual-control-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Color
        </b-tag>
        <SelectComponent
          v-model="color"
          class="select-component"
          :default="{ value: 'position', label: 'Position' }"
          :options="[
            { value: 'position', label: 'Position' },
            { value: 'sector', label: 'Sector' },
            { value: 'super', label: 'Super Sector' }
          ]"
        >
        </SelectComponent>
      </div>
      <div class="individual-control-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Lookback
        </b-tag>
        <SelectComponent
          v-model="lookback"
          class="select-component"
          v-on:input="getSectorRotationData()"
          :default="{ value: '12', label: '12' }"
          :options="[
            { value: '4', label: '4' },
            { value: '8', label: '8' },
            { value: '12', label: '12' },
            { value: '26', label: '26' },
            { value: '39', label: '39' },
            { value: '52', label: '52' }
          ]"
        >
        </SelectComponent>
      </div>
    </div>
    <div class="price-chart-container">
      <SectorRotationChart
        v-bind:index="index"
        v-bind:factor="factor"
        v-bind:color="color"
      ></SectorRotationChart>
    </div>
    <div class="tables-container">
      <ul class="tabs-list">
        <li
          class="tab"
          v-bind:class="{ active: selected == 'sectors' }"
          v-on:click="selected = 'sectors'"
        >
          Sectors
        </li>
        <li
          class="tab"
          v-bind:class="{ active: selected == 'all' }"
          v-on:click="selected = 'all'"
        >
          All
        </li>
      </ul>
      <div class="tab-container" v-show="selected == 'sectors'">
        <b-tag type="is-dark" size="is-medium" class="darker-tag">
          Super Sector %
        </b-tag>
        <b-table
          class="datatable sector"
          :data="this.sectorRotationData['super_sector_freq_pct_data']"
          :striped="true"
          :hoverable="true"
          :mobile-cards="false"
          :per-page="20"
          :default-sort="['Leading', 'desc']"
          sortable
          ref="table"
        >
          <b-table-column
            field="super_sector"
            label="Super Sector"
            sortable
            v-slot="props"
          >
            {{ props.row.super_sector }}
          </b-table-column>
          <b-table-column
            field="Lagging"
            label="Lagging"
            sortable
            v-slot="props"
          >
            {{ props.row.Lagging | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Improving"
            label="Improving"
            sortable
            v-slot="props"
          >
            {{ props.row.Improving | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Weakening"
            label="Weakening"
            sortable
            v-slot="props"
          >
            {{ props.row.Weakening | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Leading"
            label="Leading"
            sortable
            v-slot="props"
          >
            {{ props.row.Leading | formatDecimal3 }}
          </b-table-column>
        </b-table>
        <b-tag type="is-dark" size="is-medium" class="darker-tag">
          Super Sector Frequency
        </b-tag>
        <b-table
          class="datatable sector"
          :data="this.sectorRotationData['super_sector_freq_data']"
          :striped="true"
          :hoverable="true"
          :mobile-cards="false"
          :per-page="20"
          :default-sort="['Leading', 'desc']"
          sortable
          ref="table"
        >
          <b-table-column
            field="super_sector"
            label="Super Sector"
            sortable
            v-slot="props"
          >
            {{ props.row.super_sector }}
          </b-table-column>
          <b-table-column
            field="Lagging"
            label="Lagging"
            sortable
            v-slot="props"
          >
            {{ props.row.Lagging }}
          </b-table-column>
          <b-table-column
            field="Improving"
            label="Improving"
            sortable
            v-slot="props"
          >
            {{ props.row.Improving }}
          </b-table-column>
          <b-table-column
            field="Weakening"
            label="Weakening"
            sortable
            v-slot="props"
          >
            {{ props.row.Weakening }}
          </b-table-column>
          <b-table-column
            field="Leading"
            label="Leading"
            sortable
            v-slot="props"
          >
            {{ props.row.Leading }}
          </b-table-column>
        </b-table>
        <b-tag type="is-dark" size="is-medium" class="darker-tag">
          Sector %
        </b-tag>
        <b-table
          class="datatable sector"
          :data="this.sectorRotationData['sector_freq_pct_data']"
          :striped="true"
          :hoverable="true"
          :mobile-cards="false"
          :per-page="20"
          :default-sort="['Leading', 'desc']"
          sortable
          ref="table"
        >
          <b-table-column field="sector" label="Sector" sortable v-slot="props">
            {{ props.row.sector }}
          </b-table-column>
          <b-table-column
            field="Lagging"
            label="Lagging"
            sortable
            v-slot="props"
          >
            {{ props.row.Lagging | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Improving"
            label="Improving"
            sortable
            v-slot="props"
          >
            {{ props.row.Improving | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Weakening"
            label="Weakening"
            sortable
            v-slot="props"
          >
            {{ props.row.Weakening | formatDecimal3 }}
          </b-table-column>
          <b-table-column
            field="Leading"
            label="Leading"
            sortable
            v-slot="props"
          >
            {{ props.row.Leading | formatDecimal3 }}
          </b-table-column>
        </b-table>
        <b-tag type="is-dark" size="is-medium" class="darker-tag">
          Sector Frequency
        </b-tag>
        <b-table
          class="datatable sector"
          :data="this.sectorRotationData['sector_freq_data']"
          :striped="true"
          :hoverable="true"
          :mobile-cards="false"
          :per-page="20"
          :default-sort="['Leading', 'desc']"
          sortable
          ref="table"
        >
          <b-table-column field="sector" label="Sector" sortable v-slot="props">
            {{ props.row.sector }}
          </b-table-column>
          <b-table-column
            field="Lagging"
            label="Lagging"
            sortable
            v-slot="props"
          >
            {{ props.row.Lagging }}
          </b-table-column>
          <b-table-column
            field="Improving"
            label="Improving"
            sortable
            v-slot="props"
          >
            {{ props.row.Improving }}
          </b-table-column>
          <b-table-column
            field="Weakening"
            label="Weakening"
            sortable
            v-slot="props"
          >
            {{ props.row.Weakening }}
          </b-table-column>
          <b-table-column
            field="Leading"
            label="Leading"
            sortable
            v-slot="props"
          >
            {{ props.row.Leading }}
          </b-table-column>
        </b-table>
      </div>
      <div class="tab-container all" v-show="selected == 'all'">
        <b-tag type="is-dark" size="is-medium" class="darker-tag">
          All Data
        </b-tag>
        <b-table
          class="datatable all-sector"
          :data="this.sectorRotationData.main_data"
          :striped="true"
          :hoverable="true"
          :mobile-cards="false"
          :per-page="20"
          :paginated="true"
          :default-sort="['ticker', 'asc']"
          sortable
          ref="table"
        >
          <b-table-column field="ticker" label="Ticker" sortable searchable>
            <template v-slot="props">
              {{ props.row.ticker }}
            </template>
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
                ref="searchInput"
              />
            </template>
          </b-table-column>
          <b-table-column field="sector" label="Sector" sortable searchable>
            <template v-slot="props">
              {{ props.row.sector }}
            </template>
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
                ref="searchInput"
              />
            </template>
          </b-table-column>
          <b-table-column field="position" label="Position" sortable searchable>
            <template v-slot="props">
              {{ props.row.position }}
            </template>
            <template #searchable="props">
              <b-input
                v-model="props.filters[props.column.field]"
                placeholder="Search..."
                icon="magnify"
                size="is-small"
                ref="searchInput"
              />
            </template>
          </b-table-column>
          <b-table-column
            field="relative_strength"
            label="Relative Strength"
            sortable
            v-slot="props"
          >
            {{ props.row.relative_strength | formatDecimal2 }}
          </b-table-column>
          <b-table-column
            :field="this.factor.split(',')[0]"
            :label="this.factor.split(',')[1]"
            sortable
            v-slot="props"
          >
            {{ props.row[String(factor.split(",")[0])] | formatDecimal2 }}
          </b-table-column>
        </b-table>
      </div>
    </div>

    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      <a
        href="https://www.investopedia.com/articles/trading/05/020305.asp#:~:text=Sector%20rotation%20is%20the%20movement,moves%20in%20reasonably%20predictable%20cycles.&text=That%20simple%20fact%20has%20spawned,is%20based%20on%20sector%20rotation."
        target="_blank"
        >Sector rotation</a
      >
      refers to an investment strategy whereby investors shift money from one
      sector to another, anticipating changes in their respective performance. A
      sector rotation strategy can use multiple factors when making predictions,
      the most popular of which include the business cycle, technical patterns,
      and time series, etc. The approach used here looks at the relative
      strength and momentum of each stock to determine those that are leading,
      weakening, lagging, and improving. Momentum traders would want to choose
      stocks that are leading, whereas contrarian investors would want stocks
      that are lagging and/or improving.
      <br /><br />
      <h1><big>Key definitions:</big></h1>
      <br />
      <span class="key">Relative Strength</span>: The performance of each stock
      divided by the performance of its respective index. The data is normalized
      over the lookback period, and the last data point is selected.
      <br />
      <span class="key">Relative Momentum</span>: The week-over-week change in
      the Relative Strengh of each stock. The data is normalized over the
      lookback period, and the last data point is selected.
      <br />
      <span class="key">Price Momentum</span>: The week-over-week change in the
      price of each each stock. The data is normalized over the lookback period,
      and the last data point is selected.
      <br />
      <span class="key">Absolute Momentum</span>: The change in the price of
      each stock over the entire lookback period. The last data point of each
      stock is selected and the entire dataset is then normalized.
      <br />
      <span class="key">Lookback</span>: The number of weeks to be included in
      the lookback period. Data for the current week is included up to the last
      daily close.
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
const SelectComponent = () => import("@/components/SelectComponent.vue");
import SectorRotationChart from "@/components/SectorRotationChart.vue";
import { mapGetters } from "vuex";

export default {
  name: "SectorRotation",
  data() {
    return {
      index: "sp500, S&P500",
      factor: "rate_of_change, Relative Momentum",
      lookback: 12,
      loading: true,
      selected: "sectors",
      color: "position"
    };
  },
  head: {
    title: {
      inner: "Sector Rotation"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Visualize sector rotation data to help you find the leading stocks. Always stay ahead of the curve by investing in strong performers and avoid laggers."
      }
    ]
  },
  methods: {
    getSectorRotationData: function() {
      this.loading = true;
      this.$store.dispatch({
        type: "getSectorRotationData",
        index: this.index.split(",")[0],
        lookback: this.lookback,
        factor: this.factor.split(",")[0]
      });
    }
  },
  components: {
    SectorRotationChart,
    SelectComponent
  },
  computed: {
    ...mapGetters({
      sectorRotationData: "getSectorRotationData"
    })
  },
  watch: {
    sectorRotationData: function() {
      this.loading = false;
    }
  },
  mounted() {
    this.getSectorRotationData();
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.table-control-container,
.tables-container,
.price-chart-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tabs-list {
  width: max-content;
  margin: 15px 0 0px 15px;
}

.tab {
  display: inline-block;
  line-height: 50px;
  text-align: center;
  height: 50px;
  width: 95px;
  color: white;
  border-bottom: solid 0.5px rgb(148, 146, 146);
  font-family: Nunito;
  cursor: pointer;
  &.active {
    color: var(--purple-darker);
    border-bottom: solid 1px var(--purple-darker);
  }
  &:hover {
    color: var(--purple-darker);
    border-bottom: solid 1px var(--purple-darker);
    transition: all 0.2s, color 0.2s;
  }
}

.tables-container {
  background-color: var(--app-components-background-color);
}

.tab-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 70px;
  &.all {
    padding-bottom: 0px;
  }
}

.datatable.all-sector {
  background-color: var(--app-background-color);
}

.table-control-container {
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.datatable {
  width: 100%;
  border-bottom: 1px solid #353434;
  border-top: 1px solid #353434;
  &.all-sector {
    border-bottom: none;
  }
}

.datatable-tag {
  margin-right: 10px;
}

.darker-tag {
  background-color: #222121 !important;
  margin: 35px 0 20px 25px;
}

.individual-control-container {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 5px;
}

.select-component {
  width: 225px !important;
}

.key {
  font-size: 16px;
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .tables-container,
  .table-control-container,
  .price-chart-container {
    width: 80%;
    flex-wrap: wrap;
  }
  .price-chart-container {
    margin-top: 0px;
  }
  .datatable-tag {
    width: 105px;
  }
  .individual-control-container {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .table-control-container,
  .tables-container,
  .price-chart-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
}
</style>
