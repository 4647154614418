<template>
  <div class="arima">
    <h1 class="page-title">Time Series / ARIMA</h1>
    <div class="ticker-info-container">
      <TickerInfo v-bind:tickerOrigin="ticker"></TickerInfo>
    </div>
    <div class="ts-control-container">
      <TSArimaControl @updateModel="updateModel"></TSArimaControl>
    </div>
    <div class="ts-chart-container">
      <div class="ts-pred-container">
        <TSPredictionChart
          :data="tsArimaData['pred']"
          :title="'Price and Predictions'"
        ></TSPredictionChart>
      </div>
    </div>
    <div
      class="table-container"
      v-html="tsArimaData['table']"
      placeholder=""
    ></div>
    <div class="ts-chart-container">
      <div class="ts-residual-container">
        <TSResidualChart :data="tsArimaData['residual']" :title="'Residual'">
        </TSResidualChart>
      </div>
    </div>
    <div class="ts-chart-container">
      <div class="ts-chart-sub-container">
        <TSCorrelationFunctionChart
          :data="tsArimaData['acf']"
          :title="'ACF'"
        ></TSCorrelationFunctionChart>
      </div>
      <div class="ts-chart-sub-container">
        <TSCorrelationFunctionChart
          :data="tsArimaData['pacf']"
          :title="'PACF'"
        ></TSCorrelationFunctionChart>
      </div>
    </div>
    <div class="ts-chart-container">
      <div class="ts-chart-sub-container">
        <TSProbabilityChart
          :data="tsArimaData['ppplot']"
          :title="'PP Plot'"
        ></TSProbabilityChart>
      </div>
      <div class="ts-chart-sub-container">
        <TSProbabilityChart
          :data="tsArimaData['qqplot']"
          :title="'QQ Plot'"
        ></TSProbabilityChart>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      This page implements the autoregressive integrated moving average (ARIMA)
      model. It is a statistical model that is used to analyze and forecast time
      series. It does so by looking at lagged values of the series and its
      forecast errors. A detailed article can be found on the subject on
      <a
        href="https://www.quantstart.com/articles/Autoregressive-Moving-Average-ARMA-p-q-Models-for-Time-Series-Analysis-Part-1/"
        target="_blank"
        >Quantstart</a
      >

      . The main parameters are the moving average length (p), the integrated
      length (d), and the autoregression length (q). A complete parameters
      reference can be found on the
      <a
        href="https://www.statsmodels.org/stable/generated/statsmodels.tsa.arima.model.ARIMA.html"
        target="_blank"
        >Statsmodels</a
      >

      page, which is the library used here. For trend parameters "c" and "ct",
      the integrated parameter must be 0. For trend parameters "t", the
      integrated parameter must be either 0 or 1.
      <br /><br />
    </div>
  </div>
</template>

<script>
import TickerInfo from "@/components/TickerInfo.vue";
import TSCorrelationFunctionChart from "@/components/TSCorrelationFunctionChart.vue";
import TSProbabilityChart from "@/components/TSProbabilityChart.vue";
import TSResidualChart from "@/components/TSResidualChart.vue";
import TSPredictionChart from "@/components/TSPredictionChart.vue";
import TSArimaControl from "@/components/TSArimaControl.vue";
import { mapGetters } from "vuex";

export default {
  name: "TSArima",
  data() {
    return {
      p: 1,
      d: 1,
      q: 1
    };
  },
  head: {
    title: {
      inner: "ARIMA"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Time Series Analysis using the ARIMA model, a statistical model widely used in statistics and econometrics."
      }
    ]
  },
  methods: {
    init: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getTSArimaData",
        ticker: this.ticker,
        p: this.p,
        d: this.d,
        q: this.q,
        trend: "t",
        stationarity: 1,
        invertibility: 1,
        offset: 1,
        concentrate: 0
      });
    },
    updateModel: function(
      p,
      d,
      q,
      trend,
      stationarity,
      invertibility,
      offset,
      concentrate
    ) {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getTSArimaData",
        ticker: this.ticker,
        p: p,
        d: d,
        q: q,
        trend: trend,
        stationarity: stationarity,
        invertibility: invertibility,
        offset: offset,
        concentrate: concentrate
      });
    }
  },
  computed: {
    ...mapGetters({
      ticker: "getTicker",
      tsArimaData: "getTSArimaData",
      loading: "getLoading"
    })
  },
  components: {
    TickerInfo,
    TSCorrelationFunctionChart,
    TSProbabilityChart,
    TSResidualChart,
    TSPredictionChart,
    TSArimaControl
  },
  watch: {
    ticker: function() {
      this.init();
    },
    tsArimaData: function() {
      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.arima {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.page-title,
.table-container,
.ticker-info-container,
.ts-chart-container,
.text-content {
  width: 80%;
  margin: 0 auto;
}

.text-content {
  margin-top: 10px;
}

.table-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  background-color: var(--app-components-background-color);
  margin-top: 10px;
  margin-bottom: 0px !important;
  padding: 25px 45px 35px 45px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-family: Nunito;
}

.ts-control-container {
  min-width: 320px;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 10px;
  width: 80%;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
}

.ts-chart-container {
  display: flex;
  justify-content: center;
  min-width: 320px;
  margin-top: 10px;
}

.ts-chart-sub-container {
  width: 50%;
}

.ts-pred-container,
.ts-residual-container {
  width: 100%;
}

.ticker-info-container {
  margin-top: 15px;
}

.ticker-info,
.ts-chart-container {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1150px) {
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .table-container,
  .ticker-info-container,
  .ts-chart-container,
  .ts-control-container,
  .text-content {
    width: 95%;
  }

  .table-container {
    padding: 25px 25px 35px 25px;
  }

  .ts-chart-sub-container {
    width: 100%;
  }

  .ts-chart-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (max-width: 850px) {
  .ts-control-container {
    padding: 0 10px;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #292929;
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  background: #242424;
}
</style>
