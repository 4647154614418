<template>
  <div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "OptionsFlowSectorInflowChart",
  data() {
    return {};
  },
  props: {
    sectorInflow: Object,
    title: String,
    putCall: String
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "20%",
          bottom: "2%",
          left: "5%",
          right: "3%",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
            type: "shadow",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },

          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        // axisPointer: {
        //   link: { yAxisIndex: [0, 1] }
        // },
        yAxis: [
          {
            name: "$",
            type: "value",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            //boundaryGap: true,
            data: this.sectorInflow["sectors"],
            axisLabel: {
              show: true,
              //interval: 0,
              rotate: 25,
              margin: 20,
              fontSize: 13
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.sectorInflow[this.putCall],
            name: "Total Premiums $ 000s",
            type: "bar",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color:
                    this.putCall == "calls"
                      ? "rgba(5, 218, 255, 0.4)"
                      : this.putCall == "puts"
                      ? "rgba(136, 77, 245, 0.4)"
                      : "rgba(128, 133, 173, 0.3)"
                },
                {
                  offset: 0,
                  color:
                    this.putCall == "calls"
                      ? "rgba(10, 252, 180, 0.4)"
                      : this.putCall == "puts"
                      ? "rgba(217, 79, 255, 0.4)"
                      : "rgba(128, 133, 173, 0.4)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color:
                    this.putCall == "calls"
                      ? "rgba(5, 218, 255, 1)"
                      : this.putCall == "puts"
                      ? "rgba(136, 77, 245, 1)"
                      : "rgba(128, 133, 173, 1)"
                },
                {
                  offset: 0,
                  color:
                    this.putCall == "calls"
                      ? "rgba(10, 252, 180, 1)"
                      : this.putCall == "puts"
                      ? "rgba(217, 79, 255, 1)"
                      : "rgba(128, 133, 173, 1)"
                }
              ])
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["yAxis"][0]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["toolbox"]["show"] = false;
        this.options["title"]["textStyle"]["fontSize"] = 17;
        this.options["grid"]["containLabel"] = true;
        //this.options["grid"]["left"] = "13%";
        //this.options["grid"]["right"] = "6%";
        //this.options["toolbox"]["show"] = false;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    sectorInflow: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 500px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
