import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueHead from "vue-head";
import "./assets/css/styles.scss";
import "./assets/css/ticker_info.scss";
import "./assets/css/datatable.scss";
import "./assets/css/optionstable.scss";
import "./assets/css/time_series_table.scss";
import "./assets/css/radio_button.scss";
import "./assets/css/checkbox.scss";
import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import store from "./store";
import ECharts from "vue-echarts";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

Vue.component("v-chart", ECharts);

Vue.use(VueHead, {
  separator: "",
  complement: ""
});

Vue.config.productionTip = false;

import Buefy from "buefy";
import "./assets/css/buefy.scss";
Vue.use(Buefy);

import Navbar from "./components/Navbar.vue";
Vue.component("navbar", Navbar);

var numeral = require("numeral");

Vue.filter("formatNumber", function(value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatDecimal", function(value) {
  return numeral(value).format("0.0000"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatDecimal2", function(value) {
  return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatDecimal3", function(value) {
  return numeral(value).format("0.000"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatReturns", function(value) {
  return numeral(value).format("0.00"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("formatThousands", function(value) {
  return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
});

Vue.filter("plusMinus", function(value) {
  return value < 0 ? numeral(value).format("0.0000") : "    " + String(value);
});

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] =
  process.env.VUE_APP_API_BASE_URL;
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.withCredentials = true;

function check_alert(res) {
  if ("alert_show" in res.data) {
    store.commit("setAlertShow", res.data["alert_show"]);
    store.commit("setAlertMessage", res.data["alert_message"]);
  }
  if ("alert_content" in res.data) {
    store.commit("setAlertContentShow", true);
    store.commit("setAlertContent", res.data["alert_content"]);
  }
}

axios.interceptors.response.use(
  res => {
    if (typeof res.data === "object") {
      try {
        check_alert(res);
      } catch (e) {
        console.log(e);
        return res;
      }
    }
    return res;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 403) {
      var res = error.response;
      if (typeof res.data === "object") {
        try {
          check_alert(res);
        } catch (e) {
          console.log(e);
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export const eventBus = new Vue();

const root = new Vue({
  router,
  store: store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered"))
});

document.addEventListener("DOMContentLoaded", () => root.$mount("#app"));

// app.config.compilerOptions.whitespace = "preserve";
// app.config.compilerOptions.preserveWhitespace = true;
