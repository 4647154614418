<template>
  <div class="subscription">
    <button @click="submit">Pay now!</button>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import { mapGetters } from "vuex";
export default {
  name: "Subscription",
  mixins: [utils],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      stripeSession: "get_stripe_session"
    })
  },
  methods: {
    submit() {
      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      this.$store.dispatch({
        type: "subscribe"
      });
    }
  },

  watch: {
    stripeSession: function() {
      this.stripe.redirectToCheckout({ sessionId: this.stripeSession });
    }
  },
  mounted() {
    if (window.Stripe == undefined) {
      this.loadScript("https://js.stripe.com/v3").then(s => {
        document.body.appendChild(s);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
