<template>
  <div class="custom-table-container">
    <div class="table-container">
      <table class="custom-table" ref="table" :style="[layout]">
        <thead class="headers">
          <td
            v-for="(item, i) in columns"
            :key="i"
            @click="sort($event, item.field)"
            :class="item.field == sortedColumn ? 'purple-underline' : ''"
            v-show="visibleColumns.includes(item.field)"
          >
            <div class="header-container">
              {{ item.label }}
              <i
                class="mdi mdi-swap-vertical header-icon"
                :class="item.field == sortedColumn ? 'purple' : ''"
              ></i>
            </div>
          </td>
        </thead>
        <tbody>
          <td v-for="(item, i) in columns" :key="i">
            <div class="filters-container" v-show="item.filtered">
              <i class="mdi mdi-magnify"></i
              ><input
                type="text"
                placeholder="Search"
                @input="filter($event, item.field)"
              />
            </div>
          </td>
        </tbody>

        <tbody name="custom-table-body" is="transition-group" :css="false">
          <tr
            v-for="(item, itemIndex) in this.pagination.currentPage"
            :key="itemIndex"
            class="custom-table-row"
            :class="item.class"
            @click="$emit('click', item)"
          >
            <template v-for="(column, colindex) in columns">
              <td
                v-show="visibleColumns.includes(column.field)"
                :key="column + itemIndex + colindex"
              >
                <div class="cell-container">
                  <div class="column.class ? column.class: ''">
                    {{ column.prepend
                    }}{{ format(item[column.field], column.format)
                    }}{{ column.append }}
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="pagination-container" v-show="paginated">
      <PaginationComponent
        :total="pagination['total']"
        v-model="pagination['current']"
        @change="selectPageIndex(pagination['current'])"
      ></PaginationComponent>
    </div>
  </div>
</template>

<script>
import FiltersMixin from "@/filters/FiltersMixin.js";
import PaginationComponent from "@/components/PaginationComponent.vue";
export default {
  name: "TableComponent",
  mixins: [FiltersMixin],
  data() {
    return {
      sortOrder: "desc",
      sortedColumn: "",
      sortedType: "",
      tableData: [],
      visibleColumns: [],
      pagination: {
        total: 0,
        current: 1,
        perPage: 20,
        currentIndex: [0, 20],
        currentPage: []
      }
    };
  },
  computed: {
    layout() {
      return {
        "--layout": this.layoutStyle
      };
    }
  },
  methods: {
    format: function(value, format) {
      if (format == undefined) {
        return value;
      } else {
        return this[format](value);
      }
    },
    filter: function(event, column) {
      console.log(event.target.value, column);
      this.tableData = this.data.filter(element => {
        return String(element[column]).includes(String(event.target.value));
      });
    },
    selectPageIndex(selected) {
      this.pagination.currentIndex[0] =
        (selected - 1) * this.pagination.perPage;
      this.pagination.currentIndex[1] = selected * this.pagination.perPage;
      this.pagination.currentPage = this.tableData.slice(
        this.pagination.currentIndex[0],
        this.pagination.currentIndex[1]
      );
      this.pagination["total"] = this.tableData.length;
    },
    sort(event, column) {
      if (typeof this.tableData[0][column] == "number") {
        if (this.sortedColumn != column) {
          this.sortedColumn = column;
          this.sortOrder = "asc";
          return this.sortDigit(this.tableData, column, "asc");
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortDigit(this.tableData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortDigit(this.tableData, column, "asc");
          }
        }
      } else if (typeof this.tableData[0][column] == "string") {
        if (this.sortedColumn != column) {
          this.sortedColumn = column;
          this.sortOrder = "asc";
          return this.sortString(this.tableData, column, "asc");
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortString(this.tableData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortString(this.tableData, column, "asc");
          }
        }
      }
    },
    sortDigit: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "number";
        if (sort == "asc") {
          return [a][0][column] - [b][0][column];
        } else {
          return [b][0][column] - [a][0][column];
        }
      });
    },
    sortString: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "string";
        if (sort == "asc") {
          return [a][0][column].localeCompare([b][0][column]);
        } else {
          return [b][0][column].localeCompare([a][0][column]);
        }
      });
    },
    setBreakpoints: function(size) {
      this.visibleColumns = this.columns.map(element => {
        return element.field;
      });
      if (size < 1600 && size > 1250) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 1) {
            return element.field;
          }
        });
      } else if (size < 1250 && size > 850) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 2) {
            return element.field;
          }
        });
      } else if (size < 850) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 3) {
            return element.field;
          }
        });
      }
    }
  },
  components: {
    PaginationComponent
  },
  props: {
    data: Array,
    columns: Array,
    initalSort: {
      type: Array,
      required: false,
      default: null
    },
    filtered: {
      type: Boolean,
      required: false,
      default: false
    },
    layoutStyle: {
      type: String,
      required: false,
      default: "auto"
    },
    paginated: {
      type: Boolean,
      required: false,
      default: false
    },
    perPage: {
      type: Number,
      required: false,
      default: 20
    }
  },
  watch: {
    perPage: function() {
      this.pagination.perPage = this.perPage;
      this.pagination.currentIndex = [0, this.perPage];
    },
    data: function() {
      this.sortedColumn = "";
      if (this.data.length) {
        this.tableData = [...this.data];
        if (this.initalSort != null) {
          this.sortedColumn = this.initalSort[0];
          this.sortOrder = this.initalSort[1];
          this.sortedType = typeof this.data[0][this.initalSort[0]];
          if (this.sortedType == "string") {
            this.sortString(this.tableData, this.sortedColumn, this.sortOrder);
          } else {
            this.sortDigit(this.tableData, this.sortedColumn, this.sortOrder);
          }
        }
      } else {
        this.tableData = [];
      }
    },
    tableData: function() {
      if (this.paginated == true) {
        this.pagination.currentPage = this.tableData.slice(
          this.pagination.currentIndex[0],
          this.pagination.currentIndex[1]
        );
      } else {
        this.pagination.currentPage = this.tableData;
      }
      this.pagination["total"] = this.tableData.length;
    }
  },
  created() {},
  mounted() {
    window.addEventListener("resize", () => {
      this.setBreakpoints(window.innerWidth);
    });
    this.setBreakpoints(window.innerWidth);
  }
};
</script>

<style lang="scss" scoped>
.custom-table-container {
  overflow-x: hidden;
}

.table-container {
  overflow: auto;
}

.custom-table {
  font-family: Nunito;
  background-color: rgba(29, 29, 29, 1);
  color: #fff;
  width: 100%;
  overflow: hidden;
  max-width: 100%;
}

.header-container {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: max-content min-content;
  align-items: center;
  justify-items: flex-start;
  margin-left: 5px;
  padding: 10px 15px 10px 3px;
  width: 100%;
}

.header-icon {
  font-size: 20px;
  color: rgb(78, 78, 78);
  &.purple {
    color: rgb(162, 122, 236);
  }
  margin-left: 5px;
}

table {
  table-layout: var(--layout);
}

thead {
  > td {
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-bottom: 1px solid rgb(58, 57, 57);
    &:hover {
      border-bottom: 1px solid rgb(162, 122, 236);
    }
  }
}

tbody {
  cursor: default;
}

tr {
  border: solid 0.5px rgba(39, 39, 39, 0);
  &:hover {
    background-color: rgba(41, 41, 43, 0.3);
    cursor: pointer;
  }
}

tr:nth-child(odd) {
  background: rgb(31, 31, 31);
  &:hover {
    background-color: rgba(41, 41, 43, 0.4);
  }
}

.cell-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 8px;
}

td {
  font-family: Nunito;
  font-size: 15px;
  white-space: nowrap;
  padding: 0px 10px 0px 5px;
  &:last-child {
    padding: 0px 20px 0px 0px;
  }
}

.purple-underline {
  border-bottom: 1px solid rgb(162, 122, 236);
}

.filters td {
  width: 100%;
}

.filters-container {
  position: relative;
  width: 95%;
  max-width: 300px;
  display: flex;
  align-items: center;
  height: 35px;
  border: 1px solid rgb(162, 122, 236);
  border-radius: 2px;
  margin: 10px auto 6px 5px;
}

.filters-container input {
  width: 95%;
  max-width: 100%;
  color: white;
  background-color: rgb(31, 31, 31);
  margin: 0 5px;
  padding: 5px 5px 5px 30px;
  position: absolute;
  border: none;
}

.filters-container i {
  padding-left: 15px;
  z-index: 2;
  color: rgb(116, 116, 116);
}

.pagination-container {
  margin-top: 15px;
  padding: 10px;
  background-color: rgb(29, 29, 29);
}

@media only screen and (max-width: 1350px) {
  .header-container {
    padding: 10px 10px;
  }

  .cell-container {
    padding: 8px 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .header-container {
    padding: 10px 5px;
  }

  .cell-container {
    padding: 8px 15px;
  }

  .header-container {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .header-container {
    padding: 10px 2px;
  }

  .header-icon {
    display: none;
  }

  .cell-container {
    padding: 8px 2px;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #292929;
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  background: #242424;
}
</style>
