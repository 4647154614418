<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { eventBus } from "../main";
import { mapGetters } from "vuex";
// import { graphic } from "echarts/core";

export default {
  name: "ClustersReturnsChart",
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "12%",
            bottom: "50%",
            left: 100,
            right: 100,
            height: "32%"

            //bottom: "3%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "58%",
            bottom: "8%",
            left: 100,
            right: 100,
            height: "32%"

            //bottom: "3%"
          }
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1]
            },
            // magicType: {
            //   type: ["line"]
            // },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Clusters Average Returns",
            left: "30",
            top: "3%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "Clusters Count",
            left: "30",
            top: "49%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { xAxisIndex: [0, 1] }
        },
        xAxis: [
          {
            type: "category",
            gridIndex: 0,
            boundaryGap: true,
            data: this.clustersAverageData["clusters_count"][0],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 1,
            boundaryGap: true,
            data: this.clustersAverageData["clusters_count"][0],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "Average Returns",
            gridIndex: 0,
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            min: function(value) {
              return value.min;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Count",
            gridIndex: 1,
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ]
      };
      if (window.innerWidth < 850) {
        (this.options["grid"] = [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "12%",
            bottom: "50%",
            left: 20,
            right: 20,
            height: "32%"

            //bottom: "3%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "58%",
            bottom: "8%",
            left: 20,
            right: 20,
            height: "32%"

            //bottom: "3%"
          }
        ]),
          (this.options["toolbox"]["show"] = false);
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][1]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }
    },
    setOptions: function() {
      var series = [];
      Object.keys(this.clustersAverageData["clusters_average_returns"]).forEach(
        key => {
          series.push({
            name: String(key),
            xAxisIndex: 0,
            yAxisIndex: 0,
            type: "bar",
            barGap: "-100%",
            itemStyle: {
              color: "rgba(53, 216, 252, 1)",
              opacity: 0.6,
              borderWidth: 1,
              borderType: "solid",
              borderColor: "rgba(14, 14, 14, 0.9)"
            },
            data: this.clustersAverageData["clusters_average_returns"][key]
          });
        }
      );

      series.push({
        name: "Cluster Count",
        xAxisIndex: 1,
        yAxisIndex: 1,
        type: "bar",
        barGap: "-100%",
        itemStyle: {
          color: "rgba(53, 216, 252, 1)",
          opacity: 0.6,
          borderWidth: 1,
          borderType: "solid",
          borderColor: "rgba(14, 14, 14, 0.9)"
        },
        data: this.clustersAverageData["clusters_count"][1]
      });

      this.options["series"] = series;
      this.$refs.priceChart.setOption(this.options);
    }
  },
  computed: {
    ...mapGetters({
      clustersAverageData: "getClustersAverageData"
    })
  },
  watch: {
    clustersAverageData: function() {
      this.setChart();
      this.setOptions();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
      this.setOptions();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 900px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
