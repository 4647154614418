<template>
  <div>
    <RegistrationTemplate class="registration" :class="{ visible: showSignup }">
      <template v-slot:function>
        <div class="title">Sign Up</div>
        <div v-if="selectedSubscription == 'free'" class="plan">Free plan</div>
        <div v-if="selectedSubscription == 'basic'" class="plan">
          Basic plan $39 / month
        </div>
      </template>
      <template v-slot:user>
        <b-field>
          <b-input
            placeholder="Enter Username (alphanumeric, max 20)"
            minlength="5"
            maxlength="20"
            pattern="[A-Za-z0-9_]*"
            size="is-small"
            icon="account"
            class="form-input"
            v-model="username"
            ref="username_input"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            placeholder="Enter Email"
            size="is-small"
            type="email"
            icon="email"
            class="form-input"
            v-model="email"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            placeholder="Enter Password (max 20)"
            type="password"
            size="is-small"
            minlength="8"
            maxlength="20"
            pattern="[\S]*"
            icon="lock"
            class="form-input"
            v-model="password"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            placeholder="Confirm Password"
            type="password"
            size="is-small"
            minlength="8"
            maxlength="20"
            pattern="[\S]*"
            icon="lock"
            class="form-input"
            v-model="password_confirmation"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-checkbox v-model="terms">
            I agree to the terms and conditions
          </b-checkbox>
        </b-field>
        <button class="registration-button" @click="submitSignup">
          Sign Up
        </button>
        <!-- <span class="already">Already have an account? Log in</span> -->
      </template>
    </RegistrationTemplate>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import RegistrationTemplate from "@/components/RegistrationTemplate.vue";
export default {
  name: "SignUp",
  mixins: [utils],
  data() {
    return {
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
      terms: false
    };
  },
  components: {
    RegistrationTemplate
  },
  computed: {
    ...mapGetters({
      showSignup: "getShowSignup",
      selectedSubscription: "getSelectedSubscription",
      alertShow: "getAlertShow",
      stripeSession: "get_stripe_session"
    })
  },
  methods: {
    submitSignup: function() {
      if (this.username.length < 5) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter a username");
      } else if (this.email.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter an email");
      } else if (this.password.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter a password");
      } else if (this.password_confirmation.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please confirm your password");
      } else if (this.password != this.password_confirmation) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Passwords do not match");
      } else if (this.terms == false) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit(
          "setAlertMessage",
          "You must accept Terms and Conditions"
        );
      } else {
        this.$store.dispatch({
          type: "submitSignup",
          username: this.username,
          email: this.email,
          password: this.password,
          password_confirmation: this.password_confirmation,
          selectedSubscription: this.selectedSubscription
        });
      }
    }
  },
  watch: {
    stripeSession: function() {
      this.stripe.redirectToCheckout({ sessionId: this.stripeSession });
    }
  },
  mounted() {
    if (window.Stripe == undefined) {
      this.loadScript("https://js.stripe.com/v3").then(s => {
        document.body.appendChild(s);
      });
    }
    eventBus.$on("closingAlert", msg => {
      if (
        msg ==
        "You have successfully registered. We have sent you a confirmation email to activate your account."
      ) {
        this.$store.commit("setShowSignup", false);
      } else if (
        msg ==
        "You have successfully registered. We have sent you a confirmation email to activate your account. You will now be redirected to Stripe for checkout."
      ) {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        this.$store.dispatch({
          type: "checkoutSession",
          email: this.email
        });
        this.$store.commit("setShowSignup", false);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.registration {
  visibility: hidden;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0ms, visibility 250ms;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 10000000;
}

.visible {
  visibility: visible;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0s, visibility 250ms;
  transform: translateY(0px);
  opacity: 1;
}

.visible::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.visible {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.plan {
  color: #fff;
  margin-bottom: 10px;
}
</style>
