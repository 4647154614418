<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
//import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "SPYToVIXDistributionChart",
  props: {
    data: Object,
    feature: String,
    period: String,
    minmax: Array
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "15%",
          bottom: "8%",
          left: "6%",
          right: "5%",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "Probability of SPY Forward Returns | VIX Returns",
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          },
          formatter: function(params) {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            return (
              colorSpan(params.color) +
              "&nbsp;" +
              "Probability: " +
              params.data[2] +
              "%" +
              "<br/>" +
              "SPY Forward Return: " +
              (-3 + params.data[1] * 0.1).toFixed(1) +
              "%" +
              "<br/>" +
              "VIX Return: " +
              params.name +
              "%"
            );
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "category",
          name: "VIX Returns %",
          data: this.data[this.feature].index.x,
          nameLocation: "middle",
          nameGap: 35,
          nameTextStyle: {
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "category",
            name: "SPY Forward Returns %",
            data: this.data[this.feature].index.y,
            show: true,
            nameLocation: "middle",
            nameGap: 35,
            nameTextStyle: {
              fontSize: 16
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        visualMap: {
          type: "continuous",
          min: this.minmax[0],
          max: this.minmax[1],
          calculable: false,
          orient: "horizontal",
          left: "center",
          bottom: "15%",
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          },
          inRange: {
            // color: [
            //   "rgba(36, 251, 255, 1)",
            //   "rgba(89, 164, 255, 1)",
            //   "rgba(25, 52, 255, 1)",
            //   "rgba(73, 36, 255, 1)",
            //   "rgba(255, 36, 142, 1)",
            //   "rgba(255, 20, 110, 1)"
            // ],
            // color: [
            //   "rgba(65, 0, 143, 1)",
            //   "rgba(179, 0, 255, 1)",
            //   "rgba(224, 112, 146, 1)",
            //   "rgba(235, 150, 101, 1)",
            //   "rgba(255, 223, 79, 1)"
            // ],
            color: [
              "rgba(67, 207, 250, 1)",
              //"rgba(72, 149, 239, 1)",
              "rgba(67, 97, 238, 1)",
              "rgba(63, 55, 201, 1)",
              //"rgba(58, 12, 163, 1)",
              "rgba(72, 12, 168, 1)",
              "rgba(86, 11, 173, 1)",
              "rgba(114, 9, 183, 1)",
              "rgba(181, 23, 158, 1)",
              "rgba(247, 37, 133, 1)"
            ],
            symbolSize: [100, 100]
          }
        },
        series: [
          {
            data: this.data[this.feature].data[this.period],
            type: "heatmap",
            name: "%",
            itemStyle: {
              borderColor: "rgba(0, 0, 0, 1)",
              borderWidth: 0,
              shadowBlur: 0,
              //   shadowOffsetX: 0.5,
              //   shadowOffsetY: 0.5,
              shadowColor: "rgba(251, 251, 251, 0)"
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        // this.options["grid"] = {
        //   backgroundColor: "rgba(26, 217, 204, 1)",
        //   show: false,
        //   top: "23%",
        //   bottom: "8%",
        //   left: "30",
        //   right: "30",
        //   containLabel: false
        //   //bottom: "3%"
        // };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        (this.options["title"]["text"] = "SPY Returns | VIX Returns"),
          (this.options["grid"] = {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "15%",
            bottom: "8%",
            left: "8%",
            right: "5%",
            containLabel: true
            //bottom: "3%"
          });
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
