<template>
  <div class="footer">
    <div class="footer-column-container">
      <div class="footer-column">
        <div>
          <router-link to="/privacy" class="footer-link">Privacy</router-link>
        </div>
        <div>
          <router-link to="/terms" class="footer-link"
            >Terms of Use</router-link
          >
        </div>
        <div>
          <span class="footer-link email">
            info@stockgrid.io
          </span>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="footer-row">
        <div class="social">
          <a href="https://stocktwits.com/Stockgrid" target="_blank">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="45"
              viewBox="0 0 24 50"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(-10.000000, 37.000000) scale(0.02,-0.02)"
                fill="#494949"
                stroke="none"
              >
                <path
                  d="M1118 1650 l-337 -175 -1 -67 0 -67 113 -59 c61 -33 215 -112 340
-176 l228 -118 -3 118 -3 117 -192 90 c-106 50 -193 93 -193 96 1 3 87 46 192
96 105 49 192 95 195 100 2 6 2 58 1 116 l-3 104 -337 -175z"
                />
                <path
                  d="M780 1108 l0 -113 191 -90 c105 -49 193 -93 195 -97 3 -4 -83 -51
-191 -105 l-196 -97 2 -107 2 -108 126 66 c69 36 158 83 196 103 39 21 129 68
200 105 72 37 136 72 143 77 7 6 12 34 12 68 l0 58 -67 35 c-38 19 -171 87
-298 152 -126 65 -249 128 -272 142 l-43 24 0 -113z"
                />
              </g>
            </svg>
          </a>
        </div>
        <div class="social">
          <a href="https://twitter.com/stockgrid_io" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="#494949">
                <path
                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
                />
              </g>
            </svg>
          </a>
        </div>
        <div class="social">
          <a href="https://www.reddit.com/user/Stockgrid" target="_blank">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="#494949">
                <path
                  d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"
                />
              </g>
            </svg>
          </a>
        </div>
        <div class="social">
          <a href="https://discord.gg/5pXVFpGeA2" target="_blank">
            <svg
              width="27px"
              height="27px"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="bi bi-discord"
            >
              <path
                d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z"
              />
              <path
                d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;">
      <span class="footer-link copyright"
        >© 2022 Stockgrid.io. All rights reserved.</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.footer {
  height: 100px;
  background-color: #0f0f0f !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: 0;
  width: 100%;
}

.footer-column-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.footer-column {
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  margin-left: 15px;
}

.footer-row {
  margin-left: 15px;
  width: 180px;
  display: flex;
  flex-direction: row;
}

.vertical-line {
  border-left: 1px solid #494949;
  height: 70px;
}

.socials {
  height: 100%;
}

.social {
  width: 24px;
  height: 24px;
  margin: 0 5px;
  > a svg {
    color: rgb(73, 73, 73) !important;
  }
  > a:hover svg {
    fill: var(--amaranth);
    cursor: pointer;
  }
}
.social:hover g {
  fill: var(--amaranth);
  cursor: pointer;
}

.footer-link {
  color: #494949 !important;
}
.footer-link:hover {
  color: var(--amaranth) !important;
}
.footer-link.router-link-active {
  padding: 0;
}

.footer-disclaimer {
  color: #424141;
  padding-top: 5px;
  font-size: 13px;
}

.email:hover {
  cursor: default;
}

.copyright {
  padding-top: 20px;
  font-size: 13px;
  margin-left: -25px;
}

@media only screen and (max-width: 1250px) {
  .trading-view {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .trading-view {
    width: 95%;
  }
}
</style>
