<template>
  <div class="cluster-control">
    <div class="grid-container">
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Pattern Length
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="length"
          class="select-component"
          :default="{ value: '10', label: '10' }"
          :options="[
            { value: '5', label: '5' },
            { value: '10', label: '10' },
            { value: '20', label: '20' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Number of Clusters
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="clusters"
          class="select-component"
          :default="{ value: '8', label: '8' }"
          :options="[
            { value: '2', label: '2' },
            { value: '3', label: '3' },
            { value: '4', label: '4' },
            { value: '5', label: '5' },
            { value: '6', label: '6' },
            { value: '7', label: '7' },
            { value: '8', label: '8' },
            { value: '9', label: '9' },
            { value: '10', label: '10' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Metric
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="metric"
          disabled
          class="select-component"
          :default="{ value: 'euclidean', label: 'Euclidean' }"
          :options="[
            { value: 'euclidean', label: 'Euclidean' },
            { value: 'dtw', label: 'DTW' },
            { value: 'soft', label: 'SoftDWT' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Iterations
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="iterations"
          class="select-component"
          :default="{ value: '3', label: '10' }"
          :options="[
            { value: '1', label: '2' },
            { value: '2', label: '5' },
            { value: '3', label: '10' },
            { value: '4', label: '20' },
            { value: '5', label: '30' },
            { value: '6', label: '40' },
            { value: '7', label: '50' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Features
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="features"
          class="select-component"
          :default="{ value: 'pcvol', label: 'Returns and Volatility' }"
          :options="[
            { value: 'pct', label: 'Returns' },
            { value: 'volatility', label: 'Volatility' },
            { value: 'pcvol', label: 'Returns and Volatility' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Training Window
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="training"
          class="select-component"
          :default="{ value: '400', label: '400' }"
          :options="[
            { value: '200', label: '200' },
            { value: '400', label: '400' },
            { value: '600', label: '600' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
    </div>
    <hr />
    <button class="datatable-button" v-on:click="updateClustersData()">
      Update
    </button>
  </div>
</template>

<script>
const SelectComponent = () => import("@/components/SelectComponent.vue");

export default {
  name: "ClustersControl",
  data() {
    return {
      clusters: 8,
      length: 10,
      metric: "euclidean",
      iterations: 3,
      features: "pcvol",
      training: 400,
      backgroundColor: "rgb(34, 33, 33)"
    };
  },
  methods: {
    updateClustersData: function() {
      this.$store.dispatch({
        type: "getClustersData",
        ticker: this.ticker,
        nbClusters: this.clusters,
        length: this.length,
        metric: this.metric,
        iterations: this.iterations,
        features: this.features,
        trainingWindow: this.training
      });
      this.$emit("gettingClustersData");
    }
  },
  props: {
    ticker: String
  },
  components: {
    SelectComponent
  },
  watch: {
    ticker: function() {
      this.updateClustersData();
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.cluster-control {
  display: flex;
  flex-direction: column;
  background-color: var(--app-components-background-color);
  min-height: 100px;
  color: #ffffff;
  min-width: min-content;
  width: 70%;
  max-width: 100%;
  font-family: var(--main-font);
  align-items: center;
  flex-grow: 1;
  padding: 10px 10px 10px 15px;
}

hr {
  background-color: rgb(58, 58, 58);
  width: 80%;
  display: block;
  border: none;
  height: 0.1px;
  margin: 5px 0 0 0;
}

.grid-container {
  width: 100%;
  padding: 10px 10px 0 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 0 10px;
}

.grid-item {
  width: 100%;
  text-align: left;
  height: 50px;
  padding: 5px 0 5px 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.datatable-tag {
  background-color: #222121 !important;
}

.datatable-tag p {
  word-break: break-all;
}

.datatable-button {
  display: inline-block;
  box-sizing: border-box;
  margin: 10px;
  background-color: #222121;
  padding: 8px 18px;
  font-family: var(--main-font);
  color: var(--purple-darker);
  font-size: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 160px;
  height: 38px;
}

.datatable-button:active {
  color: var(--purple-darker2);
  box-shadow: 0 -1px 0 var(--purple-darker2) inset;
}

.datatable-button:hover {
  background-color: var(--app-components-background-color-lighter);
}

.select-component {
  min-width: 175px;
}

@media only screen and (max-width: 1230px) {
  hr {
    margin: 15px 0 10px 0;
  }
}

@media only screen and (max-width: 650px) {
  .cluster-control {
    width: 100%;
  }
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: row;
    grid-gap: 0 10px;
  }
}
</style>
