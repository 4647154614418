<template>
  <transition name="fade">
    <div class="alert-container" v-show="alertShow">
      <div class="alert">
        {{ alertMessage }}
        <span
          v-if="alertContentShow"
          class="action-link"
          @click="alertAction"
          >{{ alertContent }}</span
        >
        <div class="alert_buttons">
          <button v-if="alertCancel" @click="cancel">Cancel</button>
          <button @click="close">{{ alertButton }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";

export default {
  name: "Alert",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      alertShow: "getAlertShow",
      alertMessage: "getAlertMessage",
      alertContentShow: "getAlertContentShow",
      alertContent: "getAlertContent",
      alertButton: "getAlertButton",
      alertCancel: "getAlertCancel"
    })
  },
  methods: {
    close: function() {
      this.$store.commit("setAlertShow", false);
      eventBus.$emit("closingAlert", this.alertMessage);
      setTimeout(() => {
        this.$store.commit("setAlertMessage", "");
        this.$store.commit("setAlertContentShow", false);
        this.$store.commit("setAlertContent", "");
        this.$store.commit("setAlertCancel", false);
        this.$store.commit("setAlertButton", "Close");
      }, 50);
    },
    cancel: function() {
      this.$store.commit("setAlertShow", false);
      eventBus.$emit("cancelAlert");
      setTimeout(() => {
        this.$store.commit("setAlertButton", "Close");
        this.$store.commit("setAlertCancel", false);
        this.$store.commit("setAlertContentShow", false);
      }, 50);
    },
    alertAction: function() {
      this.$store.commit("setAlertShow", false);
      eventBus.$emit("alertAction", this.alertContent);
      setTimeout(() => {
        this.$store.commit("setAlertMessage", "");
        this.$store.commit("setAlertContentShow", false);
        this.$store.commit("setAlertContent", "");
        this.$store.commit("setAlertCancel", false);
        this.$store.commit("setAlertButton", "Close");
      }, 50);
    }
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 300ms, transform 300ms;
}

.fade-leave-active {
  transition: opacity 30ms, transform 30ms;
}

.fade-enter {
  transform: translateY(-10px);
}

.alert-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.alert {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 0px 10px;
  font-family: Nunito-Light;
  color: #fff;
  top: -5%;
  // left: calc(50% - 210px);
  background-color: rgba(26, 26, 26, 1);
  height: max-content;
  width: 420px;
  max-width: 80%;
  border: solid 1px var(--purple-darker);
  //border-top: solid 1px var(--purple-darker);
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
  box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
}

.alert button {
  display: inline-block;
  box-sizing: border-box;
  margin: 20px 8px 20px 8px;
  background-color: rgba(31, 31, 31, 1);
  padding: 8px 18px;
  font-family: var(--main-font);
  font-weight: 300;
  color: var(--purple-darker);
  font-size: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 160px;
  height: 38px;
}

.alert button:focus {
  //color: var(--purple-darker2);
  //box-shadow: 0 -1px 0 var(--purple-darker2) inset;
  background-color: rgba(31, 31, 31, 1) !important;
}

.alert button:hover {
  background-color: var(--app-components-background-color-lighter);
}

.alert .action-link {
  color: var(--purple-darker);
  cursor: pointer;
  &:active {
    color: var(--purple-darker2);
  }
}
</style>
