<template>
  <div>
    <div class="charts">
      <div class="charts-sub-container">
        <v-chart class="chart" autoresize ref="calls_chart" />
        <v-chart class="chart" autoresize ref="puts_chart" />
      </div>
      <div class="charts-sub-container">
        <v-chart class="chart" autoresize ref="net_chart" />
        <v-chart class="chart" autoresize ref="put_call_chart" />
      </div>
    </div>
  </div>
</template>

<script>
import "echarts";
//import { graphic } from "echarts/core";
//import { eventBus } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "OptionsFlowTopPremiumsChart",
  data() {
    return {
      //dataset: "premiums",
      title: "",
      dataset: [],
      charts: [
        { name: "Total Call Premiums", chart: "calls_chart", field: "calls" },
        { name: "Total Put Premiums", chart: "puts_chart", field: "puts" }
      ],
      ref: ""
    };
  },
  computed: {
    ...mapGetters({
      optionsFlowTopPremiumsData: "getOptionsFlowTopPremiumsData"
    })
  },
  methods: {
    setChart: function(meta) {
      this.options = {
        color: [
          "rgba(45, 252, 228, 1)",
          "rgba(89, 249, 255, 1)",
          "rgba(54, 211, 255, 1)",
          "rgba(53, 126, 252, 1)",
          "rgba(106, 64, 245, 1)",
          "rgba(120, 79, 255, 1)",
          "rgba(221, 72, 247, 1)",
          "rgba(247, 45, 227, 1)",
          "rgba(242, 29, 157, 1)",
          "rgba(247, 37, 97, 1)",
          "rgba(247, 37, 72, 1)"
        ],
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "20%",
          bottom: "0%",
          left: "3%",
          right: "5%",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: meta.name,
          left: "30",
          top: "10%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 18
          }
        },
        tooltip: {
          trigger: "item",
          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { yAxisIndex: [0] }
        }
      };
      if (window.innerWidth < 850) {
        this.options["tooltip"]["confine"] = true;
      }
    },
    set_series: function(meta) {
      this.options["series"] = [
        {
          name: meta.name + " $",
          type: "pie",
          radius: [60, 80],
          data: this.optionsFlowTopPremiumsData.sectors[meta.field],
          label: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 12
          },
          itemStyle: {
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.2,
            shadowBlur: 3,
            borderCap: "round"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ];
      this.$refs[meta.chart].setOption(this.options);
    },
    setNetSeries: function(meta) {
      this.options["series"] = [
        {
          name: meta.name + " $",
          type: "pie",
          radius: [20, 30],
          data: this.optionsFlowTopPremiumsData.sectors[meta.field].filter(
            element => element.value < 0
          ),
          label: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 12
          },
          itemStyle: {
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.2,
            shadowBlur: 3,
            borderCap: "round"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        },
        {
          name: meta.name + " $",
          type: "pie",
          radius: [60, 80],
          data: this.optionsFlowTopPremiumsData.sectors[meta.field].filter(
            element => element.value > 0
          ),
          label: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 12
          },
          itemStyle: {
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.2,
            shadowBlur: 3,
            borderCap: "round"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ];
      this.$refs[meta.chart].setOption(this.options);
    },
    setPutCallSeries: function(name) {
      this.options["color"] = ["rgba(3, 252, 235, 1)", "rgba(247, 37, 97, 1)"];
      this.options["series"] = [
        {
          name: name + " $",
          type: "pie",
          radius: [20, 60],
          data: this.optionsFlowTopPremiumsData.sum,
          label: {
            color: "rgba(255, 255, 255, 1)",
            fontSize: 12
          },
          roseType: "area",
          itemStyle: {
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.2,
            shadowBlur: 5,
            opacity: 1,
            borderCap: "round"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ];
      this.$refs["put_call_chart"].setOption(this.options);
    }
  },
  watch: {
    optionsFlowTopPremiumsData: function() {
      if (Object.keys(this.optionsFlowTopPremiumsData).length > 1) {
        this.setChart({
          name: "Put to Call Premiums",
          chart: "put_call_chart",
          field: ""
        });
        this.setPutCallSeries("Put/Call");
        this.charts.forEach(meta => {
          this.setChart(meta);
          this.set_series(meta);
        });
        this.setChart({
          name: "Total Net Premiums",
          chart: "net_chart",
          field: "net"
        });
        this.setNetSeries({
          name: "Total Net Premiums",
          chart: "net_chart",
          field: "net"
        });
      }
    }
  },
  mounted() {}
};
</script>

<style scoped>
.charts {
  display: flex;
  flex-wrap: nowrap;
}

.charts-sub-container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.chart {
  height: 350px;
  width: 50%;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1000px) {
  .charts {
    display: flex;
    flex-wrap: wrap;
  }

  .charts-sub-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  .chart {
    height: 350px;
    width: 50%;
  }
}

@media only screen and (max-width: 650px) {
  .charts-sub-container {
    flex-wrap: wrap;
  }

  .chart {
    height: 350px;
    width: 100%;
  }
}
</style>
