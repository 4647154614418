<template>
  <div class="factor-analysis">
    <h1 class="page-title">Factor Analysis</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
    </div>
    <div class="control-container">
      <SelectComponent
        v-model="factor"
        class="select-component"
        :maxHeight="'500px'"
        :default="defaultFactor"
        :options="factors"
        :backgroundColor="backgroundColor"
      >
      </SelectComponent>
      <div class="radio-container">
        <input id="r1" type="radio" value="daily" v-model="timeframe" />
        <label for="r1">Daily</label>
        <input
          id="r2"
          type="radio"
          value="weekly"
          v-model="timeframe"
          checked
        />
        <label for="r2">Weekly</label>
      </div>
    </div>
    <div class="last-signal" v-show="priorData">
      <h2>Last Signal</h2>
      <br />
      <div
        v-if="
          Object.keys(factorAnalysisData).includes('last_signal_data') &&
            Object.keys(factorAnalysisData['last_signal_data']).length > 0
        "
      >
        The last signal on {{ ticker }} for the
        {{ factors.filter(fact => fact.value == factor)[0]["label"] }} factor
        was triggered on
        <span v-show="timeframe == 'weekly'">the week of</span>
        {{ factorAnalysisData["last_signal_data"]["date"] }}. The expected
        return for a {{ factorAnalysisData["last_signal_data"]["period"]
        }}{{ timeframe == "weekly" ? "-Week" : "-Day" }} investment horizon
        using this signal is
        <span class="stats"
          >{{ factorAnalysisData["last_signal_data"]["expected_ret"] }}%</span
        >
        with an historical profit probability of
        <span class="stats"
          >{{ factorAnalysisData["last_signal_data"]["profitable_pct"] }}%</span
        >
        <span
          v-show="factorAnalysisData['last_signal_data']['profit_loss'] != '-'"
        >
          and a profit-loss ratio of
          {{ factorAnalysisData["last_signal_data"]["profit_loss"] }}</span
        >. <br /><br />
        This specific investment horizon is the one that maximizes the
        risk-adjusted probability of profit. Expected returns and probabilities
        of profit for other investment horizons are contained in the statistics
        table below.
      </div>
      <div
        v-if="
          Object.keys(this.factorAnalysisData).includes('last_signal_data') &&
            Object.keys(this.factorAnalysisData['last_signal_data']).length == 0
        "
      >
        Past signals on {{ ticker }} for the
        {{ factors.filter(fact => fact.value == factor)[0]["label"] }} factor do
        not meet quality requirements.
      </div>
    </div>
    <div class="chart-container" v-show="priorData">
      <FactorAnalysisPredictionChart
        :title="String(ticker) + ' Prediction Chart'"
        :data="factorAnalysisData['predictions_data']"
        :signal="factorAnalysisData['last_signal_data']"
        ref="predChart"
      ></FactorAnalysisPredictionChart>
    </div>
    <div class="chart-container" v-show="priorData">
      <FactorAnalysisReturnsChart
        :data="factorAnalysisData['past_signals_returns_data']"
        ref="returnChart"
      ></FactorAnalysisReturnsChart>
    </div>
    <div class="chart-container" v-show="priorData">
      <FactorAnalysisReturnHistogramChart
        :data="factorAnalysisData['past_signals_return_hist_data']"
      ></FactorAnalysisReturnHistogramChart>
    </div>
    <div class="tag-container" v-show="priorData">
      <div class="custom-tag">Statistics</div>
    </div>
    <div class="table-container" v-show="priorData">
      <TableComponent
        v-show="visibleTimeframe == 'daily'"
        :data="factorAnalysisData['factor_statistics_data']"
        :columns="[
          { label: 'Field', field: 'index' },
          { label: '1D', field: 'fwd_ret_1' },
          { label: '5D', field: 'fwd_ret_5' },
          { label: '10D', field: 'fwd_ret_10' },
          { label: '20D', field: 'fwd_ret_20' },
          { label: '40D', field: 'fwd_ret_40' },
          { label: '60D', field: 'fwd_ret_60' },
          { label: '120D', field: 'fwd_ret_120' }
        ]"
      ></TableComponent>
      <TableComponent
        v-show="visibleTimeframe == 'weekly'"
        :data="factorAnalysisData['factor_statistics_data']"
        :columns="[
          { label: 'Field', field: 'index' },
          { label: '1W', field: 'fwd_ret_1' },
          { label: '2W', field: 'fwd_ret_2' },
          { label: '4W', field: 'fwd_ret_4' },
          { label: '8W', field: 'fwd_ret_8' },
          { label: '12W', field: 'fwd_ret_12' },
          { label: '26W', field: 'fwd_ret_26' },
          { label: '52W', field: 'fwd_ret_52' }
        ]"
      ></TableComponent>
    </div>
    <div class="text-content" v-show="priorData">
      The statistics include all signals. The last signal expected return,
      profitable%, and profit-loss ratio might differ slightly from the
      statistics since these include partially completed and completed signals.
      The key statistics for the last signal are only calculated using the past
      signals (not including the last one). The statistics allow you to evaluate
      the performance of the signals at different time horizons as well as the
      integrity of the signals.
    </div>
    <div class="tag-container" v-show="priorData">
      <div class="custom-tag">Past Signals</div>
    </div>
    <div class="table-container" v-show="priorData">
      <TableComponent
        :paginated="paginated"
        v-show="visibleTimeframe == 'daily'"
        :data="factorAnalysisData['past_signals_data']"
        :columns="[
          { label: 'Date', field: 'date' },
          { label: '1D', field: 'fwd_ret_1' },
          { label: '5D', field: 'fwd_ret_5' },
          { label: '10D', field: 'fwd_ret_10' },
          { label: '20D', field: 'fwd_ret_20' },
          { label: '40D', field: 'fwd_ret_40' },
          { label: '60D', field: 'fwd_ret_60' },
          { label: '120D', field: 'fwd_ret_120' }
        ]"
      ></TableComponent>
      <TableComponent
        :paginated="paginated"
        v-show="visibleTimeframe == 'weekly'"
        :data="factorAnalysisData['past_signals_data']"
        :columns="[
          { label: 'Date', field: 'date' },
          { label: '1W', field: 'fwd_ret_1' },
          { label: '2W', field: 'fwd_ret_2' },
          { label: '4W', field: 'fwd_ret_4' },
          { label: '8W', field: 'fwd_ret_8' },
          { label: '12W', field: 'fwd_ret_12' },
          { label: '26W', field: 'fwd_ret_26' },
          { label: '52W', field: 'fwd_ret_52' }
        ]"
      ></TableComponent>
    </div>
    <div class="chart-container" v-show="priorData">
      <FactorAnalysisCumulativeReturnsChart
        :data="factorAnalysisData['cumulative_returns_data']"
        :signal="factorAnalysisData['last_signal_data']"
      ></FactorAnalysisCumulativeReturnsChart>
    </div>
    <div class="text-content" v-show="priorData">
      The cumulative returns chart allows you to see the cumulative returns of
      prior signals (including the last one if it is completed). Note that the
      implementation of this strategy would not have generated those exact same
      returns. Differences would occur in the first few signals. For instance,
      the first two signals would not have been taken since there were no prior
      signal statistics at that time. Likewise, if the first few signals
      performed poorly, the subsequent one would not have been taken. These
      signals are still included when looking at past data as a whole in order
      to analyze the factor.
    </div>
    <div class="no-data" v-show="priorData == false">
      No data
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      This page generates trade signals based on the historical return
      distribution of a set of factors. Past performance charts and statistics
      are included to help you evaluate the integrity of the signal. When a
      signal is deemed to have sufficient quality, a prediction is made. The
      signal data will be displayed in the <i>Last Signal</i> section and the
      <i>Prediction chart</i>. The last signal will be the last one generated by
      the factor and might be already completed.
    </div>
  </div>
</template>

<script>
const TickerInfo = () => import("@/components/TickerInfo.vue");
const SelectComponent = () => import("@/components/SelectComponent.vue");
const TableComponent = () => import("@/components/TableComponent.vue");
const FactorAnalysisReturnsChart = () =>
  import("@/components/FactorAnalysisReturnsChart");
const FactorAnalysisReturnHistogramChart = () =>
  import("@/components/FactorAnalysisReturnHistogramChart");
const FactorAnalysisPredictionChart = () =>
  import("@/components/FactorAnalysisPredictionChart");
const FactorAnalysisCumulativeReturnsChart = () =>
  import("@/components/FactorAnalysisCumulativeReturnsChart");

import { mapGetters } from "vuex";

export default {
  name: "FactorAnalysis",
  data() {
    return {
      factor: "uptrend",
      factors: [
        { value: "filtered_momentum", label: "Filtered Momentum" },
        { value: "momentum", label: "Momentum" },
        { value: "new_high", label: "New High" },
        { value: "uptrend", label: "New Uptrend" },
        { value: "relative_strength", label: "Relative Strength (S&P500)" },
        { value: "roc_curve", label: "ROC Curve" },
        { value: "sus_momentum", label: "Sustained Momentum" },
        { value: "dark_pools", label: "Dark Pools" }
      ],
      defaultFactor: {
        value: "uptrend",
        label: "New Uptrend"
      },
      timeframe: "weekly",
      priorData: true,
      // returnsChart: true,
      paginated: false,
      visibleTimeframe: "weekly",
      routeTrigger: false,
      backgroundColor: "rgb(34, 33, 33)"
    };
  },
  head: {
    title: {
      inner: "Factor Analysis"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Analyze the forward return distribution of specific factors and take trades backed by solid analysis. Help you uncover alpha and maximize your profit potential."
      }
    ]
  },
  methods: {
    updateTickerData(ticker) {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getFactorAnalysisData",
        ticker: ticker,
        factor: this.factor,
        timeframe: this.timeframe
      });
    }
  },
  computed: {
    ...mapGetters({
      ticker: "getTicker",
      loading: "getLoading",
      factorAnalysisData: "getFactorAnalysisData"
    })
  },
  components: {
    TickerInfo,
    SelectComponent,
    TableComponent,
    FactorAnalysisReturnsChart,
    FactorAnalysisReturnHistogramChart,
    FactorAnalysisPredictionChart,
    FactorAnalysisCumulativeReturnsChart
  },
  watch: {
    factorAnalysisData: function() {
      // console.log(this.factorAnalysisData);
      // console.log(this.factorAnalysisData["predictions_data"]);
      this.routeTrigger = false;
      this.$store.commit("setLoading", false);
      this.priorData = Object.keys(this.factorAnalysisData).length > 0;
      // this.returnsChart =
      //   this.factorAnalysisData["past_signals_return_hist_data"][
      //     Object.keys(
      //       this.factorAnalysisData["past_signals_return_hist_data"]
      //     )[0]
      //   ].length > 0;
      this.visibleTimeframe = this.timeframe;
      if (this.factorAnalysisData["past_signals_data"].length > 20) {
        this.paginated = true;
      } else {
        this.paginated = false;
      }
    },
    factor: function() {
      if (this.routeTrigger == false) {
        this.$store.commit("setLoading", true);
        this.$store.dispatch({
          type: "getFactorAnalysisData",
          ticker: this.ticker,
          factor: this.factor,
          timeframe: this.timeframe
        });
      }
    },
    timeframe: function() {
      if (this.routeTrigger == false) {
        this.$store.commit("setLoading", true);
        this.$store.dispatch({
          type: "getFactorAnalysisData",
          ticker: this.ticker,
          factor: this.factor,
          timeframe: this.timeframe
        });
      }
    }
  },
  mounted() {
    //If the url param is not empty, the ticker will be equal to the ticker passed in the url
    if (typeof this.$router.history.current.params["ticker"] != "undefined") {
      var ticker = this.$router.history.current.params["ticker"].toUpperCase();
      this.$store.commit("setTicker", ticker);
    }
    if (typeof this.$router.history.current.params["factor"] != "undefined") {
      this.factor = this.$router.history.current.params["factor"];
      this.defaultFactor = this.factors.filter(
        element => element.value == this.factor
      )[0];
    }
    if (
      typeof this.$router.history.current.params["timeframe"] != "undefined"
    ) {
      this.timeframe = this.$router.history.current.params["timeframe"];
    }

    this.$store.commit("setLoading", true);
    this.routeTrigger = true;
    this.$store.dispatch({
      type: "getFactorAnalysisData",
      ticker: this.ticker,
      factor: this.factor,
      timeframe: this.timeframe
    });
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.control-container,
.chart-container,
.table-container,
.tag-container,
.no-data,
.last-signal,
.price-chart-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-tag {
  background-color: var(--app-components-background-color);
  color: white;
  font-family: Nunito;
  font-size: 16px;
  border-radius: 3px;
  width: max-content;
  padding: 5px 10px;
}

.factor-analysis {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
  color: white;
  font-family: Nunito;
}

.tag-container,
.control-container {
  display: flex;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.last-signal {
  text-align: left;
  padding: 15px 35px;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  & h2 {
    font-size: 20px;
  }
  & .stats {
    color: var(--amaranth);
  }
}

.no-data {
  text-align: center;
  font-size: 19px;
  padding: 15px 35px;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.select-component {
  width: 300px;
}

.radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.ticker-info {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
}

@media only screen and (max-width: 1230px) {
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .price-chart-container,
  .control-container,
  .chart-container,
  .table-container,
  .tag-container,
  .no-data,
  .last-signal,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .price-chart-container,
  .chart-container,
  .control-container,
  .table-container,
  .tag-container,
  .no-data,
  .last-signal,
  .ticker-info-container {
    width: 95%;
  }
}

// /* Scrollbar Styling */
// ::-webkit-scrollbar {
//   width: 5px !important;
// }

// ::-webkit-scrollbar-track {
//   background-color: #292929;
//   -webkit-border-radius: 5px !important;
//   border-radius: 5px !important;
// }

// ::-webkit-scrollbar-thumb {
//   -webkit-border-radius: 5px !important;
//   border-radius: 5px !important;
//   background: #242424;
// }
</style>
