<template>
  <div class="subscription-cards-container">
    <div
      v-show="user.username == ''"
      class="header"
      data-aos="fade-up"
      data-aos-delay="0"
      data-aos-easing="ease-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-duration="1500"
    >
      Sign Up to Stockgrid
    </div>
    <div
      v-show="user.subscription == 'Free' || user.subscription == 'free'"
      class="header"
      data-aos="fade-up"
      data-aos-delay="0"
      data-aos-easing="ease-out-cubic"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-duration="1500"
    >
      Subscribe to Stockgrid
    </div>
    <div class="subscription-cards-sub-container">
      <!-- v-if="user.username == '' && path != 'landing'" -->
      <!-- v-if="user.username == ''" -->
      <!-- Free plan -->
      <div
        class="subscription-cards border-gradient border-gradient-purple-left"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-easing="ease-out-cubic"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-duration="1500"
      >
        <div class="sc-header">
          <span>Free Plan</span>
          <span>$0</span>
        </div>
        <div class="sc-text">
          <hr />
          <span>Access to all free features</span>
          <!-- <span class="free">Real-time option data</span> -->
          <hr />
        </div>
        <button @click="toggleSignup('free')">Sign Up</button>
        <div class="existing">
          Already have an account?
          <span @click="toggle_login">Log in</span>
        </div>
      </div>
      <!-- Basic plan -->
      <!-- <div
        class="subscription-cards border-gradient border-gradient-purple-right"
        data-aos="fade-up"
        data-aos-delay="0"
        data-aos-easing="ease-out-cubic"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-duration="1500"
      >
        <div class="sc-header">
          <span>Basic Plan</span>
          <span>$39 / month</span>
        </div>
        <div class="sc-text">
          <hr />
          <span>Real-time options flow</span>
          <span>Historical data</span>
          <span>Advanced Filtering</span>
          <span>Cumulative Stats</span>
          <span>Top Buy/Sell Levels</span>
          <hr />
        </div>
        <button v-if="user.username == ''" @click="toggleSignup('basic')">
          Sign Up
        </button>
        <button
          v-if="user.subscription == 'free' || user.subscription == 'Free'"
          @click="toggleSubscribe('basic')"
        >
          Subscribe
        </button>
        <div class="existing" v-if="user.username == ''">
          Already have an account?
          <span @click="toggle_login">Log in</span>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import { mapGetters } from "vuex";
import AOS from "aos";
export default {
  name: "SubscriptionCards",
  mixins: [utils],
  data() {
    return {
      path: ""
    };
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      stripeSession: "get_stripe_session"
    })
  },
  methods: {
    toggleSignup: function(subscription) {
      this.$store.commit("setShowSignup", true);
      this.$store.commit("setSelectedSubscription", subscription);
    },
    toggle_login: function() {
      this.$store.commit("setShowLogin", true);
    },
    toggleSubscribe: function() {
      try {
        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        this.$store.dispatch({
          type: "checkoutSession",
          email: this.user.email
        });
      } catch (e) {
        console.log(e);
        return;
      }
    },
    submit() {}
  },
  watch: {
    stripeSession: function() {
      this.stripe.redirectToCheckout({ sessionId: this.stripeSession });
    },
    user: function() {
      console.log("user", this.user);
    }
  },
  mounted() {
    if (window.Stripe == undefined) {
      this.loadScript("https://js.stripe.com/v3").then(s => {
        document.body.appendChild(s);
      });
    }

    this.path = this.active = this.$router.history.current.name;
    //console.log((this.active = this.$router.history.current.name));
    AOS.init({ offset: 20 });
  }
  // beforeCreate() {
  //   this.$store.dispatch({
  //     type: "getUserInfo"
  //   });
  // }
};
</script>

<style lang="scss" scoped>
$aos-distance: 30px;
@import "node_modules/aos/src/sass/aos.scss";

.subscription-cards-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 35px;
  padding-top: 60px;
}

.subscription-cards-sub-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  font-family: Chivo-Regular;
  font-size: 30px;
  color: white;
  margin-bottom: 35px;
}

.subscription-cards {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px 0px 10px;
  font-family: Nunito-Light;
  color: #fff;
  height: 470px;
  width: 380px;
  min-width: 340px;
  max-width: 40%;
  margin: 0 20px;
  border: solid 1px var(--purple-darker);
  //border-top: solid 1px var(--purple-darker);
  border-radius: 4px;
  padding-bottom: 20px;
  // -webkit-box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
  // box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
}

.subscription-cards button {
  display: inline-block;
  box-sizing: border-box;
  margin: 20px 8px 10px 8px;
  background-color: rgba(30, 30, 30, 1);
  padding: 8px 18px;
  font-family: var(--main-font);
  font-weight: 300;
  color: var(--purple-darker);
  font-size: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 160px;
  height: 38px;
}

.subscription-cards button:focus {
  //color: var(--purple-darker2);
  //box-shadow: 0 -1px 0 var(--purple-darker2) inset;
  background-color: rgba(31, 31, 31, 1) !important;
}

.subscription-cards button:hover {
  background-color: var(--app-components-background-color-lighter);
}

.sc-text {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > span {
    margin: 5px 0;
  }
}

.sc-header {
  margin-top: 15px;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  > span:first-child {
    font-size: 25px;
    font-family: Nunito;
    margin: 8px 0;
  }
  > span:nth-child(2) {
    font-size: 20px;
    font-family: Nunito;
    margin-bottom: 20px;
  }
}

hr {
  background-color: rgb(58, 58, 58);
  width: 80%;
  display: block;
  border: none;
  height: 0.1px;
  margin: 10px 0 15px 0;
}

.free {
  text-decoration: line-through;
  color: rgb(168, 168, 168);
}

.trial {
  font-size: 14px;
  margin-left: 5px;
  font-weight: 200;
  font-family: Nunito-Italic;
}

.border-gradient {
  border: 5px solid;
  border-image-slice: 1;
  border-width: 2px;
}
.border-gradient-purple-left {
  border-image-source: linear-gradient(
    to left,
    rgb(145, 74, 226),
    rgb(240, 116, 250)
  );
}

.border-gradient-purple-right {
  border-image-source: linear-gradient(
    to right,
    rgb(145, 74, 226),
    rgb(240, 116, 250)
  );
}

.existing {
  white-space: nowrap;
  font-size: 16px;
  margin: 10px 0 20px 0;
  span {
    color: var(--purple-darker);
    cursor: pointer;
  }
}

@media only screen and (max-width: 800px) {
  .subscription-cards-sub-container {
    flex-wrap: wrap;
  }
  .subscription-cards {
    margin-bottom: 20px;
  }
}
</style>
