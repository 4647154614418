<template>
  <div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "TSResidualChart",
  data() {
    return {};
  },
  props: {
    data: Object,
    title: String
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "20%",
          bottom: "5%",
          left: "3%",
          right: "3%",
          containLabel: true
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
            type: "line",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0)"
            },
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },

          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        yAxis: [
          {
            name: "",
            type: "value",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            data: this.data.index,
            axisLabel: {
              show: true,
              margin: 20,
              fontSize: 13
            },
            min: function(value) {
              return value.min;
            },
            max: function(value) {
              return value.max;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.data.data,
            name: "Residual",
            type: "line",
            showSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(103, 34, 148, 0.1)"
                },
                {
                  offset: 0,
                  color: "rgba(187, 56, 252, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(128, 133, 173, 1)", width: 1 },
            itemStyle: { color: "rgba(128, 133, 173, 1)" }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["xAxis"][0]["axisLabel"]["show"] = false;
        this.options["grid"]["containLabel"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
