<template>
  <div class="options-flow-table-container">
    <table class="options-flow-table" ref="table">
      <thead class="headers">
        <td
          v-for="(item, i) in columns"
          :key="i"
          @click="sort($event, item.field)"
          v-show="visibleColumns.includes(item.field)"
        >
          <div class="header-container">
            {{ item.label }}
            <i class="mdi mdi-swap-vertical header-icon"></i>
          </div>
        </td>
      </thead>
      <tbody name="options-body" is="transition-group" :css="false">
        <tr
          v-for="(item, itemIndex) in this.pagination.currentPage"
          :key="item.key + itemIndex"
          class="options-row"
          :class="item.class"
        >
          <template v-for="(column, colindex) in columns">
            <td
              v-show="visibleColumns.includes(column.field)"
              :key="column + itemIndex + colindex"
            >
              <div v-if="column.field != 'score'" class="cell-container">
                <i
                  v-bind:class="
                    column.field == 'put_call'
                      ? pcClass(item.put_call)
                      : column.field == 'option_activity_type'
                      ? iconClass(item.option_activity_type)
                      : ''
                  "
                ></i>
                <div class="column.class ? column.class: ''">
                  {{ column.prepend
                  }}{{ format(item[column.field], column.format)
                  }}{{ column.append }}
                </div>
              </div>
              <div v-else class="cell-container">
                <div class="strength-container">
                  <div
                    class="strength"
                    v-bind:style="
                      item.score != undefined
                        ? {
                            width: String(Math.abs(item.score * 100)) + '%'
                          }
                        : { width: '0%' }
                    "
                    v-bind:class="strengthClass(item.put_call)"
                  ></div>
                </div>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div class="pagination-container">
      <b-pagination
        @change="selectPageIndex(pagination['current'])"
        :total="pagination['total']"
        v-model="pagination['current']"
        :per-page="pagination['perPage']"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import FiltersMixin from "@/filters/FiltersMixin.js";
export default {
  name: "OptionsFlowTableComponent",
  mixins: [FiltersMixin],
  data() {
    return {
      sortOrder: "desc",
      sortedColumn: "time",
      sortedType: "string",
      optionsData: [],
      visibleColumns: [],
      pagination: {
        total: 0,
        current: 1,
        perPage: 10,
        currentIndex: [0, 10],
        currentPage: []
      }
    };
  },
  computed: {},
  methods: {
    format: function(value, format) {
      if (format == undefined) {
        return value;
      } else {
        return this[format](value);
      }
    },
    selectPageIndex(selected) {
      this.pagination.currentIndex[0] =
        (selected - 1) * this.pagination.perPage;
      this.pagination.currentIndex[1] = selected * this.pagination.perPage;
      this.pagination.currentPage = this.optionsData.slice(
        this.pagination.currentIndex[0],
        this.pagination.currentIndex[1]
      );
      this.pagination["total"] = this.optionsData.length;
    },
    sort(event, column) {
      const headers = document.getElementsByClassName("header-icon");
      headers.forEach(element => {
        element.classList.remove("purple");
      });

      if (event != null) {
        try {
          event.target.children[0].children[0].classList.add("purple");
        } catch {
          try {
            event.target.children[0].classList.add("purple");
          } catch {
            try {
              event.target.classList.add("purple");
            } catch {
              return;
            }
          }
        }
      }
      if (typeof this.optionsData[0][column] == "number") {
        if (this.sortedColumn != column) {
          this.sortString(this.optionsData, "time", "desc");
          this.sortedColumn = column;
          this.sortOrder = "asc";
          return this.sortDigit(this.optionsData, column, "asc");
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortDigit(this.optionsData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortDigit(this.optionsData, column, "asc");
          }
        }
      } else if (typeof this.optionsData[0][column] == "string") {
        if (this.sortedColumn != column) {
          this.sortString(this.optionsData, "time", "desc");
          if (column != "time") {
            this.sortedColumn = column;
            this.sortOrder = "asc";
            return this.sortString(this.optionsData, column, "asc");
          } else {
            this.sortedColumn = column;
            this.sortOrder = "desc";
          }
        } else {
          if (this.sortOrder == "asc") {
            this.sortOrder = "desc";
            return this.sortString(this.optionsData, column, "desc");
          } else {
            this.sortOrder = "asc";
            return this.sortString(this.optionsData, column, "asc");
          }
        }
      }
    },
    sortDigit: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "number";
        if (sort == "asc") {
          return [a][0][column] - [b][0][column];
        } else {
          return [b][0][column] - [a][0][column];
        }
      });
    },
    sortString: function(data, column, sort) {
      return data.sort((a, b) => {
        this.sortedType = "string";
        if (sort == "asc") {
          return [a][0][column].localeCompare([b][0][column]);
        } else {
          return [b][0][column].localeCompare([a][0][column]);
        }
      });
    },
    iconClass: function(item) {
      if (item == "Block") {
        return "mdi mdi-square block";
      } else if (item == "Sweep") {
        return "mdi mdi-layers-triple sweep";
      } else {
        return "mdi mdi-layers-triple hidden";
      }
    },
    pcClass: function(item) {
      if (item == "Call") {
        return "mdi mdi-menu-up call";
      } else if (item == "Put") {
        return "mdi mdi-menu-down put";
      } else {
        return null;
      }
    },
    strengthClass: function(item) {
      if (item == "Call") {
        return "strength-call";
      } else {
        return "strength-put";
      }
    },
    setBreakpoints: function(size) {
      this.visibleColumns = this.columns.map(element => {
        return element.field;
      });
      if (size < 1600 && size > 1250) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 1) {
            return element.field;
          }
        });
      } else if (size < 1250 && size > 850) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 2) {
            return element.field;
          }
        });
      } else if (size < 850) {
        this.visibleColumns = this.columns.map(element => {
          if (element.breakpoint == undefined || element.breakpoint > 3) {
            return element.field;
          }
        });
      }
    }
  },
  components: {},
  props: {
    data: Array,
    columns: Array,
    initalSortOrder: String
  },
  watch: {
    data: function() {
      if (this.data.length) {
        this.optionsData = [...this.data];
        this.sortedColumn = this.initalSortOrder;
        this.sortedType = typeof this.data[0][this.initalSortOrder];
        this.sortOrder = "desc";
        if (this.sortedType == "string") {
          this.sortString(this.optionsData, this.sortedColumn, this.sortOrder);
        } else {
          this.sortDigit(this.optionsData, this.sortedColumn, this.sortOrder);
        }
      } else {
        this.optionsData = [];
      }
    },
    optionsData: function() {
      this.pagination.currentPage = this.optionsData.slice(
        this.pagination.currentIndex[0],
        this.pagination.currentIndex[1]
      );
      this.pagination["total"] = this.optionsData.length;
    }
  },
  created() {},
  mounted() {
    window.addEventListener("resize", () => {
      this.setBreakpoints(window.innerWidth);
    });
    this.setBreakpoints(window.innerWidth);
  }
};
</script>

<style lang="scss" scoped>
.options-flow-table-container {
  font-family: Nunito;
}

.options-flow-table {
  font-family: Nunito;
  background-color: rgba(29, 29, 29, 1);
  color: #fff;
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
  zoom: 85%;
  overflow-x: scroll !important;
}

.pagination-containe {
  zoom: 85%;
}

tr {
  border: solid 0.5px rgba(39, 39, 39, 0.4);
}

td {
  font-family: Nunito;
  font-size: 17px;
  white-space: nowrap;
  padding: 0px 0px;
}

.header-container {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-columns: max-content min-content;
  align-items: center;
  justify-items: center;
  margin-left: 15px;
  padding: 30px 15px;
}

.cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  height: 50px;
}

thead {
  //border: solid 1px rgba(22, 22, 22, 0.2);
  > td {
    font-size: 20px;
    padding: 0px 0px;
    background-color: rgb(21, 21, 21);
    cursor: pointer;
  }
}

.headers td:hover {
  background-color: rgb(25, 25, 25);
}

tbody {
  cursor: default;
}

tr:hover {
  background-color: rgba(41, 41, 43, 0.3);
}

.options-row {
  transition: transform 1s;
}
.options-row > * {
  transition: transform 1s;
  overflow: visible;
  //animation: fadeIn 2s;
}

.options-body-enter-active,
.options-body-leave-active {
  transition: all 1s;
  overflow: hidden;
}
.options-body-enter,
.options-body-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  overflow: hidden;
}
.options-body-move {
  transition: transform 1s;
}

.fade-in {
  animation: fadeIn 5s;
}

.ticker {
  border-radius: 3px;
  font-family: Nunito;
  font-size: 18px;
}

.header-icon {
  font-size: 20px;
  color: rgb(78, 78, 78);
  //margin-right: -15px;
  &.purple {
    color: rgb(136, 77, 245);
  }
  margin-left: 5px;
}

.block {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(130deg, rgb(136, 77, 245) 40%, #d639eb 70%);
  margin-bottom: 0px !important;
  margin-right: 5px;
  margin-left: 0%;
  //   font-size: 20px;
  //   height: 50px;
}

.sweep {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(130deg, #ff9e30 20%, #ff2bed 70%);
  margin-bottom: 0px !important;
  margin-right: 5px;
  margin-left: 0%;
  font-size: 20px;
}

.call {
  font-size: 40px;
  margin-right: 5px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #00cfb4 50%, #0bb8fd 90%);
}

.put {
  font-size: 40px;
  margin-right: 5px;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: linear-gradient(90deg, #ff0062 50%, #ff2bdc 90%);
}

.left {
  text-align: left;
}

.trade-type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.strength {
  height: 10px;
  min-width: 2%;
  border-radius: 3px;
  border: 2px 0 2px 0 solid black;
}

.strength-call {
  background: linear-gradient(160deg, #752bff 50%, #0bc4fd 100%);
  background: linear-gradient(160deg, rgb(136, 77, 245) 50%, #b80bfd 100%);
}

.strength-put {
  background: linear-gradient(160deg, #fd0464 50%, #ff2bf4 100%);
  background: linear-gradient(160deg, rgb(136, 77, 245) 50%, #b80bfd 100%);
}

.strength-cell {
  vertical-align: middle;
}

.strength-container {
  background-color: rgb(26, 26, 26);
  border-radius: 3px;
  border: 1px solid rgba(26, 26, 26, 0.3);
  width: 100%;
}

.pagination-container {
  margin-top: 15px;
  padding: 10px;
  background-color: rgb(29, 29, 29);
}

.hidden {
  color: transparent;
  font-size: 20px;
}
.invisible {
  color: rgba(0, 0, 0, 0);
  & .strength-call {
    background: linear-gradient(
      160deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.invisible .strength-call,
.invisible .strength-put,
.invisible .strength-container {
  background: linear-gradient(
    160deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1350px) {
  .header-container {
    padding: 30px 10px;
  }

  .cell-container {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1000px) {
  .header-container {
    padding: 30px 5px;
  }

  .cell-container {
    padding: 10px 5px;
  }
  .call {
    color: #00cfb4;
    background-image: none;
  }

  .put {
    color: #ff0062;
    background-image: none;
  }
  .header-icon {
    display: none;
  }

  .header-container {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .call {
    font-size: 30px;
    margin-right: 2px;
  }

  .put {
    font-size: 30px;
    margin-right: 2px;
  }

  .header-container {
    padding: 30px 2px;
  }

  .cell-container {
    padding: 10px 2px;
  }
}
</style>
