import { render, staticRenderFns } from "./SectorPerformanceHeatmapChart.vue?vue&type=template&id=a97ebc92&scoped=true&"
import script from "./SectorPerformanceHeatmapChart.vue?vue&type=script&lang=js&"
export * from "./SectorPerformanceHeatmapChart.vue?vue&type=script&lang=js&"
import style0 from "./SectorPerformanceHeatmapChart.vue?vue&type=style&index=0&id=a97ebc92&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a97ebc92",
  null
  
)

export default component.exports