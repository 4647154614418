<template>
  <div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
//import { mapGetters } from "vuex";

export default {
  name: "TSPredictionChart",
  data() {
    return {};
  },
  props: {
    data: Object,
    title: String
  },
  methods: {
    setChart: function() {
      var base1 = this.data.prices.reduce(function(min, val) {
        return Math.floor(Math.min(min, val[1]));
      }, Infinity);
      var base2 = this.data.predictions.reduce(function(min, val) {
        return Math.floor(Math.min(min, val[2]));
      }, Infinity);

      var base = -Math.min(base1, base2);

      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "20%",
          bottom: "5%",
          left: "4%",
          right: "4%",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            var tooltipBase = 0;
            if (base > 0) {
              tooltipBase = -base;
            } else {
              tooltipBase = Math.abs(base);
            }
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            var string = "";
            try {
              string =
                colorSpan(params[0].color) +
                params[0].seriesName +
                "&nbsp;" +
                "$" +
                String(
                  (parseFloat(params[0].data[1]) + tooltipBase).toFixed(2)
                ) +
                "<br/>" +
                colorSpan(params[1].color) +
                params[1].seriesName +
                "&nbsp;" +
                "$" +
                String(
                  (parseFloat(params[1].data[1]) + tooltipBase).toFixed(2)
                ) +
                "<br/>" +
                colorSpan(params[2].color) +
                params[2].seriesName +
                "&nbsp;" +
                "$" +
                String(
                  (parseFloat(params[2].data[1]) + tooltipBase).toFixed(2)
                ) +
                "<br/>" +
                colorSpan(params[3].color) +
                params[3].seriesName +
                "&nbsp;" +
                "$" +
                String(
                  (
                    parseFloat(params[3].data[1]) +
                    parseFloat(params[2].data[1]) +
                    tooltipBase
                  ).toFixed(2)
                ) +
                "<br/>";
            } catch {
              try {
                string =
                  colorSpan(params[0].color) +
                  params[0].seriesName +
                  "&nbsp;" +
                  "$" +
                  String(
                    (parseFloat(params[0].data[1]) + tooltipBase).toFixed(2)
                  ) +
                  "<br/>" +
                  colorSpan(params[1].color) +
                  params[1].seriesName +
                  "&nbsp;" +
                  "$" +
                  String(
                    (parseFloat(params[1].data[1]) + tooltipBase).toFixed(2)
                  ) +
                  "<br/>" +
                  colorSpan(params[2].color) +
                  params[2].seriesName +
                  "&nbsp;" +
                  "$" +
                  String(
                    (
                      parseFloat(params[2].data[1]) +
                      parseFloat(params[1].data[1]) +
                      tooltipBase
                    ).toFixed(2)
                  ) +
                  "<br/>";
              } catch {
                string =
                  colorSpan(params[0].color) +
                  params[0].seriesName +
                  "&nbsp;" +
                  "$" +
                  String(
                    (parseFloat(params[0].data[1]) + tooltipBase).toFixed(2)
                  ) +
                  "<br/>";
              }
            }

            return string;
          },
          axisPointer: {
            animation: false,
            type: "line",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },

          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        yAxis: [
          {
            name: "Price",
            type: "value",
            show: true,
            axisLabel: {
              formatter: function(val) {
                return val - base;
              }
            },
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            name: "",
            boundaryGap: false,
            axisLabel: {
              show: true,
              margin: 20,
              fontSize: 13
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.data.prices.map(function(item) {
              return [item[0], (item[1] + base).toFixed(2)];
            }),
            name: "Prices",
            type: "line",
            showSymbol: true,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.data.predictions.map(function(item) {
              return [item[0], (item[1] + base).toFixed(2)];
            }),
            name: "Predictions",
            type: "line",
            showSymbol: true,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" },
            markArea: {
              itemStyle: {
                color: "rgba(43, 46, 45, 0.3)"
              },
              data: [
                [
                  {
                    name: "Predictions",
                    xAxis: this.data.predictions[0][0]
                  },
                  {
                    xAxis: this.data.predictions[0][-1]
                  }
                ]
              ]
            }
          },
          {
            data: this.data.predictions.map(function(item) {
              return [item[0], (item[2] + base).toFixed(2)];
            }),
            name: "Lower Confidence Interval",
            type: "line",
            lineStyle: {
              opacity: 1,
              color: "rgba(10, 10, 10, 0.2)",
              width: 1
            },
            itemStyle: {
              color: "rgba(252, 3, 78, 1)"
            },
            symbol: "none",
            stack: "confidence-band"
          },
          {
            data: this.data.predictions.map(function(item) {
              return [item[0], (item[3] - item[2]).toFixed(2)];
            }),
            name: "Upper Confidence Interval",
            type: "line",
            lineStyle: {
              opacity: 1,
              color: "rgba(10, 10, 10, 0.2)",
              width: 1
            },
            areaStyle: {
              color: "rgba(100, 100, 100, 0.4)"
            },
            itemStyle: {
              color: "rgba(13, 255, 194, 1)"
            },
            symbol: "none",
            stack: "confidence-band"
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"]["containLabel"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
