<template>
  <div class="options-flow">
    <h1 class="page-title">Options Flow / Live & Historical</h1>
    <div class="filters-container">
      <OptionsFlowFilters
        :optionsFlow="currentOptionsFlow"
      ></OptionsFlowFilters>
    </div>

    <div>
      <OptionsFlowKeyStats v-bind:keyStats="keyStats"></OptionsFlowKeyStats>
    </div>
    <div class="options-table-container">
      <OptionsFlowTable></OptionsFlowTable>
    </div>
    <div class="largest-premiums-container">
      <OptionsFlowTop
        v-bind:largestPremiums="largestCallPremiums"
        v-bind:type="'calls'"
      ></OptionsFlowTop>
      <OptionsFlowTop
        v-bind:largestPremiums="largestPutPremiums"
        v-bind:type="'puts'"
      ></OptionsFlowTop>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import io from "socket.io-client";
import axios from "axios";
import OptionsFlowTable from "@/components/OptionsFlowTable.vue";
import OptionsFlowTop from "@/components/OptionsFlowTop.vue";
import OptionsFlowKeyStats from "@/components/OptionsFlowKeyStats.vue";
import OptionsFlowFilters from "@/components/OptionsFlowFilters.vue";
import { mapGetters } from "vuex";
import { eventBus } from "../main";

export default {
  name: "OptionsFlow",
  data() {
    return {};
  },
  head: {
    title: {
      inner: "Options Flow"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Follow smart money with real-time options flow. Large and unusual options trades and statistics."
      }
    ]
  },
  methods: {
    send: function() {
      axios
        .get("/test_socket", {})
        .then(res => {
          console.log(res);
        })
        .catch(err => {
          console.log(err);
        });
    },
    init: function() {
      this.$store.dispatch({
        type: "getCurrentOptionsFlow"
      });
      this.$store.commit("setLoading", true);
      this.$store.commit("setOptionsFlowMode", "live");
      this.socket = io(process.env.VUE_APP_API_BASE_URL, {
        reconnection: true,
        transports: ["websocket"]
      });

      this.socket.on("connect_error", err => {
        console.log(`connect_error due to ${err.message}`);
        console.log(err);
      });

      this.socket.on("connect", () => {
        this.socket.send("Connection established");
      });

      this.socket.on("new_flow", data => {
        if (this.mode == "live") {
          // Wait for the database to return the current options flow data before initializing keys stats.
          if (this.loading == false) {
            this.$store.commit(
              "incrementCurrentOptionsFlow",
              JSON.parse(data.data)
            );
            this.$store.commit("setNewFlow", JSON.parse(data.data));
          } else {
            setTimeout(() => {
              this.$store.commit(
                "incrementCurrentOptionsFlow",
                JSON.parse(data.data)
              );
              this.$store.commit("setNewFlow", JSON.parse(data.data));
            }, 5000);
          }
        }
      });

      this.socket.on("database_cleared", () => {
        this.$store.dispatch({
          type: "getCurrentOptionsFlow"
        });
      });
    }
  },
  watch: {
    user: function() {
      if (this.loginEvent == true) {
        if (this.user.subscription == "basic") {
          this.init();
        }
      }
    },
    logoutEvent: function() {
      if (this.logoutEvent == true) {
        if (this.socket) {
          this.socket.close();
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      currentOptionsFlow: "getCurrentOptionsFlow",
      largestCallPremiums: "getLargestCallPremiums",
      largestPutPremiums: "getLargestPutPremiums",
      keyStats: "getOptionsFlowKeyStats",
      mode: "getOptionsFlowMode",
      loading: "getLoading",
      loginEvent: "getLoginEvent",
      logoutEvent: "getLogoutEvent"
    })
  },
  components: {
    OptionsFlowTable,
    OptionsFlowTop,
    OptionsFlowKeyStats,
    OptionsFlowFilters
  },
  created() {},
  mounted() {
    this.init();
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("closingAlert", msg => {
      if (msg == "You need to subscribe to access this page.") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "More info") {
        setTimeout(() => {
          this.$router.push({
            path: "/subscriptioncards"
          });
        }, 30);
      }
    });
  },
  beforeDestroy() {
    this.$store.commit("setOptionsFlowKeyStats", {});
    this.$store.commit("setCurrentOptionsFlow", []);
    if (this.socket) {
      this.socket.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.options-flow {
  display: block;
  margin: 0 auto;
  margin-top: 65px;
  margin-bottom: 55px;
  width: 85%;
}

.page-title {
  width: 100%;
}

.largest-premiums-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.filters-container {
  margin-bottom: 2px;
}

@media only screen and (max-width: 1000px) {
  .options-flow {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  .largest-premiums-container {
    flex-wrap: wrap;
  }
}
</style>
