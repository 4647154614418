<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { mapGetters } from "vuex";
import { eventBus } from "../main";

export default {
  name: "ClustersPredictionChart",
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "35",
          right: "35",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "Price And Predictions",
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            magicType: {
              type: ["line", "bar"]
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.clustersPredictionData["index"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "Volume 000s",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Price",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            },
            min: function(value) {
              return Math.round(value.min * 0.975);
            },
            max: function(value) {
              return Math.round(value.max * 1.025);
            }
          }
        ],
        series: [
          {
            data: this.clustersPredictionData["close"],
            name: "Price",
            type: "line",
            yAxisIndex: 1,
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)", width: 2 },
            itemStyle: { color: "rgba(103, 34, 148, 1)" },
            connectNulls: true,
            markArea: {
              itemStyle: {
                color: "rgba(43, 46, 45, 0.3)"
              },
              data: [
                [
                  {
                    name: "Predictions",
                    xAxis: this.clustersPredictionData["mark_dates"]["area"][
                      "start"
                    ]
                  },
                  {
                    xAxis: this.clustersPredictionData["mark_dates"]["area"][
                      "end"
                    ]
                  }
                ]
              ]
            },
            markPoint: {
              data: [
                {
                  name: "1D",
                  value: "1D",
                  itemStyle: {
                    color: "rgba(91, 235, 226, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 10
                  },
                  symbolSize: 50,
                  xAxis: this.clustersPredictionData["mark_dates"]["d1"][
                    "date"
                  ],
                  yAxis: this.clustersPredictionData["mark_dates"]["d1"][
                    "close"
                  ]
                },
                {
                  name: "2D",
                  value: "2D",
                  itemStyle: {
                    color: "rgba(91, 235, 226, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 10
                  },
                  symbolOffset: [0, "-30%"],
                  symbolSize: 50,
                  xAxis: this.clustersPredictionData["mark_dates"]["d2"][
                    "date"
                  ],
                  yAxis: this.clustersPredictionData["mark_dates"]["d2"][
                    "close"
                  ]
                },
                {
                  name: "5D",
                  value: "5D",
                  itemStyle: {
                    color: "rgba(91, 235, 226, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 10
                  },
                  symbolSize: 50,
                  xAxis: this.clustersPredictionData["mark_dates"]["d5"][
                    "date"
                  ],
                  yAxis: this.clustersPredictionData["mark_dates"]["d5"][
                    "close"
                  ]
                },
                {
                  name: "10D",
                  value: "10D",
                  itemStyle: {
                    color: "rgba(91, 235, 226, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 10
                  },
                  symbolSize: 50,
                  xAxis: this.clustersPredictionData["mark_dates"]["d10"][
                    "date"
                  ],
                  yAxis: this.clustersPredictionData["mark_dates"]["d10"][
                    "close"
                  ]
                },
                {
                  name: "20D",
                  value: "20D",
                  itemStyle: {
                    color: "rgba(91, 235, 226, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 10
                  },
                  symbolSize: 50,
                  xAxis: this.clustersPredictionData["mark_dates"]["d20"][
                    "date"
                  ],
                  yAxis: this.clustersPredictionData["mark_dates"]["d20"][
                    "close"
                  ]
                }
              ]
            }
          },
          {
            data: this.clustersPredictionData["volume"],
            name: "Volume 000s",
            type: "bar",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(170, 40, 237, 0.35)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(143, 64, 189, 0.5)"
                }
              ]),
              borderColor: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(192, 100, 245, 0.9)"
                }
              ]),
              borderWidth: 0.6
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "40",
          right: "30",
          containLabel: false
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][1]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  computed: {
    ...mapGetters({
      clustersPredictionData: "getClustersPredictionData"
    })
  },
  watch: {
    clustersPredictionData: function() {
      //console.log(this.clustersPredictionData);
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
