<template>
  <div class="short">
    <h1 class="page-title">Short Interest</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
      <ShortInfo v-bind:shortInfo="shortInfo"></ShortInfo>
    </div>
    <div class="price-chart-container">
      <ShortInterestChart
        v-bind:indShortInterest="indShortInterest"
        v-bind:priceData="priceData"
      ></ShortInterestChart>
    </div>
    <div class="table-button-container">
      <b-tag type="is-dark" size="is-medium" class="datatable-tag"
        >Select max:
      </b-tag>
      <div class="table-button-sub-container">
        <button
          class="datatable-button"
          v-on:click="getShortInterest('short', true)"
        >
          Short Interest
        </button>
        <button
          class="datatable-button"
          v-on:click="getShortInterest('days', true)"
        >
          Days To Cover
        </button>
        <button
          class="datatable-button"
          v-on:click="getShortInterest('float', true)"
        >
          %Float Short
        </button>
      </div>
    </div>
    <div class="table-container">
      <TableComponent
        :data="data"
        @click="tableClick"
        paginated
        :initalSort="selectMax"
        :columns="[
          { label: 'Ticker', field: 'Ticker' },
          { label: 'Date', field: 'Date' },
          {
            label: 'Short Interest',
            field: 'Short Interest',
            format: 'thousands'
          },
          {
            label: 'Average Volume',
            field: 'Average Volume',
            format: 'thousands'
          },
          {
            label: 'Days To Cover',
            field: 'Days To Cover',
            format: 'decimal4'
          },
          { label: '%Float Short', field: '%Float Short', format: 'decimal4' }
        ]"
      >
      </TableComponent>
    </div>

    <div class="text-content">
      <h1><big>What is short interest?</big></h1>
      <br />
      Short interest represents the number of shares that are held short by
      investors. It is typically reported twice a month. Although it has been
      shown that stocks with high short interest tend to perform poorly, when
      the short interest represents a substantial amount of all the shares
      freely trading (commonly referred to as float), the inverse can happen.
      The name given to this situation is "short squeeze" and it can lead to
      explosive moves. When it occurs, investors who held a short position rush
      to buy back the shares they shorted, creating massive buying pressure.
      <br /><br />
      Short interest is only available for NASDAQ stocks and ETFs.
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import axios from "axios";
import TickerInfo from "@/components/TickerInfo.vue";
import ShortInfo from "@/components/ShortInfo.vue";
import ShortInterestChart from "@/components/ShortInterestChart.vue";
import TableComponent from "@/components/TableComponent.vue";

//GET the short data and after GET the top 1 ticker info

export default {
  name: "ShortData",
  data() {
    return {
      data: [],
      columns: [],
      ticker: "",
      shortInfo: {},
      priceData: {},
      indShortInterest: {},
      loading: true,
      selectMax: ["%Float Short", "desc"]
    };
  },
  head: {
    title: {
      inner: "Short Interest"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Find the stocks which have the highest short interest and get in on the next short squeeze."
      }
    ]
  },
  methods: {
    getShortInterest(top = "float", select = false) {
      this.loading = true;

      if (top == "float") {
        this.selectMax[0] = "%Float Short";
      } else if (top == "days") {
        this.selectMax[0] = "Days To Cover";
      } else if (top == "short") {
        this.selectMax[0] = "Short Interest";
      }

      axios
        .get("/get_short_interest", {
          params: {
            top: top
          }
        })
        .then(res => {
          this.data = res.data.data;
          this.columns = res.data.schema;
          if (
            typeof this.$router.history.current.params["ticker"] ===
              "undefined" ||
            select == true
          ) {
            this.ticker = this.data[0]["Ticker"];
          } else {
            this.ticker = this.$router.history.current.params[
              "ticker"
            ].toUpperCase();
          }
          this.getTickerPrices(this.ticker, 252, true);
          this.getIndShortInterest(this.ticker);
          this.getIndShortInterestInfo(this.ticker);
        })
        .catch(err => {
          console.log(err);
        });
    },
    getTickerPrices(ticker, window, closed) {
      axios
        .get("/get_ticker_prices", {
          params: {
            ticker: ticker,
            window: window,
            closed: closed
          }
        })
        .then(res => {
          this.priceData = res.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getIndShortInterest(ticker) {
      axios
        .get("/get_ind_short_interest", {
          params: {
            ticker: ticker
          }
        })
        .then(res => {
          if (typeof res.data === "string") {
            if (res.data.length != 0) {
              this.indShortInterest = JSON.parse(
                res.data.replace(/\bNaN\b/g, "null")
              );
            } else {
              this.indShortInterest = "";
            }
          } else {
            this.indShortInterest = res.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    getIndShortInterestInfo(ticker) {
      axios
        .get("/get_ind_short_interest_info", {
          params: {
            ticker: ticker
          }
        })
        .then(res => {
          this.shortInfo = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateTickerData(ticker) {
      this.ticker = ticker;
      this.getTickerPrices(ticker, 252, true);
      this.getIndShortInterest(ticker);
      this.getIndShortInterestInfo(ticker);
    },
    tableClick(event) {
      this.updateTickerData(event.Ticker);
    }
  },
  components: {
    TickerInfo,
    ShortInfo,
    ShortInterestChart,
    TableComponent
  },
  mounted() {
    this.getShortInterest();
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.price-chart-container,
.table-button-container,
.table-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.table-button-container {
  display: flex;
  align-items: flex-start;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.table-container {
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.table-container {
  overflow: hidden !important;
}

.datatable-button:hover {
  background-color: var(--app-components-background-color-lighter);
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .price-chart-container,
  .table-button-container,
  .table-container,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .price-chart-container,
  .table-button-container,
  .table-container,
  .ticker-info-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  // .datatable-button {
  //   height: 48px;
  //   width: 180px;
  // }
  .ticker-info-container {
    flex-wrap: wrap;
  }
  .table-button-sub-container {
    flex-wrap: wrap;
  }
}
</style>
