<template>
  <div class="factor-analysis-table">
    <h1 class="page-title">Factor Analysis / All</h1>
    <div class="screener-container">
      <div class="checkbox-container">
        <div>
          <h2>Factor</h2>
          <ul>
            <li v-for="(factor, index) in factors" :key="'li' + String(index)">
              <input
                :key="index"
                :id="'f' + String(index)"
                :value="factor"
                v-model="factorsFilter"
                type="checkbox"
              />
              <label :key="'label' + String(index)" :for="'f' + String(index)">
                {{ factor }}
              </label>
            </li>
          </ul>
        </div>
        <div>
          <h2>Sector</h2>
          <ul>
            <li v-for="(sector, index) in sectors" :key="'li' + String(index)">
              <input
                :key="index"
                :id="'s' + String(index)"
                :value="sector"
                type="checkbox"
                v-model="sectorsFilter"
              />
              <label
                :key="'label' + String(index)"
                :for="'s' + String(index)"
                >{{ sector }}</label
              >
            </li>
          </ul>
        </div>
        <div>
          <h2>Market Cap</h2>
          <ul>
            <li
              v-for="(marketcap, index) in marketcaps"
              :key="'li' + String(index)"
            >
              <input
                :key="index"
                :id="'m' + String(index)"
                :value="marketcap"
                v-model="marketcapsFilter"
                type="checkbox"
              />
              <label
                :key="'label' + String(index)"
                :for="'m' + String(index)"
                >{{ marketcap }}</label
              >
            </li>
          </ul>
        </div>
        <div>
          <h2>Expected Return</h2>
          <ul>
            <li
              v-for="(expectedReturn, index) in expectedReturns"
              :key="'li' + String(index)"
            >
              <input
                :key="index"
                :id="'e' + String(index)"
                :value="expectedReturn"
                v-model="expectedReturnsFilter"
                type="checkbox"
              />
              <label
                :key="'label' + String(index)"
                :for="'e' + String(index)"
                >{{ expectedReturn }}</label
              >
            </li>
          </ul>
        </div>
        <div>
          <h2>Profitable %</h2>
          <ul>
            <li
              v-for="(profitablePercentage, index) in profitablePercentages"
              :key="'li' + String(index)"
            >
              <input
                :key="index"
                :id="'p' + String(index)"
                :value="profitablePercentage"
                v-model="profitablePercentagesFilter"
                type="checkbox"
              />
              <label
                :key="'label' + String(index)"
                :for="'p' + String(index)"
                >{{ profitablePercentage }}</label
              >
            </li>
          </ul>
        </div>
        <div>
          <h2>Timeframe</h2>
          <ul>
            <li
              v-for="(timeframe, index) in timeframes"
              :key="'li' + String(index)"
            >
              <input
                :key="index"
                :id="'t' + String(index)"
                :value="timeframe"
                v-model="timeframesFilter"
                type="checkbox"
              />
              <label :key="'label' + String(index)" :for="'t' + String(index)">
                {{ timeframe }}
              </label>
            </li>
          </ul>
        </div>
      </div>
      <button class="clear-button" @click="clearFilter">
        Clear <i class="mdi mdi-close"></i>
      </button>
    </div>
    <div class="table-container">
      <TableComponent
        :data="filteredSignalData"
        paginated
        @click="openSignal"
        :columns="[
          { label: 'Date', field: 'date' },
          { label: 'Ticker', field: 'ticker' },
          { label: 'Expected%', field: 'expected_ret' },
          { label: 'WL Ratio', field: 'profit_loss_ratio' },
          { label: 'Profitable%', field: 'profitable%' },
          { label: 'Period', field: 'period' },
          { label: 'Timeframe', field: 'timeframe' },
          { label: 'Factor', field: 'factor' }
        ]"
      ></TableComponent>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      This page allows you to see all the newly generated signals by the factors
      analysis feature for the current day and week. The date represents the
      date when the bar started. Daily signals are generated daily and weekly
      signals on the last day of the week.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const TableComponent = () => import("@/components/TableComponent.vue");

export default {
  name: "FactorAnalysisTable",
  data() {
    return {
      formattedSignalsData: [],
      filteredSignalData: [],
      factorMapping: [
        { field: "momentum", label: "Momentum" },
        { field: "sus_momentum", label: "Sustained Momentum" },
        { field: "uptrend", label: "New Uptrend" },
        { field: "relative_strength", label: "Relative Strength" },
        { field: "roc_curve", label: "ROC Curve" },
        { field: "filtered_momentum", label: "Filtered Momentum" },
        { field: "new_high", label: "New High" },
        { field: "dark_pools", label: "Dark Pools" }
      ],
      profitablePercentages: ["50-60%", "60-70%", "70-80%", "80-90%", "90%+"],
      profitablePercentagesFilter: [],
      expectedReturns: ["0-10%", "10-20%", "20-50%", "50%+"],
      expectedReturnsFilter: [],
      timeframes: ["Daily", "Weekly"],
      timeframesFilter: [],
      factors: [
        "New Uptrend",
        "Sustained Momentum",
        "Momentum",
        "Relative Strength",
        "New High",
        "Filtered Momentum",
        "ROC Curve",
        "Dark Pools"
      ],
      factorsFilter: [],
      marketcaps: [
        "Mega Cap",
        "Large Cap",
        "Mid Cap",
        "Small Cap",
        "Micro Cap"
      ],
      marketcapsFilter: [],
      sectors: [
        "Basic Materials",
        "Communication Services",
        "Consumer Cyclical",
        "Consumer Defensive",
        "Energy",
        "Financial",
        "Healthcare",
        "Industrials",
        "Technology",
        "Real Estate",
        "Utilities"
      ],
      sectorsFilter: []
    };
  },
  head: {
    title: {
      inner: "Factor Analysis Signals"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content: "Explore all signals generated by the Factor Analysis feature."
      }
    ]
  },
  methods: {
    filter: function() {
      this.filteredSignalData = this.formattedSignalsData.filter(signal => {
        var sector =
          this.sectorsFilter.length > 0
            ? this.sectorsFilter.includes(signal["sector"])
            : true;
        var timeframe =
          this.timeframesFilter.length > 0
            ? this.timeframesFilter.includes(signal["timeframe"])
            : true;
        var factor =
          this.factorsFilter.length > 0
            ? this.factorsFilter.includes(signal["factor"])
            : true;
        var marketcap =
          this.marketcapsFilter.length > 0
            ? this.marketcapsFilter.includes(signal["marketcap"])
            : true;
        var expectedReturn = this.returnFilter(signal["expected_ret"]);
        var profitablePercentage = this.profitableFilter(signal["profitable%"]);

        return (
          sector &&
          timeframe &&
          factor &&
          marketcap &&
          expectedReturn &&
          profitablePercentage
        );
      });
    },
    returnFilter: function(expectedReturn) {
      if (this.expectedReturnsFilter.length == 0) {
        return true;
      } else if (this.expectedReturnsFilter.includes("0-10%")) {
        if (expectedReturn < 10) {
          return true;
        }
      } else if (this.expectedReturnsFilter.includes("10-20%")) {
        if (expectedReturn >= 10 && expectedReturn < 20) {
          return true;
        }
      } else if (this.expectedReturnsFilter.includes("20-50%")) {
        if (expectedReturn >= 20 && expectedReturn < 50) {
          return true;
        }
      } else if (this.expectedReturnsFilter.includes("50%+")) {
        if (expectedReturn >= 50) {
          return true;
        }
      } else {
        return false;
      }
    },
    profitableFilter: function(expectedReturn) {
      if (this.profitablePercentagesFilter.length == 0) {
        return true;
      } else if (this.profitablePercentagesFilter.includes("50-60%")) {
        if (expectedReturn >= 50 && expectedReturn < 60) {
          return true;
        }
      } else if (this.profitablePercentagesFilter.includes("60-70%")) {
        if (expectedReturn >= 60 && expectedReturn < 70) {
          return true;
        }
      } else if (this.profitablePercentagesFilter.includes("70-80%")) {
        if (expectedReturn >= 70 && expectedReturn < 80) {
          return true;
        }
      } else if (this.profitablePercentagesFilter.includes("80-90%")) {
        if (expectedReturn >= 80 && expectedReturn < 90) {
          return true;
        }
      } else if (this.profitablePercentagesFilter.includes("90%+")) {
        if (expectedReturn >= 90) {
          return true;
        }
      } else {
        return false;
      }
    },
    clearFilter: function() {
      this.profitablePercentagesFilter = [];
      this.expectedReturnsFilter = [];
      this.timeframesFilter = [];
      this.factorsFilter = [];
      this.marketcapsFilter = [];
      this.sectorsFilter = [];
    },
    factorInverseMap: function(label) {
      var factor = this.factorMapping.filter(factor => {
        if (factor["label"] == label) {
          return factor;
        }
      });
      return factor[0]["field"];
    },
    factorMap: function(field) {
      var factor = this.factorMapping.filter(factor => {
        if (factor["field"] == field) {
          return factor;
        }
      });
      return factor[0]["label"];
    },
    openSignal: function(item) {
      var factor = this.factorInverseMap(item["factor"]);
      let route = this.$router.resolve({
        path:
          "/factor-analysis/" +
          String(factor) +
          "/" +
          String(item.timeframe.toLowerCase()) +
          "/" +
          String(item.ticker)
      });
      window.open(route.href, "_blank");
    }
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      signalsData: "getSignalsData"
    })
  },
  components: {
    TableComponent
  },
  watch: {
    sectorsFilter: function() {
      this.filter();
    },
    timeframesFilter: function() {
      this.filter();
    },
    factorsFilter: function() {
      this.filter();
    },
    marketcapsFilter: function() {
      this.filter();
    },
    expectedReturnsFilter: function() {
      this.filter();
    },
    profitablePercentagesFilter: function() {
      this.filter();
    },
    signalsData: function() {
      console.log(this.signalsData);
      this.$store.commit("setLoading", false);
      this.formattedSignalsData = this.signalsData.map(element => {
        element["timeframe"] =
          element["timeframe"].charAt(0).toUpperCase() +
          element["timeframe"].slice(1);
        element["profit_loss_ratio"] == null
          ? (element["profit_loss_ratio"] = Infinity)
          : element["profit_loss_ratio"];
        element["factor"] = this.factorMap(element["factor"]);
        return element;
      });
      this.filteredSignalData = this.formattedSignalsData;
    }
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.$store.dispatch({
      type: "getSignalsData"
    });
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.table-container,
.page-title,
.screener-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 80%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.factor-analysis-table {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
  color: white;
  font-family: Nunito;
}

.page-title {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
}

.screener-container {
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: var(--app-components-background-color);
}

.checkbox-container {
  display: grid;
  grid-gap: 0px;
  grid-template-columns: repeat(6, 1fr); //auto auto auto auto auto auto;
  width: 100%;
  & h2 {
    font-size: 18px;
    text-align: left;
    padding: 4px 6px;
  }
  & ul {
    margin-top: 7px;
    display: inline-block;
    text-align: left;
  }
  & li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    border-radius: 3px;
    transition: background 0.3s cubic-bezier(0, 0, 0.3, 1);
    &:hover {
      background-color: rgb(38, 38, 38);
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: max-content;
    border-right: 0.5px solid rgb(35, 35, 35);
    padding: 0 10px;
  }
  > div:last-child {
    border-right: 0px solid rgb(35, 35, 35);
    //border-left: 0.5px solid rgb(35, 35, 35);
  }
}

.clear-button {
  background-color: rgb(30, 30, 30);
  border: 1px solid rgb(150, 95, 252);
  height: 38px;
  padding: 0 20px;
  border-radius: 3px;
  color: rgb(160, 111, 252);
  font-family: Nunito;
  font-size: 16.5px;
  letter-spacing: 0.5px;
  &:hover {
    background-color: rgba(150, 95, 252, 0.05);
  }
  &:active {
    border: 1px solid rgb(129, 63, 253);
  }
  > i {
    font-size: 18px;
    position: relative;
    top: 2px;
    left: 2px;
  }
}

@media only screen and (max-width: 1500px) {
  .text-content,
  .screener-container,
  .table-container {
    width: 85%;
  }
}

@media only screen and (max-width: 1400px) {
  .checkbox-container {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .screener-container,
  .table-container {
    width: 95%;
  }

  .checkbox-container {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .checkbox-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 30px;
  }
}
</style>
