<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { eventBus } from "../main";
import { mapGetters } from "vuex";
// import { graphic } from "echarts/core";

export default {
  name: "ClustersBoxChart",
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "35",
          right: "35",
          containLabel: true
          //bottom: "3%"
        },
        colors: [
          "#7400b8",
          "#6930c3",
          "#5e60ce",
          "#5390d9",
          "#4ea8de",
          "#48bfe3",
          "#56cfe1",
          "#64dfdf",
          "#72efdd",
          "#80ffdb"
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1]
            },
            // magicType: {
            //   type: ["line"]
            // },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Forward Returns Boxplots",
            left: "30",
            top: "3%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        legend: {
          top: "10%",
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          },
          icon: "circle",
          itemStyle: {
            color: "#669db0"
          },
          inactiveColor: "rgba(54, 54, 54, 1)"
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { xAxisIndex: [0, 1] }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: Array.from(
            {
              length: this.clustersBoxplotData[
                Object.keys(this.clustersBoxplotData)[0]
              ]["boxplot_data"].length
            },
            (x, i) => i
          ),
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: {
          type: "value",
          name: "Returns",
          show: true,
          nameTextStyle: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        }
      };
      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "0",
          right: "20",
          containLabel: true
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }
    },
    setOptions: function() {
      var dataset = [];
      var series = [];

      //const l = this.clustersBoxplotData[0].length;
      Object.keys(this.clustersBoxplotData).forEach(key => {
        // dataset.push(
        //   {
        //     source: element
        //   },
        //   {
        //     fromDatasetIndex: index,
        //     transform: { type: "boxplot" }
        //   }
        // );
        series.push({
          name: key,
          type: "boxplot",
          //datasetIndex: index * 2 + 1,
          data: this.clustersBoxplotData[key]["boxplot_data"],
          encode: {
            tooltip: ["Low", "Q1", "Q2", "Q3", "High"]
          },
          itemStyle: {
            color: this.clustersBoxplotData[key]["color"],
            borderColor: "#000",
            opacity: 0.9,
            borderType: "solid"
          }
          // emphasis: {
          //   focus: "self",
          //   blurScope: "global"
          // }
        });
      });

      this.options["dataset"] = dataset;
      this.options["series"] = series;
      this.$refs.priceChart.setOption(this.options);
    }
  },
  computed: {
    ...mapGetters({
      clustersBoxplotData: "getClustersBoxplotData"
    })
  },
  watch: {
    clustersBoxplotData: function() {
      this.setChart();
      this.setOptions();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
      this.setOptions();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
