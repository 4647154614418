<template>
  <div class="dark-pool-info">
    <div class="name">Dark Pool Data</div>
    <div
      class="grid-container"
      v-if="Object.keys(this.darkPoolIndividualData).length > 0"
    >
      <div class="grid-item">{{ netVolume }}</div>
      <div class="grid-item">
        {{
          (darkPoolIndividualData["individual_short_volume"]["net_volume"][
            darkPoolIndividualData["individual_short_volume"]["net_volume"]
              .length - 1
          ] *
            1000)
            | formatNumber
        }}
      </div>

      <div class="grid-item">{{ netVolumeDollar }}</div>
      <div class="grid-item">
        {{
          (darkPoolIndividualData["individual_dark_pool_position_data"][
            "dollar_net_volume"
          ][
            darkPoolIndividualData["individual_dark_pool_position_data"][
              "dollar_net_volume"
            ].length - 1
          ] *
            1000)
            | formatNumber
        }}
      </div>

      <div class="grid-item">Position</div>
      <div class="grid-item">
        {{
          darkPoolIndividualData["individual_dark_pool_position_data"][
            "dp_position"
          ][
            darkPoolIndividualData["individual_dark_pool_position_data"][
              "dp_position"
            ].length - 1
          ] | formatNumber
        }}
      </div>

      <div class="grid-item">Position $</div>
      <div class="grid-item">
        {{
          (darkPoolIndividualData["individual_dark_pool_position_data"][
            "dollar_dp_position"
          ][
            darkPoolIndividualData["individual_dark_pool_position_data"][
              "dollar_dp_position"
            ].length - 1
          ] *
            1000000)
            | formatNumber
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DarkPoolInfo",
  data() {
    return {
      windowWidth: window.innerWidth,
      netVolume: "",
      netVolumeDollar: ""
    };
  },
  props: {
    darkPoolIndividualData: Object
  },
  methods: {
    set_volume_string: function() {
      if (this.windowWidth > 1670) {
        this.netVolume = "Net Short Volume";
        this.netVolumeDollar = "Net Short Volume $";
      } else if (this.windowWidth <= 1660 && this.windowWidth >= 1480) {
        this.netVolume = "Net Short Vol.";
        this.netVolumeDollar = "Net Short Vol. $";
      } else if (this.windowWidth < 1480) {
        this.netVolume = "Net Vol.";
        this.netVolumeDollar = "Net Vol. $";
      } else {
        this.netVolume = "Net Short Volume";
        this.netVolumeDollar = "Net Short Volume $";
      }
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.set_volume_string();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  watch: {
    windowWidth: function() {
      this.set_volume_string();
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Mono&display=swap");

.dark-pool-info {
  background-color: var(--app-components-background-color);
  color: #ffffff;
  min-width: 30%;
  width: max-content;
  max-width: 100%;
  font-family: var(--main-font);
}

.name {
  font-size: 23px;
  padding: 10.5px 10.5px 10px 0px;
  height: 39px;
  width: 100%;
  text-align: left;
  box-sizing: content-box;
}

.grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 25% 25% 25% 25%;
  grid-auto-flow: row;
  grid-gap: 0 8px;
  padding-right: 4px;
}

.grid-item {
  width: 100%;
  text-align: left;
  font-family: "DM Mono", monospace !important;
  white-space: nowrap;
}

@media only screen and (max-width: 1400px) {
  .dark-pool-info {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 850px) {
  .dark-pool-info {
    width: 100%;
    padding: 15px 0 15px 30px;
  }
  .grid-container {
    grid-template-columns: 50% 50%;
  }
  .name {
    font-size: 19px;
  }
}
</style>
