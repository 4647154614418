<template>
  <div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "OptionsFlowCumulativeStatsChart",
  data() {
    return {
      options: {}
    };
  },
  computed: {
    ...mapGetters({
      optionsFlowIntradayPremiumsData: "getOptionsFlowIntradayPremiumsData"
    })
  },
  methods: {
    setChart: function() {
      this.options = {
        color: ["rgba(255, 185, 64, 1)", "rgba(94, 84, 125, 1)"],
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "9%",
          bottom: "5%",
          left: "3%",
          right: "3%",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "Intraday Premiums",
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false,
            type: "cross",
            // lineStyle: {
            //   color: '#376df4',
            //   width: 2,
            //   opacity: 1
            // }
            //}
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },

          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        // axisPointer: {
        //   link: { yAxisIndex: [0, 1] }
        // },
        yAxis: [
          {
            name: "Price",
            scale: true,
            show: true,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            min: function(value) {
              return (value.min - (value.max - value.min) * 0.2).toFixed(2);
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Volume",
            show: false,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Premiums $",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            max: function(value) {
              return (value.max * 4).toFixed(0);
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        xAxis: [
          {
            type: "category",
            //boundaryGap: true,
            data: this.optionsFlowIntradayPremiumsData.index,
            axisLabel: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        dataZoom: {
          type: "inside",
          start: 0,
          end: 100,
          //orient: "horizontal",
          minSpan: 25,
          moveHandleStyle: {
            color: "rgba(45, 45, 45, 1)"
          },
          handleStyle: {
            borderColor: "rgba(99, 99, 99, 1)"
          },
          fillerColor: "rgba(75, 75, 75, 0.1)",
          emphasis: {
            moveHandleStyle: {
              color: "rgba(40, 40, 40, 1)"
            },
            handleStyle: {
              borderColor: "rgba(69, 69, 69, 1)"
            }
          },
          brushStyle: {
            color: "rgba(75, 75, 75, 0.1)"
          },
          dataBackground: {
            areaStyle: {
              color: "rgba(98, 98, 98, 1)"
            },
            lineStyle: {
              color: "rgba(30, 30, 30, 1)"
            }
          },
          selectedDataBackground: {
            areaStyle: {
              color: "rgba(98, 98, 98, 1)"
            },
            lineStyle: {
              color: "rgba(58, 58, 58, 1)"
            }
          }
        },
        series: [
          {
            type: "candlestick",
            data: this.optionsFlowIntradayPremiumsData.ohlc,
            yAxisIndex: 0,
            itemStyle: {
              color0: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.2)"
                }
              ]),
              borderColor0: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 1)"
                }
              ]),
              // color0: new graphic.LinearGradient(0, 0, 0, 1, [
              //   {
              //     offset: 1,
              //     color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
              //   },
              //   {
              //     offset: 0,
              //     color: "rgba(217, 79, 255, 0.2)"
              //   }
              // ]),
              // borderColor0: new graphic.LinearGradient(0, 0, 0, 1, [
              //   {
              //     offset: 1,
              //     color: "rgba(255, 79, 155, 1)"
              //   },
              //   {
              //     offset: 0,
              //     color: "rgba(255, 79, 155, 1)"
              //   }
              // ]),
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 0.3)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 1)"
                }
              ])
            }
          },
          // {
          //   data: this.optionsFlowIntradayPremiumsData.volume,
          //   name: "Volume",
          //   yAxisIndex: 1,
          //   type: "bar",
          //   barGap: "-100%",
          //   itemStyle: { color: "rgba(103, 34, 148, 1)" }
          // },
          {
            data: this.optionsFlowIntradayPremiumsData.calls,
            name: "Calls $",
            yAxisIndex: 2,
            type: "bar",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 1)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 1)"
                }
              ])
            }
          },
          {
            data: this.optionsFlowIntradayPremiumsData.puts,
            name: "Puts $",
            yAxisIndex: 2,
            type: "bar",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(105, 63, 145, 1)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(135, 87, 207, 1)"
                }
              ])
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][2]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["toolbox"]["show"] = false;
        this.options["title"]["textStyle"]["fontSize"] = 17;
        this.options["grid"]["containLabel"] = false;
        this.options["grid"]["left"] = "13%";
        this.options["grid"]["right"] = "6%";
        //this.options["toolbox"]["show"] = false;
      }

      this.$refs.priceChart.setOption(this.options);
    },
    set_ma: function() {
      function calculateMA(dayCount, data) {
        var result = [];
        for (var i = 0, len = data.length; i < len; i++) {
          if (i < dayCount) {
            result.push("-");
            continue;
          }
          var sum = 0;
          var nulls = 0;
          for (var j = 0; j < dayCount; j++) {
            if (data[i - j][1] != null) {
              sum += +data[i - j][1];
            } else {
              nulls += 1;
            }
          }
          result.push((sum / (dayCount - nulls)).toFixed(2));
        }
        return result;
      }
      (this.options["legend"] = {
        data: ["MA10", "MA20"],
        top: 10,
        inactiveColor: "#777",
        textStyle: {
          color: "#fff"
        }
      }),
        this.options.series.push(
          {
            name: "MA10",
            type: "line",
            data: calculateMA(10, this.optionsFlowIntradayPremiumsData.ohlc),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: "rgba(255, 185, 64, 0.8)"
            }
          },
          {
            name: "MA20",
            type: "line",
            data: calculateMA(20, this.optionsFlowIntradayPremiumsData.ohlc),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              color: "rgba(94, 84, 125, 0.8)"
            }
          }
        );
      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    optionsFlowIntradayPremiumsData: function() {
      this.setChart();
      this.set_ma();
      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 1000px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
