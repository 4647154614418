<template>
  <div>
    <div class="table-controls-container">
      <b-tag type="is-dark" size="is-medium" class="datatable-tag"
        >Cumulative:
      </b-tag>
      <button class="datatable-button" @click="set_dataset('premiums')">
        Premiums
      </button>
      <button class="datatable-button" @click="set_dataset('size')">
        Volume
      </button>
      <div class="tag-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag"
          >Days:
        </b-tag>
        <SelectComponent
          v-model="lookback"
          class="select-component"
          :default="lookbacks[19]"
          :options="lookbacks"
        >
        </SelectComponent>
      </div>
    </div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
    <b-loading
      :is-full-page="true"
      v-model="subLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
import { mapGetters } from "vuex";
const SelectComponent = () => import("@/components/SelectComponent.vue");

export default {
  name: "OptionsFlowByStrikeChart",
  data() {
    return {
      dataset: "premiums",
      title: "",
      calls: [],
      puts: [],
      options: {},
      lookback: 20,
      lookbacks: this.range(1, 40, 1)
    };
  },
  computed: {
    ...mapGetters({
      optionsFlowByStrikeData: "getOptionsFlowByStrikeData",
      ticker: "getTicker",
      subLoading: "getSubLoading"
    })
  },
  components: {
    SelectComponent
  },
  methods: {
    setChart: function() {
      if (this.dataset == "premiums") {
        this.calls = this.optionsFlowByStrikeData["call_premiums"];
        this.puts = this.optionsFlowByStrikeData["put_premiums"];
        this.title = "Cumulative Premiums By Strike $MM";
      } else if (this.dataset == "size") {
        this.calls = this.optionsFlowByStrikeData["call_size"];
        this.puts = this.optionsFlowByStrikeData["put_size"];
        this.title = "Cumulative Volume By Strike";
      }
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "10%",
            bottom: "5%",
            left: "3%",
            containLabel: true,
            width: "46%",
            right: "50%"
            //bottom: "3%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "10%",
            bottom: "5%",
            right: "3%",
            left: "50%",
            containLabel: true,
            width: "46%"
            //bottom: "3%"
          }
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            magicType: {
              type: ["bar"]
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          position: "cursor",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "rgba(23, 24, 26, 0.95)"
            }
          },
          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { yAxisIndex: [0, 1] }
        },
        yAxis: [
          {
            type: "category",
            name: "Strike",
            nameLocation: "end",
            nameTextStyle: {
              fontSize: 14,
              padding: [0, 0, 0, 10]
            },
            show: true,
            gridIndex: 0,
            data: this.optionsFlowByStrikeData["strike_price"],
            axisLabel: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            name: "",
            nameTextStyle: {
              fontSize: 14
            },
            show: true,
            offset: 0,
            gridIndex: 1,
            data: this.optionsFlowByStrikeData["strike_price"],
            axisLabel: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        xAxis: [
          {
            type: "value",
            boundaryGap: true,
            gridIndex: 0,
            inverse: true,
            axisLabel: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            boundaryGap: true,
            gridIndex: 1,
            axisLabel: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        dataZoom: {
          type: "inside",
          start: 0,
          end: 100,
          orient: "horizontal",
          minSpan: 25,
          moveHandleStyle: {
            color: "rgba(45, 45, 45, 1)"
          },
          handleStyle: {
            borderColor: "rgba(99, 99, 99, 1)"
          },
          fillerColor: "rgba(75, 75, 75, 0.1)",
          emphasis: {
            moveHandleStyle: {
              color: "rgba(40, 40, 40, 1)"
            },
            handleStyle: {
              borderColor: "rgba(69, 69, 69, 1)"
            }
          },
          brushStyle: {
            color: "rgba(75, 75, 75, 0.1)"
          },
          dataBackground: {
            areaStyle: {
              color: "rgba(98, 98, 98, 1)"
            },
            lineStyle: {
              color: "rgba(30, 30, 30, 1)"
            }
          },
          selectedDataBackground: {
            areaStyle: {
              color: "rgba(98, 98, 98, 1)"
            },
            lineStyle: {
              color: "rgba(58, 58, 58, 1)"
            }
          },
          yAxisIndex: [0, 1]
        },
        series: [
          {
            data: this.calls,
            name: "Calls",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            barWidth: 10,
            barGap: "-100%",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 0.3)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 255, 223, 1)"
                }
              ])
            }
          },
          {
            data: this.puts,
            name: "Puts",
            type: "bar",
            xAxisIndex: 0,
            yAxisIndex: 0,
            barWidth: 10,
            barGap: "-100%",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.2)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 1)"
                }
              ])
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["xAxis"][0]["axisLabel"]["show"] = false;
        this.options["xAxis"][1]["axisLabel"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        if (this.dataset == "premiums") {
          this.options["title"]["text"] = "Cum. Premiums By Strike $MM";
        } else if (this.dataset == "size") {
          this.options["title"]["text"] = "Cum. Volume By Strike";
        }
        this.options["title"]["textStyle"]["fontSize"] = 17;
        this.options["toolbox"]["show"] = false;
      }

      this.$refs.priceChart.setOption(this.options);
    },
    set_dataset(dataset) {
      this.dataset = dataset;
    },
    range: function(start, stop, step) {
      return Array.from({ length: (stop - start) / step + 1 }, function(_, i) {
        return { value: start + i * step, label: start + i * step };
      });
    }
  },
  watch: {
    optionsFlowByStrikeData: function() {
      this.setChart();
      this.$store.commit("setSubLoading", false);
    },
    dataset: function() {
      this.setChart();
    },
    lookback: function() {
      this.$store.dispatch({
        type: "getOptionsFlowByStrikeData",
        ticker: this.ticker,
        lookback: this.lookback
      });
      this.$store.commit("setSubLoading", true);
    },
    ticker: function() {
      this.$store.dispatch({
        type: "getOptionsFlowByStrikeData",
        ticker: this.ticker,
        lookback: this.lookback
      });
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 1000px;
  width: 100%;
}

.price-chart {
  width: 100%;
  height: 1000px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-controls-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}

.datatable-button {
  margin: 5px 6px 5px 0px;
  border-radius: 0px;
  height: 40px;
  margin-left: 0px;
  margin-right: 7px;
}

.datatable-tag {
  margin-left: 0px;
  margin-right: 7px;
}

.select-component {
  width: 200px !important;
}

.tag-container {
  display: flex;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1000px) {
  .table-controls-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 850px) {
}
</style>
