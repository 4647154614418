<template>
  <div class="registration-form">
    <span class="close" @click="toggleForms(false, false, false, false, false)"
      >&#10006;</span
    >
    <img src="../assets/logo/purple_small.png" alt="" />
    <div class="form">
      <slot name="function"> </slot>
      <slot name="user"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegistrationTemplate",
  data: () => ({}),
  methods: {
    toggleForms: function(f1, f2, f3, f4, f5) {
      this.$store.commit("setShowSignup", f1);
      this.$store.commit("setShowLogin", f2);
      this.$store.commit("setShowResend", f3);
      this.$store.commit("setShowResetPassword", f4);
      this.$store.commit("setShowUpdatePassword", f5);
    }
  }
};
</script>

<style lang="scss">
.registration-form {
  visibility: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: linear-gradient(
  //   140deg,
  //   rgba(233, 34, 84, 1) 0%,
  //   rgba(233, 34, 84, 1) 30%,
  //   rgba(140, 64, 226, 1) 100%
  // );
  background-color: rgba(29, 29, 29, 1);
  //backdrop-filter: blur(1px);
  //   background-image: linear-gradient(
  //     135deg,
  //     #242323 10%,
  //     #1e1e1f 50%,
  //     #222224 45%,
  //     #171717 100%,
  //     #171717 100%
  //   );
  // background-image: linear-gradient(
  //     to bottom,
  //     rgba(29, 29, 29, 0.8) 0%,
  //     rgba(140, 64, 226, 0.2) 100%
  //   ),
  //   url("../assets/svg/background.svg");
  //   background-image: linear-gradient(
  //       to bottom,
  //       rgba(23, 23, 23, 0.8) 0%,
  //       rgba(140, 64, 226, 0.8) 100%
  //     ),
  //     url("../assets/svg/background.svg");
  background-size: cover;
  background-attachment: fixed;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 250ms, visibility 250ms;
  transform: translateY(-80px);
  opacity: 0;
  z-index: 10000000;
  overflow-y: scroll;
}

.registration-form .close {
  font-size: 25px;
  color: #fff;
  position: absolute;
  top: 45px;
  right: 75px;
  &:hover {
    color: var(--purple-darker2);
    transition: all 0.3s ease;
    cursor: pointer;
  }
  &:active {
    color: rgb(161, 159, 159);
  }
}

.registration-form img {
  border-radius: 90px;
  margin-top: calc(100vh / 10);
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Nunito;
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 5px;
  height: max-content;
  width: 410px;
  max-width: 85%;
  min-width: 250px;
  background-color: var(--app-components-background-color) !important;
  z-index: 10000001;
  border: 1px var(--purple-darker) inset;
  -webkit-box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
  box-shadow: 0 0 10px rgba(177, 115, 247, 0.1);
}

.form .field {
  width: 90%;
}

.registration .form-input .input {
  font-family: Nunito !important;
  background-color: var(--app-components-background-color);
  border: none;
  border-bottom: solid 1px var(--purple-darker);
  border-radius: 0px !important;
  font-size: 13px;
  text-overflow: ellipsis;
}

.registration .form-input .input:focus + .icon {
  color: #fff !important;
  height: 2.6em !important;
}

.registration .icon {
  color: #fff !important;
  height: 2.6em !important;
}

// input:-webkit-autofill,
// input:-internal-autofill-selected {
//   background-color: var(--app-components-background-color) !important;
//   -webkit-box-shadow: 0 0 0px 1000px var(--app-components-background-color)
//     inset;
//   color: white;
// }

.registration input:-webkit-autofill,
.registration input:-webkit-autofill:hover,
.registration input:-webkit-autofill:focus,
.registration input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--app-components-background-color) inset !important;
}

.registration input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  -webkit-text-font-size: 8px !important;
}

.registration .control {
  margin: 10px auto;
  height: 45px;
  width: 80%;
}

.registration .title {
  font-size: 25px;
  color: #fff;
  font-family: Nunito;
  font-weight: 300;
  margin-top: 50px;
}

.registration .is-danger {
  box-shadow: none !important;
  color: #fff;
}

.registration .control-label {
  font-family: Nunito;
  color: #fff;
}

.registration .control .help.counter {
  color: #fff;
}

.registration .b-checkbox.checkbox {
  margin-top: 0px;
}

.registration .b-checkbox.checkbox input[type="checkbox"] + .check {
  border: 2px solid var(--purple-darker) !important;
  box-shadow: none !important;
}

.registration .b-checkbox.checkbox input[type="checkbox"]:checked + .check {
  border-color: var(--purple-darker) !important;
  background: var(--purple-darker)
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
}

.registration .b-checkbox.checkbox input[type="checkbox"]:hover {
  border: 2px solid var(--purple-darker) !important;
}

.registration button {
  display: inline-block;
  box-sizing: border-box;
  margin: 10px 8px 25px 8px;
  background-color: var(--app-components-background-color-lighter);
  padding: 8px 18px;
  font-family: var(--main-font);
  color: var(--purple-darker);
  font-size: 15px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 160px;
  height: 38px;
}

.registration .already {
  color: rgb(20, 2, 2);
  font-size: 14px;
}

.registration button:active {
  color: var(--purple-darker2);
  box-shadow: 0 -1px 0 var(--purple-darker2) inset;
}

.registration button:hover {
  background-color: var(--app-components-background-color-lighter);
}

.registration::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.registration {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
