<template>
  <div class="option-walls">
    <h1 class="page-title">Option Walls</h1>
    <div class="ticker-info-container">
      <TickerInfo v-bind:tickerOrigin="ticker"></TickerInfo>
      <div class="tag-container"><span class="tag">SPY Only</span></div>
    </div>
    <div class="option-walls-chart-container">
      <OptionWallsChart
        v-bind:optionWallsData="optionWallsData"
      ></OptionWallsChart>
    </div>
    <div class="option-minmax-chart-container">
      <OptionMinMaxChart
        v-bind:optionMinmaxData="optionMinmaxData"
      ></OptionMinMaxChart>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      An option wall refers to a large delta-adjusted open interest on a certain
      strike. It is simply the open interest for that strike multiplied by its
      delta. It is important because it tells us how many shares market makers
      need to buy or sell to remain delta neutral. Market makers typically make
      money by harvesting exchange rebates or by performing arbitrage trades. As
      such, most do not have a directional bias in the market and strive to
      remain hedged or flat. <br /><br />
      The mechanic of delta-neutrality is simple. For instance, if a market
      maker sells a call option on SPY with a delta of 0.90, it will need to buy
      90 SPY shares to remain delta neutral. As the delta-adjusted open interest
      builds up, so does the number of shares held by market makers. When close
      to expiration, a large number of these call options will be sold by
      investors, forcing, in turn, market makers to sell their shares.
      Therefore, if the market is above a large call option wall, it will create
      selling pressure as expiration approaches. This will preclude the price
      from freely moving upwards. The same goes for put option walls, but they
      instead create buyingselling pressure.
      <br /><br />
      Data updated at 4:35pm EST.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TickerInfo from "@/components/TickerInfo.vue";
import OptionWallsChart from "@/components/OptionWallsChart.vue";
import OptionMinMaxChart from "@/components/OptionMinMaxChart.vue";

export default {
  name: "Short",
  data() {
    return {
      optionWallsData: {},
      optionMinmaxData: {},
      ticker: "SPY",
      loading: true
    };
  },
  head: {
    title: {
      inner: "Options Walls"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Find the most important price levels with options walls. SPY option strikes with the highest Delta-Adjusted OI."
      }
    ]
  },
  methods: {
    get_option_walls() {
      axios
        .get("/get_option_walls")
        .then(res => {
          this.optionWallsData = res.data;
          this.getOptionMinmax();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getOptionMinmax() {
      axios
        .get("/get_option_minmax")
        .then(res => {
          this.optionMinmaxData = res.data;
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  components: {
    OptionWallsChart,
    OptionMinMaxChart,
    TickerInfo
  },
  mounted() {
    this.get_option_walls();
  },
  created() {
    //console.log("route", this.$router);
  }
};
</script>

<style lang="scss" scoped>
.tag-container {
  display: flex;
  align-items: flex-end;
  margin: 5px;
  margin-bottom: 0px;
}

.tag {
  align-items: center;
  background-color: var(--app-components-background-color);
  border-radius: 0px;
  font-family: var(--data-font);
  font-size: 14px;
  color: #ffffff;
  display: inline-flex;
  justify-content: center;
  padding: 1.5em 0.75em 1.3em 0.75em;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.text-content,
.option-walls-chart-container,
.option-minmax-chart-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .option-walls-chart-container,
  .option-minmax-chart-container,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .option-walls-chart-container,
  .option-minmax-chart-container,
  .ticker-info-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  .tag-container {
    display: none;
  }
}
</style>
