var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ts-control"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Autoregression (p) ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 1
        },"options":[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          { value: 3, label: 3 },
          { value: 4, label: 4 },
          { value: 5, label: 5 }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.p),callback:function ($$v) {_vm.p=$$v},expression:"p"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Integatred (d) ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 1
        },"disabled":_vm.dDisabled,"options":[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          { value: 3, label: 3 },
          { value: 4, label: 4 },
          { value: 5, label: 5 }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.d),callback:function ($$v) {_vm.d=$$v},expression:"d"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Moving Average (q) ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 1
        },"options":[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          { value: 3, label: 3 },
          { value: 4, label: 4 },
          { value: 5, label: 5 }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.q),callback:function ($$v) {_vm.q=$$v},expression:"q"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Trend ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 2,
          label: 't'
        },"options":[
          { value: 0, label: 'n' },
          { value: 1, label: 'c' },
          { value: 2, label: 't' },
          { value: 3, label: 'ct' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.trend),callback:function ($$v) {_vm.trend=$$v},expression:"trend"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Enforce Stationarity ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 'True'
        },"options":[
          { value: 0, label: 'False' },
          { value: 1, label: 'True' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.stationarity),callback:function ($$v) {_vm.stationarity=$$v},expression:"stationarity"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Trend Offset ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 1
        },"options":[
          { value: 0, label: 0 },
          { value: 1, label: 1 },
          { value: 2, label: 2 },
          { value: 3, label: 3 },
          { value: 4, label: 4 },
          { value: 5, label: 5 }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.offset),callback:function ($$v) {_vm.offset=$$v},expression:"offset"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Enforce Invertibility ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"default":{
          value: 1,
          label: 'True'
        },"options":[
          { value: 0, label: 'False' },
          { value: 1, label: 'True' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.invertibility),callback:function ($$v) {_vm.invertibility=$$v},expression:"invertibility"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Concentrate Scale ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{attrs:{"options":[
          { value: 0, label: 'False' },
          { value: 1, label: 'True' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.concentrate),callback:function ($$v) {_vm.concentrate=$$v},expression:"concentrate"}})],1)]),_c('hr'),_c('button',{staticClass:"datatable-button",on:{"click":function($event){return _vm.updateModel()}}},[_vm._v(" Update ")])])}
var staticRenderFns = []

export { render, staticRenderFns }