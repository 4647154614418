<template>
  <div class="option-mimax-chart">
    <v-chart class="chart" autoresize ref="optionMinmaxChart" />
  </div>
</template>

<script>
import "echarts";
import { eventBus } from "../main";
//import { graphic } from "echarts/core";

export default {
  name: "OptionMinMaxChart",
  props: {
    optionMinmaxData: Object
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "16%",
          bottom: "8%",
          left: "35",
          right: "35",
          containLabel: true
          //bottom: "3%"
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            magicType: {
              type: ["line", "bar"]
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        legend: {
          show: true,
          icon: "pin",
          right: 35,
          top: 50,
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        title: {
          text: "Maximum and Minimum Delta-Adjusted OI per Date",
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "axis",
          position: "cursor",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              backgroundColor: "rgba(23, 24, 26, 0.95)"
            }
          },
          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.optionMinmaxData["dates"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: {
          type: "value",
          name: "Strike",
          show: true,
          min: function(value) {
            return value.min - 10;
          },
          max: function(value) {
            return value.max + 10;
          },
          nameTextStyle: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        series: [
          {
            name: "Calls",
            type: "line",
            lineStyle: { color: "rgba(186, 116, 227, 0.8)" },
            itemStyle: { color: "rgba(186, 116, 227, 0.8)" },

            data: this.optionMinmaxData["calls"]
          },
          {
            name: "Puts",
            type: "line",

            // lineStyle: { color: "rgba(245, 49, 114, 0.8)" },
            // itemStyle: { color: "rgba(245, 49, 114, 0.8)" },
            //lineStyle: { color: "rgba(0, 187, 255, 1)" },
            //itemStyle: { color: "rgba(0, 187, 255, 1)" },
            // lineStyle: { color: "rgba(242, 157, 73, 0.8)" },
            // itemStyle: { color: "rgba(242, 157, 73, 0.8)" },
            lineStyle: { color: "rgba(53, 216, 252, 1)" },
            itemStyle: { color: "rgba(53, 216, 252, 1)" },
            data: this.optionMinmaxData["puts"]
          }
        ]
      };
      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "30",
          right: "30",
          containLabel: false
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.optionMinmaxChart.setOption(this.options);
    }
  },
  watch: {
    optionMinmaxData: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}

.option-chart {
  width: 100%;
}
</style>
