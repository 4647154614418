<template>
  <transition name="fade">
    <div class="popup-container" v-show="popupShow">
      <div class="popup">
        <span class="close" @click="close">&#10006;</span>
        <!-- <div class="header">Unusual Options Flow</div> -->
        <div class="header">Join our new discord</div>
        <div class="sub-header">Free for everyone</div>
        Click the icon
        <a href="https://discord.gg/5pXVFpGeA2" target="_blank"
          ><i class="mdi mdi-discord"></i
        ></a>
        <div class="body">
          You can also use the coupon code
          <span class="coupon">GRID50</span> to get 50% off on our options flow
          feature for the first month.
        </div>
        <div class="popup_buttons">
          <button v-show="path != '/'" @click="home">
            MORE INFO
          </button>
          <button @click="sub">SUBSCRIBE</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PopUp",
  data() {
    return {
      path: ""
    };
  },
  computed: {
    ...mapGetters({
      popupShow: "getPopupShow"
    })
  },
  methods: {
    home: function() {
      this.$router.push({ path: "/subscriptioncards" });
      this.$store.commit("setPopupShow", false);
    },
    sub: function() {
      this.$router.push({ path: "/subscriptioncards" });
      this.$store.commit("setPopupShow", false);
    },
    close: function() {
      this.$store.commit("setPopupShow", false);
    }
  },
  mounted() {
    this.path = this.$router.history.current.path;
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.3s, transform 0.3s;
}

.fade-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

// .fade-enter {
//   transform: translateY(-10px);
// }

.popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100000000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

a {
  color: rgb(59, 59, 59);
  &:hover {
    color: #fd3164;
    transition: all 0.2s ease;
    cursor: pointer;
  }
}

.popup .close {
  font-size: 18px;
  color: #fff;
  position: absolute;
  top: 20px;
  right: 25px;
  &:hover {
    color: #fd3164;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  &:active {
    color: rgb(161, 159, 159);
  }
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px 30px 30px;
  font-family: Nunito-Light;
  color: #fff;
  top: -5%;
  // left: calc(50% - 210px);
  background-color: rgba(26, 26, 26, 1);
  width: 420px;
  max-width: 80%;
  //border: solid 1px var(--purple-darker);
  //border-top: solid 1px var(--purple-darker);
  border-radius: 0px;
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
}

.popup button {
  display: inline-block;
  box-sizing: border-box;
  margin: 30px 8px 20px 8px;
  background-color: var(--amaranth);
  padding: 8px 18px;
  font-family: var(--main-font);
  font-weight: 300;
  color: #fff;
  font-size: 15px;
  border: none;
  //border-radius: 3px;
  //box-shadow: 0 -1px 0 var(--purple-darker) inset;
  height: 38px;
}

.popup button:focus {
  //color: var(--purple-darker2);
  //box-shadow: 0 -1px 0 var(--purple-darker2) inset;
  background-color: var(--amaranth-darker);
}

.popup button:hover {
  background-color: var(--amaranth-darker);
}

.popup .action-link {
  color: var(--purple-darker);
  cursor: pointer;
  &:active {
    color: var(--purple-darker2);
  }
}

.popup .header {
  font-size: 33px;
}

.popup .sub-header {
  font-size: 28px;
}

.popup .body {
  font-size: 20px;
}

.popup .coupon {
  font-size: 20px;
  font-family: Nunito;
  color: #fd3164;
}

.popup .mdi {
  font-size: 70px;
}

@media only screen and (max-width: 450px) {
  .popup button {
    margin: 10px 8px 10px 8px;
  }

  .popup .body {
    margin-bottom: 20px;
  }
}
</style>
