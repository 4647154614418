<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "SP500RatiosChart",
  props: {
    data: Object,
    title: String
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "11 %",
            left: 70,
            right: 70,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "41%",
            left: 70,
            right: 70,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "71%",
            bottom: "7%",
            left: 70,
            right: 70,
            height: "20%"
          }
        ],
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: this.title,
            left: "30",
            top: "3%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 23
            }
          },
          {
            text: "Change Over Last Period",
            left: "30",
            top: "35%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "Change Over Last Period %",
            left: "30",
            top: "65%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          confine: false,
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          formatter: function(params) {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';

            if (params.length == 3) {
              var dataset = params.filter(element => {
                return (
                  element.seriesName != "Change" &&
                  element.seriesName != "Change %"
                );
              });
              var change = params.filter(element => {
                return element.seriesName == "Change";
              });
              var changePct = params.filter(element => {
                return element.seriesName == "Change %";
              });
              return (
                colorSpan(params[0].color) +
                "&nbsp;" +
                params[0].name +
                "<br/>" +
                "<br/>" +
                "Value:&nbsp;&nbsp;" +
                dataset[0].data +
                "<br/>" +
                "Change:&nbsp;&nbsp;" +
                change[0].data +
                "<br/>" +
                "Change %:&nbsp;&nbsp;" +
                changePct[0].data
              );
            } else {
              return "";
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { xAxisIndex: [0, 1, 2] }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1, 2]
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: [
          {
            type: "category",
            gridIndex: 0,
            boundaryGap: true,
            data: this.data["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 1,
            boundaryGap: true,
            data: this.data["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 2,
            boundaryGap: true,
            data: this.data["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "Value",
            gridIndex: 0,
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Change",
            gridIndex: 1,
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Change %",
            gridIndex: 2,
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.data["data"],
            name: this.title,
            xAxisIndex: 0,
            yAxisIndex: 0,
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.data["change"],
            name: "Change",
            xAxisIndex: 1,
            yAxisIndex: 1,
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.data["change_pct"],
            name: "Change %",
            xAxisIndex: 2,
            yAxisIndex: 2,
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "11 %",
            left: 45,
            right: 30,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "41%",
            left: 45,
            right: 30,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "71%",
            bottom: "7%",
            left: 45,
            right: 30,
            height: "20%"
          }
        ];
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 1100px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
