<template>
  <div>
    <div class="table-controls-container">
      <b-tag type="is-dark" size="is-medium" class="datatable-tag"
        >Cumulative Days:
      </b-tag>
      <SelectComponent
        v-model="lookback"
        class="select-component"
        :default="lookbacks[18]"
        :options="lookbacks"
      >
      </SelectComponent>
    </div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
import { mapGetters } from "vuex";
const SelectComponent = () => import("@/components/SelectComponent.vue");

export default {
  name: "OptionsFlowCumulativeStatsChart",
  data() {
    return {
      options: {},
      lookback: 20,
      lookbacks: this.range(2, 40, 1)
    };
  },
  computed: {
    ...mapGetters({
      optionsFlowHistoricalPremiumData: "getOptionsFlowHistoricalPremiumData",
      ticker: "getTicker"
    })
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "11 %",
            //bottom: "60%",
            left: 70,
            right: 70,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "41%",
            //bottom: "34%",
            left: 70,
            right: 70,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "71%",
            bottom: "8%",
            left: 70,
            right: 70,
            height: "20%"
          }
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1, 2]
            },
            // magicType: {
            //   type: ["line"]
            // },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Net Premiums $MM",
            left: "30",
            top: "3%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "Call Premiums $MM",
            left: "30",
            top: "35%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "Put Premiums $MM",
            left: "30",
            top: "65%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          confine: false,
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { xAxisIndex: [0, 1, 2] }
        },
        xAxis: [
          {
            type: "category",
            gridIndex: 0,
            boundaryGap: true,
            data: this.optionsFlowHistoricalPremiumData["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 1,
            boundaryGap: true,
            data: this.optionsFlowHistoricalPremiumData["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 2,
            boundaryGap: true,
            data: this.optionsFlowHistoricalPremiumData["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "Daily",
            show: true,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            min: function(value) {
              return value.min;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Cumulative",
            show: true,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Daily",
            show: true,
            gridIndex: 1,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Cumulative",
            show: true,
            gridIndex: 1,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Daily",
            show: true,
            gridIndex: 2,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Cumulative",
            show: true,
            gridIndex: 2,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Price",
            show: false,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            min: function(value) {
              return value.min;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.optionsFlowHistoricalPremiumData["prices"],
            xAxisIndex: 0,
            yAxisIndex: 6,
            name: "Prices",
            type: "line",
            showAllSymbol: false,
            // areaStyle: {
            //   color: new graphic.LinearGradient(1, 0, 1, 1, [
            //     {
            //       offset: 1,
            //       color: "rgba(109, 89, 122, 0.2)"
            //     },
            //     {
            //       offset: 0,
            //       color: "rgba(234, 3, 255, 0.2)"
            //     }
            //   ])
            // },
            lineStyle: {
              width: 2,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 1,
                    color: "rgba(152, 71, 191, 1)" // color at 0% position
                  },
                  {
                    offset: 1,
                    color: "rgba(255, 187, 0, 1)" // color at 100% position
                  }
                ],
                global: false
                // false by default
              } //{ color: "rgba(240, 34, 99, 1)" }, //255, 178, 56 //240, 34, 99
            },
            itemStyle: { color: "rgba(152, 71, 191, 1)" }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["cum_net_premiums"],
            xAxisIndex: 0,
            yAxisIndex: 0,
            name: "Cumulative Net Premiums $MM",
            type: "line",
            showAllSymbol: false,
            symbolSize: 1,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(168, 53, 240, 0.1)"
                },
                {
                  offset: 0,
                  color: "rgba(66, 194, 245, 0.4)"
                }
              ])
            },
            lineStyle: { color: "rgba(168, 53, 240, 0)" },
            itemStyle: { color: "rgba(168, 53, 240, 0.5)" }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["net_premiums"],
            xAxisIndex: 0,
            yAxisIndex: 1,
            name: "Net Premiums $MM",
            type: "bar",
            barGap: "-100%",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 255, 223, 0.9)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 255, 223, 1)"
                }
              ])
            }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["cum_call_premiums"],
            xAxisIndex: 1,
            yAxisIndex: 3,
            name: "Cumulative Call Premiums $MM",
            showAllSymbol: false,
            symbolSize: 1,
            type: "line",
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 0.3)"
                }
              ])
            },
            lineStyle: { color: "rgba(10, 252, 180, 0.5)" },
            itemStyle: { color: "rgba(10, 252, 180, 0.5)" }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["call_premiums"],
            xAxisIndex: 1,
            yAxisIndex: 2,
            name: "Call Premiums $MM",
            type: "bar",
            barGap: "-100%",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 0.3)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 255, 223, 1)"
                }
              ])
            }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["cum_put_premiums"],
            xAxisIndex: 2,
            yAxisIndex: 5,
            name: "Cumulative Put Premiums $MM",
            showAllSymbol: false,
            symbolSize: 1,
            type: "line",
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.3)"
                }
              ])
            },
            lineStyle: { color: "rgba(217, 79, 255, 0.5)" },
            itemStyle: { color: "rgba(217, 79, 255, 0.5)" }
          },
          {
            data: this.optionsFlowHistoricalPremiumData["put_premiums"],
            xAxisIndex: 2,
            yAxisIndex: 4,
            name: "Put Premiums $MM",
            type: "bar",
            barGap: "-100%",
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.2)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.8)"
                }
              ])
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["tooltip"]["confine"] = true;
        this.options["grid"] = [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "11%",
            //bottom: "60%",
            left: 50,
            right: 50,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "41%",
            //bottom: "34%",
            left: 50,
            right: 50,
            height: "20%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "71%",
            bottom: "8%",
            left: 50,
            right: 50,
            height: "20%"
          }
        ];
      }

      this.$refs.priceChart.setOption(this.options);
    },
    range: function(start, stop, step) {
      return Array.from({ length: (stop - start) / step + 1 }, function(_, i) {
        return { value: start + i * step, label: start + i * step };
      });
    }
  },
  components: {
    SelectComponent
  },
  watch: {
    optionsFlowHistoricalPremiumData: function() {
      this.setChart();
      this.$store.commit("setLoading", false);
    },
    lookback: function() {
      this.$store.dispatch({
        type: "getOptionsFlowHistoricalPremiumData",
        ticker: this.ticker,
        lookback: this.lookback
      });
      this.$store.commit("setLoading", true);
    },
    ticker: function() {
      this.$store.dispatch({
        type: "getOptionsFlowHistoricalPremiumData",
        ticker: this.ticker,
        lookback: this.lookback
      });
      this.$store.commit("setLoading", true);
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 1000px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-controls-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}

.datatable-tag {
  margin-left: 0px;
  margin-right: 7px;
}

.select-component {
  width: 200px !important;
}
</style>
