<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "ShortInterestChart",
  props: {
    priceData: Object,
    indShortInterest: Object
  },
  data() {
    return {
      isSet: false,
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "35",
          right: "35",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "Price vs Short Interest",
          left: "30",
          top: "3%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            magicType: {
              type: ["line", "bar"]
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: this.priceData["dates"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "Volume 000s",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Price",
            show: true,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.priceData["prices"],
            name: "Price",
            type: "line",
            yAxisIndex: 1,
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.priceData["volume"],
            name: "Volume 000s",
            type: "bar",
            itemStyle: {
              color: "rgba(143, 64, 189, 0.7)"
            }
          },
          {
            data: this.indShortInterest["short_interest"],
            name: "Short Interest 000s",
            type: "line",
            lineStyle: { color: "rgba(0, 187, 255, 1)" },
            //itemStyle: { color: "rgba(0, 187, 255, 0)" },
            itemStyle: {
              opacity: 0
            },
            connectNulls: true
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "30",
          right: "30",
          containLabel: false
          //bottom: "3%"
        };
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][1]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    indShortInterest: function() {
      this.setChart();
      this.isSet = true;
    },
    priceData: function() {
      if (this.isSet == true) {
        this.setChart();
      }
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
