<template>
  <div class="clusters-table">
    <h1 class="page-title">Clusters / All</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
    </div>
    <div class="price-chart-container">
      <ClustersPredictionChart></ClustersPredictionChart>
    </div>
    <div class="table-control-container">
      <b-tag type="is-dark" size="is-medium" class="datatable-tag"
        >Forward Returns
      </b-tag>
      <div class="button-container">
        <button class="datatable-button" v-on:click="selectData('all')">
          All
        </button>
        <button class="datatable-button" v-on:click="selectData('sp500')">
          S&P 500
        </button>
        <button class="datatable-button" v-on:click="selectData('nasdaq100')">
          NASDAQ 100
        </button>
      </div>
    </div>
    <div class="table-container">
      <b-table
        class="datatable fixed"
        :data="data"
        :striped="true"
        :hoverable="true"
        :paginated="true"
        :mobile-cards="false"
        :per-page="20"
        :default-sort="sort"
        @click="table_click"
        sortable
        ref="table"
      >
        <b-table-column field="ticker" label="Ticker" sortable v-slot="props">
          {{ props.row.ticker }}
        </b-table-column>
        <b-table-column
          field="date"
          label="Date"
          v-slot="props"
          :visible="col1"
        >
          {{ props.row.date }}
        </b-table-column>
        <b-table-column
          field="close"
          label="Close"
          sortable
          v-slot="props"
          :visible="col2"
        >
          {{ props.row.close }}
        </b-table-column>
        <b-table-column
          field="1d_forward_return"
          label="1D"
          sortable
          v-slot="props"
        >
          {{ props.row["1d_forward_return"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="2d_forward_return"
          label="2D"
          sortable
          v-slot="props"
          :visible="col3"
        >
          {{ props.row["2d_forward_return"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="5d_forward_return"
          label="5D"
          sortable
          v-slot="props"
        >
          {{ props.row["5d_forward_return"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="10d_forward_return"
          label="10D"
          sortable
          v-slot="props"
        >
          {{ props.row["10d_forward_return"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="20d_forward_return"
          label="20D"
          sortable
          v-slot="props"
        >
          {{ props.row["20d_forward_return"] | formatDecimal }}
        </b-table-column>
      </b-table>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      This page uses the same k-means clustering algorithm as the
      <i>Clustering > Single Stock</i> page. It shows the forward returns for
      all the stocks being part of the S&P500 and the Nasdaq100. The parameters
      are the default ones found on the <i>Clustering > Single Stock</i> page.
      The table format makes it convenient to find the stocks with the
      highest/lowest forward returns.
    </div>
  </div>
</template>

<script>
import TickerInfo from "@/components/TickerInfo.vue";
import ClustersPredictionChart from "@/components/ClustersPredictionChart.vue";
import { mapGetters } from "vuex";
import { eventBus } from "../main";

export default {
  name: "ClustersTable",
  data() {
    return {
      ticker: "",
      data: [],
      data_selection: "all",
      sort: ["20d_forward_return", "desc"],
      col1: true,
      col2: true,
      col3: true,
      loading: true
    };
  },
  head: {
    title: {
      inner: "Clusters Table"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "List of stock market clusters and forward returns. Filter the stocks that have the top predicted performance."
      }
    ]
  },
  methods: {
    updateTickerData(ticker) {
      this.ticker = ticker;
      this.getClustersData(ticker);
      this.loading = true;
    },
    getClustersPredictionData(ticker, d1, d2, d5, d10, d20) {
      this.$store.dispatch({
        type: "getClustersPredictionData",
        ticker: ticker,
        d1: d1,
        d2: d2,
        d5: d5,
        d10: d10,
        d20: d20
      });
    },
    getClustersData(ticker) {
      this.$store.dispatch({
        type: "getClustersData",
        ticker: ticker,
        nbClusters: 8,
        length: 10,
        metric: "euclidean",
        iterations: 10,
        features: "pcvol",
        trainingWindow: 400
      });
    },
    table_click(event) {
      this.loading = true;
      this.updateTickerData(event.ticker);
      this.getClustersPredictionData(
        event["ticker"],
        event["1d_forward_return"],
        event["2d_forward_return"],
        event["5d_forward_return"],
        event["10d_forward_return"],
        event["20d_forward_return"]
      );
    },
    selectData(selector) {
      this.data = this.clustersTableData[selector]["data"];
    },
    set_breakpoints(width) {
      if (width < 1000) {
        this.col1 = false;
      } else {
        this.col1 = true;
      }
      if (width < 700) {
        this.col2 = false;
      } else {
        this.col2 = true;
      }
      if (width < 600) {
        this.col3 = false;
      } else {
        this.col3 = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      clustersTableData: "getClustersTableData",
      clustersPredictionData: "getClustersPredictionData"
    })
  },
  components: {
    TickerInfo,
    ClustersPredictionChart
  },
  mounted() {
    this.set_breakpoints(window.innerWidth);
    eventBus.$on("resize", width => {
      this.set_breakpoints(width);
    });
    this.$store.dispatch("getClustersTableData");
    //If the url param is not empty, the ticker will be equal to the ticker passed in the url
  },
  watch: {
    clustersTableData: function() {
      this.data = this.clustersTableData["all"]["data"];
      this.ticker = this.data[0]["ticker"];
      this.getClustersPredictionData(
        this.data[0]["ticker"],
        this.data[0]["1d_forward_return"],
        this.data[0]["2d_forward_return"],
        this.data[0]["5d_forward_return"],
        this.data[0]["10d_forward_return"],
        this.data[0]["20d_forward_return"]
      );
    },
    clustersPredictionData: function() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.clusters-table {
  min-height: 1000px;
}

.price-chart-container,
.text-content,
.table-control-container,
.table-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-control-container {
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.ticker-info {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
}

@media only screen and (max-width: 1250px) {
  .price-chart-container,
  .text-content,
  .table-container,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .price-chart-container,
  .text-content,
  .table-container,
  .ticker-info-container {
    width: 95%;
  }
}
</style>
