var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-table-container"},[_c('OptionsFlowTableComponent',{attrs:{"data":_vm.most_otm.most_otm_data,"initalSortOrder":'otm',"columns":[
      { label: 'Date', field: 'date', breakpoint: 3 },
      { label: 'Time', field: 'time', breakpoint: 1 },
      { label: 'Ticker', field: 'ticker', class: 'ticker' },
      { label: 'C/P', field: 'put_call' },
      { label: 'Strike', field: 'strike_price', prepend: '$' },
      { label: 'DTE', field: 'DTE', breakpoint: 1 },
      { label: 'Fill', field: 'fill', breakpoint: 1 },
      { label: 'Price', field: 'price', breakpoint: 1 },
      { label: 'Size', field: 'size', breakpoint: 2 },
      { label: 'Type', field: 'option_activity_type', breakpoint: 2 },
      { label: 'OI', field: 'open_interest', breakpoint: 2 },
      { label: 'Volume', field: 'volume', breakpoint: 3 },
      { label: 'Score', field: 'score', breakpoint: 2 },
      {
        label: 'Premium',
        field: 'cost_basis',
        format: 'thousands',
        prepend: '$',
        append: 'K'
      },
      {
        label: 'OTM By',
        field: 'otm',
        format: 'decimal_0',
        append: '%'
      }
    ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }