<template>
  <div class="spytovix">
    <h1 class="page-title">SPY To VIX Forward Returns</h1>
    <template v-for="period in [1, 2]">
      <div class="title-tag" :key="period">
        {{ period + "d Forward Returns" }}
      </div>
      <div class="table-chart-container" :key="period">
        <div class="positive-title">
          {{ title }}
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <td colspan="7">
                  <div class="cell-title">
                    Regression
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-header">
                    Parameters
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    Coefficients
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    Standard Errors
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    t-stats
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    P > |t|
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    [0.025
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    0.975]
                  </div>
                </td>
              </tr>
            </thead>
            <tbody v-if="isSet">
              <tr>
                <td>
                  <div class="cell-container">
                    alpha
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Coef."]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Std.Err."]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["t"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["P>|t|"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["[0.025"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["0.975]"]["const"]
                      )
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-container">
                    beta
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Coef."]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Std.Err."]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["t"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["P>|t|"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["[0.025"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["0.975]"]["x1"]
                      )
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="cell-container">
                    Correlation:
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["correlation"]
                      )
                    }}
                  </div>
                </td>
                <td colspan="4">
                  <div class="cell-container">
                    R-Squared:
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["r_squared"]
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <td colspan="11">
                  <div class="cell-title">
                    Probabilities
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-header">
                    SPY/VIX
                  </div>
                </td>
                <template
                  v-for="ret in spyToVixData['spy_to_vix_prob_positive'].index
                    .x"
                >
                  <td :key="ret">
                    <div class="cell-header">
                      {{ ret }}
                    </div>
                  </td>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="cell-container">
                    Positive
                  </div>
                </td>
                <template
                  v-for="(row, row_index) in spyToVixData[
                    'spy_to_vix_prob_positive'
                  ].data[period + 'd']"
                >
                  <td v-if="row_index % 2 == 1" :key="row_index">
                    <div class="cell-container">{{ decimal1(row[2]) }}%</div>
                  </td>
                </template>
              </tr>
              <tr>
                <td>
                  <div class="cell-container">
                    Negative
                  </div>
                </td>
                <template
                  v-for="(row, row_index) in spyToVixData[
                    'spy_to_vix_prob_positive'
                  ].data[period + 'd']"
                >
                  <td v-if="row_index % 2 == 0" :key="row_index">
                    <div class="cell-container">{{ decimal1(row[2]) }}%</div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="chart-container">
          <SPYToVIXProbabilityChart
            :data="spyToVixData"
            :feature="'spy_to_vix_prob_positive'"
            :period="period + 'd'"
            :minmax="[35, 65]"
          ></SPYToVIXProbabilityChart>
          <SPYToVIXDistributionChart
            :data="spyToVixData"
            :feature="'spy_to_vix_rounded_1'"
            :period="period + 'd'"
            :minmax="[0, 8]"
          ></SPYToVIXDistributionChart>
          <SPYToVIXDistributionChart
            :data="spyToVixData"
            :feature="'spy_to_vix_rounded_10'"
            :period="period + 'd'"
            :minmax="[0, 8]"
          ></SPYToVIXDistributionChart>
        </div>
      </div>
    </template>
    <template v-for="period in [5, 10, 20]">
      <div class="title-tag" :key="period">
        {{ period + "d Forward Returns" }}
      </div>
      <div class="table-chart-container" :key="period">
        <div class="positive-title">
          {{ title }}
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <td colspan="7">
                  <div class="cell-title">
                    Regression
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-header">
                    Parameters
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    Coefficients
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    Standard Errors
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    t-stats
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    P > |t|
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    [0.025
                  </div>
                </td>
                <td>
                  <div class="cell-header">
                    0.975]
                  </div>
                </td>
              </tr>
            </thead>
            <tbody v-if="isSet">
              <tr>
                <td>
                  <div class="cell-container">
                    alpha
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Coef."]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Std.Err."]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["t"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["P>|t|"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["[0.025"]["const"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["0.975]"]["const"]
                      )
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-container">
                    beta
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Coef."]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["Std.Err."]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["t"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["P>|t|"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["[0.025"]["x1"]
                      )
                    }}
                  </div>
                </td>
                <td>
                  <div class="cell-container">
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["0.975]"]["x1"]
                      )
                    }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="cell-container">
                    Correlation:
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["correlation"]
                      )
                    }}
                  </div>
                </td>
                <td colspan="4">
                  <div class="cell-container">
                    R-Squared:
                    {{
                      decimal2(
                        spyToVixData["spy_to_vix_prob_positive"][
                          "regression_data"
                        ][period + "d"]["r_squared"]
                      )
                    }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <td colspan="11">
                  <div class="cell-title">
                    Probabilities
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="cell-header">
                    SPY/VIX
                  </div>
                </td>
                <template
                  v-for="ret in spyToVixData['spy_to_vix_prob_positive'].index
                    .x"
                >
                  <td :key="ret">
                    <div class="cell-header">
                      {{ ret }}
                    </div>
                  </td>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="cell-container">
                    Positive
                  </div>
                </td>
                <template
                  v-for="(row, row_index) in spyToVixData[
                    'spy_to_vix_prob_positive'
                  ].data[period + 'd']"
                >
                  <td v-if="row_index % 2 == 1" :key="row_index">
                    <div class="cell-container">{{ decimal1(row[2]) }}%</div>
                  </td>
                </template>
              </tr>
              <tr>
                <td>
                  <div class="cell-container">
                    Negative
                  </div>
                </td>
                <template
                  v-for="(row, row_index) in spyToVixData[
                    'spy_to_vix_prob_positive'
                  ].data[period + 'd']"
                >
                  <td v-if="row_index % 2 == 0" :key="row_index">
                    <div class="cell-container">{{ decimal1(row[2]) }}%</div>
                  </td>
                </template>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="chart-container">
          <SPYToVIXProbabilityChart
            :data="spyToVixData"
            :feature="'spy_to_vix_prob_positive'"
            :period="period + 'd'"
            :minmax="[35, 65]"
          ></SPYToVIXProbabilityChart>
          <SPYToVIXDistributionChart
            :data="spyToVixData"
            :feature="'spy_to_vix_rounded_1'"
            :period="period + 'd'"
            :minmax="[0, 5]"
          ></SPYToVIXDistributionChart>
          <SPYToVIXDistributionChart
            :data="spyToVixData"
            :feature="'spy_to_vix_rounded_10'"
            :period="period + 'd'"
            :minmax="[0, 5]"
          ></SPYToVIXDistributionChart>
        </div>
      </div>
    </template>

    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      This page allows you to see the distribution of the SPY forward returns,
      given the observed VIX returns. It demonstrates that there is a
      significant correlation between the two. The strongest correlation would
      be for the 5-days horizon at ~0.86. As you can see, the probability of the
      SPY being up after 5 days, when the VIX has a +30% return, is at ~65%.
      Unfortunately, this is when a lot of people will get scared and sell their
      position. Likewise, the probability of the SPY being up after 5 days, when
      the VIX has a -30% return, is at ~44%. This shows that premature buying on
      a strong up day out of a volatile cluster does not confer a significant
      edge.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SPYToVIXDistributionChart from "@/components/SPYToVIXDistributionChart.vue";
import SPYToVIXProbabilityChart from "@/components/SPYToVIXProbabilityChart.vue";
import FiltersMixin from "@/filters/FiltersMixin.js";
export default {
  name: "SPYToVIX",
  mixins: [FiltersMixin],
  data() {
    return {
      isSet: false,
      title: "Probability Of SPY Being Positive | VIX Returns",
      periods: [1, 2, 5, 10, 20],
      spyToVixData: {
        spy_to_vix_prob_positive: { data: {}, index: {}, regression_data: {} },
        spy_to_vix_rounded_1: { data: {}, index: {} },
        spy_to_vix_rounded_10: { data: {}, index: {} }
      }
    };
  },
  head: {
    title: {
      inner: "SPY To VIX"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Heatmap of the SPY to VIX distribution. Allows you to see correlation and patterns between the current VIX return and the forward SPY returns."
      }
    ]
  },
  methods: {},
  computed: {
    ...mapGetters({
      loading: "getLoading",
      spyToVix: "getSpyToVixData"
    })
  },
  components: {
    SPYToVIXDistributionChart,
    SPYToVIXProbabilityChart
  },
  watch: {
    spyToVix: function() {
      this.spyToVixData = this.spyToVix;
      this.isSet = true;
      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.$store.dispatch({
      type: "getSpyToVixData"
    });
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    window.addEventListener("resize", () => {
      if (window.innerWidth > 850) {
        this.title = "Probability Of SPY Being Positive | VIX Returns";
      } else {
        this.title = "Prob. Of SPY Positive";
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.spytovix {
  margin-bottom: 70px;
}

.page-title,
.text-content,
.table-chart-container {
  min-width: 320px;
  margin: 0 auto;
  width: 80%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-chart-container {
  background-color: var(--app-components-background-color);
  padding: 1px 0 0 0;
}

.text-content {
  margin-top: 10px;
}

table {
  font-family: Chivo-Regular;
  color: #fff;
  background-color: var(--app-components-background-color);
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}

td {
  border: solid 0.5px rgb(50, 50, 50);
  & :hover {
    background-color: #222222;
  }
}

.table-container {
  background-color: var(--app-components-background-color);
  margin-bottom: 0px !important;
  padding: 0 20px 10px 20px;
}

.title-tag {
  margin: 30px auto 10px 10%;
}

.positive-title {
  font-family: Chivo-Regular;
  color: #fff;
  font-size: 23px;
  width: max-content;
  margin-left: 3%;
  margin-top: 25px;
  margin-bottom: 20px;
}

.page-title {
  margin-top: 60px;
}

.cell-title,
.cell-header {
  font-size: 18px;
  padding: 8px 2px;
  min-width: 54px;
  white-space: nowrap;
  font-family: Chivo-Regular;
}

.cell-title {
  font-size: 20px;
}

.cell-container {
  padding: 8px 0px;
  min-width: 54px;
  font-family: Chivo-Regular;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .text-content,
  .table-chart-container {
    width: 95%;
  }
  .title-tag {
    margin: 30px auto 10px 2.5%;
  }
  table {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background-color: #292929;
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  background: #242424;
}
</style>
