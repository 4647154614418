<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { eventBus } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "SectorRotationChart",
  data() {
    return {
      options: {}
    };
  },
  props: {
    index: String,
    factor: String,
    color: String
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "18%",
          bottom: "12%",
          left: "90",
          right: "60"
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {},
            // magicType: {
            //   type: ["line"]
            // },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        title: {
          text: String(this.index.split(",")[1]) + " Sector Rotation",
          left: "30",
          top: "5%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(20, 20, 20, 0.9)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          },
          formatter: function(params) {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            if (params.componentType == "series") {
              return (
                colorSpan(params.color) +
                "&nbsp;" +
                params.data.ticker +
                "<br/>" +
                params.data.name +
                "<br/>" +
                "Sector: &nbsp;" +
                params.data.sector +
                "<br/>" +
                "Super Sector: &nbsp;" +
                params.data.super_sector +
                "<br/>" +
                "Position: &nbsp;" +
                params.data.position
              );
            } else {
              return params.value;
            }
          }
        },
        xAxis: {
          type: "value",
          name: "Relative Strength",
          nameLocation: "middle",
          nameGap: 35,
          min: function(value) {
            return (
              Math.round((value.min * 0.9998 + Number.EPSILON) * 100) / 100
            );
          },
          max: function(value) {
            return (
              Math.round((value.max * 1.0003 + Number.EPSILON) * 100) / 100
            );
          },
          boundaryGap: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: {
          type: "value",
          name: String(this.factor.split(",")[1]),
          nameLocation: "middle",
          nameRotate: 90,
          nameGap: 40,
          show: true,
          minInterval: 1,
          nameTextStyle: {},
          min: function(value) {
            return (
              Math.round((value.min * 0.9993 + Number.EPSILON) * 100) / 100
            );
          },
          max: function(value) {
            return (
              Math.round((value.max * 1.0008 + Number.EPSILON) * 100) / 100
            );
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        }
      };
      if (window.innerWidth < 850) {
        this.options["grid"] = {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "18%",
          bottom: "12%",
          left: "35",
          right: "35"
          //bottom: "3%"
        };

        this.options["toolbox"]["show"] = false;
        this.options["yAxis"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }
    },
    setOptions: function() {
      this.options["dataset"] = {};
      this.options["dataset"]["source"] = this.sectorRotationData["main_data"];
      this.options["dataset"]["dimensions"] = [
        "close",
        "date",
        "lookback",
        "name",
        "position",
        String(this.factor.split(",")[0]),
        "relative_strength",
        "sector",
        "super_sector",
        "ticker"
      ];
      this.options["series"] = {
        type: "scatter",
        symbolSize: 16,
        encode: {
          // Map dimension "amount" to the X axis.
          x: "relative_strength",
          // Map dimension "product" to the Y axis.
          y: String(this.factor.split(",")[0]),
          label: "ticker"
        },
        itemStyle: {
          color: param => {
            if (this.color == "position") {
              if (param.data.position == "Leading") {
                return "rgba(28, 252, 196, 0.9)";
              } else if (param.data.position == "Weakening") {
                return "rgba(188, 64, 226, 0.9)";
              } else if (param.data.position == "Lagging") {
                return "rgba(45, 144, 237, 0.9)";
              } else if (param.data.position == "Improving") {
                return "rgba(140, 64, 226, 0.9)";
              }
            } else if (this.color == "sector") {
              if (param.data.sector == "Communication Services") {
                return "rgba(28, 252, 222, 0.9)";
              } else if (param.data.sector == "Information Technology") {
                return "rgba(188, 64, 226, 0.9)";
              } else if (param.data.sector == "Consumer Discretionary") {
                return "rgba(45, 144, 237, 0.9)";
              } else if (param.data.sector == "Consumer Staples") {
                return "rgba(140, 64, 226, 0.9)";
              } else if (param.data.sector == "Health Care") {
                return "rgba(35, 235, 138, 0.9)";
              } else if (param.data.sector == "Industrials") {
                return "rgba(147, 85, 166, 0.9)";
              } else if (param.data.sector == "Utilities") {
                return "rgba(94, 74, 194, 0.9)";
              } else if (param.data.sector == "Financials") {
                return "rgba(110, 81, 143, 0.9)";
              } else if (param.data.sector == "Materials") {
                return "rgba(199, 86, 140, 0.9)";
              } else if (param.data.sector == "Real Estate") {
                return "rgba(26, 124, 163, 0.9)";
              } else if (param.data.sector == "Energy") {
                return "rgba(140, 64, 226, 0.9)";
              }
            } else if (this.color == "super") {
              if (param.data.super_sector == "Sensitive") {
                return "rgba(188, 64, 226, 0.9)";
              } else if (param.data.super_sector == "Defensive") {
                return "rgba(45, 144, 237, 0.9)";
              } else if (param.data.super_sector == "Cyclical") {
                return "rgba(35, 235, 138, 0.9)";
              }
            }
          },
          borderColor: "rgba(0, 0, 0, 0.6)"
        },
        label: {
          show: true,
          fontSize: 12,
          color: "rgba(255, 255, 255, 1)",
          backgroundColor: "rgba(20, 20, 20, 0.7)"
        },
        labelLayout: {
          hideOverlap: true
        },
        markPoint: {
          data: [
            {
              value: "Leading",
              x: "93%",
              y: "14%"
            },
            {
              value: "Weakening",
              x: "93%",
              y: "95%"
            },
            {
              value: "Lagging",
              x: "8%",
              y: "95%"
            },
            {
              value: "Improving",
              x: "8%",
              y: "14%"
            }
          ],
          symbolSize: 30,
          symbol: "roundRect",
          slient: false,
          itemStyle: {
            color: "rgba(30, 30, 30, 0.2)"
          },
          label: {
            color: "rgba(251, 251, 251, 1)"
          }
        },
        markLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.2)"
          },
          symbol: "none",
          label: {
            show: false
          },
          data: [
            {
              yAxis: 100
            },
            {
              xAxis: 100
            }
          ]
        }
      };

      if (window.innerWidth < 850) {
        this.options["series"]["markPoint"] = {
          data: [
            {
              value: "Leading",
              x: "86%",
              y: "14%"
            },
            {
              value: "Weakening",
              x: "86%",
              y: "95%"
            },
            {
              value: "Lagging",
              x: "14%",
              y: "95%"
            },
            {
              value: "Improving",
              x: "14%",
              y: "14%"
            }
          ],
          symbolSize: 30,
          symbol: "roundRect",
          slient: false,
          itemStyle: {
            color: "rgba(30, 30, 30, 0.2)"
          },
          label: {
            color: "rgba(251, 251, 251, 1)"
          }
        };
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  computed: {
    ...mapGetters({
      sectorRotationData: "getSectorRotationData"
    })
  },
  watch: {
    sectorRotationData: function() {
      this.setChart();
      this.setOptions();
    },
    color: function() {
      this.setChart();
      this.setOptions();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
      this.setOptions();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 650px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
