<template>
  <div class="short-info">
    <div class="name">Short Data</div>
    <div class="grid-container">
      <div class="grid-item">Short Interest</div>
      <div class="grid-item">Days To Cover</div>
      <div class="grid-item">%Float Short</div>
      <div class="grid-item"></div>
      <div class="grid-item">
        {{ shortInfo["Short Interest"] | formatNumber }}
      </div>
      <div class="grid-item">
        {{ shortInfo["Days To Cover"] | formatDecimal }}
      </div>
      <div class="grid-item">
        {{ shortInfo["%Float Short"] | formatDecimal }}
      </div>
      <div class="grid-item">{{ empty }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortInfo",
  data() {
    return {
      empty: ""
    };
  },
  props: {
    shortInfo: Object
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=DM+Mono&display=swap");

.short-info {
  background-color: var(--app-components-background-color);
  color: #ffffff;
  min-width: 30%;
  width: max-content;
  max-width: 100%;
  font-family: var(--main-font);
}

.name {
  font-size: 23px;
  padding: 10.5px 10.5px 10px 0px;
  height: 39px;
  width: 100%;
  text-align: left;
  box-sizing: content-box;
}

.grid-container {
  display: grid;
  height: 96px;
  grid-template-columns: repeat(auto-fit, minmax(50px, 190px));
  grid-template-rows: 25% 25% 25% 25%;
  grid-auto-flow: column;
  grid-gap: 0 8px;
  padding-right: 4px;
}

.grid-item {
  width: 100%;
  text-align: left;
  font-family: "DM Mono", monospace !important;
  white-space: nowrap;
}

@media only screen and (max-width: 850px) {
  .short-info {
    width: 100%;
    padding: 15px 0 15px 30px;
  }
  .grid-container {
    grid-template-columns: 50% 50%;
  }
  .name {
    font-size: 19px;
  }
}
</style>
