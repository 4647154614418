<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
//import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "SectorPerformanceHeatmapChart",
  props: {
    data: Object,
    title: String,
    min: Number,
    max: Number
    // feature: String,
    // period: String,
    // minmax: Array
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "13%",
          bottom: "7%",
          left: "2%",
          right: "4%",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 23
          }
        },
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(162, 160, 160, 1)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          },
          formatter: params => {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            return (
              this.data.y[params.value[1]] +
              "<br/>" +
              colorSpan(params.color) +
              params.name +
              ":" +
              "&nbsp;" +
              "&nbsp;" +
              params.data[2] +
              "%"
            );
          }
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: "none"
            },
            restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "#2effc4",
              borderColor: "#2effc4"
            }
          }
        },
        xAxis: {
          type: "category",
          name: "Horizon",
          data: this.data.x,
          nameLocation: "middle",
          nameGap: 20,
          nameTextStyle: {
            fontSize: 16
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "category",
            name: "%",
            data: this.data.y,
            show: true,
            nameLocation: "end",
            nameGap: 5,
            nameTextStyle: {
              fontSize: 16
            },
            axisLabel: {
              show: true
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        visualMap: {
          type: "piecewise",
          show: false,
          pieces: this.formatPieces(),
          // pieces: [
          //   // Range of a piece can be specified by property min and max,
          //   // where min will be set as -Infinity if ignored,
          //   // and max will be set as Infinity if ignored.

          //   // { max: -10, color: "#be1841" },
          //   // { min: -10, max: -5, color: "#c8224a" },
          //   // { min: -5, max: -2, color: "#d22b53" },
          //   // { min: -2, max: -0.5, color: "#dc345c" },
          //   // { min: -0.5, max: 0, color: "#e63c65" },
          //   // { min: 0, max: 0.5, color: "#1cffd9" },
          //   // { min: 0.5, max: 2, color: "#00f7cd" },
          //   // { min: 2, max: 5, color: "#00eeb9" },
          //   // { min: 5, max: 10, color: "#00e6a3" },
          //   // { min: 10, color: "#00dd8d" }
          //   { max: 0, color: "#e12464" },
          //   { min: 0, color: "#00eba8" }
          // ],
          calculable: false,
          orient: "horizontal",
          left: "center",
          bottom: "15%",
          textStyle: {
            color: "rgba(255, 255, 255, 1)"
          }
        },
        series: [
          {
            data: this.data["returns_data"],
            type: "heatmap",
            name: "",
            label: {
              color: "rgba(50, 50, 50, 1)",
              show: true,
              fontSize: 12
            },
            itemStyle: {
              borderColor: "rgba(29, 29, 29, 1)",
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowColor: "rgba(0, 0, 0, 0.05)"
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        this.options["series"][0]["date"] = this.data[
          "returns_data"
        ].map(element => [element[0], element[1], element[2].toFixed(1)]);
        this.options["series"][0]["label"]["fontSize"] = 10;
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      if (window.innerWidth < 600) {
        this.options["series"][0]["date"] = this.data[
          "returns_data"
        ].map(element => [element[0], element[1], element[2].toFixed(1)]);
        this.options["series"][0]["label"]["fontSize"] = 10;
        this.options["toolbox"]["show"] = false;
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][0]["axisLabel"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    },
    formatPieces: function() {
      var x = this.data["returns_data"].map(element => element[2]);
      var max = Math.max(...x);
      var min = Math.min(...x);

      max = Math.min(max, this.max)
      min = Math.max(min, this.min)

      var range = 30;

      var maxRange = max / (range / 2);
      var minRange = min / (range / 2);

      console.log(max, maxRange)

      //var upColor = ["#1cffd9", "#00f7cd", "#00eeb9", "#00e6a3", "#00dd8d"];
      //var downColor = ["#e63c65", "#dc345c", "#d22b53", "#c8224a", "#be1841"];
      // var colors = ["#887ad3",
      //   "#7f80db",
      //   "#7586e2",
      //   "#698ce8",
      //   "#5a92ee",
      //   "#4998f3",
      //   "#319ef7",
      //   "#00a4fb",
      //   "#00aafd",
      //   "#00afff",
      //   "#00b4ff",
      //   "#00baff",
      //   "#00bfff",
      //   "#00c4ff",
      //   "#00c8fe",
      //   "#00cdfc",
      //   "#00d1f9",
      //   "#00d6f5",
      //   "#00daf1",
      //   "#00deed",
      //   "#00e2e8",
      //   "#00e6e2",
      //   "#00eadc",
      //   "#00edd6",
      //   "#00f1d0",
      //   "#14f4ca",
      //   "#40f7c4",
      //   "#59fabd",
      //   "#6efcb7",
      //   "#80ffb1"
      //       ]

      var colors = [
        "#887ad3",
        "#8082da",
        "#778adf",
        "#6f91e4",
        "#6698e8",
        "#5f9feb",
        "#58a6ed",
        "#52acee",
        "#4fb2ef",
        "#4eb8ef",
        "#4fbeef",
        "#53c3ee",
        "#59c9ed",
        "#60ceec",
        "#69d3eb",
        "#55fffa",
        "#54fff6",
        "#53fff2",
        "#53ffed",
        "#55ffe9",
        "#57ffe4",
        "#59ffdf",
        "#5dffd9",
        "#61ffd4",
        "#65ffce",
        "#6affc9",
        "#6fffc3",
        "#75ffbd",
        "#7affb7",
        "#80ffb1"
      ]

      var upColor = colors.slice(range / 2, range)
      var downColor = colors.slice(0, range / 2).reverse();

      var pieces = [];
      for (const i of [...Array(range/2-1).keys()]) {
        pieces.push({
          min: i * maxRange,
          max: (i + 1) * maxRange,
          color: upColor[i]
        });
        if (min < 0) {
          pieces.push({
            max: i * minRange,
            min: (i + 1) * minRange,
            color: downColor[i]
          });
        }
      }

        pieces.push({
          min: (range/2-1) * maxRange,
          color: upColor[range/2-1]
        })
        pieces.push({
          max: (range/2-1) * minRange,
          color: downColor[range/2-1]
        });
      console.log(pieces);
      return pieces;
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
    window.addEventListener("resize", () => {
      if (window.innerWidth < 600) {
        this.setChart();
      }
    });
  }
};
</script>

<style scoped>
.chart {
  height: 600px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
