<template>
  <div>
    <div class="price-chart">
      <v-chart class="chart" autoresize ref="priceChart" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
//import { mapGetters } from "vuex";

export default {
  name: "OptionsFlowLargestScatterChart",
  data() {
    return {};
  },
  computed: {},
  props: {
    largestFlow: Array,
    title: String
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "25%",
          bottom: "5%",
          left: "3%",
          right: "3%",
          containLabel: true
          //bottom: "3%"
        },

        toolbox: {
          show: true,
          feature: {
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.title,
          left: "30",
          top: "4%",
          textStyle: {
            fontFamily: "Chivo-Regular",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          formatter: function(params) {
            //console.log(params);
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            var tooltip = "";
            function concat_(param) {
              tooltip =
                tooltip +
                colorSpan(param.color.colorStops[0].color) +
                param.data[0] +
                "&nbsp;&nbsp;$" +
                (param.data[1] / 1000000).toFixed(2) +
                "mm" +
                "&nbsp;&nbsp;Strike:&nbsp;$" +
                param.data[3] +
                "&nbsp;&nbsp;Expiration:&nbsp;" +
                param.data[4] +
                "&nbsp;&nbsp;Sentiment:&nbsp;" +
                param.data[7] +
                // "&nbsp;&nbsp;Price:&nbsp;$" +
                // param.data[5] +
                // "&nbsp;&nbsp;Size:&nbsp;" +
                // param.data[6] +
                "&nbsp;&nbsp;" +
                param.data[2] +
                "<br/>";
            }
            params.forEach(param => {
              concat_(param);
            });

            //console.log(tooltip);
            return tooltip;
          },
          axisPointer: {
            animation: false,
            type: "shadow",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },

            // lineStyle: {
            //   color: '#376df4',
            //   width: 2,
            //   opacity: 1
            // }
            //}
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },

          confine: true,
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        // axisPointer: {
        //   link: { yAxisIndex: [0, 1] }
        // },
        yAxis: {
          name: "",
          type: "value",
          show: true,
          nameTextStyle: {
            fontSize: 14
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              show: true
            }
          }
        ],
        series: [
          {
            symbolSize: function(item) {
              return item[8];
            },
            data: this.largestFlow,
            type: "scatter",
            itemStyle: {
              color: function(item) {
                if (item.data[2] == "CALL") {
                  return new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 1,
                      color: "rgba(5, 218, 255, 0.6)" //"rgba(221, 40, 237, 0.3)"
                    },
                    {
                      offset: 0,
                      color: "rgba(10, 252, 180, 0.6)"
                    }
                  ]);
                } else {
                  return new graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 1,
                      color: "rgba(136, 77, 245, 0.5)"
                    },
                    {
                      offset: 0,
                      color: "rgba(217, 79, 255, 0.5)"
                    }
                  ]);
                }
              },
              borderColor: "rgba(255, 255, 255, 0.5)",
              borderWidth: 0.5
            }
          }
        ]
      };

      if (window.innerWidth < 850) {
        //this.options["yAxis"][0]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["toolbox"]["show"] = false;
        this.options["title"]["textStyle"]["fontSize"] = 17;
        // this.options["grid"]["containLabel"] = false;
        // this.options["grid"]["left"] = "13%";
        // this.options["grid"]["right"] = "6%";
        this.options["toolbox"]["show"] = false;
        this.options["tooltip"]["formatter"] = function(params) {
          //console.log(params);
          var colorSpan = color =>
            '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
            color +
            '"></span>';
          var tooltip = "";
          function concat_(param) {
            tooltip =
              tooltip +
              colorSpan(param.color.colorStops[0].color) +
              param.data[0] +
              "<br/>" +
              "&nbsp;&nbsp;$" +
              (param.data[1] / 1000000).toFixed(2) +
              "mm" +
              "<br/>" +
              "&nbsp;&nbsp;Strike:&nbsp;$" +
              param.data[3] +
              "<br/>" +
              "&nbsp;&nbsp;Expiration:&nbsp;" +
              param.data[4] +
              "<br/>" +
              "&nbsp;&nbsp;Sentiment:&nbsp;" +
              param.data[7] +
              // "&nbsp;&nbsp;Price:&nbsp;$" +
              // param.data[5] +
              // "<br/>" +
              // "&nbsp;&nbsp;Size:&nbsp;" +
              // param.data[6] +
              "<br/>" +
              "&nbsp;&nbsp;" +
              param.data[2] +
              "<br/>";
          }
          params.forEach(param => {
            concat_(param);
          });

          //console.log(tooltip);
          return tooltip;
        };
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    largestFlow: function() {
      console.log(this.largestFlow);
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
  width: 100%;
}

.price-chart {
  width: 100%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
