<template>
  <div>
    <RegistrationTemplate
      class="registration"
      :class="{ visible: showUpdatePassword }"
    >
      <template v-slot:function>
        <div class="title">Update Password</div>
      </template>
      <template v-slot:user>
        <b-field>
          <b-input
            placeholder="Enter Password (max 20)"
            size="is-small"
            minlength="8"
            maxlength="20"
            pattern="[\S]*"
            icon="lock"
            class="form-input"
            v-model="password"
          >
          </b-input>
        </b-field>
        <b-field>
          <b-input
            placeholder="Confirm Password"
            size="is-small"
            minlength="8"
            maxlength="20"
            pattern="[\S]*"
            icon="lock"
            class="form-input"
            v-model="password_confirmation"
          >
          </b-input>
        </b-field>
        <button class="registration-button" @click="updatePassword">
          Update
        </button>
      </template>
    </RegistrationTemplate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import RegistrationTemplate from "@/components/RegistrationTemplate.vue";
export default {
  name: "UpdatePassword",
  data: () => ({
    password: "",
    password_confirmation: ""
  }),
  components: {
    RegistrationTemplate
  },
  computed: {
    ...mapGetters({
      showUpdatePassword: "getShowUpdatePassword"
    })
  },
  methods: {
    updatePassword: function() {
      if (this.password.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter a password");
      } else if (this.password_confirmation.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please confirm your password");
      } else if (this.password != this.password_confirmation) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Passwords do not match");
      }
      this.$store.dispatch({
        type: "updatePassword",
        password: this.password,
        password_confirmation: this.password_confirmation
      });
    }
  },
  mounted() {
    eventBus.$on("closingAlert", msg => {
      if (msg == "Your password was successfully updated.") {
        this.$store.commit("setShowUpdatePassword", false);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.registration {
  visibility: hidden;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0s, visibility 250ms;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 20000;
}

.visible {
  visibility: visible;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 250ms ease-out 0s, visibility 250ms;
  transform: translateY(0px);
  opacity: 1;
}
</style>
