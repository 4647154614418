<template>
  <div class="options-flow-dashboard">
    <h1 class="page-title">Options flow / Dashboard</h1>
    <div
      v-if="Object.keys(indexMetrics).length > 0"
      class="index-metrics-container"
    >
      <div class="index-metrics" v-for="index in indices" v-bind:key="index">
        <div class="header">
          <div class="flex-container">
            <div class="name">{{ index }}</div>
            <div class="price" v-if="Object.keys(indexQuotes).length > 0">
              <div>${{ indexQuotes[index].latestPrice }}</div>
              <div v-bind:class="checkReturns(indexQuotes[index].change)">
                {{ indexQuotes[index].change }}
              </div>
              <div
                v-bind:class="checkReturns(indexQuotes[index].changePercent)"
              >
                {{ (indexQuotes[index].changePercent * 100) | formatReturns }}%
              </div>
            </div>
          </div>
        </div>
        <div class="metric">
          <span class="metric-name">Call Premiums</span>

          <div class="flex-container">
            ${{ indexMetrics[index].current.call_premiums | formatThousands }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            ${{ indexMetrics[index].prior.call_premiums | formatThousands }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="
              changeClass(indexMetrics[index].prior.call_premiums_chg, 'call')
            "
            ><i
              :class="iconClass(indexMetrics[index].prior.call_premiums_chg)"
            ></i>
            {{ indexMetrics[index].prior.call_premiums_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
        <div class="metric">
          <span class="metric-name">Put Premiums</span>

          <div class="flex-container">
            ${{ indexMetrics[index].current.put_premiums | formatThousands }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            ${{ indexMetrics[index].prior.put_premiums | formatThousands }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="
              changeClass(indexMetrics[index].prior.put_premiums_chg, 'put')
            "
            ><i
              :class="iconClass(indexMetrics[index].prior.put_premiums_chg)"
            ></i>
            {{ indexMetrics[index].prior.put_premiums_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
        <div class="metric">
          <span class="metric-name">Call Flows</span>

          <div class="flex-container">
            {{ indexMetrics[index].current.call_flow | formatThousands }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            {{ indexMetrics[index].prior.call_flow | formatThousands }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="
              changeClass(indexMetrics[index].prior.call_flow_chg, 'call')
            "
            ><i :class="iconClass(indexMetrics[index].prior.call_flow_chg)"></i>
            {{ indexMetrics[index].prior.call_flow_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
        <div class="metric">
          <span class="metric-name">Put Flows</span>

          <div class="flex-container">
            {{ indexMetrics[index].current.put_flow | formatThousands }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            {{ indexMetrics[index].prior.put_flow | formatThousands }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="changeClass(indexMetrics[index].prior.put_flow_chg, 'put')"
            ><i :class="iconClass(indexMetrics[index].prior.put_flow_chg)"></i>
            {{ indexMetrics[index].prior.put_flow_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
        <div class="metric">
          <span class="metric-name">Avg Call Score</span>

          <div class="flex-container">
            {{ indexMetrics[index].current.avg_call_score | formatDecimal3 }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            {{ indexMetrics[index].prior.avg_call_score | formatDecimal3 }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="
              changeClass(indexMetrics[index].prior.avg_call_score_chg, 'call')
            "
            ><i
              :class="iconClass(indexMetrics[index].prior.avg_call_score_chg)"
            ></i>
            {{ indexMetrics[index].prior.avg_call_score_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
        <div class="metric">
          <span class="metric-name">Avg Put Score</span>

          <div class="flex-container">
            {{ indexMetrics[index].current.avg_put_score | formatDecimal3 }}
            <span class="metric-period"> Today</span>
          </div>
          <div class="flex-container"></div>
          <div class="flex-container">
            {{ indexMetrics[index].prior.avg_put_score | formatDecimal3 }}
            <span class="metric-period"> Prior</span>
          </div>
          <span
            :class="
              changeClass(indexMetrics[index].prior.avg_put_score_chg, 'put')
            "
            ><i
              :class="iconClass(indexMetrics[index].prior.avg_put_score_chg)"
            ></i>
            {{ indexMetrics[index].prior.avg_put_score_chg }}%</span
          ><span class="metric-period">Prior 1d change</span>
        </div>
      </div>
    </div>
    <div class="largest-flow-container">
      <OptionsFlowLargestScatterChart
        v-bind:largestFlow="largestFlow['current']"
        v-bind:title="'Daily Largest Flows'"
      ></OptionsFlowLargestScatterChart>
      <OptionsFlowLargestScatterChart
        v-bind:largestFlow="largestFlow['weekly']"
        v-bind:title="'Weekly Largest Flows'"
      ></OptionsFlowLargestScatterChart>
    </div>
    <div class="sector-inflow-container">
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['current']"
          v-bind:title="'Daily Calls Sector Inflow'"
          v-bind:putCall="'calls'"
        ></OptionsFlowSectorInflowChart>
      </div>
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['current']"
          v-bind:title="'Daily Puts Sector Inflow'"
          v-bind:putCall="'puts'"
        ></OptionsFlowSectorInflowChart>
      </div>
    </div>
    <div class="sector-inflow-container">
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['weekly']"
          v-bind:title="'Weekly Calls Sector Inflow'"
          v-bind:putCall="'calls'"
        ></OptionsFlowSectorInflowChart>
      </div>
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['weekly']"
          v-bind:title="'Weekly Puts Sector Inflow'"
          v-bind:putCall="'puts'"
        ></OptionsFlowSectorInflowChart>
      </div>
    </div>
    <div class="sector-inflow-container">
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['current']"
          v-bind:title="'Daily Net Sector Inflow'"
          v-bind:putCall="'net'"
        ></OptionsFlowSectorInflowChart>
      </div>
      <div class="sector-inflow-sub-container">
        <OptionsFlowSectorInflowChart
          v-bind:sectorInflow="sectorInflow['weekly']"
          v-bind:title="'Weekly Net Sector Inflow'"
          v-bind:putCall="'net'"
        ></OptionsFlowSectorInflowChart>
      </div>
    </div>
    <div class="table-container">
      <div class="title-tag">Most OTM Flows</div>
      <OptionsFlowTableComponent
        :data="mostOtm.most_otm_data"
        :initalSortOrder="'otm'"
        :columns="[
          { label: 'Date', field: 'date', breakpoint: 3 },
          { label: 'Time', field: 'time', breakpoint: 1 },
          { label: 'Ticker', field: 'ticker', class: 'ticker' },
          { label: 'C/P', field: 'put_call' },
          { label: 'Strike', field: 'strike_price', prepend: '$' },
          { label: 'DTE', field: 'DTE', breakpoint: 1 },
          { label: 'Fill', field: 'fill', breakpoint: 1 },
          { label: 'Sentiment', field: 'sentiment'},
          // { label: 'Price', field: 'price', breakpoint: 1 },
          // { label: 'Size', field: 'size', breakpoint: 2 },
          { label: 'Type', field: 'option_activity_type', breakpoint: 2 },
          { label: 'OI', field: 'open_interest', breakpoint: 2 },
          { label: 'Volume', field: 'volume', breakpoint: 3 },
          { label: 'Score', field: 'score', breakpoint: 2 },
          {
            label: 'Premium',
            field: 'cost_basis',
            format: 'thousands',
            prepend: '$',
            append: 'K'
          },
          {
            label: 'OTM By',
            field: 'otm',
            format: 'decimal_0',
            append: '%'
          }
        ]"
      ></OptionsFlowTableComponent>
    </div>
    <div class="table-container">
      <div class="title-tag">High OI LEAPS</div>
      <OptionsFlowTableComponent
        :data="highOILeaps.high_oi_leaps_data"
        :initalSortOrder="'open_interest'"
        :columns="[
          { label: 'Date', field: 'date', breakpoint: 3 },
          { label: 'Time', field: 'time', breakpoint: 1 },
          { label: 'Ticker', field: 'ticker', class: 'ticker' },
          { label: 'C/P', field: 'put_call' },
          { label: 'Strike', field: 'strike_price', prepend: '$' },
          { label: 'DTE', field: 'DTE', breakpoint: 1 },
          { label: 'Fill', field: 'fill', breakpoint: 1 },
          { label: 'Sentiment', field: 'sentiment'},
          // { label: 'Price', field: 'price', breakpoint: 1 },
          // { label: 'Size', field: 'size', breakpoint: 2 },
          { label: 'Type', field: 'option_activity_type', breakpoint: 2 },
          { label: 'OI', field: 'open_interest' },
          { label: 'Volume', field: 'volume', breakpoint: 3 },
          { label: 'Score', field: 'score', breakpoint: 2 },
          {
            label: 'Premium',
            field: 'cost_basis',
            format: 'thousands',
            prepend: '$',
            append: 'K'
          }
        ]"
      ></OptionsFlowTableComponent>
    </div>
    <div class="charts-avg-container">
      <OptionsFlowMaxChangeChart
        :data="dailyOverAvgPremiums['calls']"
        :putCall="'calls'"
        :feature="'premiums'"
      ></OptionsFlowMaxChangeChart>
      <OptionsFlowMaxChangeChart
        :data="dailyOverAvgPremiums['puts']"
        :putCall="'puts'"
        :feature="'premiums'"
      ></OptionsFlowMaxChangeChart>
    </div>
    <div class="charts-avg-container">
      <OptionsFlowMaxChangeChart
        :data="dailyOverAvgSize['calls']"
        :putCall="'calls'"
        :feature="'volume'"
      ></OptionsFlowMaxChangeChart>
      <OptionsFlowMaxChangeChart
        :data="dailyOverAvgSize['puts']"
        :putCall="'puts'"
        :feature="'volume'"
      ></OptionsFlowMaxChangeChart>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      The top grid contains metrics for the current day, the prior trading day
      (penultimate), and the prior trading day 1-day change (change over the
      antepenultimate period). The 1-day change is not calculated using today's
      metrics, since it would compare a partial trading day to a full one.
      <br /><br />
      The bottom charts comparing the premiums and volume to their average using
      a 20-day moving average. The current data is the data for the last trading
      day. The tickers are sorted in descending order, with the top one
      representing the one with the largest change over the average value.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import OptionsFlowLargestScatterChart from "@/components/OptionsFlowLargestScatterChart.vue";
import OptionsFlowSectorInflowChart from "@/components/OptionsFlowSectorInflowChart.vue";
import OptionsFlowTableComponent from "@/components/OptionsFlowTableComponent.vue";
import OptionsFlowMaxChangeChart from "@/components/OptionsFlowMaxChangeChart.vue";
export default {
  name: "OptionsFlowDashboard",
  data() {
    return {
      indices: ["SPY", "QQQ", "IWM"]
    };
  },
  components: {
    OptionsFlowLargestScatterChart,
    OptionsFlowSectorInflowChart,
    OptionsFlowTableComponent,
    OptionsFlowMaxChangeChart
    //OptionsFlowTableComponent
  },
  head: {
    title: {
      inner: "Options Flows Dashboard"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Get an overview of the options flow activity for a specific ticker."
      }
    ]
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      loginEvent: "getLoginEvent",
      alertMessage: "getAlertMessage",
      user: "getUserInfo",
      ticker: "getTicker",
      indexMetrics: "getOptionsFlowIndexMetricsData",
      indexQuotes: "getOptionsFlowIndexQuotesData",
      largestFlow: "getOptionsFlowLargestFlowData",
      sectorInflow: "getOptionsFlowSectorInflowData",
      mostOtm: "getOptionsFlowMostOtmData",
      highOILeaps: "getOptionsFlowHighOILeapsData",
      dailyOverAvgPremiums: "getOptionsFlowDailyOverAvgPremiumsData",
      dailyOverAvgSize: "getOptionsFlowDailyOverAvgSizeData"
    })
  },
  methods: {
    init: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getOptionsFlowIndexMetricsData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowIndexQuotesData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowLargestFlowData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowSectorInflowData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowMostOtmData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowHighOILeapsData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowDailyOverAvgPremiumsData"
      });
      this.$store.dispatch({
        type: "getOptionsFlowDailyOverAvgSizeData"
      });
    },
    iconClass: function(item) {
      if (item > 0) {
        return "mdi mdi-arrow-up";
      } else if (item < 0) {
        return "mdi mdi-arrow-down";
      }
    },
    changeClass: function(item, putCall) {
      if (item > 0 && putCall == "call") {
        return "metric-change green";
      } else if (item < 0 && putCall == "call") {
        return "metric-change red";
      } else if (item > 0 && putCall == "put") {
        return "metric-change red";
      } else if (item < 0 && putCall == "put") {
        return "metric-change green";
      }
    },
    checkReturns: function(item) {
      if (item > 0) return "green";
      else if (item < 0) return "red";
      else return "neutral";
    }
  },
  watch: {
    user: function() {
      console.log("loginEvent", this.loginEvent, this.user);
      if (this.loginEvent == true) {
        if (this.user.subscription == "basic") {
          this.init();
        }
      }
    },
    sectorInflow: function() {
      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    this.init();

    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("closingAlert", msg => {
      if (msg == "You need to subscribe to access this page.") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "More info") {
        setTimeout(() => {
          this.$router.push({
            path: "/subscriptioncards"
          });
        }, 30);
      }
    });
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.options-flow-dashboard {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.page-title,
.charts-avg-container,
.largest-flow-container,
.sector-inflow-container,
.text-content,
.table-container,
.index-metrics-container {
  width: 80%;
  margin: 0 auto;
}

.text-content {
  margin-top: 8px;
}

.table-container {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  overflow: hidden;
}

.options-flow-table-container {
  margin-top: 10px;
}

.largest-flow-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.index-metrics-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: row;
  grid-gap: 8px;
  margin-top: 20px;
}

.index-metrics {
  display: grid;
  grid-template-rows: 0.5fr 1fr 1fr 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "header header"
    "metric metric"
    "metric metric"
    "metric metric";
  padding-bottom: 8px;
  grid-gap: 8px;
  //background-color: var(--app-components-background-color);
  //grid-auto-flow: row;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  grid-area: header;
  color: #fff;
  display: flex;
  align-content: center;
  align-self: strech;
  justify-content: center;
  font-family: Nunito;
  background-color: var(--app-components-background-color);
  //padding-top: 30px;
  & .name {
    font-size: 22px;
  }
}

.metric {
  color: #fff;
  font-family: Nunito;
  background-color: var(--app-components-background-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
}

.metric-name {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 2px;
}

.metric-change {
  font-size: 13px;
  margin-left: -13px;
}

.metric-period {
  font-size: 10px;
  margin-left: 5px;
}

.sector-inflow-container {
  margin-top: 8px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.sector-inflow-sub-container {
  width: calc(50% - 4px);
  min-width: 375px;
}

.price {
  font-family: "DM Mono", monospace !important;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: 8px;
}

.green {
  color: rgb(0, 255, 234);
}

.red {
  color: rgb(252, 41, 111);
}

.neutral {
  color: var(--neutral-color);
}

.charts-avg-container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1250px) {
  .page-title,
  .largest-flow-container,
  .charts-avg-container,
  .sector-inflow-container,
  .text-content,
  .table-container,
  .index-metrics-container {
    width: 80%;
  }

  .index-metrics-container {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
  }
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .largest-flow-container,
  .charts-avg-container,
  .sector-inflow-container,
  .text-content,
  .table-container,
  .index-metrics-container {
    width: 95%;
  }

  .sector-inflow-container {
    flex-wrap: wrap;
  }

  .sector-inflow-sub-container {
    width: 100%;
    min-width: 350px;
  }
}

@media only screen and (max-width: 850px) {
  .charts-avg-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 450px) {
  .index-metrics {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "metric"
      "metric"
      "metric"
      "metric"
      "metric"
      "metric";
    padding-bottom: 20px;
    grid-gap: 8px;
    //background-color: var(--app-components-background-color);
    //grid-auto-flow: row;
  }
  .header {
    padding: 20px 0;
  }
}
</style>
