import { render, staticRenderFns } from "./SectorPerformanceBarChart.vue?vue&type=template&id=2cff5e77&scoped=true&"
import script from "./SectorPerformanceBarChart.vue?vue&type=script&lang=js&"
export * from "./SectorPerformanceBarChart.vue?vue&type=script&lang=js&"
import style0 from "./SectorPerformanceBarChart.vue?vue&type=style&index=0&id=2cff5e77&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cff5e77",
  null
  
)

export default component.exports