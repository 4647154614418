<template>
  <div class="options-flow-filters-container">
    <div class="mode-container">
      <div class="mode-sub-container">
        <button
          class="mode-button active"
          ref="live"
          @click="toggleMode('live')"
        >
          Live
        </button>
        <button
          class="mode-button"
          ref="historical"
          v-show="showHistorical"
          @click="toggleMode('historical')"
        >
          Historical
        </button>
      </div>
      <div class="mode-sub-container">
        <button
          v-show="this.mode == 'historical' ? true : false"
          class="mode-button update"
          @click="updateHistorical"
        >
          Update
        </button>
        <b-tooltip
          v-show="this.mode == 'historical' ? true : false"
          class="historical-info"
          type="is-dark"
          position="is-right"
          multilined
          style="z-index:10000000"
        >
          <template v-slot:content>
            Maximum of 10k rows. Use the filters to screen your results.
            <br />When using historical mode, the Update button needs to be
            clicked, otherwise the filters will only screen the data currenly
            loaded in your browser.
          </template>
          ?
        </b-tooltip>
        <VueDatePicker
          v-show="this.mode == 'historical' ? true : false"
          v-model="dates"
          placeholder="Start - End"
          format="YYYY-MM-DD"
          :min-date="min_date"
          range
          range-header-text="%d to %d"
          range-input-text="%d to %d"
          validate
          :disabled="mode == 'live' ? true : false"
          :color="dateColor"
          :visible-years-number="2"
        />
      </div>
    </div>
    <div class="filters-container">
      <div class="filters-add">
        <div class="tag" @click="addFilter">Filters</div>
        <!-- <div class="plus-minus first" @click="addFilter">+</div> -->
      </div>

      <div class="filters-options-container">
        <div
          class="filters-options"
          v-for="(filter, index) in filters"
          v-bind:key="filter.id"
          v-on:remove="filters.splice(index, 1)"
        >
          <SelectComponent
            :height="'38px'"
            :default="{ label: 'Field', value: '' }"
            v-model="filter.filter"
            class="select-component"
            :options="filtersOptions"
            @input="filterData"
          >
          </SelectComponent>
          <SelectComponent
            :height="'38px'"
            :default="{ label: 'Selector', value: '' }"
            v-model="filter.selector"
            class="select-component"
            :options="selectorsOptions"
            @input="filterData"
          >
          </SelectComponent>
          <b-input
            v-if="filter.filter != 2"
            v-model="filter.value"
            placeholder="Enter Value"
            @input="filterData"
          ></b-input>
          <b-taginput
            v-if="filter.filter == 2"
            v-model="filter.tickers"
            ellipsis
            icon="label"
            placeholder="Enter ticker(s)"
            aria-close-label="Delete this tag"
            @input="filterData"
          >
          </b-taginput>
          <div class="plus-minus" @click="removeFilter(filter.id)">x</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "echarts";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import { mapGetters } from "vuex";
const SelectComponent = () => import("@/components/SelectComponent.vue");

export default {
  name: "OptionsFlowFilters",
  data() {
    return {
      dateColor: "rgba(160, 80, 245, 0.8)",
      dates: "",
      min_date: "2019-01-01",
      max_date: String(new Date()),
      color: "rgb(177, 115, 247)",
      filteredOptionsFlow: [],
      filters: [],
      filtersOptions: [
        { label: "Time", value: 1 },
        { label: "Ticker", value: 2 },
        { label: "Call/Put", value: 3 },
        { label: "Strike", value: 4 },
        { label: "DTE", value: 5 },
        { label: "Fill", value: 6 },
        // { label: "Price", value: 7 },
        // { label: "Size", value: 8 },
        { label: "Type", value: 9 },
        { label: "Open Interest", value: 10 },
        { label: "Volume", value: 11 },
        { label: "Score (0 to 1)", value: 12 },
        { label: "Premium", value: 13 },
        { label: "Sentiment", value: 14 }
      ],
      selectorsOptions: [
        { label: "Equal", value: 1 },
        { label: "Greater than", value: 2 },
        { label: "Greater than equal", value: 3 },
        { label: "Smaller than", value: 4 },
        { label: "Smaller than equal", value: 5 }
      ],
      nextFilterId: 0,
      showHistorical: true
    };
  },
  components: {
    VueDatePicker,
    SelectComponent
  },
  computed: {
    ...mapGetters({
      mode: "getOptionsFlowMode",
      historicalOptionsFlow: "getHistoricalOptionsFlow"
    })
  },
  props: {
    optionsFlow: Array
  },
  methods: {
    addFilter: function() {
      this.filters.push({
        id: this.nextFilterId++,
        filter: "",
        selector: "",
        value: ""
      });
      console.log(this.filters);
    },
    removeFilter: function(id) {
      this.filters.forEach((element, index) => {
        if (element.id == id) {
          this.filters.splice(index, 1);
        }
      });
      this.filterData();
    },
    filterData: function() {
      if (this.mode == "live") {
        this.filteredOptionsFlow = [...this.optionsFlow];
      } else if (this.mode == "historical") {
        this.filteredOptionsFlow = [...this.historicalOptionsFlow];
      }

      this.filters.forEach(element => {
        console.log(element);
        if (element.filter != "" && element.selector != "") {
          let filter;
          switch (element.filter) {
            case 1:
              filter = "time";
              break;
            case 2:
              filter = "ticker";
              break;
            case 3:
              filter = "put_call";
              break;
            case 4:
              filter = "strike_price";
              break;
            case 5:
              filter = "DTE";
              break;
            case 6:
              filter = "fill";
              break;
            case 7:
              filter = "price";
              break;
            case 8:
              filter = "size";
              break;
            case 9:
              filter = "option_activity_type";
              break;
            case 10:
              filter = "open_interest";
              break;
            case 11:
              filter = "volume";
              break;
            case 12:
              filter = "score";
              break;
            case 13:
              filter = "cost_basis";
            case 14:
              filter = "sentiment";
          }
          if (element.filter != 2 && element.value != "") {
            if (element.selector == 1) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  if (typeof row[filter] == "string") {
                    return (
                      row[filter].toUpperCase() == element.value.toUpperCase()
                    );
                  } else {
                    return row[filter] == parseFloat(element.value);
                  }
                }
              );
            } else if (element.selector == 2) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  if (typeof row[filter] == "string") {
                    return (
                      row[filter].toUpperCase() > element.value.toUpperCase()
                    );
                  } else {
                    return row[filter] > parseFloat(element.value);
                  }
                }
              );
            } else if (element.selector == 3) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  if (typeof row[filter] == "string") {
                    return (
                      row[filter].toUpperCase() >= element.value.toUpperCase()
                    );
                  } else {
                    return row[filter] >= parseFloat(element.value);
                  }
                }
              );
            } else if (element.selector == 4) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  if (typeof row[filter] == "string") {
                    return (
                      row[filter].toUpperCase() < element.value.toUpperCase()
                    );
                  } else {
                    return row[filter] < parseFloat(element.value);
                  }
                }
              );
            } else if (element.selector == 5) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  if (typeof row[filter] == "string") {
                    return (
                      row[filter].toUpperCase() <= element.value.toUpperCase()
                    );
                  } else {
                    return row[filter] <= parseFloat(element.value);
                  }
                }
              );
            }
            this.filtered = true;
          } else if (element.filter == 2 && element.tickers.length != 0) {
            if (element.selector == 1) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  return element.tickers.some(
                    ticker => ticker.toUpperCase() == row[filter].toUpperCase()
                  );
                }
              );
            } else if (element.selector == 2) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  return element.tickers.some(
                    ticker => ticker.toUpperCase() < row[filter].toUpperCase()
                  );
                }
              );
            } else if (element.selector == 3) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  return element.tickers.some(
                    ticker => ticker.toUpperCase() <= row[filter].toUpperCase()
                  );
                }
              );
            } else if (element.selector == 4) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  return element.tickers.some(
                    ticker => ticker.toUpperCase() > row[filter].toUpperCase()
                  );
                }
              );
            } else if (element.selector == 5) {
              this.filteredOptionsFlow = this.filteredOptionsFlow.filter(
                function _filter(row) {
                  return element.tickers.some(
                    ticker => ticker.toUpperCase() >= row[filter].toUpperCase()
                  );
                }
              );
            }
          }
        }
      });
    },
    toggleMode: function(mode) {
      if (mode == this.mode) {
        return;
      } else if (mode == "live") {
        this.$store.commit("setOptionsFlowMode", "live");
        this.$store.dispatch({ type: "getCurrentOptionsFlow" });
        this.$store.commit("setLoading", true);
        this.date = "";
        this.$refs.live.classList.toggle("active");
        this.$refs.historical.classList.toggle("active");
        this.root = document.documentElement;
        this.root.style.setProperty("--input", "rgb(90, 90, 90)");
      } else if (mode == "historical") {
        this.$store.commit("setOptionsFlowMode", "historical");
        this.$refs.live.classList.toggle("active");
        this.$refs.historical.classList.toggle("active");
        this.root = document.documentElement;
        this.root.style.setProperty("--input", "rgb(255, 255, 255)");
        const svg = document.getElementsByTagName("svg");
        svg[0].children[0].attributes.fill.value = "rgb(255, 255, 255)";
      }
    },
    updateHistorical: function() {
      this.$store.commit("setLoading", true);
      if (this.mode == "historical") {
        this.$store.dispatch({
          type: "getHistoricalOptionsFlow",
          dates: JSON.stringify({
            start: this.dates["start"],
            end: this.dates["end"]
          }),
          filters: JSON.stringify(this.filters)
        });
      }
    }
  },
  watch: {
    optionsFlow: function() {
      if (this.mode == "live") {
        this.filteredOptionsFlow = [...this.optionsFlow];
        this.filterData();
      }
    },
    historicalOptionsFlow: function() {
      if (this.mode == "historical") {
        this.filteredOptionsFlow = [...this.historicalOptionsFlow];
        this.$store.commit("setFilteredOptionsFlow", this.filteredOptionsFlow);
      }
    },
    filteredOptionsFlow: function() {
      this.$store.commit("setFilteredOptionsFlow", this.filteredOptionsFlow);
    }
  },
  mounted() {
    const svg = document.getElementsByTagName("svg");
    svg[0].children[0].attributes.fill.value = "rgb(90, 90, 90)";
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1000) {
        this.showHistorical = false;
      } else {
        this.showHistorical = true;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.options-flow-filters-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  min-height: 46px;
  margin-bottom: 1px;
}

.filters-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  min-height: 46px;
}

.filters-add {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 4px;
  margin-top: 4px;
}

.filters-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 46px;
}

.filters-options {
  margin: 0;
}

.filters-options-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.filters-add .tag {
  background-color: var(--app-components-background-color);
  //color: var(--purple-darker);
  color: #fff;
  font-size: 15px;
  width: 100px;
  height: 38px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  &:hover {
    color: var(--purple-darker);
  }
}

.plus-minus {
  background-color: var(--app-components-background-color);
  border-radius: 100%;
  color: #fff;
  font-family: Nunito;
  min-width: 25px;
  height: 25px;
  margin: 0px 4px 0px -2px;
  &:hover {
    color: var(--purple-darker);
    cursor: pointer;
  }
  &.first {
    margin: 5px;
  }
}

.mode-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mode-sub-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.mode-button {
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 7px 0px 0;
  background-color: var(--app-components-background-color);
  padding: 8px 18px;
  font-family: var(--main-font);
  //color: var(--purple-darker);
  font-size: 15px;
  color: rgb(107, 107, 107);
  border: none;
  border-radius: 0px;
  //box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 100px;
  height: 38px;
  &.active {
    color: #fff;
  }
  &.update {
    color: #fff;
  }
  &:hover {
    color: var(--purple-darker);
  }
}

.mode-button:active {
  //color: var(--purple-darker2);
  //box-shadow: 0 -1px 0 var(--purple-darker2) inset;
  background-color: var(--app-components-background-color);
}

// .mode-button:hover {
//   background-color: var(--app-components-background-color-lighter);
// }

.mode-container {
  margin-bottom: 4px;
}

.historical-info {
  width: 20px;
  height: 100%;
  margin: 1px;
}

.select-component {
  width: 200px !important;
  margin-top: -2px;
}

.filters-options {
  margin-left: 3px;
  gap: 6px;
  // & :nth-child(2) {
  //   margin-left: 6px;
  // }
}

@media only screen and (max-width: 850px) {
  .filters-container {
    flex-wrap: wrap;
  }
  .filters-add .tag {
    margin-bottom: 4px;
  }

  .filters-options {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .filters-add .tag {
    margin-bottom: 0px;
  }

  .filters-options {
    flex-wrap: wrap;
    margin-bottom: 5px;
  }

  .options-flow-filters-container .control.is-clearfix input {
    margin: 0;
  }
}
</style>
