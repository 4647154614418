var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cluster-control"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Pattern Length ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"default":{ value: '10', label: '10' },"options":[
          { value: '5', label: '5' },
          { value: '10', label: '10' },
          { value: '20', label: '20' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.length),callback:function ($$v) {_vm.length=$$v},expression:"length"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Number of Clusters ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"default":{ value: '8', label: '8' },"options":[
          { value: '2', label: '2' },
          { value: '3', label: '3' },
          { value: '4', label: '4' },
          { value: '5', label: '5' },
          { value: '6', label: '6' },
          { value: '7', label: '7' },
          { value: '8', label: '8' },
          { value: '9', label: '9' },
          { value: '10', label: '10' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.clusters),callback:function ($$v) {_vm.clusters=$$v},expression:"clusters"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Metric ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"disabled":"","default":{ value: 'euclidean', label: 'Euclidean' },"options":[
          { value: 'euclidean', label: 'Euclidean' },
          { value: 'dtw', label: 'DTW' },
          { value: 'soft', label: 'SoftDWT' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.metric),callback:function ($$v) {_vm.metric=$$v},expression:"metric"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Iterations ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"default":{ value: '3', label: '10' },"options":[
          { value: '1', label: '2' },
          { value: '2', label: '5' },
          { value: '3', label: '10' },
          { value: '4', label: '20' },
          { value: '5', label: '30' },
          { value: '6', label: '40' },
          { value: '7', label: '50' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.iterations),callback:function ($$v) {_vm.iterations=$$v},expression:"iterations"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Features ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"default":{ value: 'pcvol', label: 'Returns and Volatility' },"options":[
          { value: 'pct', label: 'Returns' },
          { value: 'volatility', label: 'Volatility' },
          { value: 'pcvol', label: 'Returns and Volatility' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.features),callback:function ($$v) {_vm.features=$$v},expression:"features"}})],1),_c('div',{staticClass:"grid-item"},[_c('b-tag',{staticClass:"datatable-tag",attrs:{"type":"is-dark","size":"is-medium"}},[_vm._v(" Training Window ")])],1),_c('div',{staticClass:"grid-item"},[_c('SelectComponent',{staticClass:"select-component",attrs:{"default":{ value: '400', label: '400' },"options":[
          { value: '200', label: '200' },
          { value: '400', label: '400' },
          { value: '600', label: '600' }
        ],"backgroundColor":_vm.backgroundColor},model:{value:(_vm.training),callback:function ($$v) {_vm.training=$$v},expression:"training"}})],1)]),_c('hr'),_c('button',{staticClass:"datatable-button",on:{"click":function($event){return _vm.updateClustersData()}}},[_vm._v(" Update ")])])}
var staticRenderFns = []

export { render, staticRenderFns }