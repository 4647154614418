<template>
  <div>
    <RegistrationTemplate
      class="registration"
      :class="{ visible: showResetPassword }"
    >
      <template v-slot:function>
        <div class="title">Reset Password</div>
      </template>
      <template v-slot:user>
        <b-field>
          <b-input
            placeholder="Enter Email"
            size="is-small"
            type="email"
            icon="email"
            class="form-input"
            v-model="email"
          >
          </b-input>
        </b-field>
        <button class="registration-button" @click="resetPassword">
          Reset
        </button>
      </template>
    </RegistrationTemplate>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import RegistrationTemplate from "@/components/RegistrationTemplate.vue";
export default {
  name: "ResetPassword",
  data: () => ({
    email: ""
  }),
  components: {
    RegistrationTemplate
  },
  computed: {
    ...mapGetters({
      showResetPassword: "getShowResetPassword"
    })
  },
  methods: {
    resetPassword: function() {
      if (this.email.length < 8) {
        this.$store.commit("setAlertShow", true);
        this.$store.commit("setAlertMessage", "Please enter an email");
      }
      this.$store.dispatch({
        type: "resetPassword",
        email: this.email
      });
    }
  },
  mounted() {
    eventBus.$on("closingAlert", msg => {
      if (msg == "We have sent you an email to reset your password.") {
        this.$store.commit("setShowResetPassword", false);
        this.$store.commit("setShowLogin", false);
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.registration {
  visibility: hidden;
  transition: transform 0ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 0ms ease-out 0ms, visibility 0ms;
  transform: translateY(-40px);
  opacity: 0;
  z-index: 20000;
}

.visible {
  visibility: visible;
  transition: transform 0ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
    opacity 0ms ease-out 0ms, visibility 0ms;
  transform: translateY(0px);
  opacity: 1;
}
</style>
