<template>
  <div class="landing">
    <div
      class="page-title"
      data-aos="fade-down"
      data-aos-delay="0"
      data-aos-duration="1500"
    >
      Real-time option flow data
    </div>
    <div
      class="page-intro"
      data-aos="fade-down"
      data-aos-delay="0"
      data-aos-duration="1500"
    >
      Option flow uncovers what large investors and institutions are doing.
      Instead of fighting the tape, you can now take the path of least
      resistance by simply following large transactions.
    </div>
    <div class="header">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="500px"
        height="250px"
        viewBox="0 0 500 250"
        enable-background="new 0 0 500 250"
        xml:space="preserve"
      >
        <g id="bg">
          <rect
            y="-0.432"
            fill="#FFFFFF"
            stroke="#000000"
            stroke-width="3"
            stroke-miterlimit="10"
            width="500"
            height="250.201"
          />
        </g>
        <g id="lines_1_">
          <line
            fill="none"
            stroke="#000000"
            stroke-width="3"
            stroke-miterlimit="10"
            x1="-1.952"
            y1="250"
            x2="248.048"
            y2="0"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="3"
            stroke-miterlimit="10"
            x1="500"
            y1="0"
            x2="249.98"
            y2="250.021"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="3"
            stroke-miterlimit="10"
            x1="250"
            y1="125"
            x2="375.432"
            y2="-0.432"
          />
          <line
            fill="none"
            stroke="#000000"
            stroke-width="3"
            stroke-miterlimit="10"
            x1="250"
            y1="125"
            x2="125.231"
            y2="249.769"
          />
        </g>
        <g id="title"></g>
      </svg>
      <span
        class="header-span"
        data-aos="fade-in"
        data-aos-delay="950"
        data-aos-duration="2000"
        >FOLLOW</span
      >
      <span
        class="header-gradient"
        data-aos="fade-in"
        data-aos-delay="1150"
        data-aos-duration="2500"
        >SMART MONEY</span
      >
    </div>
    <div class="strategic-container first">
      <div
        class="strategic-text-container"
        data-aos="zoom-in-right"
        data-aos-delay="0"
        data-aos-duration="1500"
        data-aos-mirror="false"
        data-aos-once="true"
      >
        <span
          data-aos="fade-down"
          data-aos-delay="600"
          data-aos-duration="1700"
        >
          Advanced data-driven platform
        </span>
        <span data-aos="fade-up" data-aos-delay="600" data-aos-duration="1700">
          Stockgrid gives you a detailed view of what smart money is doing. Get
          in depth data about large option blocks being traded, including the
          sentiment score, size, volume and order type. Stop guessing and build
          a strategy around the number 1 factor moving the market: money.
        </span>
      </div>
      <div
        class="strategic-graph-container"
        data-aos="zoom-out-left"
        data-aos-delay="500"
        data-aos-duration="1800"
        data-aos-mirror="false"
        data-aos-once="true"
      >
        <v-chart class="chart" autoresize ref="landing_chart" />
        <!-- <img
          src="../assets/svg/1_Artificial Intelligence.svg"
          class="isometric-img"
          alt=""
        /> -->
      </div>
    </div>
    <div
      class="options-table-container"
      data-aos="fade-right"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <p>Data and stats in real-time</p>
      <p>
        Live data allows you to spot unusual trades as they hit the tape. You
        can follow the entire stream, or filter it down. Historical data is
        available under the same format.
      </p>
      <OptionsFlowTable></OptionsFlowTable>
    </div>
    <div
      class="largest-premiums-container"
      data-aos="fade-left"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <p>
        Get an overview of the top inflow by tickers to quickly spot unusual
        activity
      </p>
      <p>
        The top tickers are provided in a chart format, while the data for all
        tickers can be retrieved throug the <i>Top Premiums</i> page.
      </p>
      <div class="largest-premiums-sub-container">
        <OptionsFlowTop
          v-bind:largestPremiums="largestCallPremiums"
          v-bind:type="'calls'"
        ></OptionsFlowTop>
        <OptionsFlowTop
          v-bind:largestPremiums="largestPutPremiums"
          v-bind:type="'puts'"
        ></OptionsFlowTop>
      </div>
    </div>
    <div class="cards-container">
      <div
        class="column-container"
        data-aos="fade-right"
        data-aos-delay="0"
        data-aos-duration="1500"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
      >
        <div class="cards">
          <div class="card-sub-container">
            <div class="cards-circle">
              <img src="../assets/svg/feature-tile-icon-01.svg" alt="" />
            </div>
          </div>
          <div class="card-sub-container">
            <span class="cards-title">Follow big money in real-time</span>
          </div>
        </div>
        <div class="cards">
          <div class="card-sub-container">
            <div class="cards-circle">
              <img src="../assets/svg/feature-tile-icon-03.svg" alt="" />
            </div>
          </div>
          <div class="card-sub-container">
            <span class="cards-title"
              >Get an overview of the current market bias</span
            >
          </div>
        </div>
      </div>
      <div
        class="column-container"
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-center"
      >
        <div class="cards">
          <div class="card-sub-container">
            <div class="cards-circle">
              <img src="../assets/svg/feature-tile-icon-04.svg" alt="" />
            </div>
          </div>
          <div class="card-sub-container">
            <span class="cards-title"
              >Connect the dots with analytics and insights</span
            >
          </div>
        </div>
        <div class="cards">
          <div class="card-sub-container">
            <div class="cards-circle">
              <img src="../assets/svg/feature-tile-icon-05.svg" alt="" />
            </div>
          </div>
          <div class="card-sub-container">
            <span class="cards-title"
              >Execute your strategy with confidence</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="price-chart-container"
      data-aos="fade-up"
      data-aos-delay="0"
      data-aos-duration="1500"
      data-aos-easing="ease"
      data-aos-mirror="false"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <p>Visualize the levels at which smart money is buying and selling</p>
      <OptionsFlowByStrikeChart></OptionsFlowByStrikeChart>
    </div>
    <div
      class="price-chart-container"
      data-aos="fade-down"
      data-aos-delay="0"
      data-aos-duration="1500"
      data-aos-easing="ease"
      data-aos-mirror="false"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <p>Use cumulative statistics to identify developing patterns</p>
      <OptionsFlowCumulativeStatsChart></OptionsFlowCumulativeStatsChart>
    </div>
    <div
      class="subscription-container"
      data-aos="fade-up"
      data-aos-delay="0"
      data-aos-duration="1500"
      data-aos-anchor-placement="top-center"
      data-aos-once="true"
    >
      <SubscriptionCards></SubscriptionCards>
    </div>
  </div>
</template>

<script>
import "echarts";
import SubscriptionCards from "@/components/SubscriptionCards.vue";
import OptionsFlowTable from "@/components/OptionsFlowTable.vue";
import OptionsFlowByStrikeChart from "@/components/OptionsFlowByStrikeChart.vue";
import OptionsFlowCumulativeStatsChart from "@/components/OptionsFlowCumulativeStatsChart.vue";
import OptionsFlowTop from "@/components/OptionsFlowTop.vue";
import { graphic } from "echarts/core";

import AOS from "aos";

export default {
  name: "Landing",
  data() {
    return {
      tableTriggered: false,
      largestCallPremiums: [],
      largestPutPremiums: [],
      options: {}
    };
  },
  head: {
    title: {
      inner: "Home"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Stockgrid is a website that uses data science to generate alpha. Investing is made easy with modern analytics and no longer has to rely on guessing."
      }
    ]
  },
  methods: {
    setChart: function() {
      const range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );

      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(26, 217, 204, 1)",
          show: false,
          top: "23%",
          bottom: "8%",
          left: "15",
          right: "15",
          containLabel: false
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: "Adopt a data-driven strategy",
          left: "40",
          top: "8%",
          textStyle: {
            fontFamily: "Nunito",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 20
          }
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          formatter: function() {},
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: false
        },
        xAxis: {
          type: "category",
          boundaryGap: true,
          show: false,
          data: range(0, 10, 1),
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(234, 234, 234, 0.1)",
              width: 1
            }
          }
        },
        yAxis: [
          {
            type: "value",
            name: "",
            show: false,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: [0, 10, 25, 23, 21, 22, 32, 40, 37, 50],
            name: "",
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)" //rgba(148, 93, 227, 0.2)
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)", width: 1 },
            itemStyle: { color: "rgba(103, 34, 148, 1)" },
            markPoint: {
              data: [
                {
                  name: "Data-driven strategy",
                  value: "Data-driven strategy",
                  itemStyle: {
                    color: "rgba(245, 34, 104, 1)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 12
                  },
                  symbolSize: 50,
                  xAxis: 9,
                  yAxis: 50
                }
              ]
            }
          },
          {
            data: [0, 5, 16, 10, 12, 10, 16, 23, 26, 28],
            name: "",
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)", width: 1 },
            itemStyle: { color: "rgba(103, 34, 148, 1)" },
            markPoint: {
              data: [
                {
                  name: "S&P500",
                  value: "S&P500",
                  itemStyle: {
                    color: "rgba(245, 34, 104, 0.5)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 12
                  },
                  symbolSize: 50,
                  xAxis: 9,
                  yAxis: 28
                }
              ]
            }
          },
          {
            data: [0, 3, 10, 7, 8, 6, 10, 4, 5, 6],
            name: "",
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(109, 89, 122, 1)", width: 1 },
            itemStyle: { color: "rgba(103, 34, 148, 1)" },
            markPoint: {
              data: [
                {
                  name: "YOLO",
                  value: "YOLO",
                  itemStyle: {
                    color: "rgba(245, 34, 104, 0.5)",
                    borderColor: "rgba(33, 33, 33, 1)",
                    borderWidth: 1
                  },
                  label: {
                    fontSize: 12
                  },
                  symbolSize: 50,
                  xAxis: 9,
                  yAxis: 6
                }
              ]
            }
          }
        ]
      };
      this.$refs.landing_chart.setOption(this.options);
    }
  },
  components: {
    SubscriptionCards,
    OptionsFlowTable,
    OptionsFlowByStrikeChart,
    OptionsFlowCumulativeStatsChart,
    OptionsFlowTop
  },
  beforeDestroy() {
  },
  mounted() {
    AOS.init({ offset: 30 });
    this.setChart();
    let scrollRef = 0;

    window.addEventListener("scroll", function() {
      // increase value up to 10, then refresh AOS
      scrollRef <= 10 ? scrollRef++ : AOS.refresh();
    });
    setTimeout(() => {
      this.$store.commit("setFilteredOptionsFlow", [
        {
          ticker: "TSLA",
          aggressor_ind: 0.98,
          ask: 2.01,
          bid: 1.99,
          cost_basis: 40,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 2,
          open_interest: 13578,
          option_activity_type: "Block",
          price: 2,
          put_call: "Put",
          sentiment: "Bearish",
          size: 201,
          strike_price: 750,
          time: "15:59",
          trade_count: 19,
          underlying_price: 756.78,
          volume: 58015,
          fill: "Mid",
          score: 0.9,
          DTE: 1,
          key: "1631970730"
        },
        {
          ticker: "QQQ",
          aggressor_ind: 1,
          ask: 1.74,
          bid: 1.71,
          cost_basis: 35,
          date: "21-09-16",
          date_expiration: "21-09-20",
          exchange: "NASDAQ",
          midpoint: 1.725,
          open_interest: 1942,
          option_activity_type: "Block",
          price: 1.74,
          put_call: "Call",
          sentiment: "Bullish",
          size: 200,
          strike_price: 376,
          time: "10:51",
          trade_count: 1,
          underlying_price: 375.46,
          volume: 2586,
          fill: "Ask",
          score: 0.2787777563,
          DTE: 4,
          key: "1631977205"
        },
        {
          ticker: "TQQQ",
          aggressor_ind: 0.16,
          ask: 1.6,
          bid: 1.59,
          cost_basis: 60,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 1.595,
          open_interest: 2110,
          option_activity_type: "Sweep",
          price: 1.6,
          put_call: "Call",
          sentiment: "Bearish",
          size: 374,
          strike_price: 146,
          time: "14:21",
          trade_count: 4,
          underlying_price: 146.5091,
          volume: 3505,
          fill: "Ask",
          score: 0.310179577,
          DTE: 1,
          key: "1631972936"
        },
        {
          ticker: "IWM",
          aggressor_ind: 1,
          ask: 1.68,
          bid: 1.67,
          cost_basis: 43,
          date: "21-09-16",
          date_expiration: "21-09-24",
          exchange: "ARCA",
          midpoint: 1.675,
          open_interest: 915,
          option_activity_type: "Sweep",
          price: 1.68,
          put_call: "Put",
          sentiment: "Bearish",
          size: 256,
          strike_price: 216,
          time: "11:33",
          trade_count: 3,
          underlying_price: 221.26,
          volume: 452,
          fill: "Ask",
          score: 0.4797729368,
          DTE: 8,
          key: "1631975455"
        },
        {
          ticker: "CLF",
          aggressor_ind: 1,
          ask: 0.64,
          bid: 0.62,
          cost_basis: 26,
          date: "21-09-15",
          date_expiration: "21-10-15",
          exchange: "NYSE",
          midpoint: 0.63,
          open_interest: 8169,
          option_activity_type: "Block",
          price: 0.63,
          put_call: "Call",
          sentiment: "Bullish",
          size: 412,
          strike_price: 26,
          time: "10:06",
          trade_count: 32,
          underlying_price: 22.97,
          volume: 912,
          fill: "Mid",
          score: 0.1823457582,
          DTE: 30,
          key: "1631972280"
        },
        {
          ticker: "RIOT",
          aggressor_ind: 0.59,
          ask: 1.97,
          bid: 1.96,
          cost_basis: 43,
          date: "21-09-15",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 1.965,
          open_interest: 1590,
          option_activity_type: "Sweep",
          price: 1.95,
          put_call: "Put",
          sentiment: "Neutral",
          size: 221,
          strike_price: 32,
          time: "15:05",
          trade_count: 23,
          underlying_price: 30.29,
          volume: 345,
          fill: "Below Bid",
          score: 0,
          DTE: 2,
          key: "1631970925"
        },
        {
          ticker: "AAPL",
          aggressor_ind: 0,
          ask: 1.37,
          bid: 1.36,
          cost_basis: 33,
          date: "21-09-16",
          date_expiration: "21-10-15",
          exchange: "NASDAQ",
          midpoint: 1.365,
          open_interest: 45159,
          option_activity_type: "Sweep",
          price: 1.36,
          put_call: "Call",
          sentiment: "Bullish",
          size: 243,
          strike_price: 140,
          time: "13:20",
          trade_count: 22,
          underlying_price: 148.13,
          volume: 8046,
          fill: "Bid",
          score: 0,
          DTE: 29,
          key: "1631976524"
        },
        {
          ticker: "DIDI",
          aggressor_ind: 0,
          ask: 0.82,
          bid: 0.8,
          cost_basis: 25,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NYSE",
          midpoint: 0.81,
          open_interest: 5162,
          option_activity_type: "Block",
          price: 0.8,
          put_call: "Put",
          sentiment: "Bullish",
          size: 316,
          strike_price: 9,
          time: "13:53",
          trade_count: 43,
          underlying_price: 8.24,
          volume: 765,
          fill: "Bid",
          score: 0,
          DTE: 1,
          key: "1631975926"
        },
        {
          ticker: "ORCL",
          aggressor_ind: 0,
          ask: 2.43,
          bid: 2.32,
          cost_basis: 58,
          date: "21-09-16",
          date_expiration: "23-01-20",
          exchange: "NYSE",
          midpoint: 2.375,
          open_interest: 550,
          option_activity_type: "Sweep",
          price: 2.32,
          put_call: "Call",
          sentiment: "Bearish",
          size: 248,
          strike_price: 120,
          time: "12:22",
          trade_count: 21,
          underlying_price: 87.03,
          volume: 299,
          fill: "Bid",
          score: 0.4268348845,
          DTE: 491,
          key: "1631969184"
        },
        {
          ticker: "LVS",
          aggressor_ind: 0.14,
          ask: 1.34,
          bid: 1.33,
          cost_basis: 32,
          date: "21-09-16",
          date_expiration: "21-12-17",
          exchange: "NYSE",
          midpoint: 1.335,
          open_interest: 23957,
          option_activity_type: "Sweep",
          price: 1.34,
          put_call: "Call",
          sentiment: "Bearish",
          size: 239,
          strike_price: 45,
          time: "13:15",
          trade_count: 12,
          underlying_price: 37.375,
          volume: 889,
          fill: "Ask",
          score: 0.4139895853,
          DTE: 92,
          key: "1631970168"
        },
        {
          ticker: "PLTR",
          aggressor_ind: 1,
          ask: 4.7,
          bid: 4.65,
          cost_basis: 94,
          date: "21-09-16",
          date_expiration: "21-11-19",
          exchange: "NYSE",
          midpoint: 4.675,
          open_interest: 20026,
          option_activity_type: "Block",
          price: 4.7,
          put_call: "Call",
          sentiment: "Bullish",
          size: 200,
          strike_price: 25,
          time: "11:13",
          trade_count: 1,
          underlying_price: 28.689,
          volume: 966,
          fill: "Ask",
          score: 0.338572886,
          DTE: 64,
          key: "1631971708"
        },
        {
          ticker: "CSCO",
          aggressor_ind: 0.28,
          ask: 0.66,
          bid: 0.66,
          cost_basis: 33,
          date: "21-09-15",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 0.66,
          open_interest: 3608,
          option_activity_type: "Block",
          price: 0.66,
          put_call: "Put",
          sentiment: "Bullish",
          size: 501,
          strike_price: 58.5,
          time: "14:55",
          trade_count: 33,
          underlying_price: 58.195,
          volume: 847,
          fill: "Bid",
          score: 0,
          DTE: 2,
          key: "1631977188"
        }
      ]);
    }, 500);

    setTimeout(() => {
      this.$store.commit("setFilteredOptionsFlow", [
        {
          ticker: "TSLA",
          aggressor_ind: 0.98,
          ask: 2.01,
          bid: 1.99,
          cost_basis: 40,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 2,
          open_interest: 13578,
          option_activity_type: "Block",
          price: 2,
          put_call: "Put",
          sentiment: "Bearish",
          size: 201,
          strike_price: 750,
          time: "15:59",
          trade_count: 19,
          underlying_price: 756.78,
          volume: 58015,
          fill: "Mid",
          score: 0.9,
          DTE: 1,
          key: "1631970730"
        },
        {
          ticker: "QQQ",
          aggressor_ind: 1,
          ask: 1.74,
          bid: 1.71,
          cost_basis: 35,
          date: "21-09-16",
          date_expiration: "21-09-20",
          exchange: "NASDAQ",
          midpoint: 1.725,
          open_interest: 1942,
          option_activity_type: "Block",
          price: 1.74,
          put_call: "Call",
          sentiment: "Bullish",
          size: 200,
          strike_price: 376,
          time: "10:51",
          trade_count: 1,
          underlying_price: 375.46,
          volume: 2586,
          fill: "Ask",
          score: 0.2787777563,
          DTE: 4,
          key: "1631977205"
        },
        {
          ticker: "TQQQ",
          aggressor_ind: 0.16,
          ask: 1.6,
          bid: 1.59,
          cost_basis: 60,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 1.595,
          open_interest: 2110,
          option_activity_type: "Sweep",
          price: 1.6,
          put_call: "Call",
          sentiment: "Bearish",
          size: 374,
          strike_price: 146,
          time: "14:21",
          trade_count: 4,
          underlying_price: 146.5091,
          volume: 3505,
          fill: "Ask",
          score: 0.310179577,
          DTE: 1,
          key: "1631972936"
        },
        {
          ticker: "IWM",
          aggressor_ind: 1,
          ask: 1.68,
          bid: 1.67,
          cost_basis: 43,
          date: "21-09-16",
          date_expiration: "21-09-24",
          exchange: "ARCA",
          midpoint: 1.675,
          open_interest: 915,
          option_activity_type: "Sweep",
          price: 1.68,
          put_call: "Put",
          sentiment: "Bearish",
          size: 256,
          strike_price: 216,
          time: "11:33",
          trade_count: 3,
          underlying_price: 221.26,
          volume: 452,
          fill: "Ask",
          score: 0.4797729368,
          DTE: 8,
          key: "1631975455"
        },
        {
          ticker: "CLF",
          aggressor_ind: 1,
          ask: 0.64,
          bid: 0.62,
          cost_basis: 26,
          date: "21-09-15",
          date_expiration: "21-10-15",
          exchange: "NYSE",
          midpoint: 0.63,
          open_interest: 8169,
          option_activity_type: "Block",
          price: 0.63,
          put_call: "Call",
          sentiment: "Bullish",
          size: 412,
          strike_price: 26,
          time: "10:06",
          trade_count: 32,
          underlying_price: 22.97,
          volume: 912,
          fill: "Mid",
          score: 0.1823457582,
          DTE: 30,
          key: "1631972280"
        },
        {
          ticker: "RIOT",
          aggressor_ind: 0.59,
          ask: 1.97,
          bid: 1.96,
          cost_basis: 43,
          date: "21-09-15",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 1.965,
          open_interest: 1590,
          option_activity_type: "Sweep",
          price: 1.95,
          put_call: "Put",
          sentiment: "Neutral",
          size: 221,
          strike_price: 32,
          time: "15:05",
          trade_count: 23,
          underlying_price: 30.29,
          volume: 345,
          fill: "Below Bid",
          score: 0,
          DTE: 2,
          key: "1631970925"
        },
        {
          ticker: "AAPL",
          aggressor_ind: 0,
          ask: 1.37,
          bid: 1.36,
          cost_basis: 33,
          date: "21-09-16",
          date_expiration: "21-10-15",
          exchange: "NASDAQ",
          midpoint: 1.365,
          open_interest: 45159,
          option_activity_type: "Sweep",
          price: 1.36,
          put_call: "Call",
          sentiment: "Bullish",
          size: 243,
          strike_price: 140,
          time: "13:20",
          trade_count: 22,
          underlying_price: 148.13,
          volume: 8046,
          fill: "Bid",
          score: 0,
          DTE: 29,
          key: "1631976524"
        },
        {
          ticker: "DIDI",
          aggressor_ind: 0,
          ask: 0.82,
          bid: 0.8,
          cost_basis: 25,
          date: "21-09-16",
          date_expiration: "21-09-17",
          exchange: "NYSE",
          midpoint: 0.81,
          open_interest: 5162,
          option_activity_type: "Block",
          price: 0.8,
          put_call: "Put",
          sentiment: "Bullish",
          size: 316,
          strike_price: 9,
          time: "13:53",
          trade_count: 43,
          underlying_price: 8.24,
          volume: 765,
          fill: "Bid",
          score: 0,
          DTE: 1,
          key: "1631975926"
        },
        {
          ticker: "ORCL",
          aggressor_ind: 0,
          ask: 2.43,
          bid: 2.32,
          cost_basis: 58,
          date: "21-09-16",
          date_expiration: "23-01-20",
          exchange: "NYSE",
          midpoint: 2.375,
          open_interest: 550,
          option_activity_type: "Sweep",
          price: 2.32,
          put_call: "Call",
          sentiment: "Bearish",
          size: 248,
          strike_price: 120,
          time: "12:22",
          trade_count: 21,
          underlying_price: 87.03,
          volume: 299,
          fill: "Bid",
          score: 0.4268348845,
          DTE: 491,
          key: "1631969184"
        },
        {
          ticker: "LVS",
          aggressor_ind: 0.14,
          ask: 1.34,
          bid: 1.33,
          cost_basis: 32,
          date: "21-09-16",
          date_expiration: "21-12-17",
          exchange: "NYSE",
          midpoint: 1.335,
          open_interest: 23957,
          option_activity_type: "Sweep",
          price: 1.34,
          put_call: "Call",
          sentiment: "Bearish",
          size: 239,
          strike_price: 45,
          time: "13:15",
          trade_count: 12,
          underlying_price: 37.375,
          volume: 889,
          fill: "Ask",
          score: 0.4139895853,
          DTE: 92,
          key: "1631970168"
        },
        {
          ticker: "PLTR",
          aggressor_ind: 1,
          ask: 4.7,
          bid: 4.65,
          cost_basis: 94,
          date: "21-09-16",
          date_expiration: "21-11-19",
          exchange: "NYSE",
          midpoint: 4.675,
          open_interest: 20026,
          option_activity_type: "Block",
          price: 4.7,
          put_call: "Call",
          sentiment: "Bullish",
          size: 200,
          strike_price: 25,
          time: "11:13",
          trade_count: 1,
          underlying_price: 28.689,
          volume: 966,
          fill: "Ask",
          score: 0.338572886,
          DTE: 64,
          key: "1631971708"
        },
        {
          ticker: "CSCO",
          aggressor_ind: 0.28,
          ask: 0.66,
          bid: 0.66,
          cost_basis: 33,
          date: "21-09-15",
          date_expiration: "21-09-17",
          exchange: "NASDAQ",
          midpoint: 0.66,
          open_interest: 3608,
          option_activity_type: "Block",
          price: 0.66,
          put_call: "Put",
          sentiment: "Bullish",
          size: 501,
          strike_price: 58.5,
          time: "14:55",
          trade_count: 33,
          underlying_price: 58.195,
          volume: 847,
          fill: "Bid",
          score: 0,
          DTE: 2,
          key: "1631977188"
        }
      ]);
    });
    setTimeout(() => {
      this.$store.commit("setOptionsFlowByStrikeData", {
        call_premiums: [
          0,
          0,
          0,
          0,
          4.9302,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          5.6742,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          3.45,
          0,
          0,
          2.341,
          0,
          0,
          0,
          0,
          0,
          2.2858,
          0,
          2.019,
          2.6767,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          16.3392,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1.5998,
          0,
          0,
          0,
          0,
          11.606,
          0,
          16.4419,
          0,
          0,
          18.2095,
          0,
          0,
          0,
          0,
          6.601,
          0,
          7.0368,
          0,
          0,
          25.057,
          0,
          0,
          0,
          0,
          5.7094,
          0,
          2.5195,
          0,
          0,
          3.3296,
          0.7076,
          1.0635,
          0,
          0,
          4.497,
          0,
          0,
          0,
          0,
          0,
          13.9369,
          0.9248,
          2.3472,
          0,
          1.3456,
          4.4262,
          2.3956,
          0.7305,
          2.757,
          0.2466,
          2.7653,
          6.829,
          27.1241,
          8.7237,
          15.7405,
          0.2733,
          14.8514,
          20.2374,
          44.3253,
          31.131,
          44.2953,
          0.5862,
          42.1214,
          22.979,
          94.1782,
          27.8282,
          48.0671,
          1.6526,
          23.0191,
          13.3566,
          28.1337,
          3.5294,
          5.7936,
          2.3983,
          1.4789,
          16.9653,
          0.8261,
          1.9202,
          2.0309,
          1.0487,
          6.523,
          3.8021,
          0.1826,
          0.6588,
          0.2153,
          2.3177,
          0.104,
          0.3252,
          0.3788,
          0.9399,
          0.3036,
          0.0848,
          0.2395,
          0,
          15.3149,
          0.1661,
          0.125,
          1.0094,
          0.1988,
          0.2162,
          0.2632,
          0.4472,
          0.8377,
          0.3986,
          0.1198,
          0.0876,
          1.4952,
          5.5745,
          3.4933,
          2.1291,
          0.2824,
          3.0213,
          0.028,
          0.2161,
          0.1267,
          1.0449,
          4.0183,
          0.0624,
          0.125,
          0.098
        ],
        call_size: [
          0,
          0,
          0,
          0,
          200,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          400,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          300,
          0,
          0,
          212,
          0,
          0,
          0,
          0,
          0,
          210,
          0,
          200,
          260,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          2236,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          250,
          0,
          0,
          0,
          0,
          2000,
          0,
          3076,
          0,
          0,
          3550,
          0,
          0,
          0,
          0,
          1400,
          0,
          1600,
          0,
          0,
          6060,
          0,
          0,
          0,
          0,
          1202,
          0,
          750,
          0,
          0,
          940,
          200,
          426,
          0,
          0,
          1500,
          0,
          0,
          0,
          0,
          0,
          4740,
          487,
          1280,
          0,
          584,
          1550,
          1708,
          250,
          1358,
          200,
          2451,
          7514,
          36404,
          40482,
          63215,
          678,
          102729,
          107974,
          214070,
          202902,
          241468,
          3081,
          370828,
          194221,
          470919,
          253729,
          385978,
          13673,
          221019,
          193436,
          158157,
          73801,
          50856,
          20366,
          19253,
          107893,
          20836,
          4526,
          45378,
          14373,
          18364,
          6304,
          1269,
          3655,
          475,
          11228,
          219,
          972,
          12203,
          2110,
          897,
          443,
          1009,
          0,
          47051,
          725,
          493,
          2312,
          725,
          704,
          1287,
          18311,
          11073,
          1443,
          425,
          417,
          22951,
          10843,
          4675,
          2484,
          311,
          6163,
          400,
          600,
          1881,
          4657,
          6879,
          200,
          200,
          1000
        ],
        put_premiums: [
          0.0938,
          0.04,
          0.1481,
          0.0394,
          0.0582,
          0.0362,
          0.2871,
          0.0323,
          1.9402,
          0.0348,
          0.0255,
          0.7593,
          3.1665,
          0.115,
          0.5529,
          0.1414,
          0.9487,
          1.1825,
          0.03,
          0.0269,
          0.025,
          0.0464,
          0.2026,
          0.0555,
          1.119,
          0.0633,
          0.0255,
          0.0413,
          0.125,
          0.3673,
          0.0565,
          0.1663,
          0.6629,
          1.2689,
          0.0891,
          2.762,
          0.1659,
          0.4654,
          0.1805,
          0.0607,
          0.469,
          1.3808,
          0.1795,
          0.5037,
          0.65,
          0.0706,
          0.3373,
          0.5806,
          0.1427,
          2.0082,
          0.0764,
          1.3492,
          0.0812,
          0.1725,
          0.4112,
          0.0405,
          4.0336,
          0.2059,
          0.3515,
          1.0904,
          0.1077,
          1.6159,
          0.0382,
          0.148,
          0.371,
          0.0987,
          1.923,
          0.7226,
          0.8531,
          0.072,
          1.0763,
          0.9711,
          0.393,
          1.1014,
          0.6664,
          6.2544,
          0.4183,
          0.0662,
          0.2354,
          0.4468,
          2.6272,
          0.7268,
          0.2517,
          0.3384,
          0.3612,
          6.7261,
          0.1238,
          0.2865,
          0.1091,
          0.639,
          4.8553,
          0.5828,
          0.1615,
          0.8159,
          0.5579,
          25.7948,
          0.831,
          0.4952,
          0.9992,
          0.7928,
          5.0188,
          0.4234,
          1.3047,
          0.9394,
          1.238,
          18.4208,
          1.0012,
          11.2673,
          1.4771,
          5.2178,
          9.4846,
          9.8656,
          1.419,
          3.2278,
          3.4478,
          43.7215,
          28.4416,
          7.8663,
          3.4295,
          6.2405,
          23.1449,
          1.6383,
          3.4985,
          0.0837,
          26.3919,
          2.095,
          50.0228,
          2.9115,
          5.7686,
          0.0456,
          2.9218,
          4.6836,
          37.3361,
          6.6822,
          13.7215,
          0,
          11.3709,
          13.5016,
          110.8743,
          8.5057,
          26.7838,
          2.9565,
          24.8223,
          38.2714,
          99.1655,
          37.17,
          35.8426,
          0.5619,
          43.7304,
          31.3575,
          95.8806,
          18.7547,
          24.6379,
          1.5412,
          16.6155,
          7.5879,
          9.5658,
          0.3958,
          2.0961,
          0.3049,
          0.2643,
          1.7128,
          0,
          0,
          0,
          0,
          2.4649,
          0,
          0,
          0,
          0,
          0.5496,
          0,
          0,
          0,
          6.127,
          0,
          0,
          0,
          1.68,
          0,
          0,
          0,
          2.321,
          0,
          0,
          0,
          1.5394,
          0,
          0,
          0,
          0,
          0,
          1.7404,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ],
        put_size: [
          13210,
          5000,
          1170,
          410,
          262,
          1500,
          8629,
          490,
          5809,
          490,
          500,
          16000,
          6484,
          5000,
          12348,
          233,
          25254,
          18086,
          250,
          354,
          305,
          434,
          6044,
          555,
          5608,
          907,
          250,
          369,
          1000,
          745,
          250,
          797,
          2437,
          17076,
          295,
          22991,
          796,
          4850,
          639,
          646,
          3650,
          15598,
          943,
          4823,
          3799,
          214,
          1150,
          5479,
          586,
          13839,
          352,
          12651,
          250,
          500,
          1867,
          1500,
          25602,
          514,
          5224,
          2665,
          254,
          13564,
          217,
          2961,
          8731,
          255,
          34752,
          6856,
          1706,
          2000,
          13310,
          5394,
          831,
          2193,
          3263,
          29452,
          4805,
          414,
          369,
          2742,
          7721,
          6670,
          910,
          918,
          3986,
          49902,
          513,
          1760,
          219,
          6744,
          11670,
          4938,
          732,
          1051,
          4209,
          76561,
          3273,
          1187,
          10073,
          3063,
          24302,
          1043,
          2834,
          5717,
          4424,
          76769,
          4974,
          30042,
          4714,
          18310,
          73603,
          30790,
          9127,
          23904,
          11562,
          205502,
          85648,
          15616,
          18328,
          21184,
          98765,
          7982,
          17261,
          798,
          69492,
          14694,
          190536,
          36101,
          17932,
          292,
          27446,
          14258,
          181212,
          48474,
          64629,
          0,
          132866,
          92723,
          374204,
          50155,
          206393,
          6070,
          168848,
          196459,
          425625,
          230292,
          231652,
          5433,
          212959,
          256147,
          309372,
          149725,
          212659,
          6587,
          107349,
          39704,
          12403,
          1400,
          2129,
          253,
          341,
          1697,
          0,
          0,
          0,
          0,
          1530,
          0,
          0,
          0,
          0,
          200,
          0,
          0,
          0,
          2000,
          0,
          0,
          0,
          500,
          0,
          0,
          0,
          631,
          0,
          0,
          0,
          400,
          0,
          0,
          0,
          0,
          0,
          357,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ],
        strike_price: [
          115,
          120,
          185,
          190,
          200,
          205,
          210,
          220,
          225,
          230,
          245,
          250,
          255,
          260,
          265,
          270,
          275,
          280,
          285,
          286,
          287,
          290,
          295,
          299,
          300,
          305,
          306,
          309,
          310,
          315,
          317,
          318,
          319,
          320,
          324,
          325,
          326,
          327,
          328,
          329,
          330,
          331,
          332,
          333,
          335,
          338,
          339,
          340,
          344,
          345,
          348,
          350,
          351,
          352,
          355,
          358,
          360,
          361,
          362,
          363,
          364,
          365,
          366,
          367,
          368,
          369,
          370,
          371,
          372,
          373,
          375,
          376,
          377,
          378,
          379,
          380,
          381,
          382,
          383,
          384,
          385,
          386,
          387,
          388,
          389,
          390,
          391,
          392,
          393,
          394,
          395,
          396,
          397,
          398,
          399,
          400,
          401,
          402,
          403,
          404,
          405,
          406,
          407,
          408,
          409,
          410,
          411,
          412,
          413,
          414,
          415,
          416,
          417,
          418,
          419,
          420,
          421,
          422,
          423,
          424,
          425,
          426,
          427,
          427.5,
          428,
          429,
          430,
          431,
          432,
          432.5,
          433,
          434,
          435,
          436,
          437,
          437.5,
          438,
          439,
          440,
          441,
          442,
          442.5,
          443,
          444,
          445,
          446,
          447,
          447.5,
          448,
          449,
          450,
          451,
          452,
          452.5,
          453,
          454,
          455,
          456,
          457,
          458,
          459,
          460,
          461,
          462,
          463,
          464,
          465,
          466,
          467,
          468,
          469,
          470,
          471,
          473,
          474,
          475,
          476,
          477,
          478,
          479,
          480,
          482,
          484,
          485,
          486,
          487,
          488,
          489,
          490,
          492,
          493,
          494,
          495,
          500,
          505,
          510,
          515,
          520,
          525,
          530,
          540,
          545,
          550,
          555,
          580,
          600
        ]
      });
    });
    setTimeout(() => {
      this.$store.commit("setOptionsFlowHistoricalPremiumData", {
        call_premiums: [
          64.14,
          24.28,
          68.39,
          22.58,
          25.48,
          43.79,
          53.07,
          88.92,
          44.96,
          47.61,
          33.07,
          57.8,
          71.17,
          42.8,
          24.29,
          15.18,
          15.56,
          21.3,
          71.21,
          73.17,
          53.35,
          90.63,
          22.3,
          83.97,
          137.88,
          89.71,
          44.24,
          104.33,
          41.04,
          89.79,
          63.66,
          50.7,
          94.77,
          38.16,
          32.9,
          41.88,
          19.78,
          61.94,
          52.76,
          85.95,
          44.85,
          39.81,
          20.09,
          31.19,
          86.03,
          72.55,
          59.71,
          56.44,
          80.29,
          47.5,
          126.62,
          38.7,
          50.7,
          52.1,
          27.96,
          46.15,
          56.16,
          91.66,
          73.74,
          112.21,
          65.69,
          48.27,
          74.27,
          80.24,
          58.01,
          35.68,
          81.16,
          47.2,
          27.75,
          129.36,
          33.86,
          30.43,
          86.43,
          39.45,
          23.51,
          41.03,
          31.5,
          70.07,
          73.14,
          82.21,
          40.51,
          99.23,
          47.54,
          112.69,
          33.64,
          51.31,
          32.32,
          54.18,
          91.21,
          45.18,
          49.6,
          34.21,
          57.99,
          50.84,
          121.04,
          35.17,
          65.89,
          41.14,
          34.47,
          118.5,
          18.78,
          102.07,
          93.74,
          43.37,
          51.38,
          25.87,
          33.63,
          43.9,
          42.71,
          65.29,
          59.47,
          42.42,
          43.22,
          46.01,
          29.81,
          40.89,
          68.33,
          39.1,
          40.89,
          37.13,
          17.55,
          30.7,
          26.87,
          44.22,
          14.18,
          44.71,
          31.39,
          14.81,
          135.13,
          12.18,
          40.88,
          17.21,
          28.02,
          29.2,
          38,
          88.52,
          34.32,
          37.84,
          29.38,
          16.92,
          20.73,
          24.9,
          73.08,
          32.71,
          79.56,
          27.61,
          49.69,
          32.23,
          30.72,
          54.1,
          33.45,
          27.32,
          124.83,
          22.28,
          38.56,
          28.58,
          63.79,
          48.67,
          37.34,
          23.8,
          53.01,
          43.63,
          32.4,
          20.34,
          27.73,
          18.7,
          34.9,
          24.06,
          13.97,
          16.73,
          28.08,
          34.69,
          36.72,
          14.65,
          26.39,
          27.13,
          66.19,
          70.07,
          40.32,
          43.03,
          28.73,
          41.35,
          28.74,
          19.99,
          22.4,
          64.28,
          61.53,
          42.81,
          13.03,
          31.06,
          44.58,
          33.34,
          33.5,
          52.9,
          35.18,
          49.26,
          38.86,
          41.26,
          49.92,
          51.7
        ],
        cum_call_premiums: [
          64.14,
          88.42,
          156.81,
          179.39,
          204.87,
          248.66,
          301.73,
          390.65,
          435.61,
          483.22,
          516.29,
          574.09,
          645.26,
          688.06,
          712.35,
          727.53,
          743.09,
          764.39,
          835.6,
          908.77,
          897.98,
          964.33,
          918.24,
          979.63,
          1092.03,
          1137.95,
          1129.12,
          1144.53,
          1140.61,
          1182.79,
          1213.38,
          1206.28,
          1229.88,
          1225.24,
          1233.85,
          1260.55,
          1264.77,
          1305.41,
          1286.96,
          1299.74,
          1291.24,
          1240.42,
          1238.21,
          1185.43,
          1133.58,
          1116.42,
          1131.89,
          1084,
          1123.25,
          1080.96,
          1143.92,
          1131.92,
          1087.85,
          1101.79,
          1096.85,
          1101.12,
          1137.5,
          1167.22,
          1188.2,
          1214.46,
          1235.3,
          1243.76,
          1297.94,
          1346.99,
          1318.97,
          1282.1,
          1303.55,
          1294.31,
          1241.77,
          1323.63,
          1230.87,
          1222.6,
          1258.33,
          1245.68,
          1241.23,
          1236.11,
          1211.45,
          1189.86,
          1189.26,
          1159.26,
          1134.08,
          1185.04,
          1158.31,
          1190.76,
          1166.39,
          1182.02,
          1133.18,
          1140.16,
          1203.62,
          1119.44,
          1135.18,
          1138.96,
          1110.52,
          1121.91,
          1219.44,
          1213.58,
          1247.97,
          1219.04,
          1180.37,
          1216.66,
          1194.93,
          1197.77,
          1243.97,
          1174.65,
          1192.39,
          1166.95,
          1168.26,
          1157.98,
          1109.48,
          1129.59,
          1139.46,
          1147.67,
          1132.9,
          1128.07,
          1036.84,
          1042.56,
          1045,
          1042.96,
          1049.38,
          968.01,
          966.78,
          895.41,
          828.54,
          829.39,
          792.19,
          811.03,
          808.79,
          779.7,
          872.12,
          819.01,
          800.42,
          775.21,
          760.01,
          743.2,
          751.39,
          799.02,
          765.01,
          763.75,
          752.24,
          732.03,
          735.21,
          729.41,
          775.62,
          764.11,
          829.49,
          812.39,
          830.69,
          848.11,
          743.7,
          785.62,
          778.19,
          788.3,
          885.11,
          878.19,
          878.75,
          818.81,
          848.28,
          859.11,
          867.07,
          873.95,
          906.23,
          924.96,
          884.28,
          871.91,
          820.08,
          811.17,
          796.38,
          788.21,
          771.46,
          734.09,
          728.72,
          736.09,
          647.98,
          640.35,
          628.18,
          626.73,
          629.13,
          650.53,
          653.51,
          672.74,
          648.46,
          646.18,
          642.52,
          642.17,
          636.84,
          682.42,
          709.05,
          727.8,
          726.86,
          741.19,
          757.69,
          756.34,
          753.12,
          791.37,
          800.16,
          822.29,
          794.96,
          766.15,
          775.75,
          784.42
        ],
        cum_net_premiums: [
          7.57,
          -13.42,
          18.92,
          11.87,
          -0.75,
          -28.64,
          -24.29,
          -16.1,
          -33.16,
          -70.47,
          -71.76,
          -69.82,
          -63.25,
          -102.35,
          -132.8,
          -165.55,
          -193.89,
          -195.54,
          -205.88,
          -190.58,
          -206.64,
          -268.08,
          -377.25,
          -355.37,
          -255.49,
          -228.37,
          -260.57,
          -253.28,
          -230.58,
          -156.97,
          -228.68,
          -271.62,
          -308.28,
          -305.38,
          -299.12,
          -333.06,
          -365.46,
          -519.98,
          -549.96,
          -749.5,
          -825.06,
          -818.45,
          -774.09,
          -909.54,
          -994.36,
          -963.11,
          -922.14,
          -964.82,
          -976.88,
          -1014.6,
          -878.33,
          -866.06,
          -899.45,
          -898.51,
          -919.73,
          -946.36,
          -898.26,
          -842.95,
          -860.9,
          -678.96,
          -649.92,
          -645.73,
          -775.95,
          -875.98,
          -921.17,
          -993.89,
          -975.87,
          -967,
          -988.64,
          -911.32,
          -997.8,
          -991.24,
          -925.19,
          -984.2,
          -966.87,
          -937.13,
          -969.99,
          -916.56,
          -880,
          -881.42,
          -854.19,
          -739.37,
          -606.93,
          -349.15,
          -328.69,
          -273.07,
          -331.86,
          -319.32,
          -251.32,
          -324.15,
          -304.84,
          -316.57,
          -320.81,
          -254.57,
          -260.57,
          -228.71,
          -206.17,
          -173.98,
          -160.11,
          -72.94,
          -60.52,
          -100.41,
          -55.05,
          -97.67,
          -144.8,
          -187.59,
          -213.55,
          -230.73,
          -307.89,
          -368.93,
          -466.63,
          -517.38,
          -563.01,
          -558.17,
          -546.26,
          -622,
          -628.31,
          -642.61,
          -643.52,
          -749.61,
          -750.18,
          -761.9,
          -795.57,
          -795.6,
          -758.02,
          -739.54,
          -690.75,
          -695.62,
          -557.7,
          -519.56,
          -437.83,
          -370.55,
          -324.01,
          -312.81,
          -295.7,
          -146.92,
          -175.7,
          -169.5,
          -172.84,
          -168.94,
          -170.31,
          -182.55,
          -120.26,
          -106.87,
          -34.52,
          -38.82,
          -30.72,
          -24.3,
          -142.01,
          -142.01,
          -148.02,
          -151.49,
          -87.94,
          -126.15,
          -162.13,
          -275.25,
          -383.29,
          -406.68,
          -426.49,
          -444.91,
          -474.65,
          -458.95,
          -544.45,
          -595.05,
          -643.69,
          -662.22,
          -673.61,
          -673.39,
          -683.02,
          -684.07,
          -659.23,
          -681.3,
          -782.05,
          -757.87,
          -723.61,
          -670,
          -486.31,
          -475.83,
          -469.76,
          -474,
          -453.98,
          -447.81,
          -411.65,
          -375.36,
          -418.68,
          -369.12,
          -346.84,
          -323.82,
          -314.17,
          -300.8,
          -300.58,
          -253.87,
          -249.72,
          -231.49,
          -263.01,
          -260.44,
          -318.8,
          -316.47,
          -310.8,
          -338.07
        ],
        cum_put_premiums: [
          56.57,
          101.84,
          137.89,
          167.52,
          205.62,
          277.3,
          326.02,
          406.75,
          468.77,
          553.69,
          588.05,
          643.91,
          708.51,
          790.41,
          845.15,
          893.08,
          936.98,
          959.93,
          1041.48,
          1099.35,
          1104.62,
          1232.41,
          1295.49,
          1335,
          1347.52,
          1366.32,
          1389.69,
          1397.81,
          1371.19,
          1339.76,
          1442.06,
          1477.9,
          1538.16,
          1530.62,
          1532.97,
          1593.61,
          1630.23,
          1825.39,
          1836.92,
          2049.24,
          2116.3,
          2058.87,
          2012.3,
          2094.97,
          2127.94,
          2079.53,
          2054.03,
          2048.82,
          2100.13,
          2095.56,
          2022.25,
          1997.98,
          1987.3,
          2000.3,
          2016.58,
          2047.48,
          2035.76,
          2010.17,
          2049.1,
          1893.42,
          1885.22,
          1889.49,
          2073.89,
          2222.97,
          2240.14,
          2275.99,
          2279.42,
          2261.31,
          2230.41,
          2234.95,
          2228.67,
          2213.84,
          2183.52,
          2229.88,
          2208.1,
          2173.24,
          2181.44,
          2106.42,
          2069.26,
          2040.68,
          1988.27,
          1924.41,
          1765.24,
          1539.91,
          1495.08,
          1455.09,
          1465.04,
          1459.48,
          1454.94,
          1443.59,
          1440.02,
          1455.53,
          1431.33,
          1376.48,
          1480.01,
          1442.29,
          1454.14,
          1393.02,
          1340.48,
          1289.6,
          1255.45,
          1298.18,
          1299.02,
          1272.32,
          1337.19,
          1354.54,
          1381.81,
          1388.71,
          1417.37,
          1498.52,
          1606.09,
          1665.05,
          1695.91,
          1686.24,
          1583.1,
          1664.56,
          1673.31,
          1685.57,
          1692.9,
          1717.62,
          1716.96,
          1657.31,
          1624.11,
          1624.99,
          1550.21,
          1550.57,
          1499.54,
          1475.32,
          1429.82,
          1338.57,
          1238.25,
          1145.76,
          1084.02,
          1056.01,
          1047.09,
          945.94,
          940.71,
          933.25,
          925.08,
          900.97,
          905.52,
          911.96,
          895.88,
          870.98,
          864.01,
          851.21,
          861.41,
          872.41,
          885.71,
          927.63,
          926.21,
          939.79,
          973.05,
          1004.34,
          1040.88,
          1094.06,
          1231.57,
          1265.79,
          1293.56,
          1318.86,
          1380.88,
          1383.91,
          1428.73,
          1466.96,
          1463.77,
          1473.39,
          1469.99,
          1461.6,
          1454.48,
          1418.16,
          1387.95,
          1417.39,
          1430.03,
          1398.22,
          1351.79,
          1296.73,
          1115.44,
          1126.36,
          1123.27,
          1146.74,
          1102.44,
          1093.99,
          1054.17,
          1017.53,
          1055.52,
          1051.54,
          1055.89,
          1051.62,
          1041.03,
          1041.99,
          1058.27,
          1010.21,
          1002.84,
          1022.86,
          1063.17,
          1082.73,
          1113.76,
          1082.62,
          1086.55,
          1122.49
        ],
        dates: [
          "2020-12-02",
          "2020-12-03",
          "2020-12-04",
          "2020-12-07",
          "2020-12-08",
          "2020-12-09",
          "2020-12-10",
          "2020-12-11",
          "2020-12-14",
          "2020-12-15",
          "2020-12-16",
          "2020-12-17",
          "2020-12-18",
          "2020-12-21",
          "2020-12-22",
          "2020-12-23",
          "2020-12-24",
          "2020-12-28",
          "2020-12-29",
          "2020-12-30",
          "2020-12-31",
          "2021-01-04",
          "2021-01-05",
          "2021-01-06",
          "2021-01-07",
          "2021-01-08",
          "2021-01-11",
          "2021-01-12",
          "2021-01-13",
          "2021-01-14",
          "2021-01-15",
          "2021-01-19",
          "2021-01-20",
          "2021-01-21",
          "2021-01-22",
          "2021-01-25",
          "2021-01-26",
          "2021-01-27",
          "2021-01-28",
          "2021-01-29",
          "2021-02-01",
          "2021-02-02",
          "2021-02-03",
          "2021-02-04",
          "2021-02-05",
          "2021-02-08",
          "2021-02-09",
          "2021-02-10",
          "2021-02-11",
          "2021-02-12",
          "2021-02-16",
          "2021-02-17",
          "2021-02-18",
          "2021-02-19",
          "2021-02-22",
          "2021-02-23",
          "2021-02-24",
          "2021-02-25",
          "2021-02-26",
          "2021-03-01",
          "2021-03-02",
          "2021-03-03",
          "2021-03-04",
          "2021-03-05",
          "2021-03-08",
          "2021-03-09",
          "2021-03-10",
          "2021-03-11",
          "2021-03-12",
          "2021-03-15",
          "2021-03-16",
          "2021-03-17",
          "2021-03-18",
          "2021-03-19",
          "2021-03-22",
          "2021-03-23",
          "2021-03-24",
          "2021-03-25",
          "2021-03-26",
          "2021-03-29",
          "2021-03-30",
          "2021-03-31",
          "2021-04-01",
          "2021-04-05",
          "2021-04-06",
          "2021-04-07",
          "2021-04-08",
          "2021-04-09",
          "2021-04-12",
          "2021-04-13",
          "2021-04-14",
          "2021-04-15",
          "2021-04-16",
          "2021-04-19",
          "2021-04-20",
          "2021-04-21",
          "2021-04-22",
          "2021-04-23",
          "2021-04-26",
          "2021-04-27",
          "2021-04-28",
          "2021-04-29",
          "2021-04-30",
          "2021-05-03",
          "2021-05-04",
          "2021-05-05",
          "2021-05-06",
          "2021-05-07",
          "2021-05-10",
          "2021-05-11",
          "2021-05-12",
          "2021-05-13",
          "2021-05-14",
          "2021-05-17",
          "2021-05-18",
          "2021-05-19",
          "2021-05-20",
          "2021-05-21",
          "2021-05-24",
          "2021-05-25",
          "2021-05-26",
          "2021-05-27",
          "2021-05-28",
          "2021-06-01",
          "2021-06-02",
          "2021-06-03",
          "2021-06-04",
          "2021-06-07",
          "2021-06-08",
          "2021-06-09",
          "2021-06-10",
          "2021-06-11",
          "2021-06-14",
          "2021-06-15",
          "2021-06-16",
          "2021-06-17",
          "2021-06-18",
          "2021-06-21",
          "2021-06-22",
          "2021-06-23",
          "2021-06-24",
          "2021-06-25",
          "2021-06-28",
          "2021-06-29",
          "2021-06-30",
          "2021-07-01",
          "2021-07-02",
          "2021-07-06",
          "2021-07-07",
          "2021-07-08",
          "2021-07-09",
          "2021-07-12",
          "2021-07-13",
          "2021-07-14",
          "2021-07-15",
          "2021-07-16",
          "2021-07-19",
          "2021-07-20",
          "2021-07-21",
          "2021-07-22",
          "2021-07-23",
          "2021-07-26",
          "2021-07-27",
          "2021-07-28",
          "2021-07-29",
          "2021-07-30",
          "2021-08-02",
          "2021-08-03",
          "2021-08-04",
          "2021-08-05",
          "2021-08-06",
          "2021-08-09",
          "2021-08-10",
          "2021-08-11",
          "2021-08-12",
          "2021-08-13",
          "2021-08-16",
          "2021-08-17",
          "2021-08-18",
          "2021-08-19",
          "2021-08-20",
          "2021-08-23",
          "2021-08-24",
          "2021-08-25",
          "2021-08-26",
          "2021-08-27",
          "2021-08-30",
          "2021-08-31",
          "2021-09-01",
          "2021-09-02",
          "2021-09-03",
          "2021-09-07",
          "2021-09-08",
          "2021-09-09",
          "2021-09-10",
          "2021-09-13",
          "2021-09-14",
          "2021-09-15",
          "2021-09-16",
          "2021-09-17"
        ],
        net_premiums: [
          7.57,
          -20.99,
          32.34,
          -7.05,
          -12.62,
          -27.89,
          4.35,
          8.19,
          -17.06,
          -37.31,
          -1.29,
          1.94,
          6.57,
          -39.1,
          -30.45,
          -32.75,
          -28.34,
          -1.65,
          -10.34,
          15.3,
          -8.49,
          -82.43,
          -76.83,
          14.83,
          87.26,
          -0.77,
          -27.85,
          15.48,
          5.64,
          36.3,
          -73,
          -41,
          -30.09,
          -36.2,
          -24.19,
          -66.69,
          -60.74,
          -156.17,
          -40.32,
          -184.24,
          -84.05,
          -75.82,
          -32.47,
          -120.62,
          2.44,
          30.48,
          13.12,
          -27.2,
          -6.42,
          -1.42,
          63.27,
          -28.73,
          -63.48,
          -35.26,
          -45.41,
          -93.32,
          -12.64,
          -100.86,
          -58.27,
          -2.3,
          -55.01,
          -71.63,
          -162.69,
          -220.65,
          -42.75,
          -42.24,
          31.14,
          -18.33,
          -28.06,
          75.9,
          -23.21,
          -22.17,
          2.57,
          -94.27,
          -28.08,
          -63.58,
          -45.5,
          -47.43,
          -21.71,
          -3.72,
          -27.78,
          43.19,
          -30.25,
          37.13,
          -22.29,
          13.38,
          -27.65,
          -5.79,
          39.94,
          3.07,
          -3.9,
          -33.9,
          -1.67,
          -28.03,
          -34.08,
          -31.72,
          -22.96,
          -15.24,
          -7.84,
          83.45,
          -15.36,
          3.3,
          15.11,
          -5.49,
          -69.42,
          -29.41,
          -53.61,
          -22.97,
          -37.22,
          -57.97,
          -101.6,
          -84.65,
          -47.3,
          -23.19,
          -22.17,
          -107.46,
          -29.27,
          -29.54,
          -8.75,
          -22.64,
          -15.93,
          -8.42,
          -18.56,
          -5.52,
          -31.84,
          -10.93,
          -4.82,
          -27.84,
          100.7,
          -19.83,
          -19.87,
          -17.37,
          -0.76,
          -11.99,
          -5.06,
          41.32,
          -58.05,
          -23.34,
          -12.09,
          -18.74,
          -17.3,
          -20.66,
          43.73,
          7.87,
          40.51,
          -15.23,
          3.28,
          -21.42,
          -17.01,
          -19.83,
          -25.88,
          -20.84,
          62.79,
          -50.2,
          -41.04,
          -71.8,
          -166.09,
          -46.73,
          -31.9,
          -37.16,
          -47.04,
          -4.96,
          -41.77,
          -42.73,
          -8.13,
          -33.76,
          -8.11,
          -21.2,
          -26.64,
          -20.88,
          -1.04,
          -42.91,
          -37.96,
          -26.02,
          -6.78,
          -18.19,
          17.6,
          -36.25,
          -25.83,
          -41.4,
          -27.02,
          1.21,
          -5.61,
          -6.44,
          -51.45,
          15.8,
          14.17,
          1.82,
          -16.99,
          -7.51,
          -0.82,
          3.8,
          -33.81,
          -7.79,
          -38.3,
          -15.62,
          -40.76,
          -33.92,
          -20.16,
          -68.67
        ],
        prices: [
          366.79,
          366.69,
          369.85,
          369.09,
          370.17,
          366.85,
          366.73,
          366.3,
          364.66,
          369.59,
          370.17,
          372.24,
          369.18,
          367.86,
          367.24,
          367.57,
          369,
          372.17,
          371.46,
          371.99,
          373.88,
          368.79,
          371.33,
          373.55,
          379.1,
          381.26,
          378.69,
          378.77,
          379.79,
          378.46,
          375.7,
          378.65,
          383.89,
          384.24,
          382.88,
          384.39,
          383.79,
          374.41,
          377.63,
          370.07,
          376.23,
          381.55,
          381.85,
          386.19,
          387.71,
          390.51,
          390.25,
          390.08,
          390.71,
          392.64,
          392.3,
          392.39,
          390.72,
          390.03,
          387.03,
          387.5,
          391.77,
          382.33,
          380.36,
          389.58,
          386.54,
          381.42,
          376.7,
          383.63,
          381.72,
          387.17,
          389.58,
          393.53,
          394.06,
          396.41,
          395.91,
          397.26,
          391.48,
          389.48,
          392.59,
          389.5,
          387.52,
          389.7,
          395.98,
          395.78,
          394.73,
          396.33,
          400.61,
          406.36,
          406.12,
          406.59,
          408.52,
          411.49,
          411.64,
          412.86,
          411.45,
          415.87,
          417.26,
          415.21,
          412.17,
          416.07,
          412.27,
          416.74,
          417.61,
          417.52,
          417.4,
          420.06,
          417.3,
          418.2,
          415.62,
          415.75,
          419.07,
          422.12,
          417.94,
          414.21,
          405.41,
          410.28,
          416.58,
          415.52,
          411.94,
          410.86,
          415.28,
          414.94,
          419.17,
          418.24,
          419.07,
          419.29,
          420.04,
          419.67,
          420.33,
          418.77,
          422.6,
          422.19,
          422.28,
          421.65,
          423.61,
          424.31,
          425.26,
          424.48,
          422.11,
          421.97,
          414.92,
          420.86,
          423.11,
          422.6,
          425.1,
          426.61,
          427.47,
          427.7,
          428.06,
          430.43,
          433.72,
          432.93,
          434.46,
          430.92,
          435.52,
          437.08,
          435.59,
          436.24,
          434.75,
          431.34,
          424.97,
          431.06,
          434.55,
          435.46,
          439.94,
          441.02,
          439.01,
          438.83,
          440.65,
          438.51,
          437.59,
          441.15,
          438.98,
          441.76,
          442.49,
          442.13,
          442.68,
          443.78,
          445.11,
          445.92,
          446.97,
          444.04,
          439.18,
          439.86,
          443.36,
          447.26,
          447.97,
          448.91,
          446.26,
          450.25,
          452.23,
          451.56,
          451.8,
          453.19,
          453.08,
          451.46,
          450.91,
          448.98,
          445.44,
          446.58,
          444.17,
          447.88,
          447.17,
          441.4
        ],
        put_premiums: [
          56.57,
          45.27,
          36.05,
          29.63,
          38.1,
          71.68,
          48.72,
          80.73,
          62.02,
          84.92,
          34.36,
          55.86,
          64.6,
          81.9,
          54.74,
          47.93,
          43.9,
          22.95,
          81.55,
          57.87,
          61.84,
          173.06,
          99.13,
          69.14,
          50.62,
          90.48,
          72.09,
          88.85,
          35.4,
          53.49,
          136.66,
          91.7,
          124.86,
          74.36,
          57.09,
          108.57,
          80.52,
          218.11,
          93.08,
          270.19,
          128.9,
          115.63,
          52.56,
          151.81,
          83.59,
          42.07,
          46.59,
          83.64,
          86.71,
          48.92,
          63.35,
          67.43,
          114.18,
          87.36,
          73.37,
          139.47,
          68.8,
          192.52,
          132.01,
          114.51,
          120.7,
          119.9,
          236.96,
          300.89,
          100.76,
          77.92,
          50.02,
          65.53,
          55.81,
          53.46,
          57.07,
          52.6,
          83.86,
          133.72,
          51.59,
          104.61,
          77,
          117.5,
          94.85,
          85.93,
          68.29,
          56.04,
          77.79,
          75.56,
          55.93,
          37.93,
          59.97,
          59.97,
          51.27,
          42.11,
          53.5,
          68.11,
          59.66,
          78.87,
          155.12,
          66.89,
          88.85,
          56.38,
          42.31,
          35.05,
          34.14,
          98.77,
          78.63,
          48.86,
          120.8,
          55.28,
          87.24,
          66.87,
          79.93,
          123.26,
          161.07,
          127.07,
          90.52,
          69.2,
          51.98,
          148.35,
          97.6,
          68.64,
          49.64,
          59.77,
          33.48,
          39.12,
          45.43,
          49.74,
          46.02,
          55.64,
          36.21,
          42.65,
          34.43,
          32.01,
          60.75,
          34.58,
          28.78,
          41.19,
          43.06,
          47.2,
          92.37,
          61.18,
          41.47,
          35.66,
          38.03,
          45.56,
          29.35,
          24.84,
          39.05,
          42.84,
          46.41,
          53.65,
          47.73,
          73.93,
          59.33,
          48.16,
          62.04,
          72.48,
          79.6,
          100.38,
          229.88,
          95.4,
          69.24,
          60.96,
          100.05,
          48.59,
          74.17,
          63.07,
          35.86,
          52.46,
          43.01,
          45.26,
          40.61,
          37.61,
          29.12,
          77.6,
          74.68,
          40.67,
          33.17,
          45.32,
          48.59,
          106.32,
          66.15,
          84.43,
          55.75,
          40.14,
          34.35,
          26.43,
          73.85,
          48.48,
          47.36,
          40.99,
          30.02,
          38.57,
          45.4,
          29.54,
          67.31,
          60.69,
          73.48,
          64.88,
          79.62,
          75.18,
          70.08,
          120.37
        ]
      });
    });

    this.$store.commit("setOptionsFlowMode", "live");

    this.largestCallPremiums = [
      {
        ticker: "TSLA",
        costBasis: 85635
      },
      {
        ticker: "AAPL",
        costBasis: 84784
      },
      {
        ticker: "SPY",
        costBasis: 73879
      },
      {
        ticker: "QQQ",
        costBasis: 31959
      },
      {
        ticker: "PANW",
        costBasis: 21504
      },
      {
        ticker: "JD",
        costBasis: 20822
      },
      {
        ticker: "FB",
        costBasis: 18385
      },
      {
        ticker: "MSFT",
        costBasis: 18015
      },
      {
        ticker: "IWM",
        costBasis: 17802
      },
      {
        ticker: "BABA",
        costBasis: 14177
      },
      {
        ticker: "WFC",
        costBasis: 12211
      },
      {
        ticker: "AMD",
        costBasis: 11799
      },
      {
        ticker: "IRNT",
        costBasis: 11722
      },
      {
        ticker: "LVS",
        costBasis: 11157
      },
      {
        ticker: "AMC",
        costBasis: 10766
      },
      {
        ticker: "AFRM",
        costBasis: 10372
      },
      {
        ticker: "NVDA",
        costBasis: 9015
      },
      {
        ticker: "PLTR",
        costBasis: 6407
      },
      {
        ticker: "XOP",
        costBasis: 6384
      },
      {
        ticker: "TQQQ",
        costBasis: 6074
      },
      {
        ticker: "MAR",
        costBasis: 6050
      },
      {
        ticker: "MRNA",
        costBasis: 6022
      },
      {
        ticker: "UBER",
        costBasis: 6010
      },
      {
        ticker: "X",
        costBasis: 5900
      },
      {
        ticker: "URA",
        costBasis: 5811
      },
      {
        ticker: "ATER",
        costBasis: 5660
      },
      {
        ticker: "GM",
        costBasis: 5569
      },
      {
        ticker: "SNOW",
        costBasis: 5537
      },
      {
        ticker: "TWTR",
        costBasis: 5190
      },
      {
        ticker: "CCJ",
        costBasis: 5156
      },
      {
        ticker: "KWEB",
        costBasis: 5059
      },
      {
        ticker: "BAC",
        costBasis: 4823
      },
      {
        ticker: "XLE",
        costBasis: 4748
      },
      {
        ticker: "AMZN",
        costBasis: 4529
      },
      {
        ticker: "BBIG",
        costBasis: 4083
      },
      {
        ticker: "EDIT",
        costBasis: 3926
      },
      {
        ticker: "GLD",
        costBasis: 3879
      },
      {
        ticker: "CSCO",
        costBasis: 3701
      },
      {
        ticker: "OXY",
        costBasis: 3669
      },
      {
        ticker: "QCOM",
        costBasis: 3643
      },
      {
        ticker: "CRC",
        costBasis: 3600
      },
      {
        ticker: "PAR",
        costBasis: 3577
      },
      {
        ticker: "BILL",
        costBasis: 3525
      },
      {
        ticker: "CROX",
        costBasis: 3250
      },
      {
        ticker: "ANY",
        costBasis: 3150
      },
      {
        ticker: "SPRT",
        costBasis: 2943
      },
      {
        ticker: "EEM",
        costBasis: 2924
      },
      {
        ticker: "PFE",
        costBasis: 2839
      },
      {
        ticker: "C",
        costBasis: 2648
      },
      {
        ticker: "BA",
        costBasis: 2540
      },
      {
        ticker: "SQ",
        costBasis: 2462
      },
      {
        ticker: "CCL",
        costBasis: 2457
      },
      {
        ticker: "TECK",
        costBasis: 2427
      },
      {
        ticker: "EWZ",
        costBasis: 2376
      },
      {
        ticker: "CMCSA",
        costBasis: 2354
      },
      {
        ticker: "WYNN",
        costBasis: 2235
      },
      {
        ticker: "XOM",
        costBasis: 2174
      },
      {
        ticker: "CRK",
        costBasis: 2146
      },
      {
        ticker: "CVS",
        costBasis: 2138
      },
      {
        ticker: "LI",
        costBasis: 2136
      },
      {
        ticker: "BX",
        costBasis: 2111
      },
      {
        ticker: "MU",
        costBasis: 2108
      },
      {
        ticker: "GLOB",
        costBasis: 2102
      },
      {
        ticker: "EXPE",
        costBasis: 2094
      },
      {
        ticker: "COIN",
        costBasis: 1997
      },
      {
        ticker: "ATVI",
        costBasis: 1993
      },
      {
        ticker: "FIVN",
        costBasis: 1961
      },
      {
        ticker: "PAGS",
        costBasis: 1955
      },
      {
        ticker: "REAL",
        costBasis: 1937
      },
      {
        ticker: "F",
        costBasis: 1925
      },
      {
        ticker: "DKNG",
        costBasis: 1821
      },
      {
        ticker: "SLV",
        costBasis: 1781
      },
      {
        ticker: "CLF",
        costBasis: 1741
      },
      {
        ticker: "ISEE",
        costBasis: 1682
      },
      {
        ticker: "TXN",
        costBasis: 1665
      },
      {
        ticker: "URNM",
        costBasis: 1611
      },
      {
        ticker: "SNAP",
        costBasis: 1601
      },
      {
        ticker: "XLF",
        costBasis: 1579
      },
      {
        ticker: "SOFI",
        costBasis: 1573
      },
      {
        ticker: "SBSW",
        costBasis: 1555
      },
      {
        ticker: "XBI",
        costBasis: 1552
      },
      {
        ticker: "FCEL",
        costBasis: 1549
      },
      {
        ticker: "XLV",
        costBasis: 1544
      },
      {
        ticker: "PLUG",
        costBasis: 1488
      },
      {
        ticker: "BIDU",
        costBasis: 1467
      },
      {
        ticker: "RCL",
        costBasis: 1463
      },
      {
        ticker: "TLT",
        costBasis: 1463
      },
      {
        ticker: "INTC",
        costBasis: 1449
      },
      {
        ticker: "KSS",
        costBasis: 1431
      },
      {
        ticker: "AUPH",
        costBasis: 1414
      },
      {
        ticker: "TCOM",
        costBasis: 1410
      },
      {
        ticker: "HUT",
        costBasis: 1377
      },
      {
        ticker: "GS",
        costBasis: 1353
      },
      {
        ticker: "GSAT",
        costBasis: 1348
      },
      {
        ticker: "UUUU",
        costBasis: 1327
      },
      {
        ticker: "LYV",
        costBasis: 1309
      },
      {
        ticker: "AIG",
        costBasis: 1285
      },
      {
        ticker: "ALB",
        costBasis: 1266
      },
      {
        ticker: "FINV",
        costBasis: 1233
      },
      {
        ticker: "TROX",
        costBasis: 1225
      },
      {
        ticker: "AR",
        costBasis: 1215
      },
      {
        ticker: "FCX",
        costBasis: 1209
      },
      {
        ticker: "COTY",
        costBasis: 1187
      },
      {
        ticker: "PINS",
        costBasis: 1182
      },
      {
        ticker: "CPLG",
        costBasis: 1181
      },
      {
        ticker: "TLRY",
        costBasis: 1170
      },
      {
        ticker: "DISCA",
        costBasis: 1164
      },
      {
        ticker: "HYRE",
        costBasis: 1163
      },
      {
        ticker: "DELL",
        costBasis: 1154
      },
      {
        ticker: "KBE",
        costBasis: 1152
      },
      {
        ticker: "NFLX",
        costBasis: 1151
      },
      {
        ticker: "FANG",
        costBasis: 1144
      },
      {
        ticker: "WDC",
        costBasis: 1138
      },
      {
        ticker: "ELY",
        costBasis: 1092
      },
      {
        ticker: "SDOW",
        costBasis: 1083
      },
      {
        ticker: "SPXS",
        costBasis: 1072
      },
      {
        ticker: "BILI",
        costBasis: 1063
      },
      {
        ticker: "GOLD",
        costBasis: 1037
      },
      {
        ticker: "ARKK",
        costBasis: 1035
      },
      {
        ticker: "COG",
        costBasis: 1034
      },
      {
        ticker: "TMC",
        costBasis: 1033
      },
      {
        ticker: "UAL",
        costBasis: 1031
      },
      {
        ticker: "LCID",
        costBasis: 1013
      },
      {
        ticker: "EOG",
        costBasis: 1011
      },
      {
        ticker: "MS",
        costBasis: 1011
      },
      {
        ticker: "PARR",
        costBasis: 1007
      },
      {
        ticker: "NIO",
        costBasis: 996
      },
      {
        ticker: "RBLX",
        costBasis: 988
      },
      {
        ticker: "ORCL",
        costBasis: 987
      },
      {
        ticker: "GDXJ",
        costBasis: 983
      },
      {
        ticker: "CLR",
        costBasis: 979
      },
      {
        ticker: "LRCX",
        costBasis: 975
      }
    ];

    this.largestPutPremiums = [
      {
        ticker: "SPY",
        costBasis: 138417
      },
      {
        ticker: "BABA",
        costBasis: 75319
      },
      {
        ticker: "QQQ",
        costBasis: 72413
      },
      {
        ticker: "TSLA",
        costBasis: 36345
      },
      {
        ticker: "IWM",
        costBasis: 33908
      },
      {
        ticker: "AAPL",
        costBasis: 22972
      },
      {
        ticker: "HOOD",
        costBasis: 20888
      },
      {
        ticker: "AMZN",
        costBasis: 10282
      },
      {
        ticker: "EEM",
        costBasis: 8767
      },
      {
        ticker: "WYNN",
        costBasis: 8454
      },
      {
        ticker: "SHOP",
        costBasis: 8379
      },
      {
        ticker: "C",
        costBasis: 7874
      },
      {
        ticker: "SPRT",
        costBasis: 7803
      },
      {
        ticker: "GOOG",
        costBasis: 7704
      },
      {
        ticker: "MS",
        costBasis: 6298
      },
      {
        ticker: "CHPT",
        costBasis: 5631
      },
      {
        ticker: "SE",
        costBasis: 5389
      },
      {
        ticker: "ZM",
        costBasis: 5284
      },
      {
        ticker: "NVDA",
        costBasis: 5245
      },
      {
        ticker: "EWZ",
        costBasis: 5204
      },
      {
        ticker: "X",
        costBasis: 5117
      },
      {
        ticker: "BEKE",
        costBasis: 5114
      },
      {
        ticker: "LVS",
        costBasis: 4897
      },
      {
        ticker: "CIEN",
        costBasis: 4650
      },
      {
        ticker: "BILL",
        costBasis: 4640
      },
      {
        ticker: "PYPL",
        costBasis: 4546
      },
      {
        ticker: "NIO",
        costBasis: 4514
      },
      {
        ticker: "AAL",
        costBasis: 4381
      },
      {
        ticker: "AMD",
        costBasis: 4218
      },
      {
        ticker: "FB",
        costBasis: 4072
      },
      {
        ticker: "XLE",
        costBasis: 4029
      },
      {
        ticker: "CAT",
        costBasis: 3976
      },
      {
        ticker: "URA",
        costBasis: 3905
      },
      {
        ticker: "TSM",
        costBasis: 3834
      },
      {
        ticker: "AMC",
        costBasis: 3652
      },
      {
        ticker: "TXG",
        costBasis: 3584
      },
      {
        ticker: "HYG",
        costBasis: 3535
      },
      {
        ticker: "SQ",
        costBasis: 3434
      },
      {
        ticker: "TWTR",
        costBasis: 3434
      },
      {
        ticker: "CMCSA",
        costBasis: 3428
      },
      {
        ticker: "AFRM",
        costBasis: 3313
      },
      {
        ticker: "CRWD",
        costBasis: 3184
      },
      {
        ticker: "MU",
        costBasis: 3101
      },
      {
        ticker: "T",
        costBasis: 3087
      },
      {
        ticker: "TLT",
        costBasis: 3060
      },
      {
        ticker: "UPS",
        costBasis: 3014
      },
      {
        ticker: "DOW",
        costBasis: 2990
      },
      {
        ticker: "ATER",
        costBasis: 2886
      },
      {
        ticker: "XRT",
        costBasis: 2882
      },
      {
        ticker: "BA",
        costBasis: 2820
      },
      {
        ticker: "JD",
        costBasis: 2818
      },
      {
        ticker: "PBR",
        costBasis: 2594
      },
      {
        ticker: "ORCL",
        costBasis: 2537
      },
      {
        ticker: "UBER",
        costBasis: 2456
      },
      {
        ticker: "GLD",
        costBasis: 2436
      },
      {
        ticker: "TDOC",
        costBasis: 2378
      },
      {
        ticker: "XLI",
        costBasis: 2327
      },
      {
        ticker: "BAC",
        costBasis: 2284
      },
      {
        ticker: "PLUG",
        costBasis: 2237
      },
      {
        ticker: "MSFT",
        costBasis: 2231
      },
      {
        ticker: "WDC",
        costBasis: 2226
      },
      {
        ticker: "XBI",
        costBasis: 2155
      },
      {
        ticker: "INTC",
        costBasis: 2131
      },
      {
        ticker: "PLTR",
        costBasis: 2100
      },
      {
        ticker: "IRNT",
        costBasis: 2073
      },
      {
        ticker: "SNAP",
        costBasis: 2070
      },
      {
        ticker: "FXI",
        costBasis: 2003
      },
      {
        ticker: "BYND",
        costBasis: 1961
      },
      {
        ticker: "KWEB",
        costBasis: 1819
      },
      {
        ticker: "GDX",
        costBasis: 1817
      }
    ];

    var tableHeaders = document.getElementsByClassName("header-container");
    var observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting == true) {
          if (this.tableTriggered == false) {
            setTimeout(function() {
              tableHeaders[tableHeaders.length - 1].click();
            }, 500);
            setTimeout(function() {
              tableHeaders[4].click();
            }, 2000);
            this.tableTriggered = true;
            setTimeout(() => {
              this.tableTriggered = false;
            }, 5000);
          }
        }
      },
      { threshold: 0.7 }
    );

    observer.observe(
      document.getElementsByClassName("options-table-container")[0]
    );

    document.getElementsByClassName(
      "table-controls-container"
    )[0].style.visibility = "hidden";
    document.getElementsByClassName(
      "table-controls-container"
    )[0].style.height = "0px";
    document.getElementsByClassName(
      "table-controls-container"
    )[1].style.visibility = "hidden";
    document.getElementsByClassName(
      "table-controls-container"
    )[1].style.height = "0px";
  }
};
</script>

<style lang="scss" scoped>
$aos-distance: 30px;
@import "node_modules/aos/src/sass/aos.scss";

svg {
  width: 100%;
}

rect {
  fill: none;
  stroke: #ccc;
  stroke-width: 5px;
  stroke-dasharray: 1500;
  stroke-dashoffset: 2000;
  animation: border 2s forwards;
}

line {
  stroke: #ccc;
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: lines 2s forwards;
}

path {
  fill: #ccc;
  opacity: 0;
  animation: fade 3s forwards;
  animation-delay: 1.2s;
}

@keyframes border {
  from {
    stroke-dashoffset: 2000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes lines {
  from {
    stroke-dashoffset: 400;
  }
  to {
    stroke-dashoffset: -400;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    fill: rgb(255, 255, 255);
  }
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

.landing {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
}

.overview {
  width: 70%;
  margin: 80px 0 0px 0;
  font-family: Nunito-Light;
  font-size: 30px;
  color: #fff;
  p {
    margin-bottom: 20px;
    font-size: 25px;
  }
}

.overview.chart img {
  width: 80%;
}

.options-table-container {
  margin-top: 50px;
  width: 80%;
  color: #fff;
  font-family: Nunito-Light;
  p:first-child {
    margin-bottom: 5px;
    font-size: 25px;
  }
  p:nth-child(2) {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

.price-chart-container,
.largest-premiums-container {
  margin-top: 60px;
  color: #fff;
  font-family: Nunito-Light;
  width: 70%;
  p {
    margin-bottom: 10px;
    font-size: 25px;
  }
}

.largest-premiums-container {
  width: 80%;
  p:first-child {
    margin-bottom: 5px;
    font-size: 25px;
  }
  p:nth-child(2) {
    margin-bottom: 20px;
    font-size: 17px;
  }
}

.largest-premiums-sub-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  width: 100%;
  padding: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-title {
  margin-top: 60px;
  font-family: Chivo-Regular;
  font-size: 45px;
  display: flex;
  justify-content: center;
}

.page-intro {
  font-family: Nunito-Light;
  width: 70%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  color: #fff;
}

.header-gradient {
  color: transparent;
  font-size: 55px;
  font-family: Chivo-Regular;
  position: relative;
  top: -210px;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(270deg, #e92254 10%, rgb(177, 89, 236) 80%);
  //background-image: linear-gradient(270deg, #f582ff 20%, #767aff 80%);
}

.header-span {
  color: transparent;
  font-size: 55px;
  font-family: Chivo-Regular;
  position: relative;
  color: #fff;
  top: -200px;
}

.cards-container {
  width: 40%;
  height: 550px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-top: 50px;
}

.card-sub-container {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cards {
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 5px;
}

.cards .card-sub-container:nth-child(2) {
  justify-content: flex-start;
}

.column-container {
  width: 50%;
  height: inherit;
}

.cards-title {
  margin-top: 10px;
  font-size: 22px;
  font-family: Nunito;
  color: #fff;
}

.cards-circle {
  width: 70px;
  height: 70px;
  display: -webkit-inline-box;
  display: inline-flex;
  border-radius: 50%;
  background: radial-gradient(
    farthest-corner at 90px 60px,
    #e92254 5%,
    rgb(241, 84, 255) 40%,
    #7e0eff 80%
  );
  background: linear-gradient(90deg, #767aff 30%, #c176ff 50%, #ea30ff 70%);
  background: linear-gradient(90deg, #767aff 30%, #f476ff 70%);
}

.strategic-container {
  height: 450px;
  width: 100%;
  display: flex;
  justify-content: center;
  &.first {
    margin-top: -100px;
  }
}

.strategic-text-container {
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  min-width: 400px;
  color: rgb(46, 45, 45);
  height: 400px;
  width: 35%;
  background-color: #fff; // rgba(29, 29, 29, 1);
  align-self: flex-end;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.strategic-text-container span:first-child {
  font-size: 25px;
  font-family: Nunito;
}

.strategic-text-container span {
  margin: 10px 0;
}

.strategic-graph-container {
  min-width: 400px;
  height: 400px;
  width: 35%;
  //background: linear-gradient(160deg, rgb(22, 22, 22), rgb(24, 24, 24));
  //background-color: rgb(22, 22, 22);
  align-self: flex-start;
  // -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  &.second {
    align-self: center;
    margin-top: 30px;
  }
}

.subscription-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.isometric-img {
  height: 450px;
  margin-top: -10px;
  opacity: 1;
}

@media only screen and (max-width: 1000px) {
  .header-gradient {
    font-size: 45px;
    top: -200px;
  }

  .header-span {
    font-size: 45px;
    top: -190px;
  }

  .strategic-container {
    flex-wrap: wrap;
    height: 850px;
  }

  .largest-premiums-container,
  .options-table-container,
  .price-chart-container,
  .strategic-graph-container,
  .strategic-text-container {
    width: 90%;
  }

  .cards-container {
    width: 80%;
  }

  .overview {
    width: 90%;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1000px) {
  .overview.chart img {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .largest-premiums-sub-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 800px) {
  @keyframes lines {
    from {
      stroke-dashoffset: -400;
    }
    to {
      stroke-dashoffset: 400;
    }
  }

  .header-span {
    font-size: 40px;
    top: -190px;
  }
  .header-gradient {
    font-size: 40px;
    top: -200px;
  }
}
</style>
