import Vue from "vue";
import VueRouter from "vue-router";
//import axios from "axios";
import ShortData from "../views/ShortData.vue";
import OptionWalls from "../views/OptionWalls.vue";
import DarkPools from "../views/DarkPools.vue";
import Clusters from "../views/Clusters.vue";
import ClustersTable from "../views/ClustersTable.vue";
import SectorRotation from "../views/SectorRotation.vue";
import SectorPerformance from "../views/SectorPerformance.vue";
//import MomentumClusters from "../views/MomentumClusters.vue";
import Privacy from "../components/Privacy.vue";
import Terms from "../components/Terms.vue";
import Landing from "../views/Landing.vue";
import SubscriptionSuccess from "../views/SubscriptionSuccess.vue";
import SubscriptionCards from "../components/SubscriptionCards.vue";
import Subscription from "../views/Subscription.vue";
import OptionsFlow from "../views/OptionsFlow.vue";
import OptionsFlowCumulativeStats from "../views/OptionsFlowCumulativeStats.vue";
import OptionsFlowTopPremiums from "../views/OptionsFlowTopPremiums.vue";
import OptionsFlowTickerOverview from "../views/OptionsFlowTickerOverview.vue";
import OptionsFlowDashboard from "../views/OptionsFlowDashboard.vue";
import PageNotFound from "../views/PageNotFound.vue";
import Profile from "../views/Profile.vue";
import SPYToVIX from "../views/SPYToVIX.vue";
import SP500Ratios from "../views/SP500Ratios.vue";
import FactorAnalysis from "../views/FactorAnalysis.vue";
import FactorAnalysisTable from "../views/FactorAnalysisTable.vue";
import TSArima from "../views/TSArima.vue";

import Test from "../views/Test.vue";
import Ads from "../components/Ads.vue";

//import SiteMap from "../components/SiteMap.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/shortinterest",
  //   name: "shortinterest",
  //   component: ShortData
  // },
  // {
  //   path: "/shortinterest/:ticker",
  //   name: "shortinterest",
  //   component: ShortData
  // },
  {
    path: "/optionwalls",
    name: "optionwalls",
    component: OptionWalls
  },
  {
    path: "/darkpools",
    name: "darkpools",
    component: DarkPools
  },
  {
    path: "/darkpools/:ticker",
    name: "darkpools",
    component: DarkPools
  },
  {
    path: "/clusters",
    name: "clusters",
    component: Clusters
  },
  {
    path: "/clusters/:ticker",
    name: "clusters",
    component: Clusters
  },
  {
    path: "/",
    name: "landing",
    component: DarkPools
  },
  {
    path: "/confirm/:token",
    name: "confirm",
    component: Clusters
  },
  {
    path: "/confirmpassword/:password_token",
    name: "confirmpassword",
    component: Clusters
  },
  {
    path: "/clusterstable",
    name: "clusterstable",
    component: ClustersTable
  },
  {
    path: "/Sectorrotation",
    name: "Sectorrotation",
    component: SectorRotation
  },
  {
    path: "/Sectorperformance",
    name: "Sectorperformance",
    component: SectorPerformance
  },
  {
    path: "/landing",
    name: "landing",
    component: DarkPools
  },
  {
    path: "/subscriptionsuccess",
    name: "subscriptionsuccess",
    component: SubscriptionSuccess
  },
  {
    path: "/subscription",
    name: "subscription",
    component: Subscription
  },
  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },
  {
    path: "/terms",
    name: "terms",
    component: Terms
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile
  },
  {
    path: "/subscriptioncards",
    name: "subscriptioncards",
    component: SubscriptionCards
  },
  {
    path: "/optionsflow",
    name: "optionsflow",
    component: OptionsFlow
  },
  {
    path: "/optionsflowcumulativestats",
    name: "optionsflowcumulativestats",
    component: OptionsFlowCumulativeStats
  },
  {
    path: "/optionsflowcumulativestats/:ticker",
    name: "optionsflowcumulativestats",
    component: OptionsFlowCumulativeStats
  },
  {
    path: "/optionsflowtoppremiums",
    name: "optionsflowtoppremiums",
    component: OptionsFlowTopPremiums
  },
  {
    path: "/optionsflow-ticker-overview",
    name: "optionsflow-ticker-overview",
    component: OptionsFlowTickerOverview
  },
  {
    path: "/optionsflow-dashboard",
    name: "optionsflow-dashboard",
    component: OptionsFlowDashboard
  },
  {
    path: "/optionsflow-dashboard/:ticker",
    name: "optionsflow-dashboard",
    component: OptionsFlowDashboard
  },
  {
    path: "/test",
    name: "test",
    component: Test
  },
  {
    path: "/reddit-ads",
    name: "reddit-ads",
    component: Ads
  },
  {
    path: "/spytovix",
    name: "spytovix",
    component: SPYToVIX
  },
  {
    path: "/arima",
    name: "arima",
    component: TSArima
  },
  {
    path: "/factor-analysis",
    name: "factor-analysis",
    component: FactorAnalysis
  },
  {
    path: "/factor-analysis/:factor/:timeframe/:ticker",
    name: "factor-analysis",
    component: FactorAnalysis
  },
  {
    path: "/factor-analysis-table",
    name: "factor-analysis-table",
    component: FactorAnalysisTable
  },
  {
    path: "/sp500stats",
    name: "sp500stats",
    component: SP500Ratios
  },
  // {
  //   path: "/sitemap",
  //   name: "sitemap",
  //   component: SiteMap
  // }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue")
  // }
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
