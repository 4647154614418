<template>
  <div class="price-chart">
    <v-chart class="chart" autoresize ref="priceChart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "DarkPoolsVolumeChart",
  props: {
    darkPoolIndividualData: Object
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "12%",
            bottom: "50%",
            left: 100,
            right: 100,
            height: "32%"

            //bottom: "3%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "58%",
            bottom: "8%",
            left: 100,
            right: 100,
            height: "32%"

            //bottom: "3%"
          }
        ],
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              xAxisIndex: [0, 1]
            },
            // magicType: {
            //   type: ["line"]
            // },
            // restore: {},
            saveAsImage: {}
          },
          right: 30,
          top: 10,
          emphasis: {
            iconStyle: {
              color: "rgba(186, 116, 227, 0.8)",
              borderColor: "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: [
          {
            text: "Price vs Short Volume",
            left: "30",
            top: "3%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          },
          {
            text: "Rolling 20-Day Position",
            left: "30",
            top: "49%",
            textStyle: {
              fontFamily: "Chivo-Regular",
              fontWeight: "lighter",
              color: "#ffffff",
              fontSize: 20
            }
          }
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            animation: false,
            label: {
              show: false,
              backgroundColor: "#282829",
              fontSize: 15,
              precision: 0
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        axisPointer: {
          link: { xAxisIndex: [0, 1] }
        },
        xAxis: [
          {
            type: "category",
            gridIndex: 0,
            boundaryGap: true,
            data: this.darkPoolIndividualData["prices"]["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "category",
            gridIndex: 1,
            boundaryGap: true,
            data: this.darkPoolIndividualData[
              "individual_dark_pool_position_data"
            ]["dates"],
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        yAxis: [
          {
            type: "value",
            name: "Volume 000s",
            show: true,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            min: function(value) {
              return value.min;
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Price",
            min: function(value) {
              return value.min;
            },
            max: function(value) {
              return value.max;
            },
            show: true,
            gridIndex: 0,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Net Short Volume $ 000s",
            show: true,
            gridIndex: 1,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          },
          {
            type: "value",
            name: "Position $M",
            show: true,
            gridIndex: 1,
            nameTextStyle: {
              fontSize: 14
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(234, 234, 234, 0.1)",
                width: 1
              }
            }
          }
        ],
        series: [
          {
            data: this.darkPoolIndividualData["prices"]["prices"],
            xAxisIndex: 0,
            yAxisIndex: 1,
            name: "Price",
            type: "line",
            showAllSymbol: false,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(109, 89, 122, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(234, 3, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(130, 123, 135, 1)" },
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.darkPoolIndividualData["individual_short_volume"][
              "total_volume"
            ],
            xAxisIndex: 0,
            yAxisIndex: 0,
            name: "Total Volume 000s (FINRA)",
            type: "bar",
            barGap: "-100%",
            itemStyle: { color: "rgba(103, 34, 148, 1)" }
          },
          {
            data: this.darkPoolIndividualData["individual_short_volume"][
              "short_volume"
            ],
            xAxisIndex: 0,
            yAxisIndex: 0,
            name: "Short Volume 000s",
            type: "bar",
            barGap: "-100%",
            itemStyle: { color: "rgba(0, 187, 255, 1)" }
          },
          {
            data: this.darkPoolIndividualData["individual_short_volume"][
              "net_volume"
            ],
            xAxisIndex: 0,
            yAxisIndex: 0,
            name: "Net Short Volume 000s",
            type: "bar",
            barGap: "-100%",
            itemStyle: { color: "rgba(0, 255, 213, 1)" }
          },
          {
            data: this.darkPoolIndividualData[
              "individual_dark_pool_position_data"
            ]["dollar_dp_position"],
            name: "Position $M",
            type: "line",
            showAllSymbol: false,
            xAxisIndex: 1,
            yAxisIndex: 3,
            areaStyle: {
              color: new graphic.LinearGradient(1, 0, 1, 1, [
                {
                  offset: 1,
                  color: "rgba(88, 182, 219, 0.2)"
                },
                {
                  offset: 0,
                  color: "rgba(46, 196, 255, 0.2)"
                }
              ])
            },
            lineStyle: { color: "rgba(100, 191, 227, 0.7)" },
            itemStyle: { color: "rgba(100, 191, 227, 0.7)" }
          },
          {
            data: this.darkPoolIndividualData[
              "individual_dark_pool_position_data"
            ]["dollar_net_volume"],
            name: "Net $ Volume 000s",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 2,
            barGap: "-100%",
            itemStyle: { color: "rgba(53, 216, 252, 1)" }
          }
        ]
      };

      if (window.innerWidth < 850) {
        (this.options["grid"] = [
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "12%",
            bottom: "50%",
            left: 40,
            right: 20,
            height: "32%"

            //bottom: "3%"
          },
          {
            backgroundColor: "rgba(26, 217, 204, 1)",
            show: false,
            top: "58%",
            bottom: "8%",
            left: 40,
            right: 20,
            height: "32%"

            //bottom: "3%"
          }
        ]),
          (this.options["toolbox"]["show"] = false);
        this.options["yAxis"][0]["show"] = false;
        this.options["yAxis"][1]["show"] = false;
        this.options["yAxis"][2]["show"] = false;
        this.options["yAxis"][3]["show"] = false;
        this.options["tooltip"]["confine"] = true;
      }

      this.$refs.priceChart.setOption(this.options);
    }
  },
  watch: {
    darkPoolIndividualData: function() {
      if (Object.keys(this.darkPoolIndividualData).length > 0) {
        this.setChart();
      }
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
    // var reload = () => {
    //   console.log("destroy");
    //   setTimeout(reload, 500);
    //   this.setChart();
    // };
    // setTimeout(reload, 3000);
  }
};
</script>

<style scoped>
.chart {
  height: 900px;
  width: 100%;
}

.price-chart {
  width: 100%;
}
</style>
