<template>
  <div class="ts-control">
    <div class="grid-container">
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Autoregression (p)
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="p"
          :default="{
            value: 1,
            label: 1
          }"
          :options="[
            { value: 0, label: 0 },
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Integatred (d)
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="d"
          :default="{
            value: 1,
            label: 1
          }"
          :disabled="dDisabled"
          :options="[
            { value: 0, label: 0 },
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Moving Average (q)
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="q"
          :default="{
            value: 1,
            label: 1
          }"
          :options="[
            { value: 0, label: 0 },
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Trend
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="trend"
          :default="{
            value: 2,
            label: 't'
          }"
          :options="[
            { value: 0, label: 'n' },
            { value: 1, label: 'c' },
            { value: 2, label: 't' },
            { value: 3, label: 'ct' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Enforce Stationarity
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="stationarity"
          :default="{
            value: 1,
            label: 'True'
          }"
          :options="[
            { value: 0, label: 'False' },
            { value: 1, label: 'True' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>

      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Trend Offset
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="offset"
          :default="{
            value: 1,
            label: 1
          }"
          :options="[
            { value: 0, label: 0 },
            { value: 1, label: 1 },
            { value: 2, label: 2 },
            { value: 3, label: 3 },
            { value: 4, label: 4 },
            { value: 5, label: 5 }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Enforce Invertibility
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="invertibility"
          :default="{
            value: 1,
            label: 'True'
          }"
          :options="[
            { value: 0, label: 'False' },
            { value: 1, label: 'True' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
      <div class="grid-item">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag">
          Concentrate Scale
        </b-tag>
      </div>
      <div class="grid-item">
        <SelectComponent
          v-model="concentrate"
          :options="[
            { value: 0, label: 'False' },
            { value: 1, label: 'True' }
          ]"
          :backgroundColor="backgroundColor"
        >
        </SelectComponent>
      </div>
    </div>

    <hr />
    <button class="datatable-button" v-on:click="updateModel()">
      Update
    </button>
  </div>
</template>

<script>
const SelectComponent = () => import("@/components/SelectComponent.vue");

export default {
  name: "TSArimaControl",
  data() {
    return {
      backgroundColor: "rgb(34, 33, 33)",
      color: "rgb(177, 115, 247)",
      dDisabled: false,
      p: 1,
      d: 1,
      q: 1,
      trend: 2,
      stationarity: 1,
      invertibility: 1,
      offset: 1,
      concentrate: 0,
      mapping: {
        trend: { 0: "n", 1: "c", 2: "t", 3: "ct" }
      }
    };
  },
  components: {
    SelectComponent
  },
  watch: {
    trend: function() {
      if (
        this.mapping["trend"][this.trend] == "c" ||
        this.mapping["trend"][this.trend] == "ct"
      ) {
        this.d = 0;
        this.dDisabled = true;
      } else {
        this.dDisabled = false;
      }
      if (this.mapping["trend"][this.trend] == "t") {
        this.d = 1;
      }
    },
    d: function() {
      if (this.d > 1) {
        this.trend = 0;
      }
    }
  },
  methods: {
    updateModel: function() {
      console.log(this.offset, this.concentrate, this.invertibility);
      this.$emit(
        "updateModel",
        this.p,
        this.d,
        this.q,
        this.mapping["trend"][this.trend],
        this.stationarity,
        this.invertibility,
        this.offset,
        this.concentrate
      );
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.ts-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-container {
  width: 100%;
  padding: 10px 10px 0 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 0 10px;
}

.grid-item {
  width: 100%;
  text-align: left;
  height: 50px;
  padding: 5px 0 5px 0;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.datatable-tag {
  background-color: #222121 !important;
}

.datatable-tag p {
  word-break: break-all;
}

.datatable-button {
  display: inline-block;
  box-sizing: border-box;
  margin: 10px 0px;
  background-color: #222121;
  padding: 0px 0px;
  font-family: var(--main-font);
  color: var(--purple-darker);
  font-size: 15px;
  border: none;
  border-radius: 0px;
  box-shadow: 0 -1px 0 var(--purple-darker) inset;
  width: 150px;
  height: 38px;
}

.datatable-button:active {
  color: var(--purple-darker2);
  box-shadow: 0 -1px 0 var(--purple-darker2) inset;
}

.datatable-button:hover {
  background-color: var(--app-components-background-color-lighter);
}

hr {
  background-color: rgb(37, 37, 37);
  width: 80%;
  display: block;
  border: none;
  height: 0.1px;
  margin: 5px 0 0 0;
}

@media only screen and (max-width: 1175px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 750px) {
  .grid-container {
    padding: 10px 5px 0 5px;
    grid-gap: 0 5px;
  }
}

@media only screen and (max-width: 700px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
