<template>
  <div class="subscription-success">
    <div class="main">
      <h1>Thank you for subscribing to Stockgrid!</h1>
      <br /><br />
      If you run into an issue, please send an email to info@stockgrid.io. Make
      sure that you enable cookies and scripts for Stockgrid as they are<br />
      required for authenticaton as well as processing charts and tables data.
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionSuccess",
  data() {
    return {};
  },
  methods: {},
  components: {},
  mounted() {
    this.$store.dispatch({ type: "getUserInfo" });
    window.gtag("event", "conversion", {
      send_to: "AW-1002706607/zEF0CLn9ggQQr62Q3gM",
      value: 1.0,
      currency: "CAD"
    });
  },
  created() {
    //console.log("route", this.$router);
  }
};
</script>

<style lang="scss" scoped>
.subscription-success {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.main {
  color: #fff;
  font-family: Nunito;
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 20px;
  padding: 50px;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-family: Nunito;
  color: #fff;
  font-size: 30px;
}

@media only screen and (max-width: 850px) {
  .main {
    width: 90%;
  }
}
</style>
