<template>
  <div class="options-flow-top-chart">
    <v-chart class="options-chart" :class="type" autoresize ref="chart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "OptionsFlowTop",
  props: {
    largestPremiums: Array,
    type: String
    //height: Number
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(29, 29, 29, 1)",
          show: false,
          top: 130,
          bottom: 40,
          left: "35",
          right: "35",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: this.type == "calls" ? "Top Call Premiums" : "Top Put Premiums",
          left: "30",
          top: 50,
          textStyle: {
            fontFamily: "Nunito-Light",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 18
          }
        },
        tooltip: {
          trigger: "axis",
          confine: false,
          formatter: function(params) {
            var colorSpan = color =>
              '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' +
              color +
              '"></span>';
            return (
              colorSpan(params[0].borderColor.colorStops[0].color) +
              params[0].data.ticker +
              "&nbsp;&nbsp;" +
              "$" +
              params[0].data.costBasis
            );
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(35, 35, 35, 0.2)"
            },
            animation: false,
            label: {
              backgroundColor: "rgba(23, 24, 26, 0.95)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none"
            // },
            magicType: {
              type: ["line", "bar"]
            },
            saveAsImage: {}
          },
          right: 35,
          top: 15,
          emphasis: {
            iconStyle: {
              color:
                this.type == "calls"
                  ? "rgba(10, 252, 224, 0.8)"
                  : "rgba(186, 116, 227, 0.8)",
              borderColor:
                this.type == "calls"
                  ? "rgba(10, 252, 224, 0.8)"
                  : "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "value",
          logBase: 10,
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(50, 50, 50, 0.2)",
              width: 1
            }
          },
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: "category",
          name: "Premiums 000s",
          show: true,
          nameTextStyle: {
            fontFamily: "Nunito-Light",
            fontSize: 16,
            padding: [null, null, null, 70]
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(50, 50, 50, 0.2)",
              width: 1
            }
          }
        },
        dataset: {
          source: this.sortedPremiums
        },
        series: {
          name: "Premiums",
          type: "bar",
          label: {
            verticalAlign: "top"
          },
          barWidth: 25,
          dimensions: ["ticker", "costBasis"]
        }
      };

      if (this.type == "calls") {
        this.options["series"]["itemStyle"] = {
          color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
            },
            {
              offset: 0,
              color: "rgba(10, 252, 180, 0.3)"
            }
          ]),
          borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(5, 218, 255, 1)"
            },
            {
              offset: 0,
              color: "rgba(10, 255, 223, 1)"
            }
          ])
        };
      } else {
        this.options["series"]["itemStyle"] = {
          color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
            },
            {
              offset: 0,
              color: "rgba(217, 79, 255, 0.2)"
            }
          ]),
          borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(136, 77, 245, 1)"
            },
            {
              offset: 0,
              color: "rgba(217, 79, 255, 1)"
            }
          ])
        };
      }
      if (window.innerWidth < 850) {
        this.options["xAxis"]["axisLabel"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["grid"] = {
          backgroundColor: "rgba(29, 29, 29, 1)",
          show: false,
          top: 100,
          bottom: 40,
          left: "10",
          right: "0",
          containLabel: true
          //bottom: "3%"
        };
        this.options["title"]["textStyle"]["fontSize"] = 17;
        this.options["title"]["top"] = 40;
        this.options["title"]["text"] =
          this.type == "calls" ? "Top Calls" : "Top Puts";
        this.options["toolbox"]["right"] = 3;
        this.options["toolbox"]["top"] = 8;
        //this.options["toolbox"]["show"] = false;
      }

      this.$refs.chart.setOption(this.options);
    },
    setHeight: function() {
      this.height = Math.max(this.sortedPremiums.length, 1);
      document.getElementsByClassName(
        "options-chart " + String(this.type)
      )[0].style.height = String(this.height * 35 + 275) + "px";
    }
  },
  watch: {
    largestPremiums: function() {
      this.sortedPremiums = [...this.largestPremiums];
      if (this.sortedPremiums.length < 30) {
        this.sortedPremiums = this.sortedPremiums.concat(
          Array(30 - this.sortedPremiums.length)
            .fill()
            .map((_, i) => ({
              ticker: i + this.sortedPremiums.length,
              costBasis: -i / 100 //-i - 1 / 100
            }))
        );
      }
      this.sortedPremiums = this.sortedPremiums.sort(
        (a, b) => a.costBasis - b.costBasis
      );
      this.sortedPremiums = this.sortedPremiums.slice(
        this.sortedPremiums.length - 30,
        this.sortedPremiums.length
      );

      if (
        this.$refs.chart.getOption() == undefined ||
        this.$refs.chart.getOption().series.length == 0
      ) {
        this.setChart();
      } else {
        this.options.dataset.source = this.sortedPremiums;
        this.$refs.chart.setOption(this.options);
      }
      //this.setHeight();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
    // this.$watch(
    //   vm => [vm.sortedPremiums, vm.height],
    //   val => {
    //     if (val[0] && val[1]) {
    //       this.setChart();
    //     }
    //   },
    //   {
    //     immediate: true, // run immediately
    //     deep: true // detects changes inside objects. not needed here, but maybe in other cases
    //   }
    // );
  }
};
</script>

<style scoped>
.options-chart {
  width: 100.1%;
  height: 1200px;
}

.options-flow-top-chart {
  width: 50%;
}

@media only screen and (max-width: 850px) {
  .options-flow-top-chart {
    width: 100%;
  }
}
</style>
