<template>
  <div class="options-flow-sa">
    <h1 class="page-title">Options flow / Cumulative Stats</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
    </div>
    <div class="price-chart-container">
      <OptionsFlowCumulativeStatsChart></OptionsFlowCumulativeStatsChart>
    </div>
    <div class="price-chart-container">
      <OptionsFlowByStrikeChart></OptionsFlowByStrikeChart>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OptionsFlowCumulativeStatsChart from "@/components/OptionsFlowCumulativeStatsChart.vue";
import OptionsFlowByStrikeChart from "@/components/OptionsFlowByStrikeChart.vue";
import TickerInfo from "@/components/TickerInfo.vue";
import { eventBus } from "../main";
export default {
  name: "OptionsFlowCumulativeStats",
  data() {
    return {
      ticker: "SPY"
    };
  },
  components: {
    OptionsFlowCumulativeStatsChart,
    OptionsFlowByStrikeChart,
    TickerInfo
  },
  head: {
    title: {
      inner: "Cumulative Stats"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Analyse the outstanding and cumulative premiums among large option trades to detect profitable patterns."
      }
    ]
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      loginEvent: "getLoginEvent",
      alertMessage: "getAlertMessage",
      user: "getUserInfo"
    })
  },
  methods: {
    init: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getOptionsFlowByStrikeData",
        ticker: this.ticker,
        lookback: 20
      });
      this.$store.dispatch({
        type: "getOptionsFlowHistoricalPremiumData",
        ticker: this.ticker,
        lookback: 20
      });
    }
  },
  watch: {
    user: function() {
      console.log("loginEvent", this.loginEvent, this.user);
      if (this.loginEvent == true) {
        if (this.user.subscription == "basic") {
          this.init();
        }
      }
    }
  },
  mounted() {
    if (
      this.user == undefined ||
      this.user.subscription == undefined ||
      this.user.subscription == "" ||
      this.user.subscription == "free" ||
      this.user.subscription == "Free"
    ) {
      this.$router.push({
        path: "/"
      });
    } else {
      //If the url param is not empty, the ticker will be equal to the ticker passed in the url
      if (typeof this.$router.history.current.params["ticker"] != "undefined") {
        this.ticker = this.$router.history.current.params[
          "ticker"
        ].toUpperCase();
      }
      this.init();
    }

    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "More info") {
        setTimeout(() => {
          this.$router.push({
            path: "/subscriptioncards"
          });
        }, 30);
      }
    });
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.options-flow-sa {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.price-chart-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 5px;
  width: 70%;
}

.page-title {
  width: 70%;
}

.ticker-info {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1250px) {
  .price-chart-container,
  .ticker-info-container,
  .page-title {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .price-chart-container,
  .ticker-info-container,
  .page-title {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
}
</style>
