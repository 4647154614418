<template>
  <div class="options-flow-max-change-chart">
    <v-chart class="options-chart" autoresize ref="chart" />
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";

export default {
  name: "OptionsFlowMaxChangeChart",
  props: {
    data: Object,
    putCall: String,
    feature: String
  },
  data() {
    return {
      options: {}
    };
  },
  methods: {
    setChart: function() {
      this.options = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(29, 29, 29, 1)",
          show: false,
          top: 130,
          bottom: 40,
          left: "20",
          right: "45",
          containLabel: true
          //bottom: "3%"
        },
        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text:
            this.putCall == "calls"
              ? "Large Flow Call " +
                this.feature.charAt(0).toUpperCase() +
                this.feature.slice(1) +
                " vs Average"
              : "Large Flow Put " +
                this.feature.charAt(0).toUpperCase() +
                this.feature.slice(1) +
                " vs Average",
          left: "30",
          top: 50,
          textStyle: {
            fontFamily: "Nunito-Light",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 18
          }
        },
        tooltip: {
          trigger: "axis",
          confine: false,
          axisPointer: {
            animation: false,
            type: "shadow",
            shadowStyle: {
              shadowBlur: 0,
              color: "rgba(100, 100, 100, 0.1)"
            },

            // lineStyle: {
            //   color: '#376df4',
            //   width: 2,
            //   opacity: 1
            // }
            //}
            label: {
              backgroundColor: "rgba(25, 25, 25, 0.95)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        toolbox: {
          show: true,
          feature: {
            // dataZoom: {
            //   yAxisIndex: "none"
            // },
            magicType: {
              type: ["line", "bar"]
            },
            saveAsImage: {}
          },
          right: 35,
          top: 15,
          emphasis: {
            iconStyle: {
              color:
                this.putCall == "calls"
                  ? "rgba(10, 252, 224, 0.8)"
                  : "rgba(186, 116, 227, 0.8)",
              borderColor:
                this.putCall == "calls"
                  ? "rgba(10, 252, 224, 0.8)"
                  : "rgba(186, 116, 227, 0.8)"
            }
          }
        },
        xAxis: {
          type: "value",
          logBase: 10,
          min: 0,
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(50, 50, 50, 0.2)",
              width: 1
            }
          },
          axisLabel: {
            show: true
          }
        },
        yAxis: {
          type: "category",
          name: this.feature === "premiums" ? "Premiums 000s $" : "Volume",
          data: this.data["tickers"],
          show: true,
          nameTextStyle: {
            fontFamily: "Nunito-Light",
            fontSize: 16,
            padding: [null, null, null, 70]
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(50, 50, 50, 0.2)",
              width: 1
            }
          }
        },
        series: [
          {
            name:
              this.feature === "premiums"
                ? "Average Premiums 000s $"
                : "Average Large Flow Volume",
            data: this.data["average"],
            type: "bar",
            label: {
              verticalAlign: "top"
            }
          },
          {
            name:
              this.feature === "premiums"
                ? "Premiums 000s $"
                : "Large Flow Volume",
            data: this.data["current"],
            type: "bar",
            label: {
              verticalAlign: "top"
            }
          }
        ]
      };

      this.options["series"][0]["itemStyle"] = {
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 1,
            color: "rgba(59, 59, 59, 0.4)" //"rgba(221, 40, 237, 0.3)"
          },
          {
            offset: 0,
            color: "rgba(59, 59, 59, 0.3)"
          }
        ]),
        borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 1,
            color: "rgba(59, 59, 59, 1)"
          },
          {
            offset: 0,
            color: "rgba(59, 59, 59, 1)"
          }
        ])
      };

      if (this.putCall == "calls") {
        this.options["series"][1]["itemStyle"] = {
          color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
            },
            {
              offset: 0,
              color: "rgba(10, 252, 180, 0.3)"
            }
          ]),
          borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(5, 218, 255, 1)"
            },
            {
              offset: 0,
              color: "rgba(10, 255, 223, 1)"
            }
          ])
        };
      } else {
        this.options["series"][1]["itemStyle"] = {
          color: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
            },
            {
              offset: 0,
              color: "rgba(217, 79, 255, 0.2)"
            }
          ]),
          borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 1,
              color: "rgba(136, 77, 245, 1)"
            },
            {
              offset: 0,
              color: "rgba(217, 79, 255, 1)"
            }
          ])
        };
      }
      if (window.innerWidth < 850) {
        this.options["xAxis"]["axisLabel"]["show"] = false;
        this.options["tooltip"]["confine"] = true;
        this.options["grid"] = {
          backgroundColor: "rgba(29, 29, 29, 1)",
          show: false,
          top: 100,
          bottom: 40,
          left: "10",
          right: "0",
          containLabel: true
          //bottom: "3%"
        };
        this.options["title"]["textStyle"]["fontSize"] = 17;
        this.options["title"]["top"] = 40;
        this.options["title"]["text"] =
          this.putCall == "calls"
            ? "Call Premiums vs Average"
            : "Put Premiums vs Average";
        this.options["toolbox"]["right"] = 3;
        this.options["toolbox"]["top"] = 8;
        //this.options["toolbox"]["show"] = false;
      }

      this.$refs.chart.setOption(this.options);
    }
  },
  watch: {
    data: function() {
      this.setChart();
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.options-chart {
  width: 100.1%;
  height: 1450px;
}

.options-flow-max-change-chart {
  width: 50%;
}

@media only screen and (max-width: 850px) {
  .options-flow-max-change-chart {
    width: 100%;
  }
}
</style>
