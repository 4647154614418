<template>
  <div
    class="sidebar"
    @mouseover="expandSidebar('expand')"
    @mouseleave="expandSidebar('collapse')"
  >
    <div class="links-container">
      <div
        v-for="(item, index) in links"
        v-bind:item="item"
        v-bind:index="index"
        v-bind:key="item.id"
      >
        <router-link
          :to="item.link"
          tag="div"
          class="link-container"
          :id="item.name"
          @click.native="toggleActive($event), expandSublinks($event)"
        >
          <div class="link" :ref="item.name">
            <div class="icon-container">
              <i :class="item.icon"></i>
              <template v-if="item.sublinks">
                <i class="mdi mdi-chevron-down expandable"></i>
              </template>
            </div>
          </div>
          <div class="link-name">{{ item.name }}</div>
        </router-link>
        <template v-if="item.sublinks">
          <div
            v-for="(item, index) in item.sublinks"
            v-bind:item="item"
            v-bind:index="index"
            v-bind:key="item.id"
          >
            <router-link
              :to="item.link"
              tag="div"
              class="link-container"
              :id="item.name"
              @click.native="toggleActive($event)"
            >
              <div class="link sub">
                <div class="icon-container">
                  <i :class="item.icon"></i>
                </div>
              </div>
              <div class="link-name">{{ item.name }}</div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
    <div class="expand-icon" @click="expandSidebar('fixed')">
      <div class="icon-container">
        <i class="mdi mdi-arrow-left-right-bold"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
      active: "",
      expanded: false,
      fixed: false,
      linkValues: [],
      freeLinks: [
        {
          name: "Cluster",
          icon: "mdi mdi-chart-scatter-plot",
          link: "",
          sublinks: [
            {
              name: "Single Stock",
              icon: "mdi mdi-chart-line-variant",
              link: "/clusters"
            },
            {
              name: "All",
              icon: "mdi mdi-format-list-bulleted-square",
              link: "/clusterstable"
            }
          ]
        },
        {
          name: "SPY To VIX",
          icon: "mdi mdi-chart-multiple",
          link: "/spytovix"
        },
        {
          name: "Dark Pools",
          icon: "mdi mdi-chart-line-stacked",
          link: "/darkpools"
        },
        {
          name: "Option Walls",
          icon: "mdi mdi-alphabet-aurebesh",
          link: "/optionwalls"
        },
        {
          name: "Sector Rotation",
          icon: "mdi mdi-rotate-3d-variant",
          link: "/SectorRotation"
        },
        {
          name: "Sector Performance",
          icon: "mdi mdi-poll",
          link: "/SectorPerformance"
        },
        // {
        //   name: "Short Interest",
        //   icon: "mdi mdi-chevron-triple-down",
        //   link: "/shortinterest"
        // },
        {
          name: "ARIMA",
          icon: "mdi mdi-chart-timeline-variant",
          link: "/arima"
        },
        {
          name: "S&P 500 Statistics",
          icon: "mdi mdi-percent-outline",
          link: "/sp500stats"
        },
        {
          name: "Factor Analysis",
          icon: "mdi mdi-chart-bell-curve",
          link: "",
          sublinks: [
            {
              name: "Single Stock",
              icon: "mdi mdi-chart-line-variant",
              link: "/factor-analysis"
            },
            {
              name: "All",
              icon: "mdi mdi-format-list-bulleted-square",
              link: "/factor-analysis-table"
            }
          ]
        }
      ],
      paidLinks: [
        {
          name: "Options Flow",
          icon: "mdi mdi-triangle-wave",
          link: "",
          sublinks: [
            {
              name: "Options Dashboard",
              icon: "mdi mdi-card-bulleted-outline",
              link: "/optionsflow-dashboard"
            },
            {
              name: "Ticker Overview",
              icon: "mdi mdi-crosshairs-gps",
              link: "/optionsflow-ticker-overview"
            },
            {
              name: "Live & Historical",
              icon: "mdi mdi-view-dashboard",
              link: "/optionsflow"
            },
            {
              name: "Cumulative Stats",
              icon: "mdi mdi-chart-histogram",
              link: "/optionsflowcumulativestats"
            },
            {
              name: "Top Premiums",
              icon: "mdi mdi-sort-variant",
              link: "/optionsflowtoppremiums"
            }
          ]
        }
        // Add more sections as needed for paid subscription
      ],
      links: []
    };
  },
  methods: {
    expandSublinks: function(event) {
      var path = event.path || (event.composedPath && event.composedPath());

      path.forEach((element, index) => {
        if (element.className != undefined) {
          if (element.className.split(" ")[0] == "link-container") {
            try {
              path[index].children[0].children[0].children[1].classList.toggle(
                "rotate"
              );
              if (path[index].children[0].classList != undefined) {
                path[index].children[0].classList.toggle("active-link");
              }
            } catch (err) {
              return;
            }
            try {
              var baseNode = path[index];
              var subsDiv = [];
              var namesDiv = [];
              while (baseNode) {
                try {
                  subsDiv.push(baseNode.nextSibling.children[0].children[0]);
                  namesDiv.push(baseNode.nextSibling.children[0].children[1]);
                } catch {
                  break;
                }
                baseNode = baseNode.nextSibling;
              }
            } catch {
              return;
            }
            subsDiv.forEach(element => {
              try {
                if (element.classList != undefined) {
                  element.classList.forEach(_class => {
                    if (_class == "sub") {
                      element.classList.remove("sub");
                      element.classList.add("active-link");
                      element.classList.add("sub-visible");
                    } else if (_class == "sub-visible") {
                      element.classList.add("sub");
                      element.classList.remove("active-link");
                      element.classList.remove("sub-visible");
                    }
                  });
                }
              } catch {
                return;
              }
            });
            namesDiv.forEach(element => {
              if (this.expanded == true) {
                element.classList.toggle("link-name-visible");
              }
            });
          }
        }
      });
    },
    toggleActiveOnLoad: function() {
      // Needed for the toggle on page load. Click event not available. Toggle based on router URL
      var elements = document.getElementsByClassName("link-container");
      //console.log(elements);
      elements.forEach(element => {
        this.linkValues.forEach(value => {
          if (
            (element.classList != undefined) &
            (element.innerText == value.name) &
            (value.link.split("/")[1] == this.active.split("/")[0])
          ) {
            if (
              element.parentNode.parentNode.parentNode.classList[0] != "sidebar"
            ) {
              try {
                this.$refs[
                  element.parentNode.parentNode.children[0].id
                ][0].click();
              } catch {
                return;
              }
            }
            element.classList.add("current-link");
          }
        });
      });
    },
    toggleActive: function(event) {
      var path = event.path || (event.composedPath && event.composedPath());
      var currentIndex = 0;
      path.forEach((element, index) => {
        if (element.className != undefined) {
          if (element.className.split(" ")[0] == "link-container") {
            currentIndex = index;
            if (
              path[index].nextSibling == null ||
              path[index].nextSibling.nodeType == Node.COMMENT_NODE
            ) {
              path[index].classList.add("current-link");
            }
          }
        }
      }, currentIndex);
      this.linkValues.forEach(element => {
        if (element.name != path[currentIndex].id) {
          try {
            if (
              path[currentIndex].nextSibling == null ||
              path[currentIndex].nextSibling.nodeType == Node.COMMENT_NODE
            ) {
              var current_element = document.getElementById(element.name);
              current_element.classList.remove("current-link");
            }
          } catch {
            return;
          }
        }
      });
    },
    expand: function() {
      this.$emit("expanded");
      this.expanded = !this.expanded;
      var expandIcon = document.getElementsByClassName("expand-icon");
      expandIcon[0].classList.toggle("expanded");
      var sidebar = document.getElementsByClassName("sidebar");
      sidebar[0].classList.toggle("expanded");
      var names = document.getElementsByClassName("link-name");
      names.forEach(element => {
        if (
          element.previousSibling.classList.length == 1 ||
          element.previousSibling.classList[1] != "sub"
        ) {
          element.classList.toggle("link-name-visible");
        }
      });
    },
    expandSidebar: function(exp) {
      if (
        (exp === "expand" && this.expanded === false && this.fixed === false) ||
        (exp === "collapse" && this.expanded === true && this.fixed === false)
      ) {
        this.expand();
      } else if (exp === "fixed" && this.expanded == false) {
        this.expand();
        this.fixed = !this.fixed;
      } else if (exp === "fixed" && this.expanded == true) {
        this.fixed = !this.fixed;
      }
    },
    setUpLinks: function() {
      if (this.user != undefined && this.user.subscription == "basic") {
        this.links = this.paidLinks.concat(this.freeLinks);
      } else {
        this.links = this.freeLinks;
      }
      this.links.forEach(element => {
        this.linkValues.push({ name: element.name, link: element.link });
        if (element.sublinks != undefined) {
          element.sublinks.forEach(subElement => {
            this.linkValues.push({
              name: subElement.name,
              link: subElement.link
            });
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "getIsAuthenticated",
      user: "getUserInfo"
    })
  },
  watch: {
    user: function() {
      this.setUpLinks();
    }
  },
  mounted() {
    var element = document.getElementsByClassName("sidebar");
    function sidebarResize() {
      element[0].style.marginTop =
        String(Math.max(0, 55 - window.scrollY)) + "px";
      element[0].style.height =
        String(window.innerHeight - Math.max(0, 55 - window.scrollY)) + "px";
    }
    sidebarResize();
    window.addEventListener("scroll", () => {
      sidebarResize();
    });
    window.addEventListener("resize", () => {
      sidebarResize();
    });
    this.setUpLinks();
    this.active = this.active = this.$router.history.current.name;
    //Get all link values in a flattened array linkValues
    this.toggleActiveOnLoad();
  }
};
</script>
<style lang="scss" scoped>
.sidebar {
  position: fixed;
  padding-top: 10px;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 60px;
  width: 60px;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 10;
  transition: all 300ms ease, height 0ms, margin 0ms;
  &.expanded {
    max-width: 215px;
    width: 215px;
  }
  overflow-y: scroll;
  //overflow-x: visible;
}

.links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 100px;
  overflow-x: visible;
}

.expand-icon {
  width: 60px;
  min-height: 60px;
  position: fixed;
  bottom: 0px;
  cursor: pointer;
  transition: all 300ms ease;
  & .icon-container {
    border-radius: 0px;
    min-height: 60px;
    width: 100%;
    height: 100%;
    i {
      font-size: 18px;
    }
  }
  &.expanded {
    max-width: 215px;
    width: 215px;
    transition: all 300ms ease;
  }
}

.sidebar .router-link-active {
  padding: 0;
}

.link {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 3px solid rgba(114, 66, 121, 0);
  transition: border 300ms ease;
}

.link-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: all 200ms ease;
  &:hover {
    cursor: pointer;
    //background-color: var(--app-components-background-color-lighter);
  }
}

.link-container:hover .link-name {
  cursor: pointer;
  opacity: 1;
  width: 160px;
  height: 50px;
  transition: all 300ms ease;
}

// .link-container:hover .icon-container {
//   background-color: rgb(40, 40, 40);
//   border: 1px solid rgb(29, 29, 29);
//   transition: all 200ms ease;
// }

.sidebar.expanded .link-container:hover .icon-container {
  background-color: rgb(41, 41, 41);
  border: 1px solid rgba(29, 29, 29, 0.4);
  transition: all 100ms ease;
}

.sidebar.expanded .link-container:hover {
  background-color: var(--app-components-background-color-lighter);
  transition: all 200ms ease;
}

.link-name {
  overflow: hidden; /* Hide the element content, while height = 0 */
  height: 0px;
  opacity: 0;
  width: 0px;
  color: #fff;
  font-family: Nunito;
  font-size: 14px;
  display: flex;
  justify-content: left;
  align-items: center;
  white-space: nowrap;
  padding-left: 10px;
  transition: all 300ms ease;
}

.link-container:hover i {
  cursor: pointer;
  color: rgb(252, 41, 111);
  transition: all 100ms ease;
}

.link-name-visible {
  width: 160px;
  opacity: 1;
  height: 50px;
  transition: all 300ms ease;
}

.active-link {
  border-left: 3px solid rgb(252, 41, 111);
  transition: border 300ms ease-out;
}

.group {
  font-size: inherit;
}

.sub {
  border-left: 3px solid rgba(114, 66, 121, 0);
  overflow: hidden; /* Hide the element content, while height = 0 */
  height: 0;
  opacity: 0;
  transition: height 300ms 00ms, opacity 300ms 0ms;
}

.sub-visible {
  border-left: 3px solid rgb(252, 41, 111);
  height: 50px;
  opacity: 1;
  transition: height 300ms 0ms, opacity 600ms 0ms;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 80%;
  border-radius: 4px;
  border: 0px solid rgb(29, 29, 29);
  background-color: rgb(37, 37, 37);
  i {
    color: #fff;
    font-size: 20px;
    &.expandable {
      font-size: 12px;
      position: relative;
      top: -13px;
      right: 5px;
      width: 0px;
      height: 13px;
      transition: transform 400ms;
      &.rotate {
        transform: rotateX(180deg) translateX(0px) translateY(-4px) !important;
        transition: transform 400ms;
      }
    }
  }
}

.current-link i {
  color: rgb(252, 41, 111);
  transition: all 400ms;
}

.sidebar.expanded {
  max-width: 215px;
  width: 215px;
  margin-bottom: 100px;
}

.sidebar.collapsed {
  max-width: 215px;
}

@media only screen and (max-width: 1000px) {
  .sidebar {
    display: none;
  }
}

* {
  scrollbar-width: none; /* Firefox implementation */
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 0px !important;
}

::-webkit-scrollbar-track {
  background-color: #292929;
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px !important;
  border-radius: 0px !important;
  background: #242424;
}
</style>
