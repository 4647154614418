<template>
  <div class="options-flow-key-stats-container">
    <div class="key-stats-sub-container">
      <v-chart class="trade-count-chart" autoresize ref="tradeCount" />
      <v-chart class="score-chart" autoresize ref="score" />
    </div>
    <div class="key-stats-sub-container">
      <v-chart class="cost-basis-chart" autoresize ref="costBasis" />
      <v-chart class="volume-chart" autoresize ref="volume" />
    </div>
  </div>
</template>

<script>
import "echarts";
import { graphic } from "echarts/core";
import { eventBus } from "../main";
import { mapGetters } from "vuex";

export default {
  name: "OptionsFlowKeyStats",
  props: {
    keyStats: Object
  },
  data() {
    return {
      charts: [
        {
          name: "Numbers of Trades",
          chart: "tradeCount"
        },
        { name: "Average Score", chart: "score" },
        {
          name: "Total Premiums 000s",
          chart: "costBasis"
        },
        { name: "Volume", chart: "volume" }
      ],
      options: {
        tradeCount: {},
        score: {},
        costBasis: {},
        volume: {}
      },
      isSet: false
    };
  },
  methods: {
    setChart: function(meta) {
      //console.log(this.keyStats[meta.chart][0]);
      this.options[meta.chart] = {
        backgroundColor: "rgba(29, 29, 29, 1)",
        grid: {
          backgroundColor: "rgba(29, 29, 29, 1)",
          show: false,
          top: 50,
          bottom: 20,
          left: 40,
          right: 53,
          containLabel: false
          //bottom: "3%"
        },

        textStyle: {
          fontFamily: "Chivo-Regular",
          fontSize: 16,
          fontWeight: "lighter",
          color: "rgba(255, 255, 255, 1)"
        },
        title: {
          text: [meta.name],
          left: 10,
          show: "false",
          top: 25,
          textStyle: {
            fontFamily: "Nunito",
            fontWeight: "lighter",
            color: "#ffffff",
            fontSize: 18
          }
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(35, 35, 35, 0.2)"
            },
            animation: false,
            label: {
              backgroundColor: "rgba(23, 24, 26, 0.95)"
            }
          },
          backgroundColor: "rgba(20, 20, 20, 0.7)",
          borderColor: "rgba(0, 0, 0, 1)",
          borderWidth: 0,
          textStyle: {
            color: "rgba(251, 251, 251, 1)",
            align: "left"
          }
        },
        xAxis: [
          {
            type: "value",
            splitNumber: 3,
            show: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(50, 50, 50, 0.2)",
                width: 1
              }
            },
            axisLabel: {
              color: "rgba(255, 255, 255, 0)"
            },
            nameTextStyle: {
              fontSize: 1,
              color: "rgba(255, 255, 255, 0)"
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: true,
              color: "rgba(255, 255, 255, 0.1)"
            }
          }
        ],
        yAxis: [
          {
            type: "category",
            name: "",
            data: [meta.chart],
            show: true,
            axisLabel: {
              color: "rgba(255, 255, 255, 0)"
            },
            nameTextStyle: {
              fontSize: 1,
              color: "rgba(255, 255, 255, 0)"
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              splitNumber: 3,
              lineStyle: {
                color: "rgba(50, 50, 50, 0.2)",
                width: 1
              }
            }
          }
        ],

        series: [
          {
            name: "Calls",
            type: "bar",
            data: [this.keyStats[meta.chart][0]],
            yAxisIndex: 0,
            //barGap: "-20%",
            xAxisIndex: 0,
            barWidth: 25,
            labelLine: {
              show: false
            },
            label: {
              show: true,
              fontFamily: "Nunito",
              fontWeight: "lighter",
              fontSize: 11,
              position: "right",
              borderColor: "rgba(255, 255, 255, 1)",
              color: "rgba(255, 255, 255, 1)"
            },
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 252, 180, 0.3)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(5, 218, 255, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(10, 255, 223, 1)"
                }
              ])
            }
          },
          {
            name: "Puts",
            type: "bar",
            data: [this.keyStats[meta.chart][1]],
            yAxisIndex: 0,
            barWidth: 25,
            labelLine: {
              show: false
            },
            label: {
              show: true,
              fontFamily: "Nunito",
              fontWeight: "lighter",
              fontSize: 11,
              position: "right",
              borderColor: "rgba(255, 255, 255, 1)",
              color: "rgba(255, 255, 255, 1)"
            },
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 0.4)" //"rgba(221, 40, 237, 0.3)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 0.2)"
                }
              ]),
              borderColor: new graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 1,
                  color: "rgba(136, 77, 245, 1)"
                },
                {
                  offset: 0,
                  color: "rgba(217, 79, 255, 1)"
                }
              ])
            }
          }
        ]
      };

      this.$refs[meta.chart].setOption(this.options[meta.chart]);
    }
  },
  computed: {
    ...mapGetters({
      loading: "getLoading"
    })
  },
  watch: {
    keyStats: function() {
      let setChartDelay = () => {
        this.charts.forEach(meta => {
          this.setChart(meta);
        });
      };

      if (this.isSet == false && this.loading == true) {
        setChartDelay();
        setTimeout(() => {
          this.isSet = true;
        }, 1000);
      } else if (this.isSet == true) {
        setChartDelay();
      }

      this.$store.commit("setLoading", false);
    }
  },
  mounted() {
    eventBus.$on("breakpoint", () => {
      this.setChart();
    });
  }
};
</script>

<style scoped>
.trade-count-chart,
.score-chart,
.cost-basis-chart,
.volume-chart {
  width: 50%;
  height: 170px;
  overflow: visible;
  min-width: 200px;
}

.options-flow-key-stats-container {
  width: 100%;
  padding: 0 30px 0 15px;
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--app-components-background-color);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.key-stats-sub-container {
  display: flex;
  flex-wrap: nowrap;
  width: 50%;
  min-width: 400px;
}

@media only screen and (max-width: 1250px) {
}

@media only screen and (max-width: 1000px) {
  .options-flow-key-stats-container {
    flex-wrap: wrap;
  }
  .key-stats-sub-container {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .trade-count-chart,
  .score-chart,
  .cost-basis-chart,
  .volume-chart {
    min-width: 150px;
  }
  .key-stats-sub-container {
    min-width: 300px;
  }
}
</style>
