import axios from "axios";
import Vuex from "vuex";
import Vue from "vue";
import { isValidJwt } from "@/utils";

//load Vuex
Vue.use(Vuex);

//to handle state
const state = {
  loading: false,
  subLoading: false,
  ticker: "SPY",
  popupShow: false,
  mcHeatmapData: {},
  ///////////////////////////////////////////////////////////////
  //CLUSTERS TABLE
  ///////////////////////////////////////////////////////////////
  clustersTableData: {},
  clustersPredictionData: {},
  clustersDailyData: [],
  clustersDailyColors: [],
  clustersAverageData: {},
  clustersBoxplotData: {},
  ///////////////////////////////////////////////////////////////
  //SECTOR ROTATION
  ///////////////////////////////////////////////////////////////
  sectorRotationData: {},
  ///////////////////////////////////////////////////////////////
  //SECTOR PERFORMANCE
  ///////////////////////////////////////////////////////////////
  sectorPerformanceData: {},
  ///////////////////////////////////////////////////////////////
  //LOGIN AND SIGNUP
  ///////////////////////////////////////////////////////////////
  showSignup: false,
  showLogin: false,
  showResend: false,
  showResetPassword: false,
  showUpdatePassword: false,
  updateToken: "",
  selectedSubscription: "",
  userInfo: {},
  loginEvent: false,
  logoutEvent: false,
  ///////////////////////////////////////////////////////////////
  //USER AND TOKEN
  ///////////////////////////////////////////////////////////////
  user: {},
  jwt: "",
  ///////////////////////////////////////////////////////////////
  //Alert
  ///////////////////////////////////////////////////////////////
  alertShow: false,
  alertMessage: "",
  alertContentShow: false,
  alertContent: "",
  alertButton: "Close",
  alertCancel: false,
  ///////////////////////////////////////////////////////////////
  //STRIPE
  ///////////////////////////////////////////////////////////////
  stripeSession: {},
  stripeSessionUrl: {},
  ///////////////////////////////////////////////////////////////
  //OPTIONS FLOW
  ///////////////////////////////////////////////////////////////
  //latest_options_flow: [],
  optionsFlowMode: "",
  currentOptionsFlow: [],
  historicalOptionsFlow: [],
  filteredOptionsFlow: [],
  largestCallPremiums: [],
  largestPutPremiums: [],
  optionsFlowKeyStats: {},
  newFlow: {},
  optionsFlowHistoricalPremiumData: {},
  optionsFlowByStrikeData: {},
  optionsFlowTopPremiumsData: {},
  optionsFlowIntradayPremiumsData: {},
  optionsFlowSeasonalityData: {},
  optionsFlowTickerMetricsData: {},
  optionsFlowTickerMetrics1dData: {},
  optionsFlowIndexMetricsData: {},
  optionsFlowIndexQuotesData: {},
  optionsFlowLargestFlowData: {},
  optionsFlowSectorInflowData: {},
  optionsFlowMostOtmData: {},
  optionsFlowHighOILeapsData: {},
  optionsFlowDailyOverAvgPremiumsData: {},
  optionsFlowDailyOverAvgSizeData: {},
  optionsFlowLatestTradesData: {},
  ///////////////////////////////////////////////////////////////
  //SPY TO VIX DISTRIBUTION
  ///////////////////////////////////////////////////////////////
  spyToVixSata: {},
  ///////////////////////////////////////////////////////////////
  //TIME SERIES
  ///////////////////////////////////////////////////////////////
  tsArimaData: {},
  ///////////////////////////////////////////////////////////////
  //SP500 RATIOS
  ///////////////////////////////////////////////////////////////
  sp500RatiosData: {},
  ///////////////////////////////////////////////////////////////
  //FACTOR ANALYSIS
  ///////////////////////////////////////////////////////////////
  factorAnalysisData: {},
  signalsData: []
};

//to handle state
const getters = {
  getLoading: state => state.loading,
  getSubLoading: state => state.subLoading,
  getTicker: state => state.ticker,
  getPopupShow: state => state.popupShow,
  getMCHeatmapData: state => state.mcHeatmapData,
  ///////////////////////////////////////////////////////////////
  //CLUSTERS TABLE
  ///////////////////////////////////////////////////////////////
  getClustersTableData: state => state.clustersTableData,
  getClustersPredictionData: state => state.clustersPredictionData,
  getClustersDailyData: state => state.clustersDailyData,
  getClustersDailyColors: state => state.clustersDailyColors,
  getClustersAverageData: state => state.clustersAverageData,
  getClustersBoxplotData: state => state.clustersBoxplotData,
  ///////////////////////////////////////////////////////////////
  //SECTOR ROTATION
  ///////////////////////////////////////////////////////////////
  getSectorRotationData: state => state.sectorRotationData,
  ///////////////////////////////////////////////////////////////
  //SECTOR PERFORMANCE
  ///////////////////////////////////////////////////////////////
  getSectorPerformanceData: state => state.sectorPerformanceData,
  ///////////////////////////////////////////////////////////////
  //LOGIN AND SIGNUP
  ///////////////////////////////////////////////////////////////
  getShowSignup: state => state.showSignup,
  getShowLogin: state => state.showLogin,
  getShowResend: state => state.showResend,
  getShowResetPassword: state => state.showResetPassword,
  getShowUpdatePassword: state => state.showUpdatePassword,
  getUpdateToken: state => state.updateToken,
  getSelectedSubscription: state => state.selectedSubscription,
  getUserInfo: state => state.userInfo,
  getLoginEvent: state => state.loginEvent,
  getLogoutEvent: state => state.logoutEvent,
  ///////////////////////////////////////////////////////////////
  //USER AND TOKEN
  ///////////////////////////////////////////////////////////////
  //getUser: state => state.user,
  getJwt: state => state.jwt,
  getIsAuthenticated: state => isValidJwt(state.jwt),
  ///////////////////////////////////////////////////////////////
  //Alert
  ///////////////////////////////////////////////////////////////
  getAlertShow: state => state.alertShow,
  getAlertMessage: state => state.alertMessage,
  getAlertContent: state => state.alertContent,
  getAlertContentShow: state => state.alertContentShow,
  getAlertButton: state => state.alertButton,
  getAlertCancel: state => state.alertCancel,
  ///////////////////////////////////////////////////////////////
  //STRIPE
  ///////////////////////////////////////////////////////////////
  get_stripe_session: state => state.stripeSession,
  get_stripe_session_url: state => state.stripeSessionUrl,
  ///////////////////////////////////////////////////////////////
  //OPTIONS FLOW
  ///////////////////////////////////////////////////////////////
  //get_latest_options_flow: state => state.latest_options_flow,
  getCurrentOptionsFlow: state => state.currentOptionsFlow,
  getHistoricalOptionsFlow: state => state.historicalOptionsFlow,
  getFilteredOptionsFlow: state => state.filteredOptionsFlow,
  getLargestCallPremiums: state => state.largestCallPremiums,
  getLargestPutPremiums: state => state.largestPutPremiums,
  getOptionsFlowKeyStats: state => state.optionsFlowKeyStats,
  getNewFlow: state => state.newFlow,
  getOptionsFlowHistoricalPremiumData: state =>
    state.optionsFlowHistoricalPremiumData,
  getOptionsFlowByStrikeData: state => state.optionsFlowByStrikeData,
  getOptionsFlowTopPremiumsData: state => state.optionsFlowTopPremiumsData,
  getOptionsFlowMode: state => state.optionsFlowMode,
  getOptionsFlowIntradayPremiumsData: state =>
    state.optionsFlowIntradayPremiumsData,
  getOptionsFlowSeasonalityData: state => state.optionsFlowSeasonalityData,
  getOptionsFlowTickerMetricsData: state => state.optionsFlowTickerMetricsData,
  getOptionsFlowTickerMetrics1dData: state =>
    state.optionsFlowTickerMetrics1dData,
  getOptionsFlowIndexMetricsData: state => state.optionsFlowIndexMetricsData,
  getOptionsFlowIndexQuotesData: state => state.optionsFlowIndexQuotesData,
  getOptionsFlowLargestFlowData: state => state.optionsFlowLargestFlowData,
  getOptionsFlowSectorInflowData: state => state.optionsFlowSectorInflowData,
  getOptionsFlowMostOtmData: state => state.optionsFlowMostOtmData,
  getOptionsFlowHighOILeapsData: state => state.optionsFlowHighOILeapsData,
  getOptionsFlowDailyOverAvgPremiumsData: state =>
    state.optionsFlowDailyOverAvgPremiumsData,
  getOptionsFlowDailyOverAvgSizeData: state =>
    state.optionsFlowDailyOverAvgSizeData,
  getOptionsFlowLatestTradesData: state => state.optionsFlowLatestTradesData,
  ///////////////////////////////////////////////////////////////
  //SPY TO VIX DISTRIBUTION
  ///////////////////////////////////////////////////////////////
  getSpyToVixData: state => state.spyToVixSata,
  ///////////////////////////////////////////////////////////////
  //TIME SERIES
  ///////////////////////////////////////////////////////////////
  getTSArimaData: state => state.tsArimaData,
  ///////////////////////////////////////////////////////////////
  //SP500 RATIOS
  ///////////////////////////////////////////////////////////////
  getSP500RatiosData: state => state.sp500RatiosData,
  ///////////////////////////////////////////////////////////////
  //FACTOR ANALYSIS
  ///////////////////////////////////////////////////////////////
  getFactorAnalysisData: state => state.factorAnalysisData,
  getSignalsData: state => state.signalsData
};

//to handle mutations
const mutations = {
  setLoading(state, data) {
    state.loading = data;
  },
  setSubLoading(state, data) {
    state.subLoading = data;
  },
  setTicker(state, data) {
    state.ticker = data;
  },
  setPopupShow(state, data) {
    state.popupShow = data;
  },
  setMCHeatmapData(state, data) {
    state.mcHeatmapData = data;
  },
  ///////////////////////////////////////////////////////////////
  //CLUSTERS TABLE
  ///////////////////////////////////////////////////////////////
  setClustersTableData(state, data) {
    state.clustersTableData = data;
  },
  setClustersPredictionData(state, data) {
    state.clustersPredictionData = data;
  },
  setClustersDailyData(state, data) {
    state.clustersDailyData = data;
  },
  setClustersDailyColors(state, data) {
    state.clustersDailyColors = data;
  },
  setClustersAverageData(state, data) {
    state.clustersAverageData = data;
  },
  setClustersBoxplotData(state, data) {
    state.clustersBoxplotData = data;
  },
  ///////////////////////////////////////////////////////////////
  //SECTOR ROTATION
  ///////////////////////////////////////////////////////////////
  setSectorRotationData(state, data) {
    state.sectorRotationData = data;
  },
  ///////////////////////////////////////////////////////////////
  //SECTOR PERFORMANCE
  ///////////////////////////////////////////////////////////////
  setSectorPerformanceData(state, data) {
    state.sectorPerformanceData = data;
  },
  ///////////////////////////////////////////////////////////////
  //LOGIN AND SIGNUP
  ///////////////////////////////////////////////////////////////
  setShowSignup(state, data) {
    state.showSignup = data;
  },
  setShowLogin(state, data) {
    state.showLogin = data;
  },
  setShowResend(state, data) {
    state.showResend = data;
  },
  setShowResetPassword(state, data) {
    state.showResetPassword = data;
  },
  setShowUpdatePassword(state, data) {
    state.showUpdatePassword = data;
  },
  setUpdateToken(state, data) {
    state.updateToken = data;
  },
  setSelectedSubscription(state, data) {
    state.selectedSubscription = data;
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  },
  setLoginEvent(state, data) {
    state.loginEvent = data;
  },
  setLogoutEvent(state, data) {
    state.logoutEvent = data;
  },
  ///////////////////////////////////////////////////////////////
  //USER AND TOKEN
  ///////////////////////////////////////////////////////////////
  // setUser(state, data) {
  //     state.user = data;
  // },
  setJwt(state, data) {
    state.jwt = data;
    localStorage.setItem("stockgrid_jwt", data);
  },
  deleteJwt(state) {
    state.jwt = "";
    try {
      localStorage.removeItem("stockgrid_jwt");
    } catch (err) {
      console.log(err);
    }
  },
  ///////////////////////////////////////////////////////////////
  //Alert
  ///////////////////////////////////////////////////////////////
  setAlertShow(state, data) {
    setTimeout(() => {
      state.alertShow = data;
    }, 0);
  },
  setAlertMessage(state, data) {
    setTimeout(() => {
      state.alertMessage = data;
    }, 0);
  },
  setAlertContent(state, data) {
    setTimeout(() => {
      state.alertContent = data;
    }, 0);
  },
  setAlertContentShow(state, data) {
    setTimeout(() => {
      state.alertContentShow = data;
    }, 0);
  },
  setAlertButton(state, data) {
    setTimeout(() => {
      state.alertButton = data;
    }, 0);
  },
  setAlertCancel(state, data) {
    setTimeout(() => {
      state.alertCancel = data;
    }, 0);
  },
  ///////////////////////////////////////////////////////////////
  //STRIPE
  ///////////////////////////////////////////////////////////////
  setStripeSession(state, data) {
    state.stripeSession = data;
  },
  setStripeSessionUrl(state, data) {
    state.stripeSessionUrl = data;
  },
  ///////////////////////////////////////////////////////////////
  //OPTIONS FLOW
  ///////////////////////////////////////////////////////////////
  incrementCurrentOptionsFlow(state, data) {
    state.currentOptionsFlow.splice(0, 0, data);
  },
  setCurrentOptionsFlow(state, data) {
    state.currentOptionsFlow = data;
  },
  setHistoricalOptionsFlow(state, data) {
    state.historicalOptionsFlow = data;
  },
  setFilteredOptionsFlow(state, data) {
    state.filteredOptionsFlow = data;
    this.commit("calculateKeyStats");
    this.commit("calculateLargestPremiums");
  },
  setNewFlow(state, data) {
    state.newFlow = data;
  },
  setLargestCallPremiums(state, data) {
    state.largestCallPremiums = data.data;
  },
  setLargestPutPremiums(state, data) {
    state.largestPutPremiums = data.data;
  },
  incrementLargestPremiums(state, data) {
    if (data.put_call == "Call") {
      var callExists = state.largestCallPremiums.some(function(obj) {
        if (obj.ticker === data.ticker) {
          obj.cost_basis += data.cost_basis;
          return true;
        }
      });
      if (callExists == false) {
        state.largestCallPremiums.push({
          ticker: data.ticker,
          cost_basis: data.cost_basis
        });
      }
    } else if (data.put_call == "Put") {
      var putExists = state.largestPutPremiums.some(function(obj) {
        if (obj.ticker === data.ticker) {
          obj.cost_basis += data.cost_basis;
          return true;
        }
      });
      if (putExists == false) {
        state.largestPutPremiums.push({
          ticker: data.ticker,
          cost_basis: data.cost_basis
        });
      }
    }
  },
  setOptionsFlowKeyStats(state, data) {
    state.optionsFlowKeyStats = data;
  },
  //   updateKeyStats(state, data) {
  //     const keyStatsObj = {
  //       score: state.optionsFlowKeyStats["score"],
  //       tradeCount: state.optionsFlowKeyStats["trade_count"],
  //       volume: state.optionsFlowKeyStats["volume"],
  //       costBasis: state.optionsFlowKeyStats["cost_basis"]
  //     };
  //     if (data.put_call == "Call") {
  //       keyStatsObj["score"][0] = (
  //         (state.optionsFlowKeyStats["score"][0] *
  //           state.optionsFlowKeyStats["trade_count"][0] +
  //           data.score) /
  //         (state.optionsFlowKeyStats["trade_count"][0] + 1)
  //       ).toFixed(2);

  //       keyStatsObj["tradeCount"][0] =
  //         state.optionsFlowKeyStats["trade_count"][0] + 1;
  //       keyStatsObj["volume"][0] =
  //         state.optionsFlowKeyStats["volume"][0] + data.volume;

  //       keyStatsObj["costBasis"][0] =
  //         state.optionsFlowKeyStats["cost_basis"][0] + data.cost_basis;
  //     } else if (data.put_call == "Put") {
  //       keyStatsObj["score"][1] = (
  //         (state.optionsFlowKeyStats["score"][1] *
  //           state.optionsFlowKeyStats["trade_count"][1] +
  //           data.score) /
  //         (state.optionsFlowKeyStats["trade_count"][1] + 1)
  //       ).toFixed(2);

  //       keyStatsObj["tradeCount"][1] =
  //         state.optionsFlowKeyStats["trade_count"][1] + 1;
  //       keyStatsObj["volume"][1] =
  //         state.optionsFlowKeyStats["volume"][1] + data.volume;

  //       keyStatsObj["costBasis"][1] =
  //         state.optionsFlowKeyStats["cost_basis"][1] + data.cost_basis;
  //     }
  //     state.optionsFlowKeyStats = { ...keyStatsObj };
  //   },
  calculateKeyStats(state) {
    var keyStatsObj = {
      tradeCount: [0, 0],
      score: [0, 0],
      costBasis: [0, 0],
      volume: [0, 0]
    };
    state.filteredOptionsFlow.forEach(data => {
      if (data.put_call == "Call") {
        keyStatsObj["score"][0] =
          (keyStatsObj["score"][0] * keyStatsObj["tradeCount"][0] +
            data.score) /
          (keyStatsObj["tradeCount"][0] + 1);

        keyStatsObj["tradeCount"][0] = keyStatsObj["tradeCount"][0] + 1;
        keyStatsObj["volume"][0] = keyStatsObj["volume"][0] + data.volume;

        keyStatsObj["costBasis"][0] =
          keyStatsObj["costBasis"][0] + data.cost_basis;
      } else if (data.put_call == "Put") {
        keyStatsObj["score"][1] =
          (keyStatsObj["score"][1] * keyStatsObj["tradeCount"][1] +
            data.score) /
          (keyStatsObj["tradeCount"][1] + 1);

        keyStatsObj["tradeCount"][1] = keyStatsObj["tradeCount"][1] + 1;
        keyStatsObj["volume"][1] = keyStatsObj["volume"][1] + data.volume;

        keyStatsObj["costBasis"][1] =
          keyStatsObj["costBasis"][1] + data.cost_basis;
      }
    });

    keyStatsObj["score"][0] = keyStatsObj["score"][0].toFixed(2);
    keyStatsObj["score"][1] = keyStatsObj["score"][1].toFixed(2);
    keyStatsObj["costBasis"][0] = keyStatsObj["costBasis"][0].toFixed(0);
    keyStatsObj["costBasis"][1] = keyStatsObj["costBasis"][1].toFixed(0);
    state.optionsFlowKeyStats = { ...keyStatsObj };
  },
  calculateLargestPremiums(state) {
    var largestCall = [];
    var largestPut = [];

    state.filteredOptionsFlow.forEach(data => {
      if (data.put_call == "Call") {
        var callExists = largestCall.some(function(obj) {
          if (obj.ticker === data.ticker) {
            obj.costBasis += data.cost_basis;
            return true;
          }
        });

        if (callExists == false) {
          largestCall.push({
            ticker: data.ticker,
            costBasis: data.cost_basis
          });
        }
      } else if (data.put_call == "Put") {
        var putExists = largestPut.some(function(obj) {
          if (obj.ticker === data.ticker) {
            obj.costBasis += data.cost_basis;
            return true;
          }
        });
        if (putExists == false) {
          largestPut.push({
            ticker: data.ticker,
            costBasis: data.cost_basis
          });
        }
      }
    });
    largestCall.forEach(element => {
      element.costBasis = element.costBasis.toFixed(0);
    });
    largestPut.forEach(element => {
      element.costBasis = element.costBasis.toFixed(0);
    });
    state.largestCallPremiums = [...largestCall];
    state.largestPutPremiums = [...largestPut];
  },
  setOptionsFlowHistoricalPremiumData(state, data) {
    state.optionsFlowHistoricalPremiumData = data;
  },
  setOptionsFlowByStrikeData(state, data) {
    state.optionsFlowByStrikeData = data;
  },
  setOptionsFlowTopPremiumsData(state, data) {
    state.optionsFlowTopPremiumsData = data;
  },
  setOptionsFlowMode(state, data) {
    state.optionsFlowMode = data;
  },
  setOptionsFlowIntradayPremiumsData(state, data) {
    state.optionsFlowIntradayPremiumsData = data;
  },
  setOptionsFlowSeasonalityData(state, data) {
    state.optionsFlowSeasonalityData = data;
  },
  setOptionsFlowTickerMetricsData(state, data) {
    state.optionsFlowTickerMetricsData = data;
  },
  setOptionsFlowTickerMetrics1dData(state, data) {
    state.optionsFlowTickerMetrics1dData = data;
  },
  setOptionsFlowIndexMetricsData(state, data) {
    state.optionsFlowIndexMetricsData = data;
  },
  setOptionsFlowIndexQuotesData(state, data) {
    state.optionsFlowIndexQuotesData = data;
  },
  setOptionsFlowLargestFlowData(state, data) {
    state.optionsFlowLargestFlowData = data;
  },
  setOptionsFlowSectorInflowData(state, data) {
    state.optionsFlowSectorInflowData = data;
  },
  setOptionsFlowMostOtmData(state, data) {
    state.optionsFlowMostOtmData = data;
  },
  setOptionsFlowHighOILeapsData(state, data) {
    state.optionsFlowHighOILeapsData = data;
  },
  setOptionsFlowDailyOverAvgPremiumsData(state, data) {
    state.optionsFlowDailyOverAvgPremiumsData = data;
  },
  setOptionsFlowDailyOverAvgSizeData(state, data) {
    state.optionsFlowDailyOverAvgSizeData = data;
  },
  setOptionsFlowLatestTradesData(state, data) {
    state.optionsFlowLatestTradesData = data;
  },
  ///////////////////////////////////////////////////////////////
  //SPY TO VIX DISTRIBUTION
  ///////////////////////////////////////////////////////////////
  setSpyToVixData(state, data) {
    state.spyToVixSata = data;
  },
  ///////////////////////////////////////////////////////////////
  //TIME SERIES
  ///////////////////////////////////////////////////////////////
  setTSArimaData(state, data) {
    state.tsArimaData = data;
  },
  ///////////////////////////////////////////////////////////////
  //SP500 RATIOS
  ///////////////////////////////////////////////////////////////
  setSP500RatiosData(state, data) {
    state.sp500RatiosData = data;
  },
  ///////////////////////////////////////////////////////////////
  //FACTOR ANALYSIS
  ///////////////////////////////////////////////////////////////
  setFactorAnalysisData(state, data) {
    state.factorAnalysisData = data;
  },
  setSignalsData(state, data) {
    state.signalsData = data;
  }
};

//to handle actions
const actions = {
  getMCHeatmapData(context) {
    axios
      .get("/get_mc_heatmap_data", {
        params: {}
      })
      .then(res => {
        context.commit("setMCHeatmapData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //CLUSTERS TABLE
  ///////////////////////////////////////////////////////////////
  getClustersTableData(context) {
    axios
      .get("/get_clusters_table_data", {
        params: {}
      })
      .then(res => {
        context.commit("setClustersTableData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getClustersPredictionData(context, params) {
    axios
      .get("/get_clusters_prediction_data", {
        params: {
          ticker: params.ticker,
          d1: params.d1,
          d2: params.d2,
          d5: params.d5,
          d10: params.d10,
          d20: params.d20
        }
      })
      .then(res => {
        context.commit(
          "setClustersPredictionData",
          JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
        );
      })
      .catch(err => {
        console.log(err);
      });
  },
  getClustersData(context, params) {
    axios
      .get("/get_clusters_data", {
        params: {
          ticker: params.ticker,
          nb_clusters: params.nbClusters,
          length: params.length,
          metric: params.metric,
          iterations: params.iterations,
          features: params.features,
          training_window: params.trainingWindow
        }
      })
      .then(res => {
        var data = JSON.parse(res.data.toString().replace(/\bNaN\b/g, "null"));
        var averageData = data["clusters_average_data"];
        averageData["clusters_average_returns"] = JSON.parse(
          averageData["clusters_average_returns"]
        );
        context.commit("setClustersPredictionData", data["predictions"]);
        context.commit("setClustersDailyData", data["clusters_daily_data"]);
        context.commit("setClustersDailyColors", data["clusters_daily_colors"]);
        context.commit("setClustersAverageData", averageData);
        context.commit(
          "setClustersBoxplotData",
          JSON.parse(data["clusters_boxplot_data"])
        );
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //SECTOR ROTATION
  ///////////////////////////////////////////////////////////////
  getSectorRotationData(context, params) {
    axios
      .get("/get_sector_rotation_data", {
        params: {
          index: params.index,
          lookback: params.lookback,
          factor: params.factor
        }
      })
      .then(res => {
        context.commit("setSectorRotationData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //SECTOR PERFORMANCE
  ///////////////////////////////////////////////////////////////
  getSectorPerformanceData(context) {
    axios
      .get("/get_sector_performance_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setSectorPerformanceData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setSectorPerformanceData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //LOGIN AND SIGNUP
  ///////////////////////////////////////////////////////////////
  submitSignup(context, params) {
    axios
      .post("/submit_signup", null, {
        params: {
          username: params.username,
          email: params.email,
          password: params.password,
          password_confirmation: params.password_confirmation,
          selected_subscription: params.selectedSubscription
        }
      })
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getTokenConfirmation(context, params) {
    axios
      .post("/get_token_confirmation", null, {
        params: {
          token: params.token
        }
      })
      .then(res => {
        console.log(res);
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
        if ("alert_content" in res.data) {
          context.commit("setAlertContentShow", true);
          context.commit("setAlertContent", res.data["alert_content"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  submitLogin(context, params) {
    axios
      .post("/submit_login", null, {
        params: {
          username_or_email: params.usernameOrEmail,
          password: params.password,
          remember: params.remember
        }
      })
      .then(res => {
        context.commit("setLogoutEvent", false);
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
        if ("alert_content" in res.data) {
          context.commit("setAlertContentShow", true);
          context.commit("setAlertContent", res.data["alert_content"]);
        }
        if ("token" in res.data) {
          //const tokenParts = res.data.token.split(".");
          //const body = JSON.parse(atob(tokenParts[1]));
          //context.commit("setUserInfo", body.sub);
          context.commit("setJwt", res.data.token);
          context.commit("setLoginEvent", true);
        }
        context.dispatch({
          type: "getUserInfo"
        });
      })
      .catch(err => {
        console.log(err);
      });
  },
  submitLogout(context) {
    axios
      .post("/submit_logout", null, {
        headers: {
          Authorization: `Bearer: ${context.state.jwt}`
        }
      })
      .then(res => {
        context.commit("deleteJwt");
        context.commit("setLoginEvent", false);
        context.commit("setLogoutEvent", true);
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
        context.dispatch({
          type: "getUserInfo"
        });
      })
      .catch(err => {
        context.commit("deleteJwt");
        console.log(err);
      });
  },
  resendConfirmation(context, params) {
    axios
      .post("/resend_confirmation", null, {
        params: {
          email: params.email
        }
      })
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  resetPassword(context, params) {
    axios
      .post("/reset_password", null, {
        params: {
          email: params.email
        }
      })
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getPasswordTokenConfirmation(context, params) {
    axios
      .post("/get_password_token_confirmation", null, {
        params: {
          token: params.token
        }
      })
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
        if ("alert_content" in res.data) {
          context.commit("setAlertContentShow", true);
          context.commit("setAlertContent", res.data["alert_content"]);
        }
        if ("show_update_password" in res.data) {
          context.commit(
            "setShowUpdatePassword",
            res.data["show_update_password"]
          );
          context.commit("setUpdateToken", res.data["update_token"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  updatePassword(context, params) {
    axios
      .post("/update_password", null, {
        params: {
          password: params.password,
          password_confirmation: params.password_confirmation,
          token: context.state.updateToken
        }
      })
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  checkoutSession(context, params) {
    axios
      .post("/checkoutsession", null, {
        headers: {
          "Content-Type": "text/html"
        },
        params: {
          email: params.email
        }
      })
      .then(res => {
        console.log("subscribe");
        context.commit("setStripeSession", res.data["sessionId"]);
      })
      .catch(err => {
        console.log(err);
      });
  },
  manageSubscription(context) {
    axios
      .post("/manage_subscription")
      .then(res => {
        context.commit("setStripeSessionUrl", res.data["sessionURL"]);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getUserInfo(context) {
    axios
      .get("/get_user_info")
      .then(res => {
        context.commit("setUserInfo", res.data);
        if (res.data.username == "") {
          context.commit("deleteJwt");
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  deleteUser(context) {
    axios
      .get("/delete_user")
      .then(res => {
        if ("alert_show" in res.data) {
          context.commit("setAlertShow", res.data["alert_show"]);
          context.commit("setAlertMessage", res.data["alert_message"]);
        }

        context.commit("deleteJwt");
      })
      .catch(err => {
        context.commit("deleteJwt");
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //OPTIONS FLOW
  ///////////////////////////////////////////////////////////////
  getCurrentOptionsFlow(context) {
    axios
      .get("/get_current_options_flow", null, {})
      .then(res => {
        context.commit(
          "setCurrentOptionsFlow",
          JSON.parse(res.data.options_flow)
        );
        // context.commit(
        //   "setLargestCallPremiums",
        //   JSON.parse(res.data.largest_call_premiums)
        // );
        // context.commit(
        //   "setLargestPutPremiums",
        //   JSON.parse(res.data.largest_put_premiums)
        // );
        context.commit("setOptionsFlowKeyStats", res.data.key_stats);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getHistoricalOptionsFlow(context, params) {
    axios
      .get("/get_historical_options_flow", {
        params: {
          dates: params.dates,
          filters: params.filters
        }
      })
      .then(res => {
        context.commit(
          "setHistoricalOptionsFlow",
          JSON.parse(res.data.options_flow)
        );
        context.commit(
          "setLargestCallPremiums",
          JSON.parse(res.data.largest_call_premiums)
        );
        context.commit(
          "setLargestPutPremiums",
          JSON.parse(res.data.largest_put_premiums)
        );
        context.commit("setOptionsFlowKeyStats", res.data.key_stats);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowHistoricalPremiumData(context, params) {
    axios
      .get("/get_options_flow_historical_premium_data", {
        params: {
          ticker: params.ticker,
          lookback: params.lookback
        }
      })
      .then(res => {
        context.commit("setOptionsFlowHistoricalPremiumData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowByStrikeData(context, params) {
    axios
      .get("/get_options_flow_by_strike_data", {
        params: {
          ticker: params.ticker,
          lookback: params.lookback
        }
      })
      .then(res => {
        context.commit("setOptionsFlowByStrikeData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowTopPremiumsData(context, params) {
    axios
      .get("/get_options_flow_top_premiums_data", {
        params: {
          lookback: params.lookback
        }
      })
      .then(res => {
        context.commit("setOptionsFlowTopPremiumsData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowIntradayPremiumsData(context, params) {
    axios
      .get("/get_options_flow_intraday_premiums_data", {
        params: {
          interval: params.interval,
          ticker: params.ticker
        }
      })
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowIntradayPremiumsData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowIntradayPremiumsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowSeasonalityData(context, params) {
    axios
      .get("/get_options_flow_seasonality_data", {
        params: {
          ticker: params.ticker
        }
      })
      .then(res => {
        try {
          context.commit(
            "setOptionsFlowSeasonalityData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } catch {
          context.commit("setOptionsFlowSeasonalityData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowTickerMetricsData(context, params) {
    axios
      .get("/get_options_flow_ticker_metrics_data", {
        params: {
          ticker: params.ticker
        }
      })
      .then(res => {
        try {
          context.commit(
            "setOptionsFlowTickerMetricsData",
            JSON.parse(res.data)
          );
        } catch {
          context.commit("setOptionsFlowTickerMetricsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowTickerMetrics1dData(context, params) {
    axios
      .get("/get_options_flow_ticker_metrics_1d_data", {
        params: {
          ticker: params.ticker
        }
      })
      .then(res => {
        try {
          context.commit(
            "setOptionsFlowTickerMetrics1dData",
            JSON.parse(res.data)
          );
        } catch (error) {
          console.log(error);
          context.commit("setOptionsFlowTickerMetrics1dData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowIndexMetricsData(context) {
    axios
      .get("/get_options_flow_index_metrics_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowIndexMetricsData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else if (typeof res.data === "object") {
          context.commit("setOptionsFlowIndexMetricsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowIndexQuotesData(context) {
    axios
      .get("/get_options_flow_index_quotes_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit("setOptionsFlowIndexQuotesData", JSON.parse(res.data));
        } else {
          context.commit("setOptionsFlowIndexQuotesData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowLargestFlowData(context) {
    axios
      .get("/get_options_flow_largest_flow_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowLargestFlowData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowLargestFlowData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowSectorInflowData(context) {
    axios
      .get("/get_options_flow_sector_inflow_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowSectorInflowData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowSectorInflowData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowMostOtmData(context) {
    axios
      .get("/get_options_flow_most_otm_data")
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowMostOtmData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowMostOtmData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowHighOILeapsData(context) {
    axios
      .get("/get_options_flow_high_oi_leaps_data")
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowHighOILeapsData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowHighOILeapsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowDailyOverAvgPremiumsData(context) {
    axios
      .get("/get_options_flow_daily_over_avg_premiums_data")
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowDailyOverAvgPremiumsData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowDailyOverAvgPremiumsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowDailyOverAvgSizeData(context) {
    axios
      .get("/get_options_flow_daily_over_avg_size_data")
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowDailyOverAvgSizeData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowDailyOverAvgSizeData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getOptionsFlowLatestTradesData(context, params) {
    axios
      .get("/get_options_flow_latest_trades_data", {
        params: {
          ticker: params.ticker
        }
      })
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setOptionsFlowLatestTradesData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setOptionsFlowLatestTradesData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //SPY TO VIX DISTRIBUTION
  ///////////////////////////////////////////////////////////////
  getSpyToVixData(context) {
    axios
      .get("/get_spy_to_vix_data")
      .then(res => {
        //console.log(typeof res.data);
        if (typeof res.data === "string") {
          context.commit(
            "setSpyToVixData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setSpyToVixData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //TIME SERIES
  ///////////////////////////////////////////////////////////////
  getTSArimaData(context, params) {
    axios
      .get("/get_ts_arima_data", {
        params: {
          ticker: params.ticker,
          p: params.p,
          d: params.d,
          q: params.q,
          trend: params.trend,
          stationarity: params.stationarity,
          invertibility: params.invertibility,
          offset: params.offset,
          concentrate: params.concentrate
        }
      })
      .then(res => {
        context.commit("setTSArimaData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //SP500 RATIOS
  ///////////////////////////////////////////////////////////////
  getSP500RatiosData(context, params) {
    axios
      .get("/get_sp500_ratios_data", {
        params: {
          dataset: params.dataset
        }
      })
      .then(res => {
        context.commit("setSP500RatiosData", res.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  ///////////////////////////////////////////////////////////////
  //FACTOR ANALYSIS
  ///////////////////////////////////////////////////////////////
  getFactorAnalysisData(context, params) {
    axios
      .get("/get_factor_analysis_data", {
        params: {
          ticker: params.ticker,
          factor: params.factor,
          timeframe: params.timeframe
        }
      })
      .then(res => {
        var parsedData = {};
        if (typeof res.data === "string") {
          parsedData = JSON.parse(res.data.replace(/\bNaN\b/g, "null"));
        } else {
          parsedData = res.data;
        }
        if (Object.keys(parsedData).length > 0) {
          context.commit("setFactorAnalysisData", {
            factor_statistics_data: JSON.parse(
              parsedData["factor_statistics_data"]
            ),
            past_signals_data: JSON.parse(parsedData["past_signals_data"]),
            past_signals_returns_data: parsedData["past_signals_returns_data"],
            past_signals_return_hist_data:
              parsedData["past_signals_return_hist_data"],
            last_signal_data: parsedData["last_signal_data"],
            predictions_data: parsedData["predictions_data"],
            cumulative_returns_data: parsedData["cumulative_returns_data"]
          });
        } else {
          context.commit("setFactorAnalysisData", {});
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  getSignalsData(context) {
    axios
      .get("/get_signals_data")
      .then(res => {
        if (typeof res.data === "string") {
          context.commit(
            "setSignalsData",
            JSON.parse(res.data.replace(/\bNaN\b/g, "null"))
          );
        } else {
          context.commit("setSignalsData", res.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};

//export store module
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations
});
