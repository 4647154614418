/* eslint-disable */
<template>
  <div class="test-table-container">
    <OptionsFlowTableComponent
      :data="most_otm.most_otm_data"
      :initalSortOrder="'otm'"
      :columns="[
        { label: 'Date', field: 'date', breakpoint: 3 },
        { label: 'Time', field: 'time', breakpoint: 1 },
        { label: 'Ticker', field: 'ticker', class: 'ticker' },
        { label: 'C/P', field: 'put_call' },
        { label: 'Strike', field: 'strike_price', prepend: '$' },
        { label: 'DTE', field: 'DTE', breakpoint: 1 },
        { label: 'Fill', field: 'fill', breakpoint: 1 },
        { label: 'Price', field: 'price', breakpoint: 1 },
        { label: 'Size', field: 'size', breakpoint: 2 },
        { label: 'Type', field: 'option_activity_type', breakpoint: 2 },
        { label: 'OI', field: 'open_interest', breakpoint: 2 },
        { label: 'Volume', field: 'volume', breakpoint: 3 },
        { label: 'Score', field: 'score', breakpoint: 2 },
        {
          label: 'Premium',
          field: 'cost_basis',
          format: 'thousands',
          prepend: '$',
          append: 'K'
        },
        {
          label: 'OTM By',
          field: 'otm',
          format: 'decimal_0',
          append: '%'
        }
      ]"
    ></OptionsFlowTableComponent>
    <!-- <Table :data="most_otm.most_otm_data">
      <TableColumn field="date" label="Date" v-slot="props">{{
        props.row.date
      }}</TableColumn>
      <TableColumn
        field="ticker"
        label="Ticker"
        _class="large"
        v-slot="props"
        breakpoint="1"
        >{{ props.row.ticker }}</TableColumn
      >
      <TableColumn field="put_call" label="C/P" v-slot="props">{{
        props.row.put_call
      }}</TableColumn>
      <TableColumn
        field="strike_price"
        label="Strike"
        prepend="$"
        v-slot="props"
        breakpoint="1"
        >{{ props.row.strike_price }}</TableColumn
      >

      <TableColumn field="DTE" label="DTE" v-slot="props" breakpoint="1">{{
        props.row.DTE
      }}</TableColumn>
      <TableColumn field="fill" label="Fill" v-slot="props">{{
        props.row.fill
      }}</TableColumn>
      <TableColumn field="option_activity_type" label="Type" v-slot="props">{{
        props.row.option_activity_type
      }}</TableColumn>
      <TableColumn
        field="cost_basis"
        label="Premiums"
        filter="thousands"
        v-slot="props"
        append="K"
        prepend="$"
        >{{ props.row.cost_basis }}</TableColumn
      >
      <TableColumn
        field="otm"
        label="Otm By"
        filter="decimal_0"
        append="%"
        v-slot="props"
        >{{ props.row.otm }}</TableColumn
      >
    </Table> -->
  </div>
</template>

<script>
//import Table from "@/components/table/Table.vue";
//import TableColumn from "@/components/table/TableColumn.vue";
import OptionsFlowTableComponent from "@/components/OptionsFlowTableComponent.vue";
import { mapGetters } from "vuex";
export default {
  name: "Test",
  data() {
    return {};
  },
  components: {
    OptionsFlowTableComponent
    //Table,
    //TableColumn
  },
  computed: {
    ...mapGetters({ most_otm: "getOptionsFlowMostOtmData" })
  },
  mounted() {
    this.$store.dispatch({
      type: "getOptionsFlowMostOtmData"
    });
  },
  watch: {
    most_otm: function() {}
  }
};
</script>
<style lang="scss" scoped>
.test-table-container {
  width: 90%;
  margin: 0 auto;
}
</style>
