<template>
  <div class="profile">
    <div class="user">
      <h1>User Info</h1>
      <div class="user_field-container">
        <div class="user_field">Username:</div>
        <div v-if="Object.keys(user).length >= 1" class="user_field">
          {{ user.username }}
        </div>
        <div class="user_field">Email:</div>
        <div v-if="Object.keys(user).length >= 1" class="user_field">
          {{ user.email }}
        </div>
      </div>
      <h1>Subscription</h1>
      <div class="user_field-container">
        <div class="user_field">Subscription:</div>
        <div v-if="Object.keys(user).length >= 1" class="user_field">
          {{ capitalized(user.subscription) }}
        </div>
        <div
          v-if="Object.keys(user).length >= 1 && user.subscription == 'basic'"
          class="user_field"
        >
          <div class="user_field link" @click="manageSubscription">Manage</div>
        </div>
      </div>
      <h1>Delete Account</h1>
      <div class="user_field-container">
        <div class="user_field link" @click="delete_account">
          Delete
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import { mapGetters } from "vuex";
import { eventBus } from "../main";
export default {
  name: "Profile",
  mixins: [utils],
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "getUserInfo",
      alertMessage: "getAlertMessage",
      stripeSessionUrl: "get_stripe_session_url"
    })
  },
  methods: {
    delete_account: function() {
      this.$store.commit("setAlertShow", true);
      this.$store.commit(
        "setAlertMessage",
        "Are you sure you want to delete your account? Please cancel any paid subscription before you proceed."
      );
      this.$store.commit("setAlertButton", "Delete");
      this.$store.commit("setAlertCancel", true);
    },
    manageSubscription: function() {
      this.$store.dispatch({
        type: "manageSubscription"
      });
    },
    capitalized: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },

  watch: {
    stripeSessionUrl: function() {
      window.location.href = this.stripeSessionUrl;
    }
  },
  created() {},
  mounted() {
    if (window.Stripe == undefined) {
      this.loadScript("https://js.stripe.com/v3").then(s => {
        document.body.appendChild(s);
      });
    }
    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    eventBus.$on("closingAlert", () => {
      if (
        this.alertMessage ==
        "Are you sure you want to delete your account? Please cancel any paid subscription before you proceed."
      ) {
        this.$store.dispatch({
          type: "deleteUser"
        });
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.profile {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 70%;
  background-color: var(--app-components-background-color);
  padding: 50px;
  margin: 0 auto;
  font-family: Nunito;
  color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  & h1 {
    font-size: 23px;
    margin-bottom: 10px;
  }
  & h1:not(:first-child) {
    margin-top: 15px;
  }
}

.user_field-container {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, max-content));
  grid-template-rows: auto;
  justify-items: start;
}

.user_field-container .link {
  color: #fff;
  cursor: pointer;
  &:hover {
    color: var(--amaranth);
  }
}

@media only screen and (max-width: 850px) {
  .user {
    width: 90%;
  }
}
</style>
