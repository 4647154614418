<template>
  <div class="ads-container">
    <div class="ads">
      <!-- <span class="close" @click="close">&#10006;</span> -->
      <div class="header">UNUSUAL OPTIONS FLOW</div>
      <div class="sub-header">
        Follow smart money with large options trades data
      </div>
      <i class="mdi mdi-finance"></i>
      <div class="body">
        ONLY $39 per month.
      </div>
      <div class="ads_buttons">
        <button @click="sub">SIGN UP NOW</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Ads",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({})
  },
  methods: {},
  mounted() {},
  watch: {}
};
</script>
<style lang="scss" scoped>
.ads {
  padding: 10px;
  border: solid 5px;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to right, #ff0062, rgb(240, 116, 250));
  margin: 10px;
  //background: linear-gradient(250deg, #000000 10%, rgb(13, 12, 14) 90%);
}
.ads .header {
  color: transparent;
  font-size: 45px;
  font-family: Chivo-Regular;
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(90deg, #ff0040 10%, rgb(169, 44, 253) 90%);
  //background-image: linear-gradient(270deg, #f582ff 20%, #767aff 80%);
}

.ads .sub-header {
  font-size: 30px;
  color: #fff;
  font-family: Nunito;
}

.ads .body {
  font-size: 20px;
  color: #fff;
}

.ads .coupon {
  font-size: 20px;
  font-family: Chivo-Regular;
  color: #f50e67;
}

.ads .mdi {
  font-size: 70px;
  color: #ff0062;
}

.ads button {
  display: inline-block;
  box-sizing: border-box;
  margin: 10px 8px 20px 8px;
  background-color: transparent;
  padding: 8px 18px;
  font-family: var(--main-font);
  font-weight: 300;
  color: #fff;
  font-size: 25px;
  border: none;
  //border-radius: 3px;
  //box-shadow: 0 -1px 0 var(--purple-darker) inset;
  height: 38px;
}
</style>
