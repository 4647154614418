<template>
  <div class="clusters-info">
    <div class="name">Cluster Info</div>
    <div class="clusters-info-container">
      <div class="clusters-info-element-container">
        <div>{{ date }}</div>
        <div>
          Current Cluster:
          <div class="cluster-tag">
            {{ currentCluster }}
            <div
              class="cluster-color"
              v-bind:style="{
                'background-color': this.clustersDailyColors[
                  parseInt(currentCluster)
                ]
              }"
            ></div>
          </div>
        </div>
      </div>
      <div class="clusters-info-element-container">
        <div>Average Forward Returns</div>
        <div class="grid-container">
          <div class="grid-item">
            1D:
          </div>
          <div class="grid-item">{{ this.d1 | formatReturns }}%</div>
          <div class="grid-item">
            2D:
          </div>
          <div class="grid-item">{{ this.d2 | formatReturns }}%</div>
          <div class="grid-item">
            5D:
          </div>
          <div class="grid-item">{{ this.d5 | formatReturns }}%</div>
          <div class="grid-item">
            10D:
          </div>
          <div class="grid-item">{{ this.d10 | formatReturns }}%</div>
          <div class="grid-item">
            20D:
          </div>
          <div class="grid-item">{{ this.d20 | formatReturns }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ClustersInfo",
  data() {
    return {
      currentCluster: "",
      currentClusterData: "",
      color: "",
      d1: 0,
      d2: 0,
      d5: 0,
      d10: 0,
      d20: 0
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      clustersDailyData: "getClustersDailyData",
      clustersDailyColors: "getClustersDailyColors",
      clustersAverageData: "getClustersAverageData"
    }),
    date: function() {
      return this.currentClusterData[this.currentClusterData.length - 3];
    }
  },
  watch: {
    clustersDailyData: function() {
      this.currentClusterData = this.clustersDailyData[
        this.clustersDailyData.length - 1
      ];
      this.currentCluster = this.currentClusterData[
        this.currentClusterData.length - 1
      ];
      this.d1 =
        this.clustersAverageData["clusters_average_returns"][
          "1D Forward Return"
        ][parseInt(this.currentCluster)] * 100;
      this.d2 =
        this.clustersAverageData["clusters_average_returns"][
          "2D Forward Return"
        ][parseInt(this.currentCluster)] * 100;
      this.d5 =
        this.clustersAverageData["clusters_average_returns"][
          "5D Forward Return"
        ][parseInt(this.currentCluster)] * 100;
      this.d10 =
        this.clustersAverageData["clusters_average_returns"][
          "10D Forward Return"
        ][parseInt(this.currentCluster)] * 100;
      this.d20 =
        this.clustersAverageData["clusters_average_returns"][
          "20D Forward Return"
        ][parseInt(this.currentCluster)] * 100;
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
.clusters-info {
  background-color: var(--app-components-background-color);
  color: #ffffff;
  min-width: 20%;
  width: 30%;
  max-width: 100%;
  font-family: var(--main-font);
  flex-grow: 1;
  padding-top: 5px;
}

.clusters-info-container {
  display: flex;
  flex-direction: row;
}

.clusters-info-element-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.cluster-tag {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.cluster-color {
  height: 14px;
  width: 21px;
  border-radius: 2px;
  margin-left: 5px;
}

.name {
  font-size: 23px;
  padding: 10.5px 10.5px 10px 0px;
  height: 39px;
  width: 100%;
  text-align: center;
  box-sizing: content-box;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  grid-auto-flow: row;
}

.grid-item {
  width: 100%;
  min-width: 50px;
  text-align: left;
  white-space: nowrap;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1230px) {
  .clusters-info {
    padding: 15px;
  }
  // .name {
  //   position: relative;
  //   left: 25%;
  // }

  .clusters-info-container {
    justify-content: center;
  }
  .clusters-info-element-container {
    width: 50%;
    flex-grow: 0;
  }
}
</style>
