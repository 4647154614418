<template>
  <div class="dark-pools">
    <h1 class="page-title">Dark Pools</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
      <DarkPoolInfo
        v-bind:darkPoolIndividualData="darkPoolIndividualData"
      ></DarkPoolInfo>
    </div>
    <div class="price-chart-container">
      <DarkPoolsVolumeChart
        v-bind:darkPoolIndividualData="darkPoolIndividualData"
      ></DarkPoolsVolumeChart>
    </div>
    <div class="table-control-container">
      <div class="table-control-sub-container">
        <b-tag type="is-dark" size="is-medium" class="datatable-tag"
          >Select:
        </b-tag>
        <div class="select-min-max">
          <b-radio v-model="minmax" name="name" native-value="asc">
            Min
          </b-radio>
          <b-radio v-model="minmax" name="name" native-value="desc">
            Max
          </b-radio>
        </div>
      </div>
      <div class="button-container">
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Short Volume', true)"
          v-if="largeScreen"
        >
          Short Volume
        </button>
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Short Volume %', true)"
          :visible="false"
        >
          Short Volume %
        </button>
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Net Short Volume', true)"
        >
          Net Short Vol.
        </button>
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Net Short Volume $', true)"
          v-if="largeScreen"
        >
          Net Short Vol. $
        </button>
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Dark Pools Position', true)"
        >
          DP Position
        </button>
        <button
          class="datatable-button"
          v-on:click="getDarkPoolData('Dark Pools Position $', true)"
        >
          DP Position $
        </button>
      </div>
    </div>
    <div class="table-container">
      <b-table
        class="datatable"
        :data="darkDoolData"
        :striped="true"
        :hoverable="true"
        :paginated="true"
        :mobile-cards="false"
        :per-page="15"
        :default-sort="selectMinmax"
        @click="tableClick"
        sortable
        ref="table"
      >
        <b-table-column field="Ticker" label="Ticker" sortable v-slot="props">
          {{ props.row.Ticker }}
        </b-table-column>
        <b-table-column field="Date" label="Date" v-slot="props">
          {{ props.row.Date }}
        </b-table-column>
        <b-table-column
          field="Short Volume"
          label="Short Vol."
          sortable
          v-slot="props"
        >
          {{ props.row["Short Volume"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="Short Volume %"
          label="Short Vol. %"
          :visible="largeScreen"
          sortable
          v-slot="props"
        >
          {{ props.row["Short Volume %"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="Net Short Volume"
          label="Net Short Vol."
          sortable
          v-slot="props"
        >
          {{ props.row["Net Short Volume"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="Net Short Volume $"
          label="Net Short Vol. $"
          sortable
          v-slot="props"
        >
          {{ props.row["Net Short Volume $"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="Dark Pools Position"
          label="DP Position"
          :visible="largeScreen"
          sortable
          v-slot="props"
        >
          {{ props.row["Dark Pools Position"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="Dark Pools Position $"
          label="DP Position $"
          sortable
          v-slot="props"
        >
          {{ props.row["Dark Pools Position $"] | formatNumber }}
        </b-table-column>
      </b-table>
      <b-loading
        :is-full-page="true"
        v-model="loading"
        :can-cancel="true"
      ></b-loading>
    </div>
    <div class="price-chart-container">
      <DarkPoolsVolumePercentChart
        v-bind:darkPoolIndividualData="darkPoolIndividualData"
      ></DarkPoolsVolumePercentChart>
    </div>
    <div class="table-control-container closer">
      <b-tag
        type="is-dark"
        size="is-medium"
        class="datatable-tag"
        id="ticker_tag"
        >{{ ticker }} short volume table
      </b-tag>
    </div>
    <div class="table-container" id="second-table">
      <b-table
        class="datatable"
        :data="individualShortVolumeTable"
        :striped="true"
        :hoverable="true"
        :paginated="true"
        :mobile-cards="false"
        :per-page="15"
        :default-sort="['date', 'desc']"
        sortable
        ref="individualTable"
      >
        <b-table-column field="ticker" label="Ticker" v-slot="props">
          {{ props.row.ticker }}
        </b-table-column>
        <b-table-column field="date" label="Date" sortable v-slot="props">
          {{ props.row.date }}
        </b-table-column>
        <b-table-column
          field="short_volume"
          label="Short Volume"
          sortable
          v-slot="props"
        >
          {{ props.row["short_volume"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="short_volume%"
          label="Short Volume %"
          sortable
          v-slot="props"
        >
          {{ props.row["short_volume%"] | formatDecimal }}
        </b-table-column>
        <b-table-column
          field="short_exempt_volume"
          label="Short Exempt Volume"
          sortable
          v-slot="props"
        >
          {{ props.row["short_exempt_volume"] | formatNumber }}
        </b-table-column>
        <b-table-column
          field="total_volume"
          label="Total Volume"
          sortable
          v-slot="props"
        >
          {{ props.row["total_volume"] | formatNumber }}
        </b-table-column>
        <b-table-column field="market" label="Market" v-slot="props">
          {{ props.row["market"] }}
        </b-table-column>
      </b-table>
      <b-loading
        :is-full-page="true"
        v-model="loading"
        :can-cancel="true"
      ></b-loading>
    </div>

    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      Short volume represents the aggregate volume by security for all short
      sale trades executed and reported to a TRF, the ADF, or the ORF as
      reported by <a href="https://www.finra.org/#/" target="_blank">FINRA</a>.
      Although it may seem noisy and a rather poor factor on its own, it has
      been shown by
      <a href="https://squeezemetrics.com" target="_blank">SqueezeMetrics</a>
      that higher short sale volume tends to lead to abnormal positive returns.
      The argument is that short sale volume mostly represents shares sold short
      by market markers to investors buying stocks. These investors are referred
      to as Dark Pools since the FINRA TRF trades are from off-exchange venues.
      More details can be found in their
      <a
        href="https://squeezemetrics.com/monitor/download/pdf/short_is_long.pdf?"
        target="_blank"
        >white paper</a
      >. <br /><br />
      The goal of this page is to show the cumulative net short dollar volume
      (Dark Pool Position $) by security. Is it calculated by taking the net
      short volume (short volume minus buy volume - a positive number means that
      the short volume was higher than the buy volume) times the closing price
      and taking the 20-day cumulative sum of this series. A positive position
      or position $ would be bullish, and a negative one bearish.
      <br /><br />
      <h1><big>Key definitions:</big></h1>
      <br />
      <span class="key">Net Short Volume</span>: Short volume minus buy volume.
      <br />
      <span class="key">Net Short Volume $</span>: Net short volume multiplied
      by the last close.
      <br />
      <span class="key">Position</span>: The sum of the net short volume over
      the last 20 trading days.
      <br />
      <span class="key">Position $</span>: Position multiplied by the last
      close. <br /><br />

      Data updated at 6pm EST.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TickerInfo from "@/components/TickerInfo.vue";
import DarkPoolsVolumeChart from "@/components/DarkPoolsVolumeChart.vue";
import DarkPoolsVolumePercentChart from "@/components/DarkPoolsVolumePercentChart.vue";
import DarkPoolInfo from "@/components/DarkPoolInfo.vue";

export default {
  name: "DarkPools",
  data() {
    return {
      darkDoolData: [],
      darkPoolIndividualData: {},
      individualShortVolumeTable: [],
      ticker: "",
      loading: true,
      selectMinmax: ["Dark Pools Position $", "desc"],
      minmax: "desc",
      largeScreen: true,
      windowWidth: window.innerWidth
    };
  },
  head: {
    title: {
      inner: "Dark Pools"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Dark Pools data as reported by FINRA. Find stocks with the highest short volume."
      }
    ]
  },
  methods: {
    getDarkPoolData(top = "Dark Pools Position $", select = false) {
      this.loading = true;
      this.selectMinmax[0] = top;
      this.selectMinmax[1] = this.minmax;

      axios
        .get("/get_dark_pool_data", {
          params: {
            top: top,
            minmax: this.minmax
          }
        })
        .then(res => {
          this.darkDoolData = res.data.data;
          //If the request was not triggered by a url route (select == true)
          //the ticker is the ticker from the first row returned. Otherwise, it is the param ticker of the URL.
          if (
            typeof this.$router.history.current.params["ticker"] ===
              "undefined" ||
            select == true
          ) {
            this.ticker = this.darkDoolData[0]["Ticker"];
          } else {
            this.ticker = this.$router.history.current.params[
              "ticker"
            ].toUpperCase();
          }
          this.getDarkPoolIndividualData(this.ticker);
          this.$refs["table"].initSort();
        })
        .catch(err => {
          console.log(err);
        });
    },
    getDarkPoolIndividualData(ticker) {
      axios
        .get("/get_dark_pool_individual_data", {
          params: {
            ticker: ticker
          }
        })
        .then(res => {
          this.darkPoolIndividualData = res.data;
          this.individualShortVolumeTable =
            res.data["individual_short_volume_table"].data;
          this.$refs["individualTable"].initSort();
          this.loading = false;
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateTickerData(ticker) {
      this.ticker = ticker;
      this.loading = true;
      this.getDarkPoolIndividualData(this.ticker);
    },
    tableClick(event) {
      this.updateTickerData(event.Ticker);
    },
    set_breakpoints: function() {
      if (this.windowWidth < 1100) {
        this.largeScreen = false;
      } else {
        this.largeScreen = true;
      }
    }
  },
  components: {
    TickerInfo,
    DarkPoolsVolumeChart,
    DarkPoolsVolumePercentChart,
    DarkPoolInfo
  },
  mounted() {
    this.getDarkPoolData();
    this.windowWidth = window.innerWidth;
    this.set_breakpoints();
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  watch: {
    windowWidth: function() {
      this.set_breakpoints();
    }
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.price-chart-container,
.table-control-container,
.table-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.table-control-container {
  display: flex;
  align-items: center;
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  margin-top: 15px;
}

.table-control-container.closer {
  margin-top: 18px;
}

.table-control-sub-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.button-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.table-container {
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.table-container {
  overflow: hidden !important;
}

.select-min-max {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0px 8px;
}

.b-radio.radio {
  width: 70px;
  margin: 8px 10px;
}

#second-table {
  margin-top: 13px;
}

.key {
  font-size: 16px;
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .price-chart-container,
  .table-control-container,
  .table-container,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .price-chart-container,
  .table-control-container,
  .table-container,
  .ticker-info-container {
    width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  // .datatable-button {
  //   height: 48px;
  //   width: 180px;
  // }
  .table-control-sub-container {
    flex-wrap: wrap;
  }
  .ticker-info-container {
    flex-wrap: wrap;
  }
}
</style>
