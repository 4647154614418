import { render, staticRenderFns } from "./TSProbabilityChart.vue?vue&type=template&id=1b8da7df&scoped=true&"
import script from "./TSProbabilityChart.vue?vue&type=script&lang=js&"
export * from "./TSProbabilityChart.vue?vue&type=script&lang=js&"
import style0 from "./TSProbabilityChart.vue?vue&type=style&index=0&id=1b8da7df&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8da7df",
  null
  
)

export default component.exports