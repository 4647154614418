<template>
  <div class="clusters">
    <h1 class="page-title">Clusters / Single Stock</h1>
    <div class="ticker-info-container">
      <TickerInfo
        v-bind:tickerOrigin="ticker"
        @updateTickerData="updateTickerData"
      ></TickerInfo>
    </div>
    <div class="cluster-control-container">
      <ClustersControl
        v-bind:ticker="ticker"
        @gettingClustersData="gettingClustersData"
      ></ClustersControl>
      <ClustersInfo> </ClustersInfo>
    </div>

    <div class="price-chart-container">
      <ClustersPredictionChart></ClustersPredictionChart>
    </div>

    <div class="price-chart-container">
      <ClustersDailyChart></ClustersDailyChart>
    </div>
    <div class="price-chart-container">
      <ClustersAverageChart></ClustersAverageChart>
    </div>
    <div class="price-chart-container">
      <ClustersBoxChart></ClustersBoxChart>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>What is this?</big></h1>
      <br />
      Clustering is an unsupervised machine learning algorithm that seeks to
      detect similarities between samples in a dataset. There are several
      clustering algorithms. The focus of this page is on
      <a href="https://en.wikipedia.org/wiki/K-means_clustering"
        >k-means clustering</a
      >. <br /><br />
      As implemented here, this algorithm takes moving windows of daily returns
      and/or trailing volatilities, and then groups them based on their
      similarities. Afterward, the forward returns distributions can be analyzed
      to detect potentially profitable setups. Instead of looking for classic
      patterns such as double bottoms, wedges, flags, etc..., the k-means
      algorithm tries to classify ALL patterns into clusters. The average
      forward returns then tell us the expected return for each cluster over
      different time horizons. The forward returns are simply the historical
      average returns for each cluster.
      <br /><br />
      <h1><big>How to use it?</big></h1>
      <br />
      It is straightforward to use this tool. For every stock, the
      <i>Cluster Info</i>
      section will tell you in which cluster the stock currently is, as well as
      its average returns over 1, 2, 5, 10, and 20 days. You can then confirm
      that the average return for your time horizon is in line with your bias
      (bullish/bearish) and expected return. For instance, if you look at the
      SPY, you will see that the most frequent cluster has low average returns,
      and would not be the ideal pattern for buying naked options. Most large
      moves, both to the upside and the downside, are concentrated in a few
      clusters. You can look for these patterns/clusters to maximize your
      chances of profit.

      <br /><br />
      <h1><big>Available parameters are:</big></h1>
      <br />
      Pattern Length: Number of days in the pattern.
      <br />
      Metric: Metric to be used for both cluster assignment and barycenter
      computation.
      <br />
      Features: Features to be used for clustering.
      <br />
      Iterations: Maximum number of iterations of the k-means algorithm.
      <br />
      Training Window: The number of historical data points to be included.
      <br /><br />

      Currently, DTW and SoftDTW are not available because of their high
      computational requirements.
    </div>
  </div>
</template>

<script>
import TickerInfo from "@/components/TickerInfo.vue";
import ClustersPredictionChart from "@/components/ClustersPredictionChart.vue";
import ClustersAverageChart from "@/components/ClustersAverageChart.vue";
import ClustersDailyChart from "@/components/ClustersDailyChart.vue";
import ClustersBoxChart from "@/components/ClustersBoxChart.vue";
import ClustersControl from "@/components/ClustersControl.vue";
import ClustersInfo from "@/components/ClustersInfo.vue";
import { mapGetters } from "vuex";

export default {
  name: "Clusters",
  data() {
    return {
      ticker: "SPY",
      loading: true
    };
  },
  head: {
    title: {
      inner: "Clusters"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Stock market clusters and forward returns. Detect profitable trades analysing similarities between stock patterns."
      }
    ]
  },
  methods: {
    updateTickerData(ticker) {
      this.ticker = ticker;
    },
    gettingClustersData() {
      this.loading = true;
    }
  },
  computed: {
    ...mapGetters({
      clustersDailyData: "getClustersDailyData"
    })
  },
  components: {
    TickerInfo,
    ClustersPredictionChart,
    ClustersAverageChart,
    ClustersDailyChart,
    ClustersBoxChart,
    ClustersControl,
    ClustersInfo
  },
  mounted() {
    //If the url param is not empty, the ticker will be equal to the ticker passed in the url
    if (typeof this.$router.history.current.params["ticker"] != "undefined") {
      this.ticker = this.$router.history.current.params["ticker"].toUpperCase();
    }
    this.$store.dispatch({
      type: "getClustersData",
      ticker: this.ticker,
      nbClusters: 8,
      length: 10,
      metric: "euclidean",
      iterations: 10,
      features: "pcvol",
      trainingWindow: 400
    });
  },
  watch: {
    clustersDailyData: function() {
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.text-content,
.price-chart-container,
.cluster-control-container {
  min-width: 320px;
  margin: 0 auto;
  margin-top: 15px;
  width: 70%;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.cluster-control-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
}

.ticker-info {
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ticker-info-container {
  min-width: 320px;
}

@media only screen and (max-width: 1230px) {
  .cluster-control-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1250px) {
  .text-content,
  .price-chart-container,
  .cluster-control-container,
  .ticker-info-container {
    width: 80%;
  }
}

@media only screen and (max-width: 1000px) {
  .text-content,
  .price-chart-container,
  .cluster-control-container,
  .ticker-info-container {
    width: 95%;
  }
}
</style>
