<template>
  <div class="custom-pagination">
    <nav class="pages">
      <ul>
        <li
          class="page-link"
          @click="navigateTo(1)"
          :class="currentPage == 1 ? 'current-page' : ''"
        >
          <a role="button">1</a>
        </li>
        <li v-show="leftEllipsis" class="page-link"><span>&hellip;</span></li>
        <li
          class="page-link"
          v-for="page in pageRange"
          :key="page"
          @click="navigateTo(page)"
          :class="currentPage == page ? 'current-page' : ''"
        >
          <a role="button">{{ page }}</a>
        </li>
        <li v-show="rigthEllipsis" class="page-link"><span>&hellip;</span></li>
        <li
          v-show="getPage('last') != 0 && getPage('last') != 1"
          class="page-link"
          @click="navigateTo(getPage('last'))"
          :class="currentPage == getPage('last') ? 'current-page' : ''"
        >
          <a>{{ getPage("last") }}</a>
        </li>
      </ul>
      <div class="arrows-container">
        <a class="page-link" @click="incrementCurrentPage(-1)"
          ><i class="mdi mdi-chevron-left"></i
        ></a>
        <a class="page-link" @click="incrementCurrentPage(1)"
          ><i class="mdi mdi-chevron-right"></i
        ></a>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  data() {
    return {
      currentPage: 1
    };
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: false,
      default: 20
    }
  },
  methods: {
    getPage: function(position) {
      if (position === "last") {
        return this.maxPage;
      }
    },
    incrementCurrentPage: function(increment) {
      this.currentPage += increment;
      if (this.currentPage > this.maxPage) {
        this.currentPage = this.maxPage;
      } else if (this.currentPage < 1) {
        this.currentPage = 1;
      }
      this.$emit("input", this.currentPage);
      this.$emit("change", this.currentPage);
    },
    navigateTo: function(page) {
      this.currentPage = page;
      this.$emit("input", this.currentPage);
      this.$emit("change", this.currentPage);
    }
  },
  computed: {
    pageRange: function() {
      let left = Math.max(2, this.currentPage - 1);
      if (left - 1 === 2) {
        left--;
      }
      let right = Math.min(this.currentPage + 1, this.maxPage - 1);
      if (this.maxPage - right === 2) {
        right++;
      }
      const pages = [];
      for (let i = left; i <= right; i++) {
        pages.push(i);
      }
      return pages;
    },
    maxPage: function() {
      return Math.ceil(this.total / this.perPage);
    },
    leftEllipsis: function() {
      return this.currentPage > 4;
    },
    rigthEllipsis: function() {
      return this.maxPage - this.currentPage > 3;
    }
  },
  watch: {
    total: function() {
      this.navigateTo(1);
    }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link {
  display: flex;
  box-sizing: content-box;
  width: 45px;
  height: 45px;
  border: 1px solid rgb(136, 77, 245);
  color: rgb(136, 77, 245);
  justify-content: center;
  align-items: center;
  margin: 2px;
  border-radius: 4px;
  &:hover {
    border: 1px solid rgb(92, 47, 175);
  }
  &:hover a {
    color: rgb(92, 47, 175);
  }
}

.current-page {
  background-color: rgb(136, 77, 245);
  > a {
    color: rgb(36, 36, 36) !important;
  }
}

a {
  color: rgb(136, 77, 245);
  &:hover {
    color: rgb(92, 47, 175);
  }
}

nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

li:hover {
  cursor: pointer;
}

.arrows-container {
  display: flex;
}

@media only screen and (max-width: 550px) {
  nav {
    flex-direction: column;
  }
  a {
    flex-grow: 0.3;
  }
  .arrows-container {
    flex-grow: 1;
    justify-content: center;
  }
}
</style>
