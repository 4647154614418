var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp500-ratios"},[_c('h1',{staticClass:"page-title"},[_vm._v("S&P 500 Statistics")]),_c('div',{staticClass:"select-component"},[_c('SelectComponent',{attrs:{"options":_vm.options(),"default":{
        value: 'sp500_earnings_month',
        label: 'S&P 500 PE Ratio by Month'
      },"maxHeight":'393px'},on:{"input":_vm.selectEvent}})],1),_c('div',{staticClass:"chart-container"},[_c('SP500RatiosChart',{attrs:{"data":_vm.sp500RatiosData,"title":_vm.selected.label}})],1),_vm._m(0),_c('div',{staticClass:"stats-container"},[_vm._l((_vm.sp500RatiosData['stats']),function(stats,i){return [_c('div',{key:i,staticClass:"stats-item"},[_vm._v(" "+_vm._s(i.charAt(0).toUpperCase() + i.slice(1))+": ")]),_c('div',{key:stats,staticClass:"stats-item"},[_vm._v(_vm._s(stats))])]})],2),_vm._m(1),_c('div',{staticClass:"table-container"},[_c('TableComponent',{attrs:{"data":_vm.jsonData,"paginated":"","columns":[
        { label: 'Date', field: 'dates' },
        { label: 'Value', field: 'data' },
        { label: 'Change', field: 'change' },
        { label: 'Change %', field: 'changePct' }
      ]}})],1),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":true},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-container"},[_c('div',{staticClass:"custom-tag"},[_vm._v("Statistics")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tag-container"},[_c('div',{staticClass:"custom-tag"},[_vm._v("All Values")])])}]

export { render, staticRenderFns }