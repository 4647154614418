<template>
  <div class="sp500-ratios">
    <h1 class="page-title">S&P 500 Statistics</h1>
    <div class="select-component">
      <SelectComponent
        :options="options()"
        :default="{
          value: 'sp500_earnings_month',
          label: 'S&P 500 PE Ratio by Month'
        }"
        :maxHeight="'393px'"
        @input="selectEvent"
      ></SelectComponent>
    </div>
    <div class="chart-container">
      <SP500RatiosChart
        :data="sp500RatiosData"
        :title="selected.label"
      ></SP500RatiosChart>
    </div>
    <div class="tag-container">
      <div class="custom-tag">Statistics</div>
    </div>
    <div class="stats-container">
      <template v-for="(stats, i) in sp500RatiosData['stats']">
        <div class="stats-item" :key="i">
          {{ i.charAt(0).toUpperCase() + i.slice(1) }}:
        </div>
        <div class="stats-item" :key="stats">{{ stats }}</div>
      </template>
    </div>
    <div class="tag-container">
      <div class="custom-tag">All Values</div>
    </div>
    <div class="table-container">
      <TableComponent
        :data="jsonData"
        paginated
        :columns="[
          { label: 'Date', field: 'dates' },
          { label: 'Value', field: 'data' },
          { label: 'Change', field: 'change' },
          { label: 'Change %', field: 'changePct' }
        ]"
      ></TableComponent>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>

<script>
import SelectComponent from "@/components/SelectComponent.vue";
import TableComponent from "@/components/TableComponent.vue";
import SP500RatiosChart from "@/components/SP500RatiosChart.vue";
import { mapGetters } from "vuex";

export default {
  name: "SP500Ratios",
  data() {
    return {
      datasets: {
        shiller_pe_ratio_month: "Shiller PE Ratio by Month",
        shiller_pe_ratio_year: "Shiller PE Ratio by Year",
        sp500_bvps_quarter: "S&P 500 Book Value Per Share by Quarter",
        sp500_bvps_year: "S&P 500 Book Value Per Share by Year",
        sp500_div_growth_quarter: "S&P 500 Dividend Growth by Quarter",
        sp500_div_growth_year: "S&P 500 Dividend Growth by Year",
        sp500_div_month: "S&P 500 Dividend by Month",
        sp500_div_year: "S&P 500 Dividend by Year",
        sp500_div_yield_month: "S&P 500 Dividend Yield by Month",
        sp500_div_yield_year: "S&P 500 Dividend Yield by Year",
        sp500_earnings_growth_quarter:
          "S&P 500 Earnings Growth Rate by Quarter",
        sp500_earnings_growth_year: "S&P 500 Earnings Growth Rate by Year",
        sp500_earnings_month: "S&P 500 Earnings by Month",
        sp500_earnings_year: "S&P 500 Earnings by Year",
        sp500_earnings_yield_month: "S&P 500 Earnings Yield by Month",
        sp500_earnings_yield_year: "S&P 500 Earnings Yield by Year",
        sp500_infladj_month: "S&P 500 Inflation Adjusted by Month",
        sp500_infladj_year: "S&P 500 Inflation Adjusted by Year",
        sp500_pbv_ratio_quarter: "S&P 500 Price to Book Value by Quarter",
        sp500_pbv_ratio_year: "S&P 500 Price to Book Value by Year",
        sp500_pe_ratio_month: "S&P 500 PE Ratio by Month",
        sp500_pe_ratio_year: "S&P 500 PE Ratio by Year",
        sp500_psr_quarter: "S&P 500 Price to Sales Ratio by Quarter",
        sp500_psr_year: "S&P 500 Price to Sales Ratio by Year",
        sp500_real_earnings_growth_quarter:
          "S&P 500 Real Earnings Growth by Quarter",
        sp500_real_earnings_growth_year: "S&P 500 Real Earnings Growth by Year",
        sp500_real_price_month: "S&P 500 Real Price by Month",
        sp500_real_price_year: "S&P 500 Real Price by Year",
        sp500_real_sales_growth_quarter: "S&P 500 Real Sales Growth by Quarter",
        sp500_real_sales_growth_year: "S&P 500 Real Sales Growth by Year",
        sp500_real_sales_quarter: "S&P 500 Real Sales by Quarter",
        sp500_real_sales_year: "S&P 500 Real Sales by Year",
        sp500_sales_growth_quarter: "S&P 500 Sales Growth by Quarter",
        sp500_sales_growth_year: "S&P 500 Sales Growth Rate by Year",
        sp500_sales_quarter: "S&P 500 Sales by Quarter",
        sp500_sales_year: "S&P 500 Sales by Year"
      },
      selected: {
        value: "sp500_earnings_month",
        label: "S&P 500 PE Ratio by Month"
      },
      jsonData: []
    };
  },
  head: {
    title: {
      inner: "S&P 500 Statistics"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content: "Historical multiples and ratios of the S&P 500."
      }
    ]
  },
  components: {
    SelectComponent,
    TableComponent,
    SP500RatiosChart
  },
  computed: {
    ...mapGetters({
      sp500RatiosData: "getSP500RatiosData",
      loading: "getLoading"
    })
  },
  methods: {
    selectEvent: function(value) {
      this.$store.commit("setLoading", true);
      this.selected["label"] = this.datasets[value];
      this.selected["value"] = value;
      this.$store.dispatch({
        type: "getSP500RatiosData",
        dataset: value
      });
    },
    options: function() {
      return Object.keys(this.datasets).map(element => {
        return { label: this.datasets[element], value: element };
      });
    }
  },
  watch: {
    sp500RatiosData: function() {
      this.$store.commit("setLoading", false);
      this.jsonData = this.sp500RatiosData["dates"].map((el, i) => {
        return {
          dates: el,
          data: this.sp500RatiosData["data"][i],
          change: this.sp500RatiosData["change"][i],
          changePct: this.sp500RatiosData["change_pct"][i]
        };
      });
    }
  },
  mounted() {
    this.$store.commit("setLoading", true);
    this.$store.dispatch({
      type: "getSP500RatiosData",
      dataset: "sp500_pe_ratio_month"
    });
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.sp500-ratios {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.page-title,
.tag-container,
.select-component,
.table-container,
.chart-container {
  width: 80%;
  margin: 0 auto;
}

.table-container {
  margin-top: 15px;
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.select-component {
  margin-top: 20px;
}

.chart-container {
  margin-top: 8px;
}

.custom-select {
  width: 300px;
}

.tag-container {
  margin-top: 15px;
}

.custom-tag {
  background-color: var(--app-components-background-color);
  color: white;
  font-family: Nunito;
  font-size: 16px;
  border-radius: 3px;
  width: max-content;
  padding: 5px 10px;
}

.stats-container {
  display: grid;
  margin: 15px auto 0 auto;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-flow: row;
  grid-gap: 2px;
  width: 80%;
  color: white;
  padding: 0px;
  font-family: Nunito;
}

.stats-item {
  display: flex;
  justify-content: flex-start;
  padding: 5px 5px 5px 15px;
  background-color: var(--app-components-background-color);
  &:hover {
    background-color: var(--app-components-background-color-lighter);
  }
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .tag-container,
  .stats-container,
  .select-component,
  .table-container,
  .chart-container {
    width: 95%;
  }
}

@media only screen and (max-width: 450px) {
  .stats-container {
    display: grid;

    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
</style>
