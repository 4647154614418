<template>
  <div class="options-flow-intraday-premiums">
    <h1 class="page-title">Options flow / Ticker Overview</h1>
    <div class="ticker-info-container">
      <TickerInfo v-bind:tickerOrigin="ticker"></TickerInfo>
    </div>

    <div class="ticker-metrics" v-if="Object.keys(tickerMetrics1d).length > 0">
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Call Premiums</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          ${{ tickerMetrics1d.current.call_premiums | formatThousands }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          ${{ tickerMetrics1d.prior.call_premiums | formatThousands }}
          <span class="metric-period"> Prior</span>
        </div>
        <span
          :class="changeClass(tickerMetrics1d.prior.call_premiums_chg, 'call')"
          ><i :class="iconClass(tickerMetrics1d.prior.call_premiums_chg)"></i>
          {{ tickerMetrics1d.prior.call_premiums_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Put Premiums</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          ${{ tickerMetrics1d.current.put_premiums | formatThousands }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          ${{ tickerMetrics1d.prior.put_premiums | formatThousands }}
          <span class="metric-period"> Prior</span>
        </div>
        <span
          :class="changeClass(tickerMetrics1d.prior.put_premiums_chg, 'put')"
          ><i :class="iconClass(tickerMetrics1d.prior.put_premiums_chg)"></i>
          {{ tickerMetrics1d.prior.put_premiums_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Call Flows</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          {{ tickerMetrics1d.current.call_flow | formatThousands }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          {{ tickerMetrics1d.prior.call_flow | formatThousands }}
          <span class="metric-period"> Prior</span>
        </div>
        <span :class="changeClass(tickerMetrics1d.prior.call_flow_chg, 'call')"
          ><i :class="iconClass(tickerMetrics1d.prior.call_flow_chg)"></i>
          {{ tickerMetrics1d.prior.call_flow_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Put Flows</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          {{ tickerMetrics1d.current.put_flow | formatThousands }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          {{ tickerMetrics1d.prior.put_flow | formatThousands }}
          <span class="metric-period"> Prior</span>
        </div>
        <span :class="changeClass(tickerMetrics1d.prior.put_flow_chg, 'put')"
          ><i :class="iconClass(tickerMetrics1d.prior.put_flow_chg)"></i>
          {{ tickerMetrics1d.prior.put_flow_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Avg Call Score</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          {{ tickerMetrics1d.current.avg_call_score | formatDecimal3 }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          {{ tickerMetrics1d.prior.avg_call_score | formatDecimal3 }}
          <span class="metric-period"> Prior</span>
        </div>
        <span
          :class="changeClass(tickerMetrics1d.prior.avg_call_score_chg, 'call')"
          ><i :class="iconClass(tickerMetrics1d.prior.avg_call_score_chg)"></i>
          {{ tickerMetrics1d.prior.avg_call_score_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Avg Put Score</span
          ><span class="metric-period">1d</span>
        </div>
        <div class="flex-container">
          {{ tickerMetrics1d.current.avg_put_score | formatDecimal3 }}
          <span class="metric-period"> Today</span>
        </div>
        <div class="flex-container"></div>
        <div class="flex-container">
          {{ tickerMetrics1d.prior.avg_put_score | formatDecimal3 }}
          <span class="metric-period"> Prior</span>
        </div>
        <span
          :class="changeClass(tickerMetrics1d.prior.avg_put_score_chg, 'put')"
          ><i :class="iconClass(tickerMetrics1d.prior.avg_put_score_chg)"></i>
          {{ tickerMetrics1d.prior.avg_put_score_chg }}%</span
        ><span class="metric-period">Prior 1d change</span>
      </div>

      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Call Premiums</span
          ><span class="metric-period">5d</span>
        </div>
        ${{ tickerMetrics.call_premiums | formatThousands }}
        <span :class="changeClass(tickerMetrics.call_premiums_chg, 'call')"
          ><i :class="iconClass(tickerMetrics.call_premiums_chg)"></i>
          {{ tickerMetrics.call_premiums_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Put Premiums</span
          ><span class="metric-period">5d</span>
        </div>
        ${{ tickerMetrics.put_premiums | formatThousands }}
        <span :class="changeClass(tickerMetrics.put_premiums_chg, 'put')"
          ><i :class="iconClass(tickerMetrics.put_premiums_chg)"></i>
          {{ tickerMetrics.put_premiums_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Call Flows</span
          ><span class="metric-period">5d</span>
        </div>
        {{ tickerMetrics.call_flow | formatThousands }}
        <span :class="changeClass(tickerMetrics.call_flow_chg, 'call')"
          ><i :class="iconClass(tickerMetrics.call_flow_chg)"></i>
          {{ tickerMetrics.call_flow_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Put Flows</span
          ><span class="metric-period">5d</span>
        </div>
        {{ tickerMetrics.put_flow | formatThousands }}
        <span :class="changeClass(tickerMetrics.put_flow_chg, 'put')"
          ><i :class="iconClass(tickerMetrics.put_flow_chg)"></i>
          {{ tickerMetrics.put_flow_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Avg Call Score</span
          ><span class="metric-period">5d</span>
        </div>
        {{ tickerMetrics.avg_call_score | formatDecimal3 }}
        <span :class="changeClass(tickerMetrics.avg_call_score_chg, 'call')"
          ><i :class="iconClass(tickerMetrics.avg_call_score_chg)"></i>
          {{ tickerMetrics.avg_call_score_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
      <div class="metric">
        <div class="flex-container">
          <span class="metric-name">Avg Put Score</span
          ><span class="metric-period">5d</span>
        </div>
        {{ tickerMetrics.avg_put_score | formatDecimal3 }}
        <span :class="changeClass(tickerMetrics.avg_put_score_chg, 'put')"
          ><i :class="iconClass(tickerMetrics.avg_put_score_chg)"></i>
          {{ tickerMetrics.avg_put_score_chg }}%</span
        ><span class="metric-period">Over last period</span>
      </div>
    </div>

    <div class="chart-controls-container">
      <b-tag type="is-dark" size="is-medium" class="datatable-tag"
        >Interval:
      </b-tag>
      <SelectComponent
        v-model="interval"
        class="select-component"
        :default="intervals[1]"
        :options="intervals"
      >
      </SelectComponent>
    </div>
    <div class="charts-container">
      <OptionsFlowIntradayPremiumsChart></OptionsFlowIntradayPremiumsChart>
    </div>
    <div class="seasonality-charts-container">
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'intraday'"
          v-bind:putCall="'calls'"
        ></OptionsFlowSeasonalityChart>
      </div>
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'intraday'"
          v-bind:putCall="'puts'"
        ></OptionsFlowSeasonalityChart>
      </div>
    </div>
    <div class="seasonality-charts-container">
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'daily'"
          v-bind:putCall="'calls'"
        ></OptionsFlowSeasonalityChart>
      </div>
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'daily'"
          v-bind:putCall="'puts'"
        ></OptionsFlowSeasonalityChart>
      </div>
    </div>
    <div class="seasonality-charts-container">
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'monthly'"
          v-bind:putCall="'calls'"
        ></OptionsFlowSeasonalityChart>
      </div>
      <div class="seasonality-charts-sub-container">
        <OptionsFlowSeasonalityChart
          v-bind:timeframe="'monthly'"
          v-bind:putCall="'puts'"
        ></OptionsFlowSeasonalityChart>
      </div>
    </div>
    <div class="table-container">
      <div class="title-tag">Latest Trades</div>
      <OptionsFlowTableComponent
        :data="latestTrades"
        :initalSortOrder="'date'"
        :columns="[
          { label: 'Date', field: 'date', breakpoint: 3 },
          { label: 'Time', field: 'time', breakpoint: 1 },
          { label: 'Ticker', field: 'ticker', class: 'ticker' },
          { label: 'C/P', field: 'put_call' },
          { label: 'Strike', field: 'strike_price', prepend: '$' },
          { label: 'DTE', field: 'DTE', breakpoint: 1 },
          { label: 'Fill', field: 'fill', breakpoint: 1 },
          { label: 'Sentiment', field: 'sentiment' },
          // { label: 'Price', field: 'price', breakpoint: 1 },
          // { label: 'Size', field: 'size', breakpoint: 2 },
          { label: 'Type', field: 'option_activity_type', breakpoint: 2 },
          { label: 'OI', field: 'open_interest', breakpoint: 2 },
          { label: 'Volume', field: 'volume', breakpoint: 3 },
          { label: 'Score', field: 'score', breakpoint: 2 },
          {
            label: 'Premium',
            field: 'cost_basis',
            format: 'thousands',
            prepend: '$',
            append: 'K'
          }
        ]"
      ></OptionsFlowTableComponent>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="loading"
      :can-cancel="true"
    ></b-loading>
    <div class="text-content">
      <h1><big>?</big></h1>
      <br />
      The top grid contains metrics for periods of 1 and 5 days. For the 1 day
      period, it contains metrics for the current day, the prior trading day
      (penultimate), and the prior trading day 1-day change (change over the
      antepenultimate period). The 1-day change is not calculated using today's
      metrics, since it would compare a partial trading day to a full one. For
      the 5 day period, it contains metrics for the last trading week, up to the
      last trading day (excluding the current day since it is partial data). The
      change over last period represents the change over the penultimate trading
      week.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "../main";
import OptionsFlowIntradayPremiumsChart from "@/components/OptionsFlowIntradayPremiumsChart.vue";
import OptionsFlowSeasonalityChart from "@/components/OptionsFlowSeasonalityChart.vue";
import OptionsFlowTableComponent from "@/components/OptionsFlowTableComponent.vue";
import TickerInfo from "@/components/TickerInfo.vue";
const SelectComponent = () => import("@/components/SelectComponent.vue");
export default {
  name: "OptionsFlowTickerOverview",
  data() {
    return {
      interval: 1,
      intervals: [
        { value: 0, label: "1 minute" },
        { value: 1, label: "10 minutes" },
        { value: 2, label: "30 minutes" }
      ]
    };
  },
  components: {
    OptionsFlowTableComponent,
    OptionsFlowIntradayPremiumsChart,
    OptionsFlowSeasonalityChart,
    TickerInfo,
    SelectComponent
  },
  head: {
    title: {
      inner: "Ticker Overview"
    },
    meta: [
      { name: "application-name", content: "Stockgrid" },
      {
        name: "description",
        content:
          "Get an overview of the options flow activity for a specific ticker."
      }
    ]
  },
  computed: {
    ...mapGetters({
      loading: "getLoading",
      loginEvent: "getLoginEvent",
      alertMessage: "getAlertMessage",
      user: "getUserInfo",
      ticker: "getTicker",
      tickerMetrics: "getOptionsFlowTickerMetricsData",
      tickerMetrics1d: "getOptionsFlowTickerMetrics1dData",
      latestTrades: "getOptionsFlowLatestTradesData"
    })
  },
  methods: {
    init: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getOptionsFlowIntradayPremiumsData",
        interval: this.interval,
        ticker: this.ticker
      });
      this.$store.dispatch({
        type: "getOptionsFlowSeasonalityData",
        ticker: this.ticker
      });
      this.$store.dispatch({
        type: "getOptionsFlowTickerMetricsData",
        ticker: this.ticker
      });
      this.$store.dispatch({
        type: "getOptionsFlowTickerMetrics1dData",
        ticker: this.ticker
      });
      this.$store.dispatch({
        type: "getOptionsFlowLatestTradesData",
        ticker: this.ticker
      });
    },
    iconClass: function(item) {
      if (item > 0) {
        return "mdi mdi-arrow-up";
      } else if (item < 0) {
        return "mdi mdi-arrow-down";
      }
    },
    changeClass: function(item, putCall) {
      if (item > 0 && putCall == "call") {
        return "metric-change green";
      } else if (item < 0 && putCall == "call") {
        return "metric-change red";
      } else if (item > 0 && putCall == "put") {
        return "metric-change red";
      } else if (item < 0 && putCall == "put") {
        return "metric-change green";
      }
    }
  },
  watch: {
    interval: function() {
      this.$store.commit("setLoading", true);
      this.$store.dispatch({
        type: "getOptionsFlowIntradayPremiumsData",
        interval: this.interval,
        ticker: this.ticker
      });
    },
    ticker: function() {
      this.init();
    },
    user: function() {
      console.log("loginEvent", this.loginEvent, this.user);
      if (this.loginEvent == true) {
        if (this.user.subscription == "basic") {
          this.init();
        }
      }
    }
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", evt => {
      if (evt.key == "Escape") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("closingAlert", msg => {
      if (msg == "You need to subscribe to access this page.") {
        this.$store.commit("setLoading", false);
      }
    });
    eventBus.$on("alertAction", msg => {
      if (msg == "More info") {
        setTimeout(() => {
          this.$router.push({
            path: "/subscriptioncards"
          });
        }, 30);
      }
    });
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.options-flow-intraday-premiums {
  display: block;
  margin-top: 65px;
  margin-bottom: 55px;
}

.page-title,
.ticker-metrics,
.table-container,
.chart-controls-container,
.ticker-info-container,
.seasonality-charts-container,
.text-content,
.charts-container {
  width: 80%;
  margin: 0 auto;
}

.text-content {
  margin-top: 8px;
}

.seasonality-charts-container {
  margin-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.seasonality-charts-sub-container {
  width: calc(50% - 5px);
}

.chart-controls-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 8px;
}

.ticker-info-container {
  margin-top: 15px;
}

.charts-container {
  margin-top: 8px;
}

.ticker-metrics {
  display: grid;
  margin-top: 8px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 8px;
  //grid-auto-flow: row;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-container {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
  overflow: hidden;
}

.options-flow-table-container {
  margin-top: 10px;
}

.metric {
  color: #fff;
  font-family: Nunito;
  background-color: var(--app-components-background-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}

.metric-name {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 2px;
}

.metric-change {
  font-size: 13px;
  margin-left: -13px;
}

.metric-period {
  font-size: 10px;
  margin-left: 5px;
}

.green {
  color: rgb(0, 255, 234);
}

.red {
  color: rgb(252, 41, 111);
}

@media only screen and (max-width: 1250px) {
  .page-title,
  .ticker-metrics,
  .table-container,
  .chart-controls-container,
  .ticker-info-container,
  .text-content,
  .seasonality-charts-container,
  .charts-container {
    width: 80%;
  }

  .ticker-metrics {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 8px;
    //grid-auto-flow: row;
  }
}

.select-component {
  width: 200px !important;
}

@media only screen and (max-width: 1000px) {
  .page-title,
  .ticker-metrics,
  .table-container,
  .chart-controls-container,
  .ticker-info-container,
  .text-content,
  .seasonality-charts-container,
  .charts-container {
    width: 95%;
  }

  .seasonality-charts-container {
    flex-wrap: wrap;
  }

  .seasonality-charts-sub-container {
    width: 100%;
  }
}

@media only screen and (max-width: 650px) {
  .ticker-metrics {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 8px;
    //grid-auto-flow: row;
  }
}

@media only screen and (max-width: 450px) {
  .ticker-metrics {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-gap: 8px;
    //grid-auto-flow: row;
  }
}
</style>
